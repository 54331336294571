// comments associated with each annotation
class Annotation {
    constructor(desc, start, end, id, unsplash, unsplashUid, unsplashName, firebaseImg, firebaseImgName) {
        if (start === undefined || end === undefined) {
            start = null;
            end = null;
        }

        if (!unsplash) {
            unsplash = null;
            unsplashUid = null;
            unsplashName = null;
        }
        if (!unsplashUid || !unsplashName) {
            unsplashUid = null;
            unsplashName = null;
        }
        if (!firebaseImg) {
            firebaseImg = null;
            firebaseImgName = null;
        }
        this.desc = desc;
        this.start = start;
        this.end = end;
        this.id = id;
        this.unsplash = unsplash;
        this.unsplashUid = unsplashUid;
        this.unsplashName = unsplashName;
        this.firebaseImg = firebaseImg;
        this.firebaseImgName = firebaseImgName;
    }

    static annotationConverter = {
        toFirestore: function (annotation) {
            return {
                desc: annotation.desc,
                end: annotation.end,
                start: annotation.start,
                unsplash: annotation.unsplash,
                unsplashUid: annotation.unsplashUid,
                unsplashName: annotation.unsplashName,
                firebaseImg: annotation.firebaseImg,
                firebaseImgName: annotation.firebaseImgName,
            };
        },
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            return new Annotation(
                data.desc,
                data.start,
                data.end,
                snapshot.id,
                data.unsplash,
                data.unsplashUid,
                data.unsplashName,
                data.firebaseImg,
                data.firebaseImgName,
            );
        },
    };

    /**
     *  Remove image
     */
    removeImage() {
        this.unsplash = null;
        this.unsplashName = null;
        this.unsplashUid = null;
        this.firebaseImg = null;
        this.firebaseImgName = null;
    }

    /**
     * Set unsplash image stuff
     * @param {string} imgLink
     * @param {string} uid
     * @param {string} name
     */
    setUnsplashImage(imgLink, uid, name) {
        this.unsplash = imgLink;
        this.unsplashUid = uid;
        this.unsplashName = name;
        this.firebaseImg = null;
        this.firebaseImgName = null;
    }

    setFirebaseImage(filename, url) {
        this.firebaseImg = url;
        this.firebaseImgName = filename;
        this.unsplash = null;
        this.unsplashName = null;
        this.unsplashUid = null;
    }

    setDescription(newDesc) {
        this.desc = newDesc;
    }

    getDescription() {
        return this.desc;
    }

    getStartAndEnd() {
        return [this.start, this.end];
    }
}

export default Annotation;
