import React, { Component } from 'react';
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';
import Create from '../../../Icons/Create';
import BlueCheck from '../../../Icons/BlueCheck';

const AddDiv = styled.div`
    padding: 0;
    border: none;
    margin-right: 5px;
    background: none;
    outline: none;
    height: auto;
    display: flex;
    align-items: center;
`;

const ItemRow = styled.button`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
    background: ${(props) => (props.hover ? 'var(--rsSurfaceHover)' : 'none')} !important;
    border-radius: 8px;
    padding: 5px;
    align-items: center;
`;

const ItemText = styled.div`
    background: none !important;
    padding: 0;
    color: var(--rsSecondary);
`;

class AddShareButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: false,
            clicked: false,
            loading: false,
            hover: false,
        };
        this.add = this.add.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    changeButton() {
        this.setState({
            buttonDisabled: !this.state.buttonDisabled,
            clicked: true,
            hover: false,
        });
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    async add() {
        this.setState({ loading: true });
        var success = await this.props.add(this.props.itemId);
        this.setState({ loading: false });

        if (success) this.changeButton();
    }

    render() {
        return (
            <ItemRow
                className="row ghost"
                onClick={this.add}
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                disabled={this.state.buttonDisabled}
                hover={this.state.hover}
            >
                <ItemText className="col text-left">{this.props.title}</ItemText>
                {this.state.loading ? (
                    <AddDiv style={{ color: 'var(--rsPrimary)' }} className="col-sm-auto">
                        <Spinner size="sm" as="span" animation="border" role="status"></Spinner>
                    </AddDiv>
                ) : (
                    <AddDiv className="col-sm-auto">{this.state.clicked ? <BlueCheck /> : <Create size="18px" hover={this.state.hover} />}</AddDiv>
                )}
            </ItemRow>
        );
    }
}

export default AddShareButton;
