class DescriptionDict {
    constructor(id, ref, songIds) {
        this.id = id;
        this.ref = ref;
        this.songIds = songIds;
    }
    /**
     * Gets id of the subject reference
     * @return {string}
     */
    getId() {
        return this.id;
    }

    /**
     * Gets the reference of this subject.
     * @return {string}
     */
    getRef() {
        return this.ref;
    }

    getSongIds() {
        return this.songIds;
    }

    static descriptionConverter = {
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new DescriptionDict(snapshot.id, snapshot.ref, data.songIds);
        },
    };
}

export default DescriptionDict;
