import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lightbulbSvg from './lightbulb.svg';

class Lightbulb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const hover = typeof this.props.hover !== 'undefined' ? this.props.hover : this.state.hover;

        const color = this.props.disabled ? 'var(--rsDisabled)' : hover ? 'var(--rsPrimary)' : 'var(--rsSecondary)';

        const size = this.props.size ? this.props.size : '30px';

        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <img
                    src={lightbulbSvg}
                    alt="Lightbulb"
                    width={size}
                    height={size}
                    style={{
                        stroke: color,
                        strokeWidth: '2',
                    }}
                />
            </div>
        );
    }
}

Lightbulb.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default Lightbulb;
