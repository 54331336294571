import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const hover = typeof this.props.hover !== 'undefined' ? this.props.hover : this.state.hover;

        const color = this.props.color ? this.props.color : this.props.disabled ? 'var(--rsDisabled)' : hover ? 'var(--rsPrimary)' : 'var(--rsSecondary)';
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg
                    width={this.props.size ? this.props.size : '24px'}
                    height={this.props.size ? this.props.size : '24px'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                >
                    <path d="M15.44,17.5A1.8,1.8,0,0,1,14.17,17L1.28,4.08A1.8,1.8,0,0,1,3.83,1.53L16.72,14.42a1.81,1.81,0,0,1-1.28,3.08Z" fill={color} />
                    <path
                        d="M2.55,17.5a1.81,1.81,0,0,1-1.27-3.08L14.17,1.53a1.79,1.79,0,0,1,2.55,0,1.81,1.81,0,0,1,0,2.55L3.83,17A1.8,1.8,0,0,1,2.55,17.5Z"
                        fill={color}
                    />
                </svg>
            </div>
        );
    }
}

Group.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
    /**
     * A color override. If defined, hover styles are removed and the color
     * is made constant.
     */
    color: PropTypes.string,
};

export default Group;
