import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../Firebase';
import Form from './form'; // Import the form component

const PageWrapper = styled.div`
  font-family: 'Avenir', sans-serif;
  width: 100vw;
  display: block;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 90px;
  padding-left: 10px;
`;

const Logo = styled.img`
  cursor: pointer;
  width: 259px;
  height: 91px;
`;

const SpecialSpan = styled.span`
  font-family: 'Avenir', sans-serif;
  font-weight: 600;
  font-size: 24px;
`;

const Title = styled.h1`
  font-family: 'Avenir', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 36px;
  text-align: center;
  margin-bottom: 2px;
`;

const HighlightedText = styled.span`
  font-family: 'Avenir', sans-serif;
  font-size: 36px;
  color: ${(props) => props.color || 'inherit'};
`;

class SignIn extends Component {
  render() {
    return (
      <PageWrapper>
        <MainWrapper>
          <Logo id="nav_home" src="/images/logos/rapstudyblue_trademarked.png" alt="Logo" style={{marginTop: "-40px"}}/>
          <div style={{ textAlign: 'center', marginTop: "20px", marginBottom: '-20px' }}>
            <Title style={{ fontWeight: '900' }}>
              Your students' favorite songs — recreated with{' '}
              <HighlightedText color="#37DBF6">educational</HighlightedText> lyrics.
            </Title>

            <SpecialSpan>
              <HighlightedText color="#37DBF6" style={{fontSize: '24px'}}>Join</HighlightedText> our waitlist for early access to songs, study tools,
              standards-aligned resources, and more.
            </SpecialSpan>
          </div>
          {/* FORM IS INCLUDED HERE */}
          <Form/>
        </MainWrapper>
      </PageWrapper>
    );
  }
}

export default withFirebase(SignIn);
