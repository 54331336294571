import React, { Component } from 'react';

import ContactMain from './ContactMain';
import LandingFooter from '../../Landing/LandingFooter';

class ContactUs extends Component {
    render() {
        return (
            <div>
                <ContactMain />
                <LandingFooter />
            </div>
        );
    }
}

export default ContactUs;
