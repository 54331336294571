import React, { Component } from 'react';
import styled from 'styled-components';

import AlbumCoverCarouselController from '../../../../UI-Library/ImageContainers/AlbumCoverCarouselController';

const MainWrapper = styled.div``;

class PlaylistShare extends Component {
    render() {
        const emptyAndAdmin = this.props.playlists.length === 0 && this.props.isAdmin;

        if (emptyAndAdmin) {
            return (
                <MainWrapper>
                    <h2 className="bold secondary">Shared Playlists</h2>
                    <p className="secondary">
                        To add a playlist, go to the playlist and click on the{' '}
                        <img src={'/images/icons/utility/dropdowndots.svg'} style={{ width: '18px', height: '18px' }} alt=" dots " /> icon in the top right.
                    </p>
                </MainWrapper>
            );
        }

        return (
            <MainWrapper>
                <AlbumCoverCarouselController
                    header={'Shared Playlists'}
                    items={this.props.playlists}
                    itemType={'playlist'}
                    remove={this.props.remove}
                    rows={1}
                />
            </MainWrapper>
        );
    }
}

export default PlaylistShare;
