import * as ROUTES from '../../../../constants/routes';

import React, { Component } from 'react';
import { setCurrentTime, setEnded, setPlay } from '../../../../redux/slices/audioPlayer';

import AddToPlaylistModal from '../../Modals/AddToPlaylistModal';
import Create from '../../Icons/Create';
// import Draggable from 'react-draggable';
import Fullscreen from '../../Icons/Fullscreen';
// import { DraggableCore } from 'react-draggable';
import PlayPause from './PlayPause';
import RsLink from '../../ButtonsOrLinks/RsLink';
// import { Resizable } from 're-resizable';
import RsSlider from '../../../UI-Library/Forms/RsSlider';
import Share from '../../Icons/Share';
import Skip from '../../Icons/Skip';
import SongFeedbackModal from '../../Modals/SongFeedbackModal';
import SpeedDropdown from './SpeedDropdown';
import Volume from './Volume';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';

const MainWrapper = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 0px 20px 10px #222;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    padding: 50px 20px;
    display: ${({ display }) => (display ? 'flex' : 'none')};
    width: 100%;
    max-height: 30px;
    align-items: center;
    justify-content: space-around;
`;

const PlayPauseWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 78%;
`;

const ControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    padding-bottom: 40px;
`;

const ProgressBarWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    padding: 0 20px;
    width: 40%;
    display: flex;
    left: 450px;
    align-items: center;
    justify-content: center;
`;

const AlbumWrapper = styled.div`
    display: flex;
    position: relative;
`;

const AlbumCover = styled.img`
    width: 75px;
    height: 75px;
    flex-shrink: 0;
    border-radius: 5px;
    background: url(${(props) => props.src}), lightgray 50% / cover no-repeat;
`;

const AlbumInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 160px;
    height: 100%;
    margin-left: 10px;
`;

const AlbumTitle = styled.div`
    color: var(--primary-text, #fff);
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
`;

const AlbumSubtitle = styled.div`
    color: var(--primary-text, #cecece);
    font-family: Nunito;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
`;

const TimeStamp = styled.span`
    font-size: 0.8em;
    margin-right: ${({ mr }) => mr + 'px' || '0px'};
    margin-left: ${({ ml }) => ml + 'px' || '0px'};
`;

const IconWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    left: 40px;
    z-index: 10;
`;

const BottomControlsWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    display: flex;
    right: 70px;
    align-items: center;
    width: 25%;
    justify-content: space-between;
`;

/**
 * The audio bar includes the play pause button, the progress bar, the volume
 * slider, and the speed dropdown.
 */
class AudioBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            volume: 1,
            duration: 0,
            playback: '1x',
            displayShare: false,
            displayAddToPlaylist: false,
        };
        this.audio = React.createRef();
        this.playOrPause = this.playOrPause.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.handleCanPlay = this.handleCanPlay.bind(this);
        this.handleEnded = this.handleEnded.bind(this);
        this.loadAudio = this.loadAudio.bind(this);
        this.handleVolumeChange = this.handleVolumeChange.bind(this);
        this.handlePlaybackChange = this.handlePlaybackChange.bind(this);
        this.handleProgressChange = this.handleProgressChange.bind(this);
        this.toggleAddToPlaylistModal = this.toggleAddToPlaylistModal.bind(this);
        this.completeFeedback = this.completeFeedback.bind(this);
        this.forwardSkip = this.forwardSkip.bind(this);
        this.backwardSkip = this.backwardSkip.bind(this);
    }

    forwardSkip() {
        const { currentTime, duration } = this.audio.current;
        const newTime = currentTime + 10;
        this.audio.current.currentTime = Math.min(newTime, duration);
    }
    backwardSkip() {
        const { currentTime } = this.audio.current;
        const newTime = currentTime - 10;
        this.audio.current.currentTime = Math.max(newTime, 0);
    }

    toggleAddToPlaylistModal() {
        this.setState({ displayAddToPlaylist: !this.state.displayAddToPlaylist });
    }

    componentDidMount() {
        const { songDetails } = this.props;
        if (this.props.audioLoaded) this.loadAudio(songDetails.audioSrc);
        if (this.props.play) this.play();
    }

    componentDidUpdate(prevProps) {
        const { songDetails } = this.props;
        const prevAudioSrc = prevProps.songDetails.audioSrc;
        if (songDetails.audioSrc !== prevAudioSrc) {
            if (this.props.audioLoaded) this.loadAudio(songDetails.audioSrc);
        }
        // this.audio.current.currentTime = this.props.currentTime;
        if (this.props.play !== prevProps.play) {
            if (this.props.play) this.play();
            else this.pause();
        }

        if (this.props.currentTime === 0) this.audio.current.currentTime = this.props.currentTime;
        else if (this.audio.current && Math.abs(this.props.currentTime - prevProps.currentTime) > 0.7) {
            this.audio.current.currentTime = this.props.currentTime;
            this.play();
        }
    }

    componentWillUnmount() {
        this.clearAudioEvents();
    }

    loadAudio = (src) => {
        this.clearAudioEvents();
        this.audio.current.pause();
        this.audio.current.src = src;
        this.audio.current.load();
        this.audio.current.currentTime = this.props.currentTime;
        this.addAudioEvents();
        if (this.props.play) this.play();
    };

    addAudioEvents = () => {
        this.audio.current.addEventListener('playing', () => console.log('Audio started playing'));
        this.audio.current.addEventListener('canplay', this.handleCanPlay);
        this.audio.current.addEventListener('ended', this.handleEnded);
        this.audio.current.addEventListener('error', (e) => console.log('Audio error', e));
    };

    handleCanPlay = (event) => {
        let dur;
        try {
            dur = this.audio.current.duration;
        } catch (err) {
            dur = 0;
        }
        if (dur !== this.state.duration) this.setState({ duration: dur });
    };

    handleEnded = () => {
        clearInterval(this.updateTime);
        this.props.setEnded(true);
        this.props.setPlay(false);
    };

    clearAudioEvents = () => {
        const audio = this.audio.current;
        audio.removeEventListener('canplay', this.handleCanPlay);
        audio.removeEventListener('ended', this.handleEnded);
    };

    play() {
        this.updateTime = setInterval(() => {
            if (!this.audio.current) {
                clearInterval(this.updateTime);
                this.props.setCurrentTime(0);
                this.props.setPlay(false);
                return;
            }
            this.props.setCurrentTime(this.audio.current.currentTime);
            this.props.setEnded(this.audio.current.ended);
        }, 200);
        this.audio.current.play();
        this.props.setPlay(true);
        this.props.setCurrentTime(this.audio.current.currentTime);
    }

    pause() {
        this.audio.current.pause();
        this.props.setPlay(false);
        this.audio.current.currentTime = this.props.currentTime;
        clearInterval(this.updateTime);
    }

    playOrPause() {
        if (this.props.play) this.pause();
        else this.play();
    }

    handleProgressChange(event, value) {
        const newTime = this.audio.current.duration * (value / 100);
        this.audio.current.currentTime = newTime;
        this.props.setCurrentTime(newTime);
    }

    handleVolumeChange(event, value) {
        this.audio.current.volume = value / 100;
        this.setState({ volume: value / 100 });
    }

    handlePlaybackChange(text, speed) {
        this.audio.current.playbackRate = speed;
        this.setState({ playback: text });
    }

    /**
     * Returns a string that represents the number of seconds in MM:SS format.
     * @param {number} time
     * @returns {string}
     */
    secondsToMMSS(time) {
        var minutes = Math.floor(time / 60);
        var seconds = time - minutes * 60;

        seconds = Math.floor(Math.round(seconds * 100) / 100);

        var result = minutes < 10 ? '0' + minutes : minutes;
        result += ':' + (seconds < 10 ? '0' + seconds : seconds);

        return result;
    }

    completeFeedback(id, rating, message, isTeacher) {
        this.props.firebase.completeFeedback(id, rating, message, isTeacher);
    }

    render() {
        const percentage = (this.props.currentTime / this.state.duration) * 100;

        return (
            <MainWrapper
                maxWidth={this.props.maxWidth}
                background={this.props.songDetails.background}
                display={this.props.songDetails?.songID !== null ? true : undefined}
            >
                <AddToPlaylistModal id={this.props.songDetails.songID} toggle={this.toggleAddToPlaylistModal} show={this.state.displayAddToPlaylist} />
                <SongFeedbackModal
                    onClose={() => this.setState({ displayShare: false })}
                    show={this.state.displayShare}
                    completeFeedback={this.completeFeedback}
                    firebase={this.props.firebase}
                    userToken={this.props.userToken}
                    id={this.props.songDetails.songID}
                />
                <audio id="mainAudio" ref={this.audio} />
                <AlbumWrapper>
                    <RsLink to={`${ROUTES.KARAOKE}`} state={{ reset: false }} search={`?songId=${this.props.songDetails.songID}`}>
                        <AlbumCover src={this.props.songDetails.coverImage} alt="Album Cover" /> {/* replace with actual link or source */}
                    </RsLink>
                    <RsLink to={`${ROUTES.KARAOKE}`} state={{ reset: false }} search={`?songId=${this.props.songDetails.songID}`}>
                        <AlbumInfo>
                            <AlbumTitle>{this.props.songDetails.newTitle}</AlbumTitle>
                            <AlbumSubtitle>{this.props.songDetails.originalTitle}</AlbumSubtitle>
                            <AlbumSubtitle>{this.props.songDetails.origArtist}</AlbumSubtitle>
                        </AlbumInfo>
                    </RsLink>
                </AlbumWrapper>
                <ControlsWrapper>
                    <IconWrapper>
                        <Create disabled={false} size="24px" hover={false} onClick={this.toggleAddToPlaylistModal} />
                        <Share onClick={() => this.setState({ displayShare: true })} />
                    </IconWrapper>
                    <PlayPauseWrapper>
                        <Skip onClick={this.backwardSkip} direction="left" hover={true} />
                        <PlayPause play={this.props.play} audioLoaded={this.props.audioLoaded} onClick={this.playOrPause} />
                        <Skip onClick={this.forwardSkip} direction="right" hover={true} />
                    </PlayPauseWrapper>
                </ControlsWrapper>
                <ProgressBarWrapper>
                    <TimeStamp mr={20}>{this.secondsToMMSS(this.props.currentTime)}</TimeStamp>
                    <RsSlider onChange={this.handleProgressChange} label="progress" percentage={percentage} />
                    <TimeStamp ml={20}>{this.secondsToMMSS(this.state.duration)}</TimeStamp>
                </ProgressBarWrapper>
                <BottomControlsWrapper>
                    <Volume value={this.state.volume * 100} onChange={this.handleVolumeChange} />
                    <SpeedDropdown changeValue={this.handlePlaybackChange} playback={this.state.playback} />
                    <RsLink to={`${ROUTES.KARAOKE}`} state={{ reset: false }} search={`?songId=${this.props.songDetails.songID}`}>
                        <Fullscreen />
                    </RsLink>
                </BottomControlsWrapper>
            </MainWrapper>
        );
    }
}

// maps the Redux state to this component's props
const mapStateToProps = (state) => ({
    ...state.audioPlayer,
});

// maps actions to this component's props
const mapDispatchToProps = (dispatch) => ({
    setPlay: (play) => dispatch(setPlay(play)),
    setCurrentTime: (time) => dispatch(setCurrentTime(time)),
    setEnded: (ended) => dispatch(setEnded(ended)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(AudioBar));
