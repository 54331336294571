/**
 * Calls the searchUnsplash cloud function and returns a Promise that resolves
 * with an array of unsplash images.
 */
export async function cloudSearchUnsplash(searchQuery) {
    try {
        if (!searchQuery || searchQuery === '' || searchQuery === ' ') return;
        const searchUnsplash = this.functions.httpsCallable('unsplash-searchUnsplash');
        const json = await searchUnsplash({ searchQuery: searchQuery });
        const data = json.data;
        const newImgArray = [];
        if (!data.results || data.results.length === 0) {
            return;
        }
        for (var i = 0; i < data.results.length; i++) {
            newImgArray.push([
                data.results[i],
                data.results[i].urls.thumb,
                data.results[i].urls.regular,
                data.results[i].user.username,
                data.results[i].user.first_name + ' ' + data.results[i].user.last_name,
            ]);
        }
        return newImgArray;
    } catch (err) {
        // TODO ErrorHandling: Log error to Sentry.
        console.log(err);
        throw err;
    }
}

export async function downloadUnsplash(imgObj) {
    try {
        if (!imgObj) return;
        const download = this.functions.httpsCallable('unsplash-downloadUnsplash');
        download({ photoObj: imgObj });
    } catch (err) {
        // TODO ErrorHandling: Log error to Sentry.
        console.log(err);
        throw err;
    }
}
