export default async function appendToWaitlist(entryData) {
    try {
        const { email, name, zip, selectedGrades, selectedSubjects, music, extra } = entryData;

        // Reference to the document in the 'waitlist' collection using the email as the document ID
        const waitlistRef = this.db.collection('waitlist').doc(email);

        // Get the current document
        const doc = await waitlistRef.get();

        if (doc.exists) {
            // If the document exists, update the fields (append to arrays if needed)
            const data = doc.data();

            // Update the document fields, appending to arrays where necessary
            await waitlistRef.update({
                name: name || data.name, // Update name if provided, or keep existing
                schoolAddress: email || data.schoolAddress,
                zipcode: zip || data.zipcode,
                grade: data.grade ? `${data.grade},${selectedGrades.join(',')}` : selectedGrades.join(','), // Append to the existing grades string
                subject: data.subject ? `${data.subject},${selectedSubjects.join(',')}` : selectedSubjects.join(','), // Append to the existing subjects string
                music: music ? (data.music ? `${data.music},${music}` : music) : data.music, // Append new music to existing
                extra: extra ? (data.extra ? `${data.extra},${extra}` : extra) : data.extra, // Append new extra info to existing
            });

            console.log('Document successfully updated with new data!');
        } else {
            // If the document doesn't exist, create it
            await waitlistRef.set({
                name: name,
                schoolAddress: email,
                zipcode: zip,
                grade: selectedGrades.join(','), // Save as a comma-separated string
                subject: selectedSubjects.join(','), // Save as a comma-separated string
                music: music,
                extra: extra,
            });

            console.log('Document successfully created!');
        }
    } catch (error) {
        console.error('Error updating document: ', error);
    }
}
