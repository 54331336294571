import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: var(--rsSecondary);
  text-decoration: none;
  font-size: ${(props) => props.fontSize + ';'}
  font-weight: ${(props) => props.fontWeight + ';'}
  color: ${(props) => props.color + ';'}

  &:hover {
    color: var(--rsPrimary) !important;
    text-decoration: underline;
  }
`;

const StyledButton = styled.button`
  color: var(--rsSecondary);
  text-decoration: none;
  font-size: ${(props) => props.fontSize + ';'}
  font-weight: ${(props) => props.fontWeight + ';'}
  color: ${(props) => props.color + ';'}
  outline: none;
  border: 0;
  background: none;
  padding: 0;

  &:hover {
    color: var(--rsPrimary) !important;
    text-decoration: underline;
  }
`;

/**
 * The standard link component. If no route is defined in the 'to' prop,
 * it behaves as a button with the 'onClick' prop.
 */
class RsLink extends Component {
    render() {
        const fontSize = this.props.small ? '14px' : '18px';
        const fontWeight = this.props.bold ? 'bold' : 'normal';
        const color = this.props.secondary ? 'var(--rsSecondary)' : 'var(--rsPrimary)';
        const to =
            this.props.state && this.props.search
                ? { pathname: this.props.to, state: this.props.state, search: this.props.search }
                : this.props.state
                ? { pathname: this.props.to, state: this.props.state }
                : this.props.search
                ? { pathname: this.props.to, search: this.props.search }
                : this.props.to;
        if (this.props.to)
            return (
                <StyledLink to={to} onClick={this.props.onClick} fontSize={fontSize} fontWeight={fontWeight} color={color}>
                    {this.props.children}
                </StyledLink>
            );

        return (
            <StyledButton onClick={this.props.onClick} fontSize={fontSize} fontWeight={fontWeight} color={color}>
                {this.props.children}
            </StyledButton>
        );
    }
}

RsLink.propTypes = {
    /**
     * The route to link to.
     */
    to: PropTypes.string,
    /**
     * The function fired when the component is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The font size of the link. By default it is the standard 18px. Small
     * is 14px.
     */
    small: PropTypes.bool,
    /**
     * Is the text bolded?
     */
    bold: PropTypes.bool,
    /**
     * Is it colored as secondary text?
     */
    secondary: PropTypes.bool,
};

export default RsLink;
