import React, { Component } from 'react';
import styled from 'styled-components';
import EmptyContent from '../../../UI-Library/Misc/EmptyContent';

const MainWrapper = styled.div`
    padding: 5%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

class EmptyClasses extends Component {
    render() {
        const isTeacher = this.props.userToken.claims.teacher ? this.props.userToken.claims.teacher : false;
        return (
            <MainWrapper>
                <EmptyContent src="/images/animationAssets/girlSitting.png" lead={isTeacher ? "You don't have any classes!" : "You're not in any classes!"}>
                    <p>
                        {isTeacher
                            ? 'To create a class, click the button in the top banner. Classes allow you to share study sets, playlists, and songs with your students. Assignments are also coming soon!'
                            : 'To join a class, click the button in the top banner. Then in the pop up, enter the code that your teacher has given you.'}
                    </p>
                </EmptyContent>
            </MainWrapper>
        );
    }
}

export default EmptyClasses;
