import * as React from 'react';
import { withFirebase } from '../../Firebase';
import SimpleBanner from '../../UI-Library/Containers/SimpleBanner';
import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const Lead = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
`;

const Sublead = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
`;

const PageWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

function LessonView(props) {
    const [lessons, setLessons] = React.useState([]);

    React.useEffect(() => {
        const fetchLessons = async () => {
            const res = await props.firebase.getLessons();
            setLessons(() => res);
        };

        fetchLessons();
    }, [props.firebase]);

    return lessons?.map((lesson) => {
        const subject = lesson?.details.subject;
        const grade = lesson?.details.grade;
        const standards = lesson?.details.standards;
        const duration = lesson?.details.duration;

        return (
            <PageWrapper>
                <SimpleBanner image={lesson?.image} lead={lesson?.title} sub={`${lesson?.subject}, ${lesson?.grade}`} description={lesson?.description}>
                    <BackArrow />
                </SimpleBanner>
                <Lead>Lesson Details</Lead>
                <div key={lesson}>{`subject: ${subject} grade: ${grade} standard: ${standards} duration: ${duration}`}</div>

                <Sublead>Objectives</Sublead>
                <div key={lesson}>
                    {lesson?.objectives.map((objective) => {
                        return <li>{objective}</li>;
                    })}
                </div>

                <Sublead>Standards Addressed</Sublead>
                <div>{lesson?.standard}</div>

                <Sublead>Related Songs</Sublead>
                <div key={lesson}>
                    {lesson?.relatedSongs.map((relatedSong) => {
                        return (
                            <Link to={`${ROUTES.KARAOKE}?songId=${relatedSong}`}>
                                <>{relatedSong}</>
                            </Link>
                        );
                    })}
                </div>
            </PageWrapper>
        );
    });
}

export default withFirebase(LessonView);
