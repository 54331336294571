import * as ROUTES from '../../../constants/routes';

import { Redirect, Route } from 'react-router-dom';

import React from 'react';

const ProtectedRoute = ({ component: Component, path, authUser, verified, verificationDone, ...rest }) => {
    const url = window.location.pathname + window.location.search;
    return (
        <Route
            {...rest}
            path={path}
            render={(props) => {
                if (!verificationDone) {
                    return null;
                }
                if (authUser && !verified) {
                    return <Redirect to={ROUTES.FREELANDING} />;
                }
                if (!authUser) {
                    // If it's an invite link and the user is not logged in, redirect to the invite login page
                    // and save the inviter in localStorage to display on the login page
                    if (rest.guest) {
                        const inviteId = window.location.search.split('=')[1];
                        localStorage.setItem('redirect', url);
                        rest.firebase.db
                            .collection('invites')
                            .doc(inviteId)
                            .get()
                            .then((doc) => {
                                if (doc.exists) {
                                    localStorage.setItem('inviter', doc.data().inviter);
                                    window.location.href = `${ROUTES.INVITE_LOGIN}`;
                                }
                            });
                        return;
                    }
                    return <Redirect to={ROUTES.FORBIDDEN} />;
                }
                return <Component {...props} {...rest} />;
            }}
        />
    );
};

export default ProtectedRoute;
