export const dark = {
    background: '#000000', //'#121212',
    primary: '#FFFFFF',
    secondary: '#cecece',
    tertiary: '#889097',
    disabled: '#3d3d3d',
    surface: '#000000', //'#222222'
    surfaceHover: '#2e2e2e',
    majorHighlight: '#37dbf6',
    minorHighlight: '#8c2155',
};

export const light = {
    background: '#F6F8FA',
    primary: '#000000',
    secondary: '#555555',
    tertiary: '#979797',
    disabled: '#DDDDDD',
    surface: '#FFFFFF',
    surfaceHover: '#E5E5E5',
    majorHighlight: '#8c2155',
    minorHighlight: '#37dbf6',
};
