import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';
// eslint-disable-next-line no-unused-vars
import Loading from '../../UI-Library/Misc/Loading';

import StandardsBanner from '../../UI-Library/Containers/StandardsBanner';
import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';
import StandardsView from './StandardsView';
import StandardsSearchBar from './StandardsSearchBar';
import VersionIIView from './VersionIIView';

const PageWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding-top: 0;
    padding-left: 5%;
    padding-right: 5%;
    overflow: visible;
`;

const StandardTitle = styled.p`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: bold;
`;

/**
 * Standard is the component for the "Standard" page in Internal.
 */
export class StandardBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: null,
            standards: false,
            selectedStandard: '',
            selectedSubject: '',
            selectedGrade: '',
            selectedUnit: '',
            topics: [],
            subTopics: [],
            subStandards: [],
            descriptions: [],
            objectList: [],
            showCard: false,
            queryData: [],
            titles: [
                {
                    id: 0,
                    imgSrc: 'https://images.pexels.com/photos/129731/pexels-photo-129731.jpeg?cs=srgb&dl=pexels-fwstudio-129731.jpg&fm=jpg',
                    title: 'choice 0',
                    SubContent1: 'SubContent0_1',
                    SubContent2: 'SubContent0_2',
                    MainContent:
                        'Main content of Chioce0: Explain major differences between poems, drama, and prose, and refer to the structural elements of poems(e.g., verse, rhythm, mater) and drama(e.e., casts of characters, settings, descriptions, dialogue, stage directions) when writing or speaking about a text',
                    AdditionalStandard: 'thisistheadditionalstandardofchoice0',
                },
                {
                    id: 1,
                    imgSrc: 'https://images.pexels.com/photos/4107337/pexels-photo-4107337.jpeg?cs=srgb&dl=pexels-takeshi-arai-4107337.jpg&fm=jpg',
                    title: 'choice 1',
                    SubContent1: 'SubContent1_1',
                    SubContent2: 'SubContent1_2',
                    MainContent: 'Main content of Choice1: 1 MainContent MainContent MainContent1, This is the main content of Choice 1',
                    AdditionalStandard: 'AdditionalStandard1',
                },
                {
                    id: 2,
                    imgSrc: 'https://images.pexels.com/photos/4726054/pexels-photo-4726054.jpeg?cs=srgb&dl=pexels-jo%C3%A3o-delicado-4726054.jpg&fm=jpg',
                    title: 'choice 2',
                    SubContent1: 'SubContent2_1',
                    SubContent2: 'SubContent2_2',
                    MainContent: 'Main content of Choice2: 2 MainContent MainContent MainContent2, rapStudy rapStudy rap Study, Study',
                    AdditionalStandard: 'AdditionalStandard2',
                },
            ],
            test: [1, 2, 3, 4],
            active: 0,
            pdfLink: 'https://www.me.columbia.edu/files/seas/content/mechanical_engineering_courses_syllabi.pdf',
            refresh: 0,
            versionIIObjectList: [],
        };
        this.toggleStandards = this.toggleStandards.bind(this);
        this.setStateFromFilters = this.setStateFromFilters.bind(this);
        this.setShowCardStateToTrue = this.setShowCardStateToTrue.bind(this);
    }

    async componentDidMount() {
        try {
            this.setState({ ready: true });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    toggleStandards() {
        this.setState({
            standards: !this.state.standards,
        });
    }

    //this function will be passed into standardSearchBar component so that the the child component can change the state of parent component
    async setStateFromFilters(unitDict, selectedStandard, selectedSubject) {
        // Clear the previous search results
        this.setState({ objectList: [] });
        const listOfObject = [];
        // Make sure that the unit dict is valid so nothing happens when you click search without any selections
        if (unitDict !== null) {
            // Get an array of topic references that are used to then get a subtopic reference array and so forth
            var topics = await this.props.firebase.getTopics(unitDict.getRef());
            console.log(topics);
            // TODO some subtopics are misaligned from their topics and firebase layout
            await Promise.all(
                topics.map(async (topicDict) => {
                    var subTopicRefs = await this.props.firebase.getSubTopics(topicDict.getRef());
                    console.log(subTopicRefs);
                    // Get the next subtopics in the collection that need to be added to the list
                    await Promise.all(
                        subTopicRefs.map(async (subTopicDict) => {
                            var subStandardRefs = await this.props.firebase.getSubStandards(subTopicDict.getRef());
                            // Array containing substandards corresponding to the subtopic specified by subTopicDict
                            var subStandardsToAdd = subStandardRefs.map((subStandardDict) => subStandardDict.getId().replaceAll('%2F', '/'));
                            var currDescArr = [];
                            await Promise.all(
                                subStandardRefs.map(async (subStandardDict) => {
                                    // There is only one description corresponding to each substandard
                                    // the description array needs to be an array of arrays, so we use
                                    // a currDesc array to keep the array of descriptions that correspond to the same subtopic.
                                    var descriptionRef = await this.props.firebase.getDescriptions(subStandardDict.getRef());
                                    var descriptionToAdd = descriptionRef.map((descDict) => ({
                                        desc: descDict.getId().replaceAll('%2F', '/'),
                                        songIds: descDict.getSongIds(),
                                    }));

                                    currDescArr = [...currDescArr, ...descriptionToAdd];
                                }),
                            );
                            const objArr = subStandardsToAdd.map((value, index) => ({
                                [value]: currDescArr[index],
                            }));
                            objArr.sort(function (a, b) {
                                var keyA = Object.keys(a)[0];
                                var keyB = Object.keys(b)[0];
                                var aFirstNum = keyA.match(/\d+/);
                                var bFirstNum = keyB.match(/\d+/);
                                if (aFirstNum[0] < bFirstNum[0]) return -1;
                                if (aFirstNum[0] > bFirstNum[0]) return 1;
                                if (aFirstNum[0] === bFirstNum[0]) {
                                    var aLastNum = keyA.match(/(\d+)(?!.*\d)/);
                                    var bLastNum = keyB.match(/(\d+)(?!.*\d)/);
                                    if (aLastNum[0] < bLastNum[0]) return -1;
                                    if (aLastNum[0] > bLastNum[0]) return 1;
                                }
                                return 0;
                            });
                            // Add the singular topic and subTopic at this point along with the array of subStandards
                            // descriptions corresponding to that topic and subTopic.
                            // Note that '%2F' is a randomly chosen string used in the AppScript to encode forward slashes
                            // because forward slashes aren't allowed in firebase strings, and then we replaceAll to revert
                            // the substrings back into forward slashes.
                            subStandardsToAdd = objArr.map((ele) => Object.keys(ele)).flat();
                            currDescArr = objArr.map((ele) => ele[Object.keys(ele)]);
                            var firstId = [''];
                            if (currDescArr.length > 0) {
                                firstId = [currDescArr[0].songIds[0]];
                            }
                            var firstSong = await Promise.allSettled(firstId.map((id) => this.props.firebase.getSong(id)));
                            if (firstSong[0].status === 'fulfilled') {
                                firstSong = '"' + firstSong[0].value.data().origTitle + '"';
                            } else {
                                firstSong = '';
                            }
                            const curObject = {
                                topic: topicDict,
                                subTopic: subTopicDict.getId().replaceAll('%2F', '/'),
                                subStandards: subStandardsToAdd,
                                subStandardDescriptions: currDescArr,
                                firstSong,
                            };
                            listOfObject.push(curObject);
                        }),
                    );
                }),
            );
            this.setState({
                selectedStandard: selectedStandard,
                selectedSubject: selectedSubject,
                objectList: listOfObject,
            });
        }
    }

    // this function will be passed into standardSearchBar component so that the the child component
    // can change the state of parent component
    setShowCardStateToTrue() {
        this.setState({ showCard: true });
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }
        if (!this.state.ready) {
            return <Loading zoom={true} />;
        }
        return (
            <PageWrapper>
                <StandardsBanner lead={'Standards'} sub={'Songs'} onClick={this.toggleStandards} standards={this.state.standards} color={'var(--rsBlue)'}>
                    <BackArrow />
                </StandardsBanner>
                {!this.state.standards || this.state.standards ? (
                    <StandardsSearchBar
                        {...this.props}
                        setShowCardStateToTrue={this.setShowCardStateToTrue}
                        setStateFromFilters={this.setStateFromFilters}
                        objectList={this.state.objectList}
                        createVersionIIObjectList={this.createVersionIIObjectList}
                    />
                ) : null}
                {!this.state.standards &&
                this.state.showCard &&
                this.state.selectedStandard !== 'Select a standard' &&
                this.state.selectedSubject !== 'Select a subject' &&
                this.state.selectedGrade !== 'Select a grade' &&
                this.state.selectedUnit !== 'Select a strand' &&
                this.state.objectList.length !== 0 ? (
                    <div style={{ marginBotton: '15px' }}>
                        <VersionIIView objectList={this.state.objectList} refresh={this.state.refresh}></VersionIIView>
                    </div>
                ) : (
                    <MainWrapper>
                        {this.state.showCard &&
                        this.state.selectedStandard !== 'Select a standard' &&
                        this.state.selectedSubject !== 'Select a subject' &&
                        this.state.selectedGrade !== 'Select a grade' &&
                        this.state.selectedUnit !== 'Select a strand' &&
                        this.state.objectList.length !== 0 ? (
                            <div>
                                <StandardTitle>
                                    {this.state.selectedStandard} Standards in {this.state.selectedSubject}
                                </StandardTitle>
                                {this.state.objectList.map((item, index) => (
                                    <StandardsView
                                        {...this.props}
                                        itemList={this.state.titles}
                                        active={this.state.active}
                                        topic={item.topic.getId().replaceAll('%2F', '/')}
                                        subTopic={item.subTopic}
                                        subStandard={item.subStandards}
                                        subStandardDescription={item.subStandardDescriptions.map((ele) => ele.desc)}
                                        expandSubstandard={item.subStandards}
                                        expandSubstandardDesp={item.subStandardDescriptions.map((ele) => ele.desc)}
                                        songIds={item.subStandardDescriptions
                                            .map((ele) => ele.songIds)
                                            .flat()
                                            .filter(function (item, index, inputArray) {
                                                return inputArray.indexOf(item) === index;
                                            })}
                                        img={item.topic.src}
                                        firstSong={item.firstSong}
                                        pdfLink={this.state.pdfLink}
                                    ></StandardsView>
                                ))}
                            </div>
                        ) : null}
                    </MainWrapper>
                )}
            </PageWrapper>
        );
    }
}

const Standard = withFirebase(StandardBase);
export default Standard;
