import React, { Component } from 'react';

import ColorPicker from '../../Forms/ColorPicker';
import RsModal from '../../Modals/RsModal';

/**
 * Props:
 * hexList Array<Array<string>>
 * selected string
 * onSelect fun (string -> any)
 */
class ColorPickerModal extends Component {
    render() {
        return (
            <RsModal show={this.props.show} toggle={this.props.toggle} title={'Choose a color'}>
                <RsModal.Body fx={'col-center'} max-height={'200px'}>
                    <ColorPicker hexList={this.props.hexList} selected={this.props.selected} onSelect={this.props.onSelect} />
                </RsModal.Body>
            </RsModal>
        );
    }
}

export default ColorPickerModal;
