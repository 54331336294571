import React, { Component } from 'react';
import { withFirebase } from '../../../Firebase';
import styled from 'styled-components';

import Loading from '../../../UI-Library/Misc/Loading';
import PendingStudents from './PendingStudents';
import ActiveStudents from './ActiveStudents';
import EmptyStudents from './EmptyStudents';

const MainWrapper = styled.div`
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    height: 100%;
`;

class ClassMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentProfileList: [],
            pendingProfileList: [],
            prevStudentsProps: [],
            prevPendingProps: [],
            ready: false,
            error: null,
        };

        this.acceptStudent = this.acceptStudent.bind(this);
        this.removeStudent = this.removeStudent.bind(this);
        this.rejectStudent = this.rejectStudent.bind(this);
    }

    arrayEqual(arr1, arr2) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    async updateStudentList(oldProfiles, oldArr, newArr) {
        const oldSet = new Set(oldArr);
        const newSet = new Set(newArr);

        const constant = new Set();
        const inserted = new Set();
        const deleted = new Set();

        newArr.forEach((ele) => {
            if (oldSet.has(ele)) {
                constant.add(ele);
            } else {
                inserted.add(ele);
            }
        });

        oldArr.forEach((ele) => {
            if (!newSet.has(ele)) deleted.add(ele);
        });

        const filteredDeletes = oldProfiles.filter((ele) => constant.has(ele.uid));
        const insertedProfiles = await Promise.all([...inserted].map(async (studentId) => await this.props.firebase.getUserProfile(studentId)));
        const resultProfiles = filteredDeletes.concat(insertedProfiles);
        return resultProfiles;
    }

    async componentDidMount() {
        try {
            const classStudentsPr = Promise.all(this.props.students.map(async (studentId) => await this.props.firebase.getUserProfile(studentId)));
            const pendingStudentsPr = Promise.all(this.props.pending.map(async (studentId) => await this.props.firebase.getUserProfile(studentId)));
            const resolved = await Promise.all([classStudentsPr, pendingStudentsPr]);
            this.setState({
                ready: true,
                studentProfileList: resolved[0],
                pendingProfileList: resolved[1],
            });
        } catch (err) {
            console.log(err);
            this.setState({ error: err, ready: true });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        try {
            if (!this.arrayEqual(this.props.students, prevProps.students)) {
                const oldArr = prevProps.students;
                const newArr = this.props.students;
                const oldProfiles = this.state.studentProfileList;
                const newStudentProfiles = await this.updateStudentList(oldProfiles, oldArr, newArr);

                this.setState({ studentProfileList: newStudentProfiles });
            }
            if (!this.arrayEqual(this.props.pending, prevProps.pending)) {
                const oldArr = prevProps.pending;
                const newArr = this.props.pending;
                const oldProfiles = this.state.pendingProfileList;
                const newPendingProfiles = await this.updateStudentList(oldProfiles, oldArr, newArr);

                this.setState({ pendingProfileList: newPendingProfiles });
            }
        } catch (err) {
            console.log(err);
            this.setState({ error: err, ready: true });
        }
    }

    async removeStudent(student) {
        try {
            await this.props.firebase.removeStudentFromClass(student.uid, this.props.id);
        } catch (err) {
            console.log(err);
            this.setState({ error: err });
        }
    }

    async acceptStudent(student) {
        try {
            await this.props.firebase.approveDenyStudentToJoinClass(student.uid, this.props.id, true);
        } catch (err) {
            console.log(err);
            this.setState({ error: err });
        }
    }

    async rejectStudent(student) {
        try {
            await this.props.firebase.approveDenyStudentToJoinClass(student.uid, this.props.id, false);
        } catch (err) {
            console.log(err);
            this.setState({ error: err });
        }
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }
        if (!this.state.ready) {
            return <Loading zoom={true} />;
        }
        const empty = this.props.students && this.props.pending && this.props.students.length === 0 && this.props.pending.length === 0;

        if (empty) return <EmptyStudents isAdmin={this.props.isAdmin} />;

        return (
            <MainWrapper>
                <PendingStudents
                    students={this.state.pendingProfileList}
                    userToken={this.props.userToken}
                    adminId={this.props.adminId}
                    classId={this.props.id}
                    acceptStudent={this.acceptStudent}
                    rejectStudent={this.rejectStudent}
                    isAdmin={this.props.isAdmin}
                />
                <ActiveStudents
                    students={this.state.studentProfileList}
                    userToken={this.props.userToken}
                    adminId={this.props.adminId}
                    classId={this.props.id}
                    removeStudent={this.removeStudent}
                    isAdmin={this.props.isAdmin}
                    totalStudents={this.props.totalStudents}
                />
            </MainWrapper>
        );
    }
}

export default withFirebase(ClassMembers);
