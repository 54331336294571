import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ScorePill from '../../ButtonsOrLinks/ScorePill';
import { Row, Collapse, Col } from 'react-bootstrap';
import Caret from '../../Icons/Caret';
import { capitalizeFLetter } from '../../../../utility/stringFunctions';

const MainWrapper = styled.div`
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border: none;
    background: ${(props) => (props.hover ? 'var(--rsSurfaceHover)' : 'var(--rsSurface)')};
    cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
`;

const ImageColumn = styled(Col)``;

const InfoColumn = styled(Col)`
    margin-top: 10px;
`;

const SongPath = styled.p`
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    color: var(--rsSecondary);
    margin-bottom: 0px;
`;

const SummaryHeader = styled.p`
    font-size: 24px;
    line-height: 33px;
    text-align: left;
    color: var(--rsPrimary);
    margin-bottom: 1px;
`;

const ThumbnailImg = styled.img`
    width: 82px;
    height: 82px;
    border: none;
    border-radius: 5px;
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
`;

const ScorePillDiv = styled.div`
    display: flex;
    align-items: center;
`;

const ToggleButton = styled.button`
    background: none;
    border: 0;
    margin-left: 20px;
`;

const ToggleColumn = styled(Col)`
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const ChildrenRow = styled(Row)`
    margin: 0;
    margin-bottom: 10px;

    cursor: default;
`;

const ChildrenCollapse = styled(Collapse)`
    width: 100%;
`;

/**
 * A preview component for an exercise in an assignment.
 */
class GradeCollapse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            open: false,
        };
        this.onHover = this.onHover.bind(this);
        this.offHover = this.offHover.bind(this);
        this.setOpen = this.setOpen.bind(this);
    }

    onHover(e) {
        this.setState({
            hover: true,
        });
    }

    offHover(e) {
        this.setState({
            hover: false,
        });
    }

    setOpen() {
        this.props.onToggle();
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        return (
            <MainWrapper hover={this.state.hover}>
                <Row style={{ margin: 0 }} onMouseOver={this.onHover} onMouseLeave={this.offHover} onClick={this.setOpen}>
                    <ImageColumn sm md="auto">
                        <ThumbnailImg src={this.props.src} />
                    </ImageColumn>
                    <InfoColumn>
                        <SongPath>{this.props.unitName + ' > ' + this.props.topicName + ' > ' + this.props.subTopicName}</SongPath>
                        <SummaryHeader>
                            <b>{this.props.featureName + ': '}</b>
                            {this.props.setName}
                        </SummaryHeader>
                        <ScorePillDiv>
                            <div>
                                <span
                                    style={{
                                        fontSize: '14px',
                                    }}
                                    className="bold"
                                >
                                    {`Mode: `}
                                </span>
                                <span
                                    style={{
                                        margin: '0 0 0 5px',
                                        fontSize: '14px',
                                    }}
                                    className="secondary"
                                >
                                    {capitalizeFLetter(this.props.mode)}
                                </span>
                            </div>
                        </ScorePillDiv>
                    </InfoColumn>
                    <ToggleColumn sm md="auto">
                        <ScorePill score={this.props.score} total={this.props.total} status={this.props.status} clickable={false} />
                        <ToggleButton aria-controls="children" aria-expanded={this.state.open}>
                            <Caret direction={this.state.open ? 'up' : 'down'} />
                        </ToggleButton>
                    </ToggleColumn>
                </Row>
                <ChildrenRow>
                    <ChildrenCollapse in={this.state.open}>
                        <div id="children">
                            <div style={{ marginTop: '15px' }}>{this.props.children}</div>
                        </div>
                    </ChildrenCollapse>
                </ChildrenRow>
            </MainWrapper>
        );
    }
}

GradeCollapse.propTypes = {
    /**
     * Points scored on exercise
     */
    score: PropTypes.number.isRequired,
    /**
     * The mode used.
     */
    mode: PropTypes.string.isRequired,
    /**
     * Total points of exercise
     */
    total: PropTypes.number.isRequired,
    /**
     * The status of the exercise.
     */
    status: PropTypes.oneOf(['not started', 'in progress', 'complete']).isRequired,
    /**
     * Image src/url
     */
    src: PropTypes.string.isRequired,
    /**
     * Assignment unit name
     */
    unitName: PropTypes.string.isRequired,
    /**
     * Assignment topic name
     */
    topicName: PropTypes.string.isRequired,
    /**
     * Assignment sub-topic name
     */
    subTopicName: PropTypes.string.isRequired,
    /**
     * The name of the feature
     */
    featureName: PropTypes.oneOf(['Review', 'Breakdown', 'Blanks']).isRequired,
    /**
     * Set name of exercise
     */
    setName: PropTypes.string.isRequired,
    /**
     * A function that fires when the collapse is toggled open.
     */
    onToggle: PropTypes.func,
};

export default GradeCollapse;
