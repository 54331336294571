import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import { Collapse } from 'react-bootstrap';
import Subjects from '../../../UI-Library/Icons/Subjects';
import Scrollbars from 'react-custom-scrollbars-2';
//import MainLink from '../../../Internal/VerticalNav/Main/index.js';
//import CreatePlaylistToggle from "../../../UI-Library/Modals/CreatePlaylistToggle";

const UnitLink = {
    verticalAlign: 'middle',
    color: 'var(--rsSecondary)',
    textDecoration: 'none',
    marginLeft: '0px',
    width: '80%',
    display: 'block',
    whiteSpace: 'nowrap',
    //fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: '0px',
    marginBottom: '0px',
    fontSize: '14px',
};

const SubjectText = styled.p`
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
    color: white;
    margin-bottom: 0;
    margin-left: 0px;
`;

const MainHeader = styled.p`
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0;
    color: var(--rsTertiary);
    font-size: 12px;
    transition: color 0.3s ease;
    &:hover {
        color: var(--rsPrimary);
    }
    margin-top: 0;
`;

const SubjectItemWrapper = styled.div`
    padding-top: 5px;
    margin-bottom: 5px;
`;

const Toggler = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const MainWrapper = styled.div`
    margin-bottom: 10px;
    margin-left: 10px;
`;

export class SubjectItem extends Component {
    render() {
        const unitLinks = this.props.units.map((item, key) => (
            <NavLink key={key} to={ROUTES.UNITVIEW + '/' + item.id} style={UnitLink} className="nav-item vertNav-link">
                {item.title}
            </NavLink>
        ));
        const name = this.props.name === 'English Language Arts' ? 'ELA' : this.props.name;

        return (
            <SubjectItemWrapper>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                    <Subjects size="16px" subject={this.props.name} />
                    <SubjectText>{name}</SubjectText>
                </div>

                <ul className="nav flex-column">{unitLinks}</ul>
            </SubjectItemWrapper>
        );
    }
}

/**
 * LibraryHeader is simply the component for the header of the library section
 * in the vertical nav bar.
 */
export class LibraryHeader extends Component {
    render() {
        return (
            <div>
                <Toggler className="noPadMar ghost" onClick={this.props.toggle} aria-controls="library" aria-expanded={this.props.open}>
                    {/* <img src="/images/icons/utility/folder1.png" alt="lib" /> */}
                    <MainHeader style={{ color: '#37DBF6', marginLeft: '0', fontSize: '15px' }}>Library</MainHeader>
                    {/* <Caret
            src="/images/icons/utility/caretgrey.png"
            open={this.props.open}
          ></Caret> */}
                </Toggler>
            </div>
        );
    }
}

/**
 * Library is the component for the "Library" <ul> in VerticalNav. It contains
 * a list of the user's bookmarked topics by subject.
 */
export class Library extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
        this.toggle = this.toggle.bind(this);
        this.renderLinks = this.renderLinks.bind(this);
    }

    toggle() {
        this.setState({ open: !this.state.open });
    }

    renderLinks() {
        return Object.keys(this.props.library).map((item, key) => <SubjectItem key={key} name={item} units={this.props.library[item]} />);
    }

    render() {
        return (
            <MainWrapper>
                <LibraryHeader open={this.state.open} toggle={this.toggle} />
                {this.props.library != null && (
                    <Collapse in={this.state.open} id="library">
                        <div style={{}}>
                            <Scrollbars onScroll={this.handleScroll} style={{ width: 160, height: 130 }}>
                                {this.renderLinks()}
                            </Scrollbars>
                        </div>
                    </Collapse>
                )}
            </MainWrapper>
        );
    }
}

export default withFirebase(Library);
