import Question from './Question.js';

/**
 * An array of Question objects and its associated methods.
 */
class QuestionList {
    /**
     * Creates a new QuestionList object. If readOnly is true, the questionList
     * cannot be modified after construction, EXCEPT to add existing Question
     * instances to the list via the `add` method.
     *
     * @param {bool} readOnly
     * @param {number} score
     * @param {number} length
     * @param {string} name
     * @param {string} authorId
     * @param {string} authorName
     * @param {string} songId
     */
    constructor(readOnly = false, score = 0, length = 0, name = '', authorId = '', authorName = '', songId = '') {
        this.questions = [];
        this.questionIds = new Set();
        this.songId = songId;
        this.empty = length === 0;
        this.length = length;
        this.name = name;
        this.authorId = authorId;
        this.authorName = authorName;
        // for assignments
        this.score = score;
        this.readOnly = readOnly;
    }

    /**
     * Sets the name field of the Question object.
     * @param {string} text
     */
    setName(text) {
        this.assertWritable();
        if (!(typeof text === 'string')) {
            throw Error('Failed precondition: "text" is not a string');
        }
        this.name = text;
    }

    getName() {
        return this.name;
    }

    getAuthorId() {
        return this.authorId;
    }

    setAuthorId(id) {
        this.assertWritable();
        if (!id) throw Error('Author id was falsy');
        this.authorId = id;
    }

    getAuthorName() {
        return this.authorName;
    }

    setAuthorName(name) {
        this.assertWritable();
        if (!name) throw Error('Author name was falsy');
        this.authorName = name;
    }

    hasId(id) {
        return this.questionIds.has(id);
    }

    /**
     * Returns the length of the Question list.
     * @return {number}
     */
    getLength() {
        return this.length;
    }

    /**
     * Returns the empty status of the Question List.
     * @return {boolean}
     */
    isEmpty() {
        return this.empty;
    }

    /**
     * Returns the Question at the given index.
     * @param {number} pos
     * @return {Question}
     */
    index(pos) {
        return this.questions[pos];
    }

    /**
     * Reinserts the Question from the start index to the end index.
     * @param {number} startIndex
     * @param {number} endIndex
     */
    reorder(startIndex, endIndex) {
        this.assertWritable();
        const [removed] = this.questions.splice(startIndex, 1);
        this.questions.splice(endIndex, 0, removed);
        for (var i = 0; i < this.questions.length; i++) {
            const q = this.index(i);
            q.setIndex(i);
        }
    }

    /**
     * Appends an empty Question object to the list.
     * @param {string} randomId
     */
    addEmpty(randomId) {
        this.assertWritable();
        if (!randomId) throw new Error('Precondition Violated: randomId was falsy.');
        if (this.questionIds.has(randomId)) throw new Error('The passed id is not unique and already exists in the list.');
        this.questionIds.add(randomId);
        this.empty = false;
        this.questions.push(new Question(this.songId, '', '', '', '', '', 'A', null, null, randomId, this.getLength(), null, null, null, null, null, null, 0));
        this.length += 1;
    }

    /**
     * Adds a Question object to the list
     * @param {Question} question
     */
    add(question) {
        if (!(question instanceof Question)) throw new Error('Precondition Violated: question is not a Question object.');
        if (!question || !question.getId()) throw new Error('Precondition Violated: the Question object or its id was falsy.');
        if (this.questionIds.has(question.getId())) throw new Error('The passed id is not unique and already exists in the list.');
        this.questionIds.add(question.getId());
        this.length += 1;
        this.empty = false;
        this.questions.push(question);
    }

    /**
     * Adds an array of Question objects in a batch operation. If the array is
     * empty, the function does nothing. Does not modify the score or length
     * parameters - intended for lazy loading purposes.
     * @param {Array<Question>} questions
     */
    addQuestionList(questions) {
        if (!questions || questions.length === 0) return;
        this.questions = questions;
        this.empty = false;
        this.length = questions.length;
        this.questionIds = new Set(questions.map((question) => question.getId()));
    }

    /**
     * Deletes the Question at the given index.
     * @param {number} index
     */
    deleteQuestion(index) {
        this.assertWritable();
        if (this.getLength() === 0) {
            throw Error('deleteQuestion: cannot delete from an empty list.');
        }
        if (this.getLength() === 1) {
            this.empty = true;
        }
        this.length -= 1;
        const removed = this.questions.splice(index, 1);
        for (var i = 0; i < this.length; i++) {
            const q = this.index(i);
            q.setIndex(i);
        }
        this.questionIds.delete(removed[0].getId());
        return removed;
    }

    /**
     * Returns the question objects.
     * @return {Array<Question>}
     */
    getQuestions() {
        return this.questions;
    }

    /**
     * Returns the Question object at the index. Throws and exception if the
     * index is invalid.
     * @param {number} index
     * @return {Question}
     */
    getQuestion(index) {
        if (this.isEmpty() || index > this.length - 1) throw Error('Precondition Invalidated: index is out of bounds.');
        return this.questions[index];
    }

    /**
     * Sets the selected choice for a question.
     * @param {number} index
     * @param {string} choice
     */
    setSelectedAtIndex(index, choice) {
        this.assertWritable();
        const q = this.getQuestion(index);
        const previouslyCorrect = q.isAnswered() && q.isCorrect();
        const correct = q.setSelected(choice);
        // CASE 1: A correct answer that fixes the previous incorrect or unanswered
        // response.
        if (correct && !previouslyCorrect) {
            this.score += 1;
        }
        // CASE 2: An incorrect answer that undos the previously correct answer.
        if (!correct && previouslyCorrect) {
            this.score -= 1;
        }
        // CASE 3: If correct and previously correct, do not change score.
        // CASE 4: If not correct and previously not correct, do not change score.
    }

    /**
     * Resets the selected question for all questions in the set.
     */
    resetSelected() {
        this.assertWritable();
        if (this.isEmpty()) return;
        this.score = 0;
        this.questions.forEach((qObj) => {
            qObj.resetSelected();
        });
        return;
    }

    /**
     * Returns the current score of the QuestionList.
     * @return {number}
     */
    getScore() {
        return this.score;
    }

    /**
     * Returns the song id for the question list.
     * @returns {string}
     */
    getSongId() {
        return this.songId;
    }

    /**
     * Sets the song id for this keyword.
     * @param {string} songId
     */
    setSongId(songId) {
        this.assertWritable();
        if (!songId) throw Error('Invalid argument: songId');
        this.songId = songId;
    }

    /**
     * Throws an exception if the QuestionList is in read only mode.
     */
    assertWritable() {
        if (this.readOnly)
            throw Error('Precondition Invalidated: QuestionList instance is read-only. Only add operations for existing Question instances are allowed.');
    }
}

export default QuestionList;
