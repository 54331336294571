import React, { Component } from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import DefaultImg from '../../../UI-Library/ImageContainers/DefaultImg';

import PropTypes from 'prop-types';

const MainWrapper = styled.div`
    width: 97%;
    margin-bottom: 30px;
    background-color: var(--rsSurface);
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    transition: all 0.3s ease 0s;
    ${(props) => (props.disabled ? 'color: var(--rsDisabled);' : '')}
    &:hover {
        background-color: ${(props) => (props.disabled ? 'var(--rsSurface)' : props.color)};
    }
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    margin-left: 3%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
`;

const HeaderInfo = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const ClassTitle = styled.h2`
    margin: 0;
    margin-right: 4.8%;
    font-weight: bold;
`;

const ClassPhoto = styled.div`
    position: relative;
    left: -3%;
    height: 180px;
    width: 180px;
`;

/**
 * A container with an image and a title for previewing data. Nest nodes in
 * the component via `props.children`.
 */
class PreviewCard extends Component {
    render() {
        const selectedColor = this.props.color ? this.props.color : '#37dbf6';
        const tColor = tinycolor(selectedColor);
        const selectedColorBackground = tColor.darken(20).toHexString();
        if (!this.props.src && !this.props.defaultImgType) {
            console.warn('PRECONDITION INVALIDATED: PreviewCard must have a valid src or defaultImgType prop.');
        }
        return (
            <MainWrapper color={selectedColorBackground} disabled={this.props.disabled} onClick={this.props.disabled ? null : this.props.onClick}>
                <ClassPhoto>
                    <DefaultImg
                        disabled={this.props.disabled}
                        color={selectedColor}
                        type={this.props.defaultImgType}
                        src={this.props.src}
                        to={''}
                        isClickable={false}
                    />
                </ClassPhoto>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Header>
                        <HeaderInfo>
                            <ClassTitle>{this.props.title}</ClassTitle>
                        </HeaderInfo>
                    </Header>
                    {this.props.children}
                </div>
            </MainWrapper>
        );
    }
}

PreviewCard.propTypes = {
    /**
     * The title for the card.
     */
    title: PropTypes.string.isRequired,
    /**
     * The color for the card on hover. If there is no image source defined in
     * `src`, the default image is used and the background color will be this
     * color.
     */
    color: PropTypes.string,
    /**
     * The type used for the inner DefaultImage component if no `src` is defined.
     */
    defaultImgType: PropTypes.string,
    /**
     * The source url for the image. If not defined, the image will fall back
     * to the DefaultImage icon specified by `defaultImgType`.
     */
    src: PropTypes.string,
    /**
     * The function fired when the card is clicked. The entire card is clickable.
     */
    onClick: PropTypes.func.isRequired,
    /**
     * Is the card disabled?
     */
    disabled: PropTypes.bool,
};

export default PreviewCard;
