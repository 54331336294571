import React, { Component } from 'react';
import styled from 'styled-components';

import RsButton from '../../ButtonsOrLinks/RsButton';
import RsModal from '../../Modals/RsModal';

const Body = styled.p`
    margin-bottom: 0;
`;

class DiscardConfirmModal extends Component {
    render() {
        return (
            <RsModal show={this.props.show} toggle={this.props.toggle} title={'Discard changes?'}>
                <RsModal.Body>
                    <Body>Current edits will be lost.</Body>
                </RsModal.Body>
                <RsModal.Footer jContent={'space-between'}>
                    <RsButton secondary={true} onClick={this.handleModalShowHide}>
                        No, go back!
                    </RsButton>
                    <RsButton
                        onClick={() => {
                            this.props.discard();
                            this.props.toggle();
                        }}
                    >
                        Yes
                    </RsButton>
                </RsModal.Footer>
            </RsModal>
        );
    }
}

export default DiscardConfirmModal;
