import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Circle = styled.div`
    display: flex;
    justify-content: center;
    padding: 1.5px 0px 0px 0px;
    align-items: center;
    position: relative;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    font-size: ${(props) => props.fontSize};
    font-weight: bold;
    text-align: center;
`;

class UserInitial extends Component {
    render() {
        var letter = this.props.letter;
        var size = this.props.large ? '100px' : '20px';
        var sizeAdjust = this.props.mini ? '25px' : size;
        var fontSize = this.props.large ? '80px' : '24px';
        var fontAdjust = this.props.mini ? '16px' : fontSize;
        var color = this.props.color ? this.props.color : 'var(--rsBlue)';

        return (
            <Circle size={sizeAdjust} fontSize={fontAdjust} color={color}>
                {letter}
            </Circle>
        );
    }
}

UserInitial.propTypes = {
    /**
     * The initial to be represented inside the circle.
     */
    letter: PropTypes.string,
    /**
     * Whether or not the icon is large.
     */
    large: PropTypes.bool,
    /**
     * The color of the circle. Defaults to rapStudy blue if not defined.
     */
    color: PropTypes.string,
};

export default UserInitial;
