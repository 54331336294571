import * as ROUTES from '../../../../constants/routes';

import React, { Component } from 'react';

import Group from '../../../UI-Library/Icons/Group';
import House from '../../../UI-Library/Icons/House';
import Magnifying from '../../../UI-Library/Icons/Magnifying';
import { NavLink } from 'react-router-dom';
import NotificationsPending from '../../../UI-Library/ButtonsOrLinks/NotificationsPending';
import PenAndPaper from '../../../UI-Library/Icons/PenAndPaper';
import styled from 'styled-components';

const MainIcon = styled.div`
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 20px;
`;

const LinkStyle = {
    verticalAlign: 'middle',
    fontWeight: 'bold',
    color: 'var(--rsSecondary)',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    borderLeft: '5px solid black',
};

const IconLinkWrapper = styled.li`
    margin-bottom: 5px;
    margin-left: -20px;
    padding-left: 0;
`;

const UlWrapper = styled.ul`
    display: block;
    margin-top: 20px;
    padding-bottom: 9px;
`;

const ActiveLinkStyle = {
    borderLeft: '5px solid var(--rsMajor)',
    color: 'white',
    fontWeight: 'bold',
};

/**
 * Main is the component for the "Main" <ul> in VerticalNav. It contains
 * the links for "Home", "Browse", "Search", and "Your Liked Songs".
 */
class Main extends Component {
    render() {
        const notifications = this.props.assignmentsFollowed
            ? this.props.assignmentsFollowed.filter(
                  (assignmentFollow) =>
                      assignmentFollow.releaseDate.toDate() < new Date() && assignmentFollow.dueDate.toDate() > new Date() && !assignmentFollow.complete,
              ).length
            : 0;
        return (
            <UlWrapper className="nav flex-column">
                <MainLink to={ROUTES.DASHBOARD} wrapped={House} text={'Home'} />
                <MainLink to={ROUTES.BROWSE_SUBJECTS} wrapped={Magnifying} text={'Browse'} />
                <MainLink to={!this.props.guest && ROUTES.STANDARDS} wrapped={PenAndPaper} text={'Standards'} />
                {/* <MainLink to={!this.props.guest && ROUTES.LESSON_VIEW} wrapped={Wrench} text={'Lessons'} /> */}
                {/* <MainLink
          to={ROUTES.CONTENTMANAGER}
          wrapped={Wrench}
          text={"Lessons"}
        /> */}
                <MainLink to={!this.props.guest && ROUTES.CLASSES} wrapped={Group} text={'Classes'} notifications={notifications} />
            </UlWrapper>
        );
    }
}

class MainLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const Wrapped = this.props.wrapped;
        return (
            <IconLinkWrapper
                className="nav-item"
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                //activeStyle={ActiveLinkStyle}
            >
                <NavLink to={this.props.to} style={LinkStyle} activeStyle={ActiveLinkStyle} className="vertNav-link">
                    <MainIcon>
                        <Wrapped
                            size="14px"
                            // hover={this.state.hover}
                            hover={true}
                            onClick={() => null}
                        />
                    </MainIcon>
                    <span style={{ fontSize: '16px' }}>{this.props.text}</span>
                    <span style={{ marginLeft: '2px' }}>{this.props.notifications > 0 && <NotificationsPending number={this.props.notifications} />}</span>
                </NavLink>
            </IconLinkWrapper>
        );
    }
}

export default Main;
