import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Gear extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const hover = typeof this.props.hover !== 'undefined' ? this.props.hover : this.state.hover;

        const color = this.props.disabled ? 'var(--rsDisabled)' : hover ? 'var(--rsPrimary)' : 'var(--rsSecondary)';
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg
                    width={this.props.size ? this.props.size : '24px'}
                    height={this.props.size ? this.props.size : '24px'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                >
                    <circle cx="9" cy="9" r="3.06" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <polygon
                        points="7.44 3.13 7.84 1.38 10.03 1.38 10.46 3.13 12.08 3.83 13.57 2.77 15.16 4.36 14.24 5.92 14.87 7.44 16.69 7.77 16.69 10.19 14.9 10.49 14.24 12.05 15.16 13.41 13.51 15.16 12.08 14.17 10.49 14.73 10.13 16.62 7.87 16.62 7.44 14.83 5.95 14.2 4.53 15.23 2.84 13.51 3.83 12.05 3.13 10.49 1.31 10.16 1.31 7.87 3.13 7.58 3.76 6.05 2.7 4.36 4.43 2.74 5.95 3.76 7.44 3.13"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </svg>
            </div>
        );
    }
}

Gear.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default Gear;
