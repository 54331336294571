import 'rc-slider/assets/index.css';
import './SongBanner.css';

import React, { Component } from 'react';

import AudioBar from '../../../UI-Library/AudioOrLyrics/AudioBar';
import BackArrow from '../../../UI-Library/ButtonsOrLinks/BackArrow';
import SongNav from './SongNav';
import TimeContext from '../../../Contexts/CurrentTime';
import { map } from 'jquery';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import { withRouter } from 'react-router-dom';

const BannerWrapper = styled.div`
    position: relative;
    height: 160px;
    padding-left: 5%;
    padding-right: 5%;
    margin: 0;

    background: linear-gradient(0deg, var(--rsBackground) 0%, ${(props) => props.backgroundColor} 100%);

    ${({ fullscreen }) =>
        fullscreen &&
        `
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background: var(--rsSurface);
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  `}
`;

const BannerRow = styled.div`
    padding-top: 20px;
    padding-bottom: -80px;
    height: 100%;

    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    padding-right: 0;
    margin: 0;

    ${({ fullscreen }) =>
        fullscreen &&
        `
    padding-top: 10px;
    margin-bottom: 0;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 30px;
  `}
`;

const ImageColumn = styled.div`
    display: flex;
    @media screen and (max-width: 1000px) {
        display: none;
    }
`;

const BoxImage = styled.img`
    margin: 0;
    width: 140px;
    height: 140px;
    @media screen and (max-width: 1152px) {
        height: 140px;
        width: 140px;
    }
    border: none;
    border-radius: 5px;
    box-shadow: 5px 5px 12px #222222;
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 0;
    ${({ fullscreen }) =>
        fullscreen &&
        `
    flex-direction: row;
    align-items: center;

  `}
    margin: 0;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
`;

const TitleP = styled.p`
    font-size: 24px;
    margin-bottom: 0;
    @media screen and (max-width: 1120px) {
        font-size: 24px;
    }
`;

const FullScreenTitle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const EllipsisHeader = styled.p`
    padding-left: 10%;
    padding-right: 10%;
    font-size: 24px;
    margin-bottom: 0;
    @media screen and (max-width: 1200px) {
        font-size: 20px;
    }
`;

// const Artist = styled.span`
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
//   color: white;
//   @media screen and (max-width: 1120px) {
//     font-size: 14px;
//   }
// `;

class SongBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playback: '1x',
            sliderValue: 0,
            volumeLevel: 50,
            showAddToPlaylist: false,
            showShareSongToClass: false,
            showShareSetToClass: false,
            pdfWindow: false,
            artistInfo: {},
            loaded: false,
        };
        this.variantRef = React.createRef();
        this.toggleAddToPlaylistModal = this.toggleAddToPlaylistModal.bind(this);
        this.toggleShareSetToClassModal = this.toggleShareSetToClassModal.bind(this);
        this.toggleShareSongToClassModal = this.toggleShareSongToClassModal.bind(this);
        this.changePlayback = this.changePlayback.bind(this);
        this.togglePdf = this.togglePdf.bind(this);
    }

    changePlayback(text, speed) {
        this.setState({ playback: text });
        this.props.playback(speed);
    }

    handleVolumeChange = (event, value) => {
        this.props.setVolume({ value });
        this.setState({ volumeLevel: value });
    };

    handleProgressChange = (event, value) => {
        this.props.setTime((value / 100) * this.props.duration);
    };

    toggleAddToPlaylistModal() {
        this.setState({ showAddToPlaylist: !this.state.showAddToPlaylist });
    }

    toggleShareSetToClassModal() {
        this.setState({ showShareSetToClass: !this.state.showShareSetToClass });
    }

    toggleShareSongToClassModal() {
        this.setState({ showShareSongToClass: !this.state.showShareSongToClass });
    }

    togglePdf() {
        this.setState({ pdfWindow: !this.state.pdfWindow });
    }

    toggleLoaded() {
        this.setState({ loaded: true });
    }

    static contextType = TimeContext;

    componentDidMount() {
        const { setCurrentPlay, setInSongView } = this.context;
        setCurrentPlay(false);
        setInSongView(true);

        const vari = map(this.props.songArr, (song) => song['origArtist']);
        const ids = this.props.songArr.reduce((acc, song) => {
            if (!acc[song['origArtist']]) {
                acc[song['origArtist']] = [];
            }
            acc[song['origArtist']].push(song['id']);
            return acc;
        }, {});

        const initializeartistInfo = async () => {
            await this.props.firebase.getArtists().then((res) => {
                const artistMap = res.reduce((acc, artist) => {
                    if (vari.includes(artist.name)) acc[artist.name] = { image: artist.image, id: ids[artist.name] };
                    // if (artist.name == "Ariana Grande") {
                    //   acc[artist.name] = { image: artist.image, id: "d94" };
                    //   //acc[artist.name + " "] = { image: artist.image, id: "d197" };
                    // }
                    return acc;
                }, {});
                this.setState({ artistInfo: artistMap });
            });
        };
        initializeartistInfo();
    }

    componentWillUnmount() {
        const { setCurrentTime, setCurrentSong, setCurrentPlay, setInSongView } = this.context;
        setCurrentSong(this.props.id);
        setCurrentTime(this.props.currentTime);
        if (this.props.play) {
            setCurrentPlay(true);
        }
        setInSongView(false);
    }

    render() {
        setTimeout(() => this.toggleLoaded(), 500);
        const menuItems = [
            {
                toggleMenuItemAction: this.toggleAddToPlaylistModal,
                requiredRole: 'Both',
                desc: 'Add to playlist',
            },
            {
                toggleMenuItemAction: this.toggleShareSongToClassModal,
                requiredRole: 'Teacher',
                desc: 'Add song to class',
            },
        ];

        if (this.props.currSetId && this.props.currSetId !== null && this.props.currSetId !== 'null')
            menuItems.push({
                toggleMenuItemAction: this.toggleShareSetToClassModal,
                requiredRole: 'Teacher',
                desc: 'Add set to class',
            });

        var subject_nospace = this.props.subject.name.replace(' ', '%20');
        var unit_lower = this.props.unit.name.toLowerCase();

        var j = 0;
        while (j <= 4) {
            subject_nospace = subject_nospace.replace(' ', '%20');
            unit_lower = unit_lower.replace('&', '');
            unit_lower = unit_lower.replace(' ', '_');
            j = j + 1;
        }

        return (
            <BannerWrapper backgroundColor={this.props.backgroundColor} fullscreen={this.props.fullscreen}>
                {!this.props.fullscreen && !this.props.guest ? (
                    <BackArrow displayFeedback={false} firebase={this.props.firebase} userToken={this.props.userToken} id={this.props.id} />
                ) : null}
                <BannerRow className="row fillVW" fullscreen={this.props.fullscreen}>
                    {!this.props.fullscreen ? (
                        <ImageColumn className="col-auto" style={{ paddingLeft: 0 }}>
                            <BoxImage src={this.props.imgSrc} />
                        </ImageColumn>
                    ) : (
                        <FullScreenTitle className="col-4">
                            <EllipsisHeader className="bold ellipsisWrap">{this.props.newTitle}</EllipsisHeader>
                        </FullScreenTitle>
                    )}
                    <InfoColumn className="col" fullscreen={this.props.fullscreen}>
                        {!this.props.fullscreen ? (
                            <div style={{}}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Title className="justify-content-between">
                                        <TitleP className="bold ellipsisWrap" style={{ paddingRight: '200px', overflow: 'hidden' }}>
                                            {this.props.newTitle}
                                        </TitleP>
                                        {this.state.loaded ? (
                                            <div className="variationDiv" style={{}}>
                                                {/* < div style={{ fontSize: "11px", bottom: "20px", marginLeft: "-20px" }}>
                        Choose a variation:
                      </div> */}
                                                <div className="artistsContainer">
                                                    {Object.keys(this.state.artistInfo).map((key, index) => {
                                                        const curr_id = window.location.href.substring(window.location.href.lastIndexOf('d'));
                                                        if (key === this.props.origArtist[0]) {
                                                            return (
                                                                <div key={index} className="variationArtist" style={{ zIndex: 10 }}>
                                                                    <a href={`/internal/songView/karaoke?songId=${curr_id}`}>
                                                                        <img
                                                                            className="artistSelected"
                                                                            src={this.state.artistInfo[key].image}
                                                                            alt="selected artist"
                                                                        />
                                                                    </a>
                                                                    <hr />
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div key={index} className="variationArtist">
                                                                    <a href={`/internal/songView/karaoke?songId=${this.state.artistInfo[key].id}`}>
                                                                        <img className="artist" src={this.state.artistInfo[key].image} alt="artist" />
                                                                    </a>
                                                                    <hr />
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                {/* <div style={{ fontSize: "10px", marginLeft: "-20px", marginTop: "-25px", color: "#889097" }}>
                        Based on
                      </div> */}
                                                <div style={{ fontWeight: 'bold', marginLeft: '-20px', fontSize: '20px', marginTop: '-20px' }}>
                                                    {this.props.origArtist}
                                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                                        {this.props.songArr.map((song, index) => {
                                                            if (song['origArtist'] === this.props.origArtist[0]) {
                                                                const leftMargin = index === 0 ? 0 : 10;
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            marginLeft: leftMargin,
                                                                            marginRight: 10,
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className="artist-sm"
                                                                            src={this.state.artistInfo[this.props.origArtist[0]]?.image}
                                                                            alt="artist"
                                                                        />
                                                                        <a
                                                                            style={{ fontSize: 14, marginLeft: 10 }}
                                                                            href={`/internal/songView/karaoke?songId=${song['id']}`}
                                                                        >
                                                                            {song['origTitle']}
                                                                        </a>
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Title>
                                    <div style={{ marginTop: '5px', display: 'flex' }} className="justify-content-between">
                                        <p
                                            className="ellipsisWrap"
                                            style={{
                                                marginTop: '-7px',
                                                fontSize: '15px',
                                            }}
                                        >
                                            <a href={`/internal/browse/units/${subject_nospace}`}>{this.props.subject.name}</a>,{' '}
                                            <a href={`/internal/unitView/${unit_lower}`}>{this.props.unit.name}</a>,{' '}
                                            <a href={`/internal/unitView/${unit_lower}`}>{this.props.topic.name}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {!this.props.fullscreen ? (
                            <></>
                        ) : (
                            // <div style={{ height: '10px', position: 'absolute', width: '600px', marginTop: '63px' }}>
                            //   <AudioBar
                            //     play={this.props.play}
                            //     audioLoaded={this.props.audioLoaded}
                            //     currentTime={this.props.currentTime}
                            //     duration={this.props.duration}
                            //     playback={this.state.playback}
                            //     volumeLevel={this.state.volumeLevel}
                            //     playOrPause={this.props.playOrPause}
                            //     handleProgressChange={this.handleProgressChange}
                            //     changePlayback={this.changePlayback}
                            //     handleVolumeChange={this.handleVolumeChange}
                            //     showProgress={true}
                            //     showSpeed={true}
                            //     showVolume={true}
                            //     maxWidth={'600px'}
                            //   />
                            // </div>
                            // <div style={{ height: '10px', position: 'absolute', width: '600px', marginTop: '63px' }}>
                            //   <AudioBar
                            //     play={this.props.play}
                            //     audioLoaded={this.props.audioLoaded}
                            //     currentTime={this.props.currentTime}
                            //     duration={this.props.duration}
                            //     playback={this.state.playback}
                            //     volumeLevel={this.state.volumeLevel}
                            //     playOrPause={this.props.playOrPause}
                            //     handleProgressChange={this.handleProgressChange}
                            //     changePlayback={this.changePlayback}
                            //     handleVolumeChange={this.handleVolumeChange}
                            //     showProgress={true}
                            //     showSpeed={true}
                            //     showVolume={true}
                            //     maxWidth={'600px'}
                            //   />
                            // </div>
                            <div style={{ width: '600px' }}>
                                <AudioBar
                                    play={this.props.play}
                                    audioLoaded={this.props.audioLoaded}
                                    currentTime={this.props.currentTime}
                                    duration={this.props.duration}
                                    playback={this.state.playback}
                                    volumeLevel={this.state.volumeLevel}
                                    playOrPause={this.props.playOrPause}
                                    handleProgressChange={this.handleProgressChange}
                                    changePlayback={this.changePlayback}
                                    handleVolumeChange={this.handleVolumeChange}
                                    showProgress={true}
                                    showSpeed={true}
                                    showVolume={true}
                                    maxWidth={'600px'}
                                />
                            </div>
                        )}
                        {!this.props.fullscreen ? (
                            <div style={{ position: 'absolute', marginTop: '75px', width: '600px' }}>
                                <SongNav id={this.props.id} guest={this.props.guest} backgroundColor={this.props.backgroundColor} />
                            </div>
                        ) : null}
                    </InfoColumn>
                    {/* <AddToPlaylistModal id={this.props.id} toggle={this.toggleAddToPlaylistModal} show={this.state.showAddToPlaylist} /> */}
                    {/*<ShareToClassModal
            show={this.state.showShareSongToClass}
            toggle={this.toggleShareSongToClassModal}
            contentType={"Song"}
            contentId={this.props.id}
          />
          <ShareToClassModal
            show={this.state.showShareSetToClass}
            toggle={this.toggleShareSetToClassModal}
            contentType={this.props.currFeature}
            contentId={this.props.currSetId}
          /> */}
                </BannerRow>
                {/* <PdfModal
          show={this.state.pdfWindow}
          feature={'PDF'}
          onHide={this.togglePdf}
          showCross={false}
          showFooter={true}
          pdfLink={this.props.pdfLink}
        />
        <div style={{ display: 'flex', marginLeft: '10px' }}>
          <IconWrapper>
            <Tooltip placement="bottom" text={`Share ${this.props.currFeature} to Google Classroom`}>
              <GoogleShareToClassRoom
                key={window.location.href + '_' + this.props.currFeature}
                itemType="material"
                url={window.location.href}
                title={`${this.props.currFeature}: ${this.props.newTitle} based on ${this.props.origTitle}`}
                theme="dark"
                onShare={type => console.log(`GoogleShareToClassRoom:${type}`)}
                onShareComplete={() => {
                  console.log('GoogleShareToClassRoom:onShareComplete:' + this.props.currFeature);
                  this.props.logAnalyticsShare();
                }}
                onShareStart={() => console.log('GoogleShareToClassRoom:onShareStart')}
              />
            </Tooltip>
          </IconWrapper>
          <IconWrapper>
            <Tooltip placement="bottom" text="PDF">
              <Info size={'32px'} onClick={this.togglePdf} />
            </Tooltip>
          </IconWrapper>
          <IconWrapper>
            <Tooltip placement="bottom" text={`Copy link to ${this.props.currFeature}`}>
              <Copy size={'32px'} onClick={this.props.copyLink} />
            </Tooltip>
          </IconWrapper>
          <DropdownMenu iconSize="large" isTeacher={this.props.userToken.claims.teacher} menuItems={menuItems} direction={'left'} />
        </div> */}
            </BannerWrapper>
        );
    }
}

export default withFirebase(withRouter(SongBanner));
