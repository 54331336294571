import * as React from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import SimpleBanner from '../../../UI-Library/Containers/SimpleBanner';
import BackArrow from '../../../UI-Library/ButtonsOrLinks/BackArrow';
import { useLocation, Link } from 'react-router-dom';
import SongRow from '../SongRow.js';
import * as ROUTES from '../../../../constants/routes';
import Loading from '../../../UI-Library/Misc/Loading';

const SubLead = styled.p`
    font-size: 24px;
    margin-bottom: 0;
`;

const BrowseHeader = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    color: white;
`;

const Lead = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
`;

const ArtistProfile = styled.p`
    display: inline-flex;
    padding: 10px;
`;

function ArtistPage(props) {
    const artist = useLocation().state?.artist;
    const [partition, setPartition] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchArtistSongs = async () => {
            const response = await props.firebase.cloudSearchAlgolia(artist?.name);
            const songs = await response.songs;
            setLoading(false);
            sortBySubject(songs);
        };
        fetchArtistSongs();
    }, [artist.name, props.firebase]);

    const sortBySubject = (songs) => {
        let subjects = [];
        let partition = {};

        try {
            songs.forEach((song) => {
                if (subjects.includes(song?.subject)) {
                    partition[song?.subject].push(song);
                } else {
                    subjects.push(song?.subject);
                    partition[song?.subject] = [song];
                }
            });
            let res = [];
            for (const [subject, songs] of Object.entries(partition)) {
                res.push([subject, songs]);
            }

            res.sort((a, b) => {
                if (a[1].length > b[1].length) {
                    return -1;
                } else if (a[1].length === b[1].length) {
                    return 0;
                } else {
                    return 1;
                }
            });
            setPartition(() => res);
        } catch (error) {
            console.log(error);
        }
    };

    if (loading) {
        return <Loading zoom={true} />;
    }

    return (
        <>
            <SimpleBanner color={artist?.color} height="150px">
                <BackArrow />
                <BrowseHeader>
                    <div style={{ marginTop: '5px' }}>
                        <div style={{ display: 'inline-block', paddingRight: '40px' }}>Artists</div>
                        <div style={{ display: 'inline-block', color: '#bebeb6' }}>
                            |
                            <Link
                                to={ROUTES.BROWSE_SUBJECTS}
                                style={{
                                    textDecoration: 'none',
                                    color: '#bebeb6',
                                    justifyContent: 'space-between',
                                    paddingLeft: '40px',
                                }}
                            >
                                Subjects
                            </Link>
                        </div>
                    </div>
                </BrowseHeader>
            </SimpleBanner>

            <ArtistProfile style={{ marginTop: '0px' }}>
                <img style={{ borderRadius: '50%', marginLeft: '30px' }} src={artist?.image} height="250px" width="250px" alt="Avatar"></img>

                <div style={{ marginLeft: '35px', marginTop: '15px' }}>
                    <SubLead style={{ color: 'gray' }}>Artist</SubLead>
                    <Lead>{artist?.name}</Lead>
                    <SubLead style={{ color: 'gray' }}>{artist?.listeners.toLocaleString()} Monthly Listeners</SubLead>
                    <SubLead style={{ color: 'gray', fontSize: '16px' }}>{artist?.genre}</SubLead>
                </div>
            </ArtistProfile>

            <div style={{ marginLeft: '35px' }}>
                {partition.map((category) => {
                    return <SongRow guest={props.guest} type={'Song'} header={category[0]} subdata={category[1]} />;
                })}
            </div>
        </>
    );
}

export default withFirebase(ArtistPage);
