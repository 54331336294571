import Annotation from '../../Classes/Annotation.js';
import AnnotationDict from '../../Classes/AnnotationDict.js';

export async function getAnnotationSetsForSong(songId) {
    const setsRef = this.db.collection('annotationSets');
    const querySnap = await Promise.all([
        setsRef.where('author', '==', this.auth.currentUser.uid).where('songId', '==', songId).orderBy('lastModified', 'desc').get(),
        setsRef.where('author', '==', process.env.REACT_APP_RAPSTUDY_UID).where('songId', '==', songId).orderBy('lastModified', 'desc').get(),
    ]);

    const mergedSnapshots = querySnap.reduce((acc, snap) => acc.concat(snap.docs), []);
    if (mergedSnapshots.length === 0) return [];

    return mergedSnapshots.map((docSnap) => {
        const obj = docSnap.data();
        obj.setId = docSnap.id;
        return obj;
    });
}

export async function getAnnotationDict(setId) {
    var annotationDict = null;
    if (setId !== 'null') {
        const customAnnonationsDoc = this.db.collection('annotationSets').doc(setId);

        const customSet = customAnnonationsDoc.get();
        const customAnnotations = customAnnonationsDoc.collection('annotations').withConverter(Annotation.annotationConverter).get();

        annotationDict = new AnnotationDict();
        const resources = await Promise.all([customSet, customAnnotations]);
        const setSnap = resources[0];
        if (!setSnap.exists) throw this.NotFoundError;
        var annotationDocs = resources[1].docs;
        annotationDocs.forEach((annotation) => {
            annotationDict.setAnnotation(annotation);
        });
        // Metadata handling
        const name = setSnap.get('name');
        const authorId = setSnap.get('author');
        const authorProfileSnap = await this.db.collection('userProfiles').doc(authorId).get();
        const authorName = authorProfileSnap.get('first_name') + ' ' + authorProfileSnap.get('last_name');

        annotationDict.setName(name);
        annotationDict.setAuthorId(authorId);
        annotationDict.setAuthorName(authorName);
    }
    return annotationDict;
}

/**
 * Creates a new document for question sets content
 * @param {string} songId
 * @param {string} name
 */
export async function createCustomAnnotations(songId, name) {
    if (!songId || !name) {
        console.log('Create breakdown called with invalid parameters.');
        return;
    }
    var ref = this.db.collection('annotationSets').doc();
    await ref.set({
        name: name,
        author: this.auth.currentUser.uid,
        created: this.firebase.firestore.FieldValue.serverTimestamp(),
        lastModified: this.firebase.firestore.FieldValue.serverTimestamp(),
        songId: songId,
    });
    //Log in event 'set_created' [Firebase Analytics]
    this.analytics.logEvent('set_created', {
        feature: 'breakdown',
    });
    return ref.id;
}

/**
 * Updates an existing annotation set's content and returns a Promise that resolves
 * with the customAnnotation Array<Object>.
 * @param {string} songId
 * @param {string} set_id
 * @param {string} name
 * @returns {Array<Object>}
 */
export async function updateCustomAnnotations(songId, set_id, annotationDict) {
    if (!songId || !set_id) {
        console.log('Update breakdown called with invalid parameters.');
        return;
    }

    var setDoc = this.db.collection('annotationSets').doc(set_id);
    var annotationsRef = setDoc.collection('annotations');

    //compare annotations documents with annotationslookup keys to determine whether there is a need to delete
    var query = await annotationsRef.get();
    var aDocs = query.docs;
    var firebaseRefs = aDocs.map((doc) => {
        return doc.ref;
    }); // array of firebase document references

    // 1. delete refs in firestore but not in local
    await Promise.all(
        firebaseRefs.map(async (ref) => {
            if (!annotationDict.annotationExists(ref.id)) {
                await ref.delete();
            }
        }),
    );

    // 2. for all local refs, set to create new OR overwrite
    var annotationLookup = annotationDict.getAnnotationLookup();
    await Promise.all(
        Object.keys(annotationLookup).map(async (key) => {
            await annotationsRef.doc(key).withConverter(Annotation.annotationConverter).set(annotationDict.getAnnotation(key, null));
        }),
    );

    // 3. update name and numItems
    await setDoc.update({
        name: annotationDict.getName(),
        numItems: annotationDict.getNumberOfRefs(),
        lastModified: this.firebase.firestore.FieldValue.serverTimestamp(),
    });
    return;
}

/**
 * Deletes the given annotations set document.
 * @param {string} songId
 * @param {string} setId
 */
export async function deleteCustomAnnotations(songId, setId) {
    const deleteAnnotationSet = this.functions.httpsCallable('featureSets-deleteFeatureSet');
    await deleteAnnotationSet({ setId: setId, featureName: 'Breakdown' });
}
