import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { withTheme } from '@material-ui/core';

import { device } from '../../../../constants/breakpoints';

const MainInput = styled.input`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  min-height: ${(props) => (props.minHeight ? props.minHeight : '45px')};
  ${(props) => ('max-height: ' + props.maxHeight + ';' ? props.maxHeight : '')}

  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  background: var(--rsForm);
  border-radius: 30px;
  border: 0;
  outline: 0;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  &:focus-within {
    border: 2px solid rgba(255, 255, 255, 0.6) !important;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--rsSecondary);
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--rsSecondary);
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--rsSecondary);
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const TextAreaInput = styled.textarea`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  min-height: ${(props) => (props.minHeight ? props.minHeight : '45px')};
  ${(props) => ('max-height: ' + props.maxHeight + ';' ? props.maxHeight : '')}

  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  background: var(--rsForm);
  border-radius: 30px;
  border: 0;
  outline: 0;
  color: var(--rsPrimary);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  &:focus-within {
    border: 2px solid rgba(255, 255, 255, 0.6) !important;
  }
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--rsSecondary);
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--rsSecondary);
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--rsSecondary);
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

/**
 * The standard input field.
 */
class RsInput extends Component {
    render() {
        if (this.props.textarea)
            return (
                <TextAreaInput
                    type="text"
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    onKeyPress={this.props.handleKeyPress}
                    width={this.props.width}
                    height={this.props.height}
                    minHeight={this.props.minHeight}
                    maxHeight={this.props.maxHeight}
                    mb={this.props.mb}
                    placeholder={this.props.placeholder}
                />
            );
        return (
            <MainInput
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChange}
                onKeyPress={this.props.handleKeyPress}
                width={this.props.width}
                height={this.props.height}
                minHeight={this.props.minHeight}
                maxHeight={this.props.maxHeight}
                mb={this.props.mb}
                placeholder={this.props.placeholder}
            />
        );
    }
}

RsInput.propTypes = {
    /**
     * The value shown in the input.
     */
    value: PropTypes.string.isRequired,
    /**
     * Toggles if it is a longer textarea or a simple text input.
     */
    textarea: PropTypes.bool,
    /**
     * The label to allow for dynamic onChange functions to identify the input.
     */
    name: PropTypes.string,
    /**
     * The function that calls whenever the input is changed.
     */
    onChange: PropTypes.func,
    /**
     * The function that calls whenever a key press event is registered.
     */
    onKeyPress: PropTypes.func,
    /**
     * The width of the input bar. Defaults to 100% of the parent.
     */
    width: PropTypes.string,
    /**
     * The width of the input bar. Defaults to 'auto'.
     */
    height: PropTypes.string,
    /**
     * The minimum height of the input bar. Defaults to '45px'.
     */
    minHeight: PropTypes.string,
    /**
     * The maximum height of the input bar. No default value.
     */
    maxHeight: PropTypes.string,
    /**
     * The bottom margin under the input bar. Defaults to '0px'.
     */
    mb: PropTypes.string,
    /**
     * The placeholder text in the input.
     */
    placeholder: PropTypes.string,
};

export default RsInput;
