import React, { Component } from 'react';

import Cross from '../../Icons/Cross';
import { Modal } from 'react-bootstrap';
import Rating from '../../Forms/Rating';
import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';

const Container = styled.div`
    padding-top: 29px;
    padding-right: 30px;
    padding-left: 34px;
`;

const ModalHeader = styled(Modal.Header)`
    align-items: center;
`;

const titleStyle = {
    fontWeight: 'bold',
    fontSize: '30px',
    marginBottom: '-20px',
};

const bodyStyle = {
    textAlign: 'left',
    color: 'rgba(255, 255, 255, 0.78)',
};

export class SongFeedbackModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHide: this.props.show,
            feedback: '',
            rating: null,
            emails: ['', '', ''],
            showEnding: false,
        };
        this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
        this.submit = this.submit.bind(this);
        this.handleRatingClick = this.handleRatingClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleEndingShowHide = this.handleEndingShowHide.bind(this);
    }

    handleModalShowHide() {
        const { emails } = this.state;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if all the entered emails are valid
        const allEmailsValid = emails.every((email) => emailRegex.test(email) || email === '');

        if (allEmailsValid) {
            this.setState({
                showHide: !this.state.showHide,
                showEnding: !this.state.showEnding,
            });
        } else {
            alert('Invalid email address!');
            console.log('Invalid email address!');
        }
    }

    handleEndingShowHide() {
        this.setState({
            showEnding: !this.state.showEnding,
            emails: ['', '', ''],
            feedback: '',
            rating: null,
        });
        const validEmails = this.state.emails.filter((email) => email !== '').map((email) => email.trim().toLowerCase());
        this.props.firebase.sendInvites(this.props.id, validEmails);
        if (this.props.onClose) this.props.onClose();
    }

    handleFeedbackChange(event) {
        this.setState({ feedback: event.target.value });
    }

    handleEmailChange(index, event) {
        const newEmails = [...this.state.emails];
        newEmails[index] = event.target.value;
        this.setState({ emails: newEmails });
    }

    submit() {
        this.props.completeFeedback(this.props.id, this.state.rating, this.state.feedback, this.props.userToken.claims.teacher);
        this.handleEndingShowHide();
    }

    handleRatingClick(rating) {
        this.setState({ rating: rating });
    }

    render() {
        return (
            <div>
                <Modal
                    dialogClassName="border-radius-16-wide gradient-background"
                    show={this.props.show}
                    onHide={this.handleModalShowHide}
                    centered
                    animation={false}
                >
                    {!this.state.showEnding ? (
                        <Container>
                            <ModalHeader>
                                <Modal.Title style={titleStyle}>Did your class enjoy this song?</Modal.Title>
                                <Cross size="18px" onClick={this.handleModalShowHide} />
                            </ModalHeader>
                            <Modal.Body style={bodyStyle}>
                                Your feedback is important to us!
                                <Rating rating={this.state.rating} handleRatingClick={this.handleRatingClick} />
                            </Modal.Body>
                            <ModalHeader>
                                <Modal.Title style={titleStyle}>
                                    Want to share this song?{' '}
                                    <span role="img" aria-label="share">
                                        📧
                                    </span>
                                </Modal.Title>
                            </ModalHeader>
                            <Modal.Body style={bodyStyle}>
                                Enter your fellow educators’ emails below — they’ll be able to listen for free!{' '}
                                <span role="img" aria-label="celebration emoji">
                                    🥳
                                </span>{' '}
                                <br />
                                <br />
                                {this.state.emails.map((email, index) => (
                                    <RsInput
                                        key={index}
                                        value={email}
                                        height="37px"
                                        width="100%"
                                        textarea={true}
                                        placeholder="Enter email address here"
                                        onChange={(event) => this.handleEmailChange(index, event)}
                                    />
                                ))}
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {/* <RsButton secondary={true} onClick={this.handleModalShowHide}>
                  Ask me later
                </RsButton> */}
                                3/3 share credits remaining on this song
                                <RsButton disabled={this.state.rating == null} onClick={this.handleModalShowHide}>
                                    Share Song
                                </RsButton>
                            </Modal.Footer>
                        </Container>
                    ) : (
                        <Container>
                            <ModalHeader>
                                <Modal.Title style={titleStyle}>Did your class enjoy this song?</Modal.Title>
                            </ModalHeader>
                            <Modal.Body style={bodyStyle}>
                                Thanks! If you want to give more feedback, you can type it below.{' '}
                                <span role="img" aria-label="pointer">
                                    👇
                                </span>
                                <RsInput onChange={this.handleFeedbackChange} value={this.state.feedback} textarea={true}></RsInput>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <RsButton secondary={true} onClick={this.handleEndingShowHide}>
                                    Nah, all good
                                </RsButton>
                                <RsButton disabled={this.state.feedback == null} onClick={this.submit}>
                                    Submit
                                </RsButton>
                            </Modal.Footer>
                        </Container>
                    )}
                </Modal>
            </div>
        );
    }
}

export default withFirebase(SongFeedbackModal);
