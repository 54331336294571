import React, { Component } from 'react';

import AddToPlaylistModal from '../../../Modals/AddToPlaylistModal';
import Create from '../../../Icons/Create';
import DropdownMenu from '../../../ButtonsOrLinks/DropdownMenu';
import FontSizeControl from '../../../AudioOrLyrics/FontSizeController';
import Fullscreen from '../../../Icons/Fullscreen';
import List from '../../../Icons/List';
import Pencil from '../../../Icons/Pencil';
import Reset from '../../../Icons/Reset';
import ShareToClassModal from '../../../Modals/ShareToClassModal';
import Tooltip from '../../../Misc/Tooltip';
import styled from 'styled-components';

const MainWrapper = styled.div`
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ fullscreen }) => fullscreen && `padding: 0;`}
`;

const IconWrap = styled.div`
    margin-right: 20px;
`;

const FullscreenWrap = styled.div`
    top: ${({ fullscreen }) => (fullscreen ? '5%' : 'unset')};
    display: flex;
    align-items: center;
    position: fixed;
    right: 10%;
    justify-content: center;
    flex-direction: column;
`;

class ViewHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showShareSongToClass: false,
            showAddToPlaylist: false,
        };
        this.toggleShareSongToClassModal = this.toggleShareSongToClassModal.bind(this);
        this.toggleAddToPlaylistModal = this.toggleAddToPlaylistModal.bind(this);
    }

    toggleAddToPlaylistModal() {
        this.setState({ showAddToPlaylist: !this.state.showAddToPlaylist });
    }

    toggleShareSongToClassModal() {
        this.setState({ showShareSongToClass: !this.state.showShareSongToClass });
    }

    render() {
        const setMenuItems =
            this.props.featureName === 'Karaoke'
                ? null
                : this.props.userSets.map((set) => ({
                      desc: set.name,
                      toggleMenuItemAction: () => this.props.selectSet(set.setId),
                      requiredRole: 'Both',
                  }));
        const menuItems = [
            {
                toggleMenuItemAction: this.toggleAddToPlaylistModal,
                requiredRole: 'Both',
                desc: 'Add to playlist',
            },
            {
                toggleMenuItemAction: this.toggleShareSongToClassModal,
                requiredRole: 'Teacher',
                desc: 'Add song to class',
            },
        ];
        return (
            <MainWrapper fullscreen={this.props.fullscreen}>
                {this.props.featureName === 'Karaoke' && (
                    <>
                        <AddToPlaylistModal id={this.props.id} toggle={this.toggleAddToPlaylistModal} show={this.state.showAddToPlaylist} />
                        <ShareToClassModal
                            toggle={this.toggleShareSongToClassModal}
                            show={this.state.showShareSongToClass}
                            contentType={'Song'}
                            contentId={this.props.id}
                        />
                    </>
                )}
                <div>
                    {this.props.featureName !== 'Karaoke' && !this.props.fullscreen ? (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                }}
                            >
                                <h1 className="bold" style={{ margin: 0 }}>
                                    {this.props.title}
                                </h1>
                                <p className="secondary" style={{ fontSize: '14px', margin: 0, marginLeft: '10px' }}>
                                    by {this.props.authorName}
                                </p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {this.props.featureName !== 'Breakdown' && (
                                    <IconWrap>
                                        <Tooltip placement="bottom" text="Reset">
                                            <Reset onClick={this.props.reset} />
                                        </Tooltip>
                                    </IconWrap>
                                )}

                                {this.props.isAuthor && (
                                    <IconWrap>
                                        <Tooltip placement="bottom" text="Edit">
                                            <Pencil onClick={this.props.toggleEdit} />
                                        </Tooltip>
                                    </IconWrap>
                                )}
                                <IconWrap>
                                    <Tooltip placement="bottom" text="Create a set">
                                        <Create onClick={this.props.customContentModalToggle} />
                                    </Tooltip>
                                </IconWrap>
                                <IconWrap>
                                    <DropdownMenu menuItems={setMenuItems} direction={'center'}>
                                        <Tooltip placement="bottom" text="Change set">
                                            <List onClick={() => null} />
                                        </Tooltip>
                                    </DropdownMenu>
                                </IconWrap>
                            </div>
                        </div>
                    ) : null}
                </div>
                <FullscreenWrap fullscreen={this.props.fullscreen}>
                    {this.props.fullscreen ? (
                        <Fullscreen toEnter={false} onClick={this.props.clickFullScreen} />
                    ) : (
                        <>
                            {this.props.featureName === 'Karaoke' && (
                                <div style={{ position: 'relative', right: '-78px', marginBottom: '20px', top: '-13px' }}>
                                    <DropdownMenu
                                        iconSize="large"
                                        isTeacher={this.props?.userToken?.claims?.teacher}
                                        menuItems={menuItems}
                                        direction={'left'}
                                    />
                                </div>
                            )}
                            {/* <Tooltip placement="bottom" text="Enter fullscreen">
                <Fullscreen toEnter={true} onClick={this.props.clickFullScreen} />
              </Tooltip> */}
                        </>
                    )}
                    {this.props.fontSizeControl && (
                        <FontSizeControl fontSize={this.props.fontSize} onChange={this.props.handleFontSizeChange} top={'80px'} right={'-100px'} />
                    )}
                </FullscreenWrap>
            </MainWrapper>
        );
    }
}

export default ViewHeader;
