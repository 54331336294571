import React, { Component } from 'react';
import styled from 'styled-components';
import ColorPickerModal from '../../../../UI-Library/Modals/ColorPickerModal';
import FormModal from '../../../../UI-Library/Modals/FormModal';

import DropdownMenu from '../../../../UI-Library/ButtonsOrLinks/DropdownMenu';

import { rowsPaletteList } from '../../../../../constants/colors';
import DeleteConfirmModal from '../../../../UI-Library/Modals/DeleteConfirmModal';
import Tooltip from '../../../../UI-Library/Misc/Tooltip';
import Copy from '../../../../UI-Library/Icons/Copy';

import GoogleShareToClassRoom from 'google-classroom-share';

const MainWrapper = styled.div`
    display: flex;
`;

const IconWrapper = styled.span`
    margin: 0;
    margin-right: 10px;
    height: 32px;
`;

class PlaylistTopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editShowHide: false,
            deleteShowHide: false,
            colorShowHide: false,
        };
        this.handleEditModalShowHide = this.handleEditModalShowHide.bind(this);
        this.handleDeleteModalShowHide = this.handleDeleteModalShowHide.bind(this);
        this.handleAddToClassModalShowHide = this.handleAddToClassModalShowHide.bind(this);
        this.onSelectColor = this.onSelectColor.bind(this);
        this.handleColorModalShowHide = this.handleColorModalShowHide.bind(this);
    }

    handleEditModalShowHide() {
        this.setState({ editShowHide: !this.state.editShowHide });
    }

    handleDeleteModalShowHide() {
        this.setState({ deleteShowHide: !this.state.deleteShowHide });
    }

    handleAddToClassModalShowHide() {
        this.props.setClassShareType('Playlist', this.props.id);
        this.props.toggleClassShare();
    }

    handleColorModalShowHide() {
        this.setState({ colorShowHide: !this.state.colorShowHide });
    }

    onSelectColor(hex) {
        this.props.onSelectColor(hex);
        this.handleColorModalShowHide();
    }

    render() {
        var menuItems = [];
        const isTeacher = this.props.userToken.claims.teacher;
        if (this.props.isAuthor) {
            menuItems = menuItems.concat(
                {
                    toggleMenuItemAction: this.handleEditModalShowHide,
                    requiredRole: 'Both',
                    desc: 'Edit details',
                },
                {
                    toggleMenuItemAction: this.handleColorModalShowHide,
                    requiredRole: 'Both',
                    desc: 'Change color',
                },
                {
                    toggleMenuItemAction: this.handleDeleteModalShowHide,
                    requiredRole: 'Both',
                    desc: 'Delete playlist',
                },
            );
        }

        menuItems.push({
            toggleMenuItemAction: this.handleAddToClassModalShowHide,
            requiredRole: 'Teacher',
            desc: 'Add playlist to class',
        });

        return (
            <MainWrapper>
                <IconWrapper>
                    <Tooltip placement="bottom" text="Share playlist to Google Classroom">
                        <GoogleShareToClassRoom
                            body={`I've made a playlist to share some of my favorite songs, click the link to give them a listen!`}
                            itemType="material"
                            url={`${window.location.origin}/internal/playlistView/${this.props.id}`}
                            title={`Check out my rapStudy playlist "${this.props.name}"`}
                            theme="dark"
                            onShare={(type) => console.log(`GoogleShareToClassRoom:${type}`)}
                            onShareComplete={() => {
                                console.log('GoogleShareToClassRoom:onShareComplete');
                                this.props.logAnalyticsShare();
                            }}
                            onShareStart={() => console.log('GoogleShareToClassRoom:onShareStart')}
                        />
                    </Tooltip>
                </IconWrapper>
                <IconWrapper>
                    <Tooltip placement="bottom" text="Copy link">
                        <Copy size={'32px'} onClick={this.props.copyLink} />
                    </Tooltip>
                </IconWrapper>
                <DropdownMenu iconSize="large" isTeacher={isTeacher} menuItems={menuItems} direction={'left'} />
                <FormModal
                    submit={this.props.changeHeader}
                    toggle={this.handleEditModalShowHide}
                    show={this.state.editShowHide}
                    name={this.props.name}
                    desc={this.props.desc}
                />
                <DeleteConfirmModal
                    delete={this.props.delete}
                    toggle={this.handleDeleteModalShowHide}
                    show={this.state.deleteShowHide}
                    title={'Delete Playlist?'}
                />
                <ColorPickerModal
                    hexList={rowsPaletteList}
                    toggle={this.handleColorModalShowHide}
                    onSelect={this.onSelectColor}
                    show={this.state.colorShowHide}
                    selected={this.props.color}
                />
            </MainWrapper>
        );
    }
}

export default PlaylistTopMenu;
