import React, { Component } from 'react';

import PropTypes from 'prop-types';

class Fullscreen extends Component {
    render() {
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 22 25" fill="none">
                    <path
                        d="M21.4986 0.977524C21.4861 0.701667 21.2523 0.488205 20.9764 0.500744L16.4811 0.705078C16.2052 0.717617 15.9917 0.951409 16.0043 1.22727C16.0168 1.50312 16.2506 1.71659 16.5265 1.70405L20.5223 1.52242L20.704 5.51829C20.7165 5.79415 20.9503 6.00761 21.2262 5.99507C21.502 5.98253 21.7155 5.74874 21.7029 5.47288L21.4986 0.977524ZM13.5685 9.8801L21.3684 1.33736L20.6299 0.663094L12.83 9.20583L13.5685 9.8801Z"
                        fill="white"
                    />
                    <path
                        d="M0.501735 23.9051C0.525802 24.1802 0.768319 24.3837 1.04341 24.3596L5.52629 23.9674C5.80138 23.9434 6.00487 23.7009 5.98081 23.4258C5.95674 23.1507 5.71422 22.9472 5.43913 22.9712L1.45435 23.3199L1.10573 19.3351C1.08166 19.06 0.839145 18.8565 0.564054 18.8806C0.288962 18.9046 0.0854667 19.1472 0.109534 19.4222L0.501735 23.9051ZM8.05252 14.6786L0.61681 23.5401L1.38285 24.1829L8.81857 15.3214L8.05252 14.6786Z"
                        fill="white"
                    />
                </svg>
            </div>
        );
    }
}

Fullscreen.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
    /**
     * Does the icon indicate entering fullscreen mode?
     */
    toEnter: PropTypes.bool,
};

export default Fullscreen;
