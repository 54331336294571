import SubjectDict from '../../Classes/SubjectDict.js';
import DescriptionDict from '../../Classes/DescriptionDict.js';
import TopicDict from '../../Classes/TopicDict.js';

//TODO: Factor these functions into one helper

export async function getGradeRefs() {
    var querySnap = await this.db
        .collection('standards') //devstandards
        .withConverter(SubjectDict.subjectConverter)
        .get();
    if (!querySnap || querySnap.docs.length === 0) return null;
    return await Promise.all(
        querySnap.docs.map(async (snap) => {
            const gradeDict = snap.data();
            return gradeDict;
        }),
    );
}

export async function getStandardRefs() {
    var querySnap = await this.db.collection('standard').withConverter(SubjectDict.subjectConverter).get();
    if (!querySnap || querySnap.docs.length === 0) return null;
    return await Promise.all(
        querySnap.docs.map(async (snap) => {
            const subjectDict = snap.data();
            return subjectDict;
        }),
    );
}

export async function getStandardsInGrades(gradeRef) {
    if (gradeRef === null) return;
    const standardQuery = await gradeRef.collection('standard').withConverter(SubjectDict.subjectConverter).get();
    return standardQuery.docs.map((snap) => {
        const standardDict = snap.data();
        return standardDict;
    });
}

export async function getSubjects(standardRef) {
    if (standardRef === null) return;
    const subjectQuery = await standardRef.collection('subject').withConverter(SubjectDict.subjectConverter).get();
    return subjectQuery.docs.map((snap) => {
        const subjectDict = snap.data();
        return subjectDict;
    });
}

export async function getUnits(subjectRef) {
    if (subjectRef === null) return;
    const unitQuery = await subjectRef.collection('unit').withConverter(SubjectDict.subjectConverter).get();
    return unitQuery.docs.map((snap) => {
        const unitDict = snap.data();
        return unitDict;
    });
}

export async function getTopics(unitRef) {
    if (unitRef === null) return;
    const topicQuery = await unitRef.collection('topic').withConverter(TopicDict.topicConverter).get();
    return await Promise.all(
        topicQuery.docs.map(async (snap) => {
            const topicDict = snap.data();

            // TODO: Just use firebaseImg field when db is updated.
            topicDict.setSrc(await this.getTopicImageURL(topicDict.imgName));
            return topicDict;
        }),
    );
}

export async function getSubTopics(topicRef) {
    if (topicRef === null) return;
    const subTopicQuery = await topicRef.collection('subTopic').withConverter(SubjectDict.subjectConverter).get();
    return subTopicQuery.docs.map((snap) => {
        const subTopicDict = snap.data();
        return subTopicDict;
    });
}

export async function getSubStandards(subTopicRef) {
    if (subTopicRef === null) return;
    const subStandardQuery = await subTopicRef.collection('subStandard').withConverter(SubjectDict.subjectConverter).get();
    return subStandardQuery.docs.map((snap) => {
        const subStandardDict = snap.data();
        return subStandardDict;
    });
}

export async function getDescriptions(subStandardRef) {
    if (subStandardRef === null) return;
    const subStandardDescQuery = await subStandardRef.collection('subStandardDescription').withConverter(DescriptionDict.descriptionConverter).get();
    return subStandardDescQuery.docs.map((snap) => {
        const subStandardDescDict = snap.data();
        return subStandardDescDict;
    });
}

export async function getTopicImageURL(id) {
    if (typeof id == 'undefined') {
        return undefined;
    }
    var url;
    try {
        url = await this.storage.ref('images/topics').child(id).getDownloadURL();
    } catch (err) {
        url = null;
    }

    return url;
}
