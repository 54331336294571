import React, { Component } from 'react';
import styled from 'styled-components';

import { device } from '../../../../../constants/breakpoints';

const ProfileWrapper = styled.div`
    width: 150px;
    height: 150px;
    background-image: url(${(props) => props.src});
    background-size: contain;
    margin-right: 45px;
    margin-left: 45px;
    margin-bottom: 140px;
    border-radius: 50%;

    @media ${device.laptop} {
        width: 150px;
        height: 150px;
        margin-right: 25px;
        margin-bottom: 25px;
    }

    @media ${device.tablet} {
        width: 150px;
        height: 150px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    @media ${device.mobileL} {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
`;

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 200px;
`;

const Name = styled.p`
    font-size: 18px;
    font-weight: bold;

    @media ${device.tablet} {
        font-size: 14px;
    }

    @media ${device.mobileL} {
        font-size: 8px;
    }
`;

const Pos = styled.p`
    margin-bottom: 0;
    font-size: 14px;
    color: #889097;

    @media ${device.tablet} {
        font-size: 12px;
    }

    @media ${device.mobileL} {
        font-size: 6px;
    }
`;

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.setState({ hover: true });
    }

    handleMouseLeave() {
        this.setState({ hover: false });
    }

    render() {
        return (
            <ProfileWrapper onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} src={this.props.src}>
                <Overlay>
                    <Name style={{ marginBottom: 0 }} className="text-center">
                        {this.props.name}
                    </Name>
                    <Pos style={{ marginBottom: 0 }} className="text-center">
                        {this.props.pos}
                    </Pos>
                </Overlay>
            </ProfileWrapper>
        );
    }
}

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
`;

class ProfileGrid extends Component {
    render() {
        return (
            <MainWrapper>
                {this.props.data.map((ele) => (
                    <Profile src={ele.src} name={ele.name} pos={ele.pos} />
                ))}
            </MainWrapper>
        );
    }
}

export default ProfileGrid;
