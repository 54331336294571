import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const hover = typeof this.props.hover !== 'undefined' ? this.props.hover : this.state.hover;

        const color = this.props.disabled ? 'var(--rsDisabled)' : hover ? 'var(--rsPrimary)' : 'var(--rsSecondary)';
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg
                    width={this.props.size ? this.props.size : '24px'}
                    height={this.props.size ? this.props.size : '24px'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                >
                    <path
                        d="M2.71,17V15A2.74,2.74,0,0,1,5.2,12.08h.89A2.74,2.74,0,0,1,8.58,15v2Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.39,17V15a2.74,2.74,0,0,1,2.5-2.92h.88A2.74,2.74,0,0,1,16.26,15v2Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle cx="9.36" cy="3.27" r="1.92" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <line x1="11.41" y1="10.75" x2="7.56" y2="10.75" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path
                        d="M6.47,8.25A2.8,2.8,0,0,1,8.92,5.56h.89a2.78,2.78,0,0,1,2.44,2.6"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle cx="5.65" cy="10.16" r="1.92" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <circle cx="13.33" cy="10.16" r="1.92" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        );
    }
}

Group.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default Group;
