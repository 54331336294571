import React, { Component } from 'react';

import DefaultImg from '../DefaultImg';
import PropTypes from 'prop-types';
import RsLink from '../../ButtonsOrLinks/RsLink';
import Trash from '../../Icons/Trash';
import styled from 'styled-components';

const BoxTitle = styled.p`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    font-size: 12px;
`;

const BoxSubTitle = styled.p`
    width: 140px;
    margin: 0;
    font-weight: normal;
    font-size: 11px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--rsSecondary);
    margin-top: -3px;
    padding-bottom: 4px;
`;

const BoxWrap = styled.div`
    width: 130px;
    margin-bottom: 10px;
    margin-right: ${(props) => (props.mr ? props.mr : '40px')};
    margin-left: ${(props) => props.ml && props.ml};
`;

// const ExpandWrap = styled.div`
//   position: relative;
//   top: 0;
//   margin-bottom: 10px;
//   margin-right: ${props => (props.mr ? props.mr : '40px')};
//   margin-left: ${props => props.ml && props.ml};
//   z-index: 9999;
//   &:hover {
//     transform: scale(1.1);
//     transition: transform 0.5s;
//   }
// `;

const ImageWrapper = styled.div`
    position: relative; // position the icon relative to this container
`;

const ArtistIconImg = styled.img`
    position: absolute;
    bottom: -7px;
    right: -30px;
    height: 60px;
    width: 60px;
    border-radius: 50%; // make the image circular
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); // add box-shadow
`;

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
`;

/**
 * BoxPreview is a square image with a background, a title, and a description
 * below. A "remove" function can be passed as a prop to allow for removing the
 * item from the parent component.
 *
 * Uses: DefaultImg
 */
class BoxPreview extends Component {
    state = {
        isHovered: false,
        showHi: false,
    };

    handleMouseEnter = () => {
        this.setState({ isHovered: true }, () => {
            this.hoverTimer = setTimeout(() => {
                if (this.state.isHovered) {
                    this.setState({ showHi: true });
                }
            }, 500);
        });
    };

    handleMouseLeave = () => {
        this.setState({ isHovered: false, showHi: false });
        clearTimeout(this.hoverTimer);
    };

    render() {
        const { mr, ml, bgColor, type, to, image, artistImage, title, remove, id, subtitle, state } = this.props;
        const Box = BoxWrap;
        return (
            <Box mr={mr} ml={ml} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <ImageWrapper>
                    <DefaultImg
                        color={bgColor}
                        type={type}
                        borderRadius={this.props.borderRadius}
                        isClickable={to ? true : false}
                        src={image}
                        to={to}
                        state={state}
                    />
                    {artistImage && <ArtistIconImg src={artistImage} alt="Artist Icon" />}
                </ImageWrapper>
                <TitleWrapper>
                    {to ? (
                        <RsLink to={to} small={true} bold={true} state={state}>
                            <BoxTitle center={this.props.center}>{title}</BoxTitle>
                        </RsLink>
                    ) : (
                        <BoxTitle>{title}</BoxTitle>
                    )}

                    {remove && <Trash size="18px" onClick={() => remove(id)} />}
                </TitleWrapper>
                <BoxSubTitle className="pt-0">{subtitle}</BoxSubTitle>
            </Box>
        );
    }
}

BoxPreview.propTypes = {
    /**
     * The optional source for the image to be used. The image will be
     * resized to be contained in the square.
     */
    image: PropTypes.string,
    /**
     * The route to redirect to upon clicking.
     */
    to: PropTypes.string,
    /**
     * The main title underneath the image.
     */
    title: PropTypes.string,
    /**
     * The subtitle.
     */
    subtitle: PropTypes.string,
    /**
     * The type of the item being passed. This is only necessary if the element
     * is either a playlist or class, as these are the only elements with default
     * icons to be passed into the DefaultImg child component.
     */
    type: PropTypes.string,
    /**
     * The background color for the image if the [image] prop is not defined.
     */
    bgColor: PropTypes.string,
    /**
     * The identifier for the passed element. Ex: the song id, or the playlist id.
     */
    id: PropTypes.string,
    /**
     * The function that removes the element. It must take a single parameter,
     * which is the the id of the element [id].
     */
    remove: PropTypes.func,
    /**
     * An optional override for the right margin of the component. Defaults to
     * 40px.
     */
    mr: PropTypes.string,
};

export default BoxPreview;
