import React, { Component } from 'react';
import styled from 'styled-components';
import './Loading.css';

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 15%;
`;

/**
 * The standard loading spinner based on the bootstrap component.
 * Aligns itself in the center of the parent component with a slight
 * top padding.
 */
class Loading extends Component {
    render() {
        var loadStyle = {
            marginTop: '40px',
            marginLeft: '-225px',
            paddingTop: '30px',
        };

        if (window.location.href.includes('breakdown') || window.location.href.includes('blanks') || window.location.href.includes('review')) {
            loadStyle = {
                marginTop: '-100px',
                marginLeft: '-225px',
                paddingTop: '30px',
            };
        }

        return (
            <MainWrapper>
                {this.props.zoom ? (
                    <div style={loadStyle}>
                        <img src="/images/animationAssets/record.png" style={{ verticalAlign: 'middle' }} className="App-logo-secondary" alt="logo" />
                    </div>
                ) : (
                    <div style={{ paddingTop: '50px' }}>
                        <img src="/images/logos/newlogo.png" style={{ verticalAlign: 'middle' }} className="App-logo" alt="logo" />
                    </div>
                )}
            </MainWrapper>
        );
    }
}
export default Loading;
