import React, { Component } from 'react';
import styled from 'styled-components';

import UnsplashLink from '../../../../../../UI-Library/ButtonsOrLinks/UnsplashLink';
import RsButton from '../../../../../../UI-Library/ButtonsOrLinks/RsButton';

import RemoveImageButton from '../../../../../../UI-Library/ButtonsOrLinks/RemoveImageButton';
import RsInput from '../../../../../../UI-Library/Forms/RsInput';

import Tooltip from '../../../../../../UI-Library/Misc/Tooltip';
import Trash from '../../../../../../UI-Library/Icons/Trash';
import AnswerChoice from '../../../../../../UI-Library/ButtonsOrLinks/AnswerChoice';

const MainBox = styled.div`
    border-radius: 16px;
    background-color: var(--rsSurface);
    padding: 40px;
`;

const Body = styled.div``;

const BoxTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    display: flex;
    margin-bottom: 15px;
`;

const AllChoices = styled.div``;

const IconWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const ChoiceText = styled.div`
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const QuestionInput = styled.input`
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 24px;
    width: 100%;
    color: var(--rsPrimary);
    font-weight: bold;
`;

const Footer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const ImagePlace = styled.img`
    max-width: 100%;
    max-height: 300px;
    box-shadow: 5px 5px 12px black;
    margin-bottom: 10px;
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 0;
`;

/**
 * QuestionEdit component is a single question editor.
 */
class QuestionEdit extends Component {
    render() {
        const q = this.props.question;
        const hasImg = q.getSrc() !== null;
        const hasLyrics = q.getLineStart() !== null;
        return (
            <MainBox>
                <Body className="row">
                    <div className="col">
                        <BoxTitle className="row">
                            <div className="col-sm-auto">
                                {this.props.index + 1}
                                {'. '}
                            </div>
                            <div className="col">
                                <QuestionInput
                                    type="input"
                                    placeholder="Enter a question here"
                                    value={q.getQuestion()}
                                    onChange={(e) => {
                                        this.props.handleQuestionChange(q.getIndex(), e);
                                    }}
                                />
                            </div>
                        </BoxTitle>
                        <AllChoices className="col-9">
                            {['A', 'B', 'C', 'D'].map((letter) => (
                                <ChoiceText className="row" key={`QuestionEditChoices_${q.getId()}_${letter}`}>
                                    <IconWrap className="col-sm-auto">
                                        <AnswerChoice
                                            selected={q.getCorrect() === letter}
                                            onClick={() => this.props.makeChoiceTrue(q.getIndex(), letter)}
                                            letter={letter}
                                        />
                                    </IconWrap>

                                    <div className="col">
                                        <RsInput
                                            value={q.getChoice(letter)}
                                            placeholder={'Choice ' + letter}
                                            onChange={(e) => {
                                                this.props.handleChoiceChange(q.getIndex(), letter, e);
                                            }}
                                        ></RsInput>
                                    </div>
                                </ChoiceText>
                            ))}
                        </AllChoices>
                    </div>
                    <ImageContainer className="col-3">
                        <div style={{ position: 'relative', width: 'fit-content' }}>
                            {q.getSrc() == null ? null : <RemoveImageButton onClickFn={this.props.removeImage} onClickArg={q.getIndex()} />}
                            <ImagePlace src={q.getSrc()} />
                        </div>
                        {q.unsplash && <UnsplashLink uid={q.unsplashUid} name={q.unsplashName} />}
                    </ImageContainer>
                </Body>

                <Footer className="row">
                    <div className="col">
                        <Tooltip placement="bottom" text="Delete question">
                            <Trash
                                onClick={() => {
                                    this.props.deleteQuestion(q.getIndex());
                                }}
                            />
                        </Tooltip>
                    </div>

                    <div className="col-sm-auto">
                        <RsButton
                            secondary={hasImg ? true : false}
                            onClick={() => {
                                this.props.toggleChooseImageModal(q.getIndex());
                            }}
                        >
                            {hasImg ? 'Change Image' : 'Choose Image'}
                        </RsButton>
                        <RsButton
                            ml={'30px'}
                            secondary={hasLyrics ? true : false}
                            onClick={() => {
                                this.props.toggleChooseLyricModal(q.getIndex());
                            }}
                        >
                            {hasLyrics ? 'Change Lyrics' : 'Choose Lyrics'}
                        </RsButton>
                    </div>
                </Footer>
            </MainBox>
        );
    }
}

export default QuestionEdit;
