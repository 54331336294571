import React, { Component } from 'react';

class Share extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: '2px',
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 15 22" fill="none">
                    <rect x="0.5" y="7" width="14" height="14" fill="black" stroke="#37DBF6" strokeWidth="2" rx="2" />
                    <path
                        d="M7.85355 0.646446C7.65829 0.451184 7.34171 0.451184 7.14645 0.646446L3.96447 3.82843C3.7692 4.02369 3.7692 4.34027 3.96447 4.53553C4.15973 4.7308 4.47631 4.7308 4.67157 4.53553L7.5 1.70711L10.3284 4.53553C10.5237 4.7308 10.8403 4.7308 11.0355 4.53553C11.2308 4.34027 11.2308 4.02369 11.0355 3.82843L7.85355 0.646446ZM8 13V1H7L7 13H8Z"
                        fill="#37DBF6"
                        stroke="#37DBF6"
                        strokeWidth="1"
                        rx="2"
                    />
                </svg>
            </div>
        );
    }
}

export default Share;
