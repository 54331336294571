import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    songDetails: {
        songID: null,
        coverImage: null,
        originalTitle: null,
        origArtist: null,
        newTitle: null,
        background: null,
        songLyrics: {},
        audioSrc: null,
        displayShare: false,
    },
    play: false,
    ended: false,
    currentTime: 0,
    audioLoaded: false,
};

const audioPlayerSlice = createSlice({
    name: 'audioPlayer',
    initialState,
    reducers: {
        setSongDetails(state, action) {
            state.songDetails.songID = action.payload.songID;
            state.songDetails.originalTitle = action.payload.originalTitle;
            state.songDetails.origArtist = action.payload.origArtist;
            state.songDetails.newTitle = action.payload.newTitle;
            state.songDetails.background = action.payload.background;
            state.songDetails.songLyrics = action.payload.songLyrics;
            state.songDetails.audioSrc = action.payload.audioSrc;
            state.songDetails.coverImage = action.payload.coverImage;
            state.audioLoaded = action.payload.audioSrc ? true : false;
            if (action.payload.reset) {
                state.play = true;
                state.ended = false;
                state.currentTime = 0;
            }
        },
        setBackground(state, action) {
            state.songDetails.background = action.payload;
        },
        setSongLyrics(state, action) {
            state.songDetails.songLyrics = action.payload;
        },
        setPlay(state, action) {
            state.play = action.payload;
        },
        setEnded(state, action) {
            state.ended = action.payload;
        },
        setCurrentTime(state, action) {
            state.currentTime = action.payload;
        },
        setAudioSrc(state, action) {
            state.songDetails.audioSrc = action.payload;
            state.audioLoaded = true;
        },
        setDisplayShare(state, action) {
            state.songDetails.displayShare = action.payload;
        },
    },
});

export const { setSongDetails, setSongLyrics, setPlay, setEnded, setBackground, setCurrentTime, setAudioSrc, setDisplayShare } = audioPlayerSlice.actions;
export default audioPlayerSlice.reducer;
