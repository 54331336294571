import * as React from 'react';
import { withFirebase } from '../../../Firebase';
import { useLocation, Link } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import BackArrow from '../../../UI-Library/ButtonsOrLinks/BackArrow';
import SimpleBanner from '../../../UI-Library/Containers/SimpleBanner';
import RsInput from '../../../UI-Library/Forms/RsInput';
import styled from 'styled-components';

const Lead = styled.p`
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
`;

const Artist = (artist) => {
    return (
        <div
            style={{
                display: 'inline-block',
                paddingRight: '30px',
                paddingBottom: '20px',
            }}
        >
            <Link
                to={{ pathname: ROUTES.BROWSE_PROFILE, state: { artist: artist } }}
                style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '15px',
                }}
            >
                <img src={artist?.image} width="180px" height="180px" alt="" style={{ borderRadius: '8px' }} />
                <div>{artist?.name}</div>
            </Link>
        </div>
    );
};

const ViewContainer = (artists, header, filter) => {
    return (
        <>
            <div style={{ display: 'inline-block', marginLeft: '30px' }}>
                <Lead>{header}</Lead>
                {artists.map((artist) => {
                    return artist?.name.toLowerCase().includes(filter?.toLowerCase()) ? Artist(artist) : null;
                })}
            </div>
        </>
    );
};

function ViewAllArtists() {
    const artists = useLocation().state?.artists;
    const header = useLocation().state?.header;

    const [filter, setFilter] = React.useState('');

    const handleInputChange = (e) => {
        e.persist();
        setFilter(e.target.value);
    };

    return (
        <>
            <SimpleBanner>
                <RsInput placeholder={' Find Your Favorite Artist!'} width={'60%'} value={filter} onChange={(e) => handleInputChange(e)} />
                <BackArrow />
            </SimpleBanner>
            {ViewContainer(artists, header, filter)}
        </>
    );
}

export default withFirebase(ViewAllArtists);
