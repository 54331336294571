import * as ROUTES from '../../constants/routes';

import Question from '../../Classes/Question.js';
import QuestionList from '../../Classes/QuestionList.js';
import { v4 as uuid } from 'uuid';

export async function getQuestionSetsForSong(songId) {
    const setsRef = this.db.collection('questionSets');
    const querySnap = await Promise.all([
        setsRef.where('author', '==', this.auth.currentUser.uid).where('songId', '==', songId).orderBy('lastModified', 'desc').get(),
        setsRef.where('author', '==', process.env.REACT_APP_RAPSTUDY_UID).where('songId', '==', songId).orderBy('lastModified', 'desc').get(),
    ]);

    const mergedSnapshots = querySnap.reduce((acc, snap) => acc.concat(snap.docs), []);
    if (mergedSnapshots.length === 0) return [];

    return mergedSnapshots.map((docSnap) => {
        const obj = docSnap.data();
        obj.setId = docSnap.id;
        return obj;
    });
}

/** Queries the given song id's questions URL from firestore with a custom set.
 *
 * @param {string} setId
 * @returns a Promise that resolves with an array of Question objects.
 */
export async function getQuestionList(setId) {
    let qList = null;
    if (setId !== 'null') {
        const setDoc = this.db.collection('questionSets').doc(setId);
        qList = new QuestionList();

        // Setup promises
        const setPr = setDoc.get();
        const questionPr = setDoc.collection('questions').orderBy('index').withConverter(Question.questionConverter).get();

        // resolve promises
        const resources = await Promise.all([setPr, questionPr]);
        const setSnap = resources[0];

        if (!setSnap.exists) throw this.NotFoundError;

        const questionDocs = resources[1].docs;
        questionDocs.forEach((question) => {
            qList.add(question.data());
        });

        // Metadata handling
        const name = setSnap.get('name');
        const authorId = setSnap.get('author');
        const songId = setSnap.get('songId');
        const authorProfileSnap = await this.db.collection('userProfiles').doc(authorId).get();
        const authorName = authorProfileSnap.get('first_name') + ' ' + authorProfileSnap.get('last_name');

        qList.setName(name);
        qList.setAuthorId(authorId);
        qList.setAuthorName(authorName);
        qList.setSongId(songId);
    }
    return qList;
}

/**
 * Updates an existing question set's content
 * @param {string} songId
 * @param {string} set_id
 * @param {string} name
 * @param {QuestionList} qList
 */
export async function updateCustomQuestions(songId, set_id, qList) {
    if (!set_id || !qList) {
        throw Error('Update questions called with invalid parameters.');
    }
    const setRef = this.db.collection('questionSets').doc(set_id);
    const questionsRef = setRef.collection('questions');

    const query = await questionsRef.get();
    const qDocs = query.docs;
    const firebaseRefs = qDocs.map((doc) => {
        return doc.ref;
    }); // array of firebase document references

    // 1. delete refs in firestore but not in local
    await Promise.all(
        firebaseRefs.map(async (ref) => {
            if (!qList.hasId(ref.id)) {
                await ref.delete();
            }
        }),
    );

    // 2. for all local refs, set to create new OR overwrite
    const questions = qList.getQuestions();
    await Promise.all(
        Object.values(questions).map(async (question) => {
            await questionsRef.doc(question.getId()).withConverter(Question.questionConverter).set(question);
        }),
    );

    // 3. update name and numitems
    await setRef.update({
        name: qList.getName(),
        numItems: qList.getLength(),
        lastModified: this.firebase.firestore.FieldValue.serverTimestamp(),
    });
    return;
}

/**
 * Creates a new document for question sets content
 * @param {string} songId
 * @param {string} name
 * @returns {string} Firestore id of the set document.
 */
export async function createCustomQuestions(songId, name) {
    const questionSetsRef = this.db.collection('questionSets');
    const setRef = await questionSetsRef.add({
        name: name,
        author: this.auth.currentUser.uid,
        lastModified: this.firebase.firestore.FieldValue.serverTimestamp(),
        created: this.firebase.firestore.FieldValue.serverTimestamp(),
        songId: songId,
    });
    //Log in event 'set_created' [Firebase Analytics]
    this.analytics.logEvent('set_created', {
        feature: 'review',
    });
    return setRef.id;
}

/**
 * Deletes the given question set document.
 * @param {string} songId
 * @param {string} setId
 */
export async function deleteCustomQuestions(songId, setId) {
    const deleteReviewSet = this.functions.httpsCallable('featureSets-deleteFeatureSet');
    await deleteReviewSet({ setId: setId, featureName: 'Review' });
}

export async function setCustomQuestionImg(file, fileName) {
    // TODO Review: case work on what 'file' is
    const type = file.type.split('/')[1];
    if (type === 'jpeg' || type === 'png') {
        const fileRef = this.storage.ref('users/' + this.auth.currentUser.uid + '/images/' + fileName);
        await fileRef.put(file);
        const url = await fileRef.getDownloadURL();
        return url;
    }
}

/**
 * Creates a new document in the invites collection that links the song details to an inviteId and an email.
 * @param {string} songId
 * @param {string} email
 * @returns {string} inviteId
 */
export async function createSongInvite(songId, email) {
    const songsRef = this.db.collection('songs');
    const songDoc = await songsRef.doc(songId).get();
    if (!songDoc.exists) throw new Error('Song does not exist');

    const songData = songDoc.data();
    const mp3Url = await this.getMp3(songId);
    const jsonTxt = await this.getJson(songId);
    const imgUrl = await this.getSongCover(songData.subtopic.subtopicRef.id);

    const subtopicDoc = await songData.subtopic.subtopicRef.get();
    const subtopicData = subtopicDoc.exists ? subtopicDoc.data() : {};
    const inviter = this.auth.currentUser.displayName;

    const inviteId = uuid();

    const invitesRef = this.db.collection('invites');

    await invitesRef.doc(inviteId).set({
        ...songData,
        songId: songDoc.id,
        inviteId,
        mp3Url,
        jsonTxt,
        imgUrl,
        subtopicData,
        inviter,
    });
    // Get a reference to the invitees collection
    try {
        const inviteesRef = this.db.collection('invitees');
        // Check if the invitee already exists
        const inviteeQuery = await inviteesRef.where('email', '==', email).get();
        if (inviteeQuery.empty) {
            // Create a new invitee document
            const inviteeDoc = inviteesRef.doc();
            await inviteeDoc.set({ email });

            // Add the invite to the invitee's collection
            const inviteSubcollectionRef = inviteeDoc.collection('invites');
            await inviteSubcollectionRef.doc(inviteId).set({
                inviteId,
                songId: songDoc.id,
            });
        } else {
            // Add the invite to the existing invitee's collection
            const inviteeDoc = inviteeQuery.docs[0];
            const inviteSubcollectionRef = inviteeDoc.ref.collection('invites');
            await inviteSubcollectionRef.doc(inviteId).set({
                inviteId,
                songId: songDoc.id,
            });
            // validate the user in firebase if they are not already because they have a new invite now
            await this.functions.httpsCallable('auth-invalidateInvitee')({
                email: email,
                invalidate: false,
            });
        }
    } catch (error) {
        console.log('Error creating invite: ' + error);
    }

    return inviteId;
}

/**
 * Deletes the invite with the given inviteId and removes it from the invitee's collection.
 * @param {string} inviteId
 * @param {string} email
 */
export async function deleteSongInvite(inviteId, email) {
    // Get a reference to the invites collection
    const invitesRef = this.db.collection('invites');

    // Delete the document with the given inviteId
    await invitesRef.doc(inviteId).delete();

    // Get a reference to the invitees collection
    const inviteesRef = this.db.collection('invitees');

    // Query the invitees collection to find the invitee document based on the email
    const inviteeQuery = await inviteesRef.where('email', '==', email).get();

    if (!inviteeQuery.empty) {
        const inviteeDoc = inviteeQuery.docs[0];
        const inviteSubcollectionRef = inviteeDoc.ref.collection('invites');

        // Delete the invite from the invitee's collection
        await inviteSubcollectionRef.doc(inviteId).delete();

        // Check if the invitee's invites collection is empty
        const inviteeInvitesQuery = await inviteSubcollectionRef.get();

        if (inviteeInvitesQuery.empty) {
            // Call the invalidateInvitee cloud function on the user
            await this.functions.httpsCallable('auth-invalidateInvitee')({
                email: email,
                invalidate: true,
            });
        }
    }
}

/**
 * Retrieves the song data associated with the given invite and fetches its JSON.
 * @param {string} inviteId
 * @param {string} email
 * @returns {Object} song data or null if no song is found
 */
export async function getSongFromInvite(inviteId, email) {
    const inviteesRef = this.db.collection('invitees');
    const inviteeQuery = await inviteesRef.where('email', '==', email).get();

    if (!inviteeQuery.empty) {
        console.log(inviteeQuery.docs[0].data());
        const inviteeDoc = inviteeQuery.docs[0];
        const inviteSubcollectionRef = inviteeDoc.ref.collection('invites');
        const inviteSnapshot = await inviteSubcollectionRef.doc(inviteId).get();

        if (inviteSnapshot.exists) {
            const inviteRef = this.db.collection('invites');
            const inviteDoc = await inviteRef.doc(inviteId).get();
            console.log('retrieved invite doc');

            if (inviteDoc.exists) {
                // Extract the data from the document
                let data = inviteDoc.data();
                const mp3Url = data.mp3Url;
                const imgUrl = data.imgUrl;

                return [data, data.jsonTxt, mp3Url, imgUrl, data.subtopicData];
            }
        }
    } else console.log('No invitee found with email: ' + email);

    return null;
}

/**
 * Sends an email invite for a song to each email address in the provided array.
 * @param {string} songId The ID of the song to invite users to.
 * @param {Array} emails An array of email addresses to send invites to.
 */
export async function sendInvites(songId, emails) {
    // Define a callable function for sending emails
    const sendEmail = this.functions.httpsCallable('misc-sendEmail');
    // Loop over each email
    for (const email of emails) {
        try {
            // Generate an invite for the song
            const inviteId = await this.createSongInvite(songId, email);
            const inviter = this.auth.currentUser.displayName;

            // Generate an invite link (replace with your actual domain)
            const inviteLink = `${process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT}${ROUTES.INVITE_KARAOKE}?songId=${inviteId}`;
            const body = `
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<div dir="ltr">Hi there!&nbsp;👋<div><br></div>
    <div>
        <div>${inviter} gifted you a free rapStudy song — click&nbsp;<a href=${inviteLink} target="_blank">this
                link</a>&nbsp;to start learning&nbsp;lyrically!&nbsp;🎶</div>
        <div><br></div>
        <div>Please let me know if you have any questions or feedback. I hope you enjoy!&nbsp;🤗</div>
        <div><br></div>
        <div>
            <div dir="ltr" class="gmail_signature">
                <div dir="ltr">
                    <div>Kindest regards,<br><br>
                        <div>Drew A. Speckman<div>Founder &amp; CEO</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div dir="ltr" class="gmail_signature">
            <div dir="ltr">
                <div>
                    <div>
                        <div><br></div>
                        <div><i>
                                <font size="1">&quot;After silence, that which comes nearest to expressing the
                                    inexpressible is music.&quot; -&nbsp;Aldous Huxley</font>
                            </i></div>
                    </div>
                </div>
                <div><br></div>
                <div><img width="96" height="32"
                        src="https://ci3.googleusercontent.com/mail-sig/AIorK4x8m0ZG2ImPXGwE4L7bEx2bQFgBQEprDUjMbo8eLeYIB2NVzfbcXPidNP1nwc6K9KRBrIDsbE0"
                        class="gmail-CToWUd"><br></div>
                <div><br></div>
                <div>
                    <div>
                        <font size="1"><b style="color:rgb(0,0,0)">RapStudy Inc.</b><br></font>
                    </div>
                    <div>
                        <div>
                            <font color="#000000" size="1"><b>9100 Wilshire Blvd.</b></font>
                        </div>
                        <div><b style="color:rgb(0,0,0)">
                                <font size="1">Suite 850E</font>
                            </b></div>
                        <div>
                            <font color="#000000" size="1"><b>Beverly Hills, CA 90212</b></font>
                        </div>
                    </div>
                    <div><br></div>
                </div>
                <div>
                    <font size="1" style="color:rgb(153,153,153)">This e-mail, and any attachments and links hereto, are
                        intended only for use by the addressee(s) named herein and may contain legally privileged and/or
                        confidential information. If you are not the intended recipient of this e-mail, you are hereby
                        notified that any dissemination, distribution or copying of this e-mail, and any attachments and
                        links hereto, is strictly prohibited. If you have received this e-mail in error, please
                        immediately notify me and permanently delete the original and any copies thereof, including of
                        the attachments and links hereto.&nbsp;</font><span
                        style="color:rgb(153,153,153);font-size:x-small">Disclaimer: All information has been referenced
                        and/or projected in earnest but does not guarantee accuracy or financial
                        returns.&nbsp;</span><span style="color:rgb(153,153,153);font-size:x-small">Draft: Information
                        and terms are subject to change. Terms are only binding upon the mutual execution of a formal
                        agreement.&nbsp;</span><span style="color:rgb(153,153,153);font-size:x-small">Position: The
                        foregoing is without prejudice to any and all rights, remedies, claims and contentions of Drew
                        A. Speckman, RapStudy Inc., and/or the matter, all of which are expressly reserved.</span>
                </div>
            </div>
        </div>
    </div>
</div>`;

            const data = {
                to: email,
                subject: `[rapStudy] Gifted Song from ${inviter}! 🥳`,
                body: body,
            };

            // Send the email
            await sendEmail(data);
        } catch (error) {
            console.error(`Failed to send invite to ${email}:`, error);
        }
    }
}
