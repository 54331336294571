import * as ROUTES from '../../../constants/routes';

import { Link, NavLink, withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import Gear from '../../UI-Library/Icons/Gear';
import Info from '../../UI-Library/Icons/Info';
import Library from './Library';
import Main from './Main';
import MiniPlayer from './MiniPlayer';
import Playlists from './Playlists';
import TimeContext from '../../Contexts/CurrentTime';
import UserInitial from '../../UI-Library/Misc/UserInitial';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';

const HeaderPhoto = styled.img`
    height: 55px;
    width: auto;
    margin-bottom: -17px;
    @media screen and (max-width: 576px) {
        margin-left: 5px;
        height: 60px;
    }
`;

const Toggler = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const VertNav = styled.div`
    padding-left: 5px;
    width: 165px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    //height: 100vh;
    height: 100%;
    background-color: var(--rsSurface);
    padding-top: 0;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
`;

const ActiveLinkStyle = {};

const LinkStyle = {
    verticalAlign: 'middle',
    color: 'var(--rsSecondary)',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    fontSize: '14px',
    border: 'none',
    outline: 'none',
    padding: 0,
};

const IconWrapper = styled.div`
    margin-right: 13px;
`;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 10px;
`;

const Padded = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    width: 165px;
`;

var divStyle = {
    width: '140px',
    height: '0.2px',
    backgroundColor: 'gray',
    marginTop: '0px',
    marginLeft: '8px',
};

/**
 * VerticalNav is the vertical navigation bar on the left side of the
 * internal pages.
 *
 * The components that it renders are each their own <ul>.
 */
export class VerticalNavBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            followed: null,
            created: null,
            library: null,
            show: true,
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const uid = this.props.firebase.auth.currentUser.uid;
        const userRef = this.props.firebase.db.collection('users').doc(uid);
        const playlistFollowedRef = this.props.firebase.db.collection('playlistsFollowed');
        const playlistsRef = this.props.firebase.db.collection('playlists');

        // listener for followed playlists
        this.playlistFollowedListener = playlistFollowedRef
            .where('follower', '==', uid)
            .where('author', '!=', uid)
            .onSnapshot(
                (querySnapshot) => {
                    this.updateFollowedPlaylists(querySnapshot);
                },
                (err) => {
                    console.log(err);
                },
            );

        // listener for created playlists
        this.playlistCreatedListener = playlistsRef.where('author', '==', uid).onSnapshot(
            (querySnapshot) => {
                this.updateCreatedPlaylists(querySnapshot);
            },
            (err) => {
                console.log(err);
            },
        );

        // listener for library
        this.libraryListener = userRef
            .collection('library')
            .orderBy('subject')
            .onSnapshot(
                (snapshot) => {
                    this.updateLibrary(snapshot);
                },
                (err) => {
                    console.log(err);
                },
            );
    }

    componentWillUnmount() {
        this.libraryListener();
        this.playlistFollowedListener();
    }

    async updateFollowedPlaylists(querySnapshot) {
        var playlistArray = await this.props.firebase.playlistsOfPlaylistsFollowed(querySnapshot);
        if (!playlistArray || playlistArray.length === 0) {
            this.setState({ followed: null });
            return;
        }
        this.setState({ followed: playlistArray });
    }

    toggle() {
        this.setState({ show: !this.state.show });
    }

    async updateCreatedPlaylists(querySnapshot) {
        const playlists = querySnapshot.docs.map((docSnap) => {
            const obj = docSnap.data();
            obj.playlistId = docSnap.id;
            return obj;
        });

        if (!playlists || playlists.length === 0) {
            this.setState({ created: null });
            return;
        }
        this.setState({ created: playlists });
    }

    updateLibrary(snapshot) {
        const library = this.props.firebase.sortLibrary(snapshot);
        this.setState({ library: library });
    }

    //USE CONTEXT
    static contextType = TimeContext;

    render() {
        const userData = this.props.userData;
        const { inSongView } = this.context;
        var playlists = [];
        playlists = this.state.created === null ? playlists : playlists.concat(this.state.created);
        playlists = this.state.followed === null ? playlists : playlists.concat(this.state.followed);

        return (
            <VertNav>
                <div>
                    <Padded style={{ paddingLeft: '18px', paddingTop: '10px' }}>
                        <Link to={ROUTES.DASHBOARD} className="navbar-brand">
                            <HeaderPhoto src={'/images/logos/newlogo1.png'} alt="RapStudy" />
                        </Link>
                    </Padded>

                    <div style={{ marginLeft: '15px' }}>
                        <Main assignmentsFollowed={this.props.assignmentsFollowed} guest={this.props.guest} />
                    </div>

                    <div style={divStyle}></div>

                    <>
                        <Padded style={{ overflow: 'hidden', marginTop: '10px' }}>
                            <Library library={this.state.library} />
                        </Padded>
                        <div style={divStyle}></div>
                        <Padded style={{ marginTop: '9px', overflow: 'hidden', paddingBottom: '9px' }}>
                            <Playlists playlists={playlists} style={{ overflow: 'hidden' }} />
                        </Padded>
                        <div style={divStyle}></div>
                        {!this.props.guest && (
                            <>
                                <Padded style={{ position: 'static', marginTop: '15px', overflow: 'hidden', marginBottom: '9px' }}>
                                    {!inSongView && false ? <MiniPlayer style={{ bottom: '0px', position: 'absolute', paddingBottom: '5px' }} /> : null}
                                    <Toggler className="noPadMar ghost" style={{ color: 'white' }} onClick={this.toggle} aria-controls="library">
                                        <div style={{ paddingBottom: '3px' }}>
                                            <div style={{ display: 'inline-block' }}>
                                                <UserInitial
                                                    letter={userData.first_name.charAt(0)}
                                                    large={false}
                                                    mini={true}
                                                    color={this.state.edit ? this.state.colorInput : userData.color}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: 'inline-block',
                                                    paddingLeft: '5px',
                                                    paddingRight: '7px',
                                                    width: '100px',
                                                    textOverflow: 'ellipsis',
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        marginBottom: '5px',
                                                        fontSize: '14px',
                                                        width: '90px',
                                                        height: '18px',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {userData.first_name} {userData.last_name}
                                                </p>
                                            </div>
                                            {this.state.show ? (
                                                <div style={{ marginLeft: '9px', marginTop: '8px', overflowY: 'hidden' }}>
                                                    <LinkWrapper>
                                                        <IconWrapper>
                                                            <Info size="14px" hover={false} />
                                                        </IconWrapper>
                                                        <a style={LinkStyle} href="https://help.rapstudy.com/" target="_blank" rel="noopener noreferrer">
                                                            Help
                                                        </a>
                                                    </LinkWrapper>
                                                    <LinkWrapper>
                                                        <IconWrapper>
                                                            <Gear size="14px" hover={false} />
                                                        </IconWrapper>
                                                        <NavLink to={ROUTES.SETTINGS} style={LinkStyle} activeStyle={ActiveLinkStyle} className="vertNav-link">
                                                            Settings
                                                        </NavLink>
                                                    </LinkWrapper>
                                                    {/* <LinkWrapper style={{ color: 'var(--rsSecondary)' }}>
                            <IconWrapper>
                              <Person size="14px" hover={false} />
                            </IconWrapper>
                            <RsLink onClick={this.props.signOut} activeStyle={ActiveLinkStyle} className="vertNav-link" hover={show}>
                              <div style={LinkStyle}>Sign Out</div>
                            </RsLink>
                          </LinkWrapper> */}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Toggler>
                                </Padded>
                                {/* <div style={divStyle}></div> */}
                            </>
                        )}
                    </>
                </div>

                {!this.props.guest && <div style={{ height: '220px' }}></div>}
            </VertNav>
        );
    }
}

const VerticalNav = withFirebase(withRouter(VerticalNavBase));
export default VerticalNav;
