import React from 'react';
import styled from 'styled-components';
// import LandingSelect from '../../LandingSelect';
import ProfileGrid from './ProfileGrid';
import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
    padding: 8%;
    padding-bottom: 4%;
    background: #2e2e2e;
    justify-content: center;
    align-items: center;
    height: auto;
    overflow: visible;
`;

const Header = styled.h1`
    font-size: 30px;
    font-weight: bold;
    color: #aed4e6;
    text-align: center;
    padding-bottom: 25px;

    @media ${device.tablet} {
        font-size: 30px;
    }
    @media ${device.mobileL} {
        font-size: 24px;
    }
`;

const Line = styled.div`
    border: 0.1px solid rgba(255, 255, 255, 0.5);
`;

const Team = styled.div`
    justify-content: center;
    align-items: center;
`;

/**
 * About is the entire About page.
 *
 * This includes 'our story' and a few AboutJumbos for the team.
 */
class AboutMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 'CORE',
        };
        this.select = this.select.bind(this);
    }

    select(key) {
        this.setState({ selected: key });
    }

    render() {
        const data = [
            {
                name: 'Drew Speckman',
                pos: 'Founder, CEO',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fdrewspeckman.png?alt=media&token=68b7c5ae-9dd7-43ff-9e98-f5f2d9433686',
            },
            {
                name: 'Mason Bulling',
                pos: 'Principal Software Engineer',
                src: '',
            },
            {
                name: 'Andrew Choi',
                pos: 'Principal Software Engineer',
                src: '',
            },
            {
                name: 'Devin Chin',
                pos: 'UX Designer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fdevinchin.JPG?alt=media&token=60c5339e-bcb3-43cc-ac6f-2fb782ce4f9a',
            },
            {
                name: 'Akimasa Ihara',
                pos: 'Web Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fakimasaihara.jpeg?alt=media&token=11fed68b-5983-4863-9132-04c8f5edf492',
            },
            {
                name: 'Emily Wang',
                pos: 'Web Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Femilywang.jpeg?alt=media&token=c0370916-19a3-40b0-a82e-10ec67221fef',
            },
            {
                name: 'Ethan Ma',
                pos: 'Mobile Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fethanma.jpeg?alt=media&token=388134b5-cce0-48a8-9acd-64df1b533232',
            },
            {
                name: 'Sofia Yoon',
                pos: 'Web Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fsofiayoon.jpeg?alt=media&token=87b4e198-5e2c-4fa5-9043-8f90ee3fd1ff',
            },
            {
                name: 'Unnathi Uptal Kumar',
                pos: 'Mobile Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Funnathiutpalkumar.JPG?alt=media&token=4ae830cf-33b0-480e-b44a-4814d305755f',
            },
            {
                name: 'Alejandro Dávila',
                pos: 'Singer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Falejandroda%CC%81vila.jpeg?alt=media&token=e853a8e5-db99-4ddb-a698-f7419b9c5cea',
            },
            {
                name: 'Anna Scholfield',
                pos: 'Songwriter',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fannascholfield.jpeg?alt=media&token=20199aa4-726b-4102-bce0-f3a696f47e17',
            },
            {
                name: 'Ella Collier',
                pos: 'Singer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fellacollier.jpeg?alt=media&token=f8d244de-4268-4e7f-a141-36e633868a7f',
            },
            {
                name: 'Greta Pasqua',
                pos: 'Singer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fgretapasqua.jpeg?alt=media&token=4e96b2c7-4dc8-4c2c-b0e2-9f8d8dff9bfd',
            },
            {
                name: 'Izzy Brown',
                pos: 'Singer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fizzybrown.jpeg?alt=media&token=363233a9-b524-4237-925d-a9369d79eab2',
            },
            {
                name: 'Kate Grahn',
                pos: 'Singer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fkategrahn.jpeg?alt=media&token=c140a5f3-978d-4fa4-a590-d46d2c825ec6',
            },
            {
                name: 'Katie Kivinski',
                pos: 'Songwriter',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fkatiekivinski.jpeg?alt=media&token=8a39dabf-99f3-4d6c-8ca0-40b3943df9c7',
            },
            {
                name: 'Kyle Tolbert',
                pos: 'Singer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fkylelux.jpeg?alt=media&token=a3a3fe41-c34c-4272-a778-b050a6b5f39a',
            },
            {
                name: 'Michael Shofi',
                pos: 'Producer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fmichaelshofi.jpeg?alt=media&token=416be1bf-0395-48e9-b746-252918930bba',
            },
            {
                name: 'Tabon Ward',
                pos: 'Singer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Ftabonward.jpeg?alt=media&token=99cf612b-514b-427b-a023-0f429b0ca1c7',
            },
            {
                name: 'Zoe Benson',
                pos: 'Songwriter',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fzoebenson.jpeg?alt=media&token=15707f90-86a9-4faa-9bfa-d62ed2a02834',
            },
            {
                name: 'Hannah Drexler',
                pos: 'Research',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fhannahdrexler.jpeg?alt=media&token=c558cc68-a051-46a9-9a25-87eafa3ebd38',
            },
            {
                name: 'Matthew Gavieta',
                pos: 'Research',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fmattgavieta.jpeg?alt=media&token=cf257d96-b0b7-4a16-ad4f-aa25cbc284b1',
            },
            {
                name: 'Swastik Chaki',
                pos: 'Software Engineer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fswastikchaki.jpeg?alt=media&token=01fb3c55-f4c1-4289-90a7-78efc20d4105',
            },
            {
                name: 'Max Pace',
                pos: 'Mobile Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fmaxpace.jpeg?alt=media&token=7052c615-21f0-4faa-8b90-8985f2657b0c',
            },
            {
                name: 'Aarya Tavshikar',
                pos: 'Finance',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Faarya.JPG?alt=media&token=1e429dd7-691e-422e-b119-d9b7eaa27160',
            },
            {
                name: 'Kevin Park',
                pos: 'Design',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fkevinpark.jpg?alt=media&token=482198da-ec54-441f-896d-023197104e00',
            },
            {
                name: 'Steven Long',
                pos: 'Web Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fstevenlong.jpg?alt=media&token=6d2a21af-8a59-4211-80e0-f54e3597f37a',
            },
            {
                name: 'James Liang',
                pos: 'Web Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fjamesliang.jpg?alt=media&token=9aab0e1e-1671-4e39-8a1e-42ace70fcbba',
            },
            {
                name: 'Amy Ouyang',
                pos: 'Web Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Famyouyang.jpg?alt=media&token=89e86fd9-43bb-4eb2-966e-96d3e5f376a0',
            },
            {
                name: 'Zhiwei Hu',
                pos: 'Web Developer',
                src:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fzhiweihu.jpeg?alt=media&token=00847207-d68c-4c60-800b-7574f30a13d1',
            },
        ];

        return (
            <MainWrapper>
                <Header>RAPSTUDY TEAM</Header>
                <Line />
                <Team>
                    <ProfileGrid data={data} />
                </Team>
            </MainWrapper>
        );
    }
}

export default AboutMain;
