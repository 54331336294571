import React, { Component } from 'react';
import styled from 'styled-components';

import QuestionEditor from './QuestionEditor';

import ChooseImageModal from '../../../../UI-Library/Modals/ChooseImageModal';
import ChooseLyricModal from '../../../../UI-Library/Modals/ChooseLyricModal';

import DeleteConfirmModal from '../../../../UI-Library/Modals/DeleteConfirmModal';
import DiscardConfirmModal from '../../../../UI-Library/Modals/DiscardConfirmModal';
import RsButton from '../../../../UI-Library/ButtonsOrLinks/RsButton';

const EditFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 30px;
`;

class ReviewEdit extends Component {
    render() {
        let beg = null;
        let end = null;
        let id = null;
        if (this.props.qList !== null && !this.props.qList.isEmpty() && this.props.activeQuestion !== null) {
            const activeQobj = this.props.qList.index(this.props.activeQuestion);
            beg = activeQobj.getLineStart();
            end = activeQobj.getLineEnd();
            id = activeQobj.getId();
        }
        return (
            <div>
                <QuestionEditor
                    qList={this.props.qList}
                    reorder={this.props.reorderQuestions}
                    makeChoiceTrue={this.props.makeChoiceTrue}
                    handleChoiceChange={this.props.handleChoiceChange}
                    handleQuestionChange={this.props.handleQuestionChange}
                    deleteQuestion={this.props.deleteQuestion}
                    selectImage={this.props.selectImage}
                    lrc={this.props.lrc}
                    setStartEnd={this.props.setStartEnd}
                    toggleChooseLyricModal={this.props.toggleChooseLyricModal}
                    toggleChooseImageModal={this.props.toggleChooseImageModal}
                    removeImage={this.props.removeImage}
                />
                <EditFooter>
                    <RsButton mb={'20px'} large={true} onClick={this.props.createQuestion}>
                        Add a Question
                    </RsButton>
                </EditFooter>
                <ChooseLyricModal
                    setStartEnd={this.props.setStartEnd}
                    questions={this.props.qList}
                    id={id}
                    show={this.props.showChooseLyricModal}
                    lrc={this.props.lrc}
                    toggle={this.props.toggleChooseLyricModal}
                    beg={beg}
                    end={end}
                    index={this.props.activeQuestion}
                />
                <ChooseImageModal
                    selectImage={this.props.selectImage}
                    firebaseQImage={this.props.firebaseQImage}
                    index={this.props.activeQuestion}
                    toggle={this.props.toggleChooseImageModal}
                    show={this.props.showChooseImageModal}
                    id={id}
                />
                <DeleteConfirmModal
                    title={'Delete Set?'}
                    delete={() => {
                        this.props.deleteSet(this.props.setId);
                    }}
                    show={this.props.showDelete}
                    toggle={this.props.deleteModalToggle}
                />
                <DiscardConfirmModal
                    discard={() => {
                        this.props.selectSet(this.props.setId);
                    }}
                    show={this.props.showDiscard}
                    toggle={this.props.discardModalToggle}
                />
            </div>
        );
    }
}

export default ReviewEdit;
