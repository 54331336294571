import React, { Component } from 'react';

import AlbumCoverContainer from '../../UI-Library/ImageContainers/AlbumCover';
import HomeBanner from './HomeBanner';
import InProgress from './InProgress';
import Loading from '../../UI-Library/Misc/Loading';
import Recommended from './Recommended';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';

var h = window.innerHeight - 240;
const MainBody = styled.div`
    margin: 0;
    padding-left: 3%;
    padding-right: 1%;
    padding-top: 0%;
`;

/**
 * Home is the component for the entire Home page. It will
 * display the InProgress and HeavyRot components.
 */
export class HomeBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recents: [],
            newReleases: [],
            recentExists: false,
            ready: false,
            error: null,
            recommendedBySubject: [],
            recommendedByArtist: [],
            recommendedByGenre: [],
            subject: null,
            artist: null,
            genre: null,
        };
    }

    getArtistImage = async (name) => {
        const artistInfo = await this.props.firebase.getArtist(name);
        if (artistInfo) return artistInfo.image;
    };

    async componentDidMount() {
        try {
            if (this.props.guest) {
                const newReleasesPr = this.props.firebase.getNewReleases();
                const rsPlaylistsPr = this.props.firebase.getAuthoredPlaylists('rAm5Iza0yTVPFu94KcZ2AghpnKq2');
                const resolved = await Promise.all([newReleasesPr, rsPlaylistsPr]);
                const newReleasesDocs = resolved[0];
                const rsPlaylists = resolved[1];
                const withImagesPrs = [];
                withImagesPrs.push(
                    Promise.all(
                        newReleasesDocs.map(async (item) => {
                            var data = item;
                            data.artistImage = await this.getArtistImage(item.origArtist[0]);
                            data.src = await this.props.firebase.getSongCover(item.subtopic.subtopicRef.id);
                            return data;
                        }),
                    ),
                );

                const resolvedImages = await Promise.all(withImagesPrs);
                this.setState({
                    newReleases: resolvedImages[0],
                    rsPlaylists: rsPlaylists,
                    recentExists: false,
                    ready: true,
                });

                return;
            }
            const recentsPr = this.props.firebase.getRecentSongs(20);
            const newReleasesPr = this.props.firebase.getNewReleases();
            const rsPlaylistsPr = this.props.firebase.getAuthoredPlaylists('rAm5Iza0yTVPFu94KcZ2AghpnKq2');
            var recommendedBySubject = this.props.firebase.getRecommendedBySubject();
            var recommendedByArtist = this.props.firebase.getRecommendedByArtist();
            var recommendedByGenre = this.props.firebase.getRecommendedByGenre();

            const resolved = await Promise.all([recentsPr, newReleasesPr, rsPlaylistsPr, recommendedBySubject, recommendedByArtist, recommendedByGenre]);

            const recentsDocs = resolved[0];
            const newReleasesDocs = resolved[1];
            const rsPlaylists = resolved[2];
            const recommendedBySubjectDocs = resolved[3].songs;
            const recommendedByArtistDocs = resolved[4].songs;
            const recommendedByGenreDocs = resolved[5].songs;
            this.setState({
                subject: resolved[3].subject,
                artist: resolved[4].artist,
                genre: resolved[5].genre,
            });

            const withImagesPrs = [];
            var recentsExists = false;

            if (recentsDocs.length !== 0) {
                recentsExists = true;
                withImagesPrs.push(
                    Promise.all(
                        recentsDocs.map(async (item) => {
                            var data = item;
                            const subtopicSongs = (await item.subtopic.subtopicRef.get()).data();
                            if (subtopicSongs != null) {
                                (subtopicSongs.songArr).forEach(async (song) => {
                                    if (song.id === item.id) {
                                        data.origArtist = song.origArtist;
                                        data.artistImage = await this.getArtistImage(song.origArtist);
                                    }
                                });
                            }
                            
                            data.src = await this.props.firebase.getSongCover(item.subtopic.subtopicRef.id);
                            return data;
                        }),
                    ),
                );
            } else {
                withImagesPrs.push([]);
            }

            withImagesPrs.push(
                Promise.all(
                    newReleasesDocs.map(async (item) => {
                        var data = item;
                        data.artistImage = await this.getArtistImage(item.origArtist[0]);
                        data.src = await this.props.firebase.getSongCover(item.subtopic.subtopicRef.id);
                        return data;
                    }),
                ),
            );

            if (recentsDocs.length !== 0) {
                recentsExists = true;
                withImagesPrs.push(
                    Promise.all(
                        recommendedBySubjectDocs.map(async (item) => {
                            var data = item;
                            const subtopicSongs = (await item.subtopic.subtopicRef.get()).data().songArr;
                            subtopicSongs.forEach(async (song) => {
                                if (song.id === item.id) {
                                    data.origArtist = song.origArtist;
                                    data.artistImage = await this.getArtistImage(song.origArtist);
                                }
                            });
                            data.src = await this.props.firebase.getSongCover(item.subtopic.subtopicRef.id);
                            return data;
                        }),
                    ),
                );
            } else {
                withImagesPrs.push([]);
            }

            if (recentsDocs.length !== 0) {
                recentsExists = true;
                withImagesPrs.push(
                    Promise.all(
                        recommendedByArtistDocs.map(async (item) => {
                            var data = item;
                            const subtopicSongs = (await item.subtopic.subtopicRef.get()).data().songArr;
                            subtopicSongs.forEach(async (song) => {
                                if (song.id === item.id) {
                                    data.origArtist = song.origArtist;
                                    data.artistImage = await this.getArtistImage(song.origArtist);
                                }
                            });
                            data.src = await this.props.firebase.getSongCover(item.subtopic.subtopicRef.id);
                            return data;
                        }),
                    ),
                );
            } else {
                withImagesPrs.push([]);
            }

            if (recentsDocs.length !== 0) {
                recentsExists = true;
                withImagesPrs.push(
                    Promise.all(
                        recommendedByGenreDocs.map(async (item) => {
                            var data = item;
                            const subtopicSongs = (await item.subtopic.subtopicRef.get()).data().songArr;
                            subtopicSongs.forEach(async (song) => {
                                if (song.id === item.id) {
                                    data.origArtist = song.origArtist;
                                    data.artistImage = await this.getArtistImage(song.origArtist);
                                }
                            });
                            data.src = await this.props.firebase.getSongCover(item.subtopic.subtopicRef.id);
                            return data;
                        }),
                    ),
                );
            } else {
                withImagesPrs.push([]);
            }

            const resolved2 = await Promise.all(withImagesPrs);
            var recents = resolved2[0];
            const newReleases = resolved2[1];
            recommendedBySubject = resolved2[2];
            recommendedByArtist = resolved2[3];
            recommendedByGenre = resolved2[4];

            var seen = {};
            recents = recents.filter(function (song) {
                var subtopic = song.subtopic.name;
                return seen.hasOwnProperty(subtopic) ? false : (seen[subtopic] = true);
            });

            this.setState({
                recents: recents,
                newReleases: newReleases,
                rsPlaylists: rsPlaylists,
                recentExists: recentsExists,
                recommendedBySubject: recommendedBySubject,
                recommendedByArtist: recommendedByArtist,
                recommendedByGenre: recommendedByGenre,
                ready: true,
            });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    render() {
        if (this.state.error) {
            console.log(this.state.error);
            throw this.state.error;
        }
        if (!this.state.ready) {
            // this.setState({ hidden: true });
            return <Loading zoom={true} />;
        }
        return (
            <div style={{ height: { h } }}>
                <div style={{ position: 'static', width: '100%', height: '100%' }}>
                    <HomeBanner guest={this.props.guest} userData={this.props.userData} userToken={this.props.userToken} />
                </div>
                <MainBody style={{}}>
                    <div style={{ height: `${window.innerHeight}` - 240, width: '100%', marginTop: '20px' }}>
                        <Recommended
                            recents={this.state.recommendedByArtist}
                            recentExists={this.state.recentExists}
                            categoryTitle={this.state.artist}
                            category="artist"
                        />

                        <InProgress recents={this.state.recents} recentExists={this.state.recentExists} />

                        {this.state.newReleases.length === 0 ? null : (
                            <AlbumCoverContainer header={'New Releases'} items={this.state.newReleases} itemType={'song'} rows={1} guest={this.props.guest} />
                        )
                        }
                        
                        <Recommended
                            recents={this.state.recommendedBySubject}
                            recentExists={this.state.recentExists}
                            categoryTitle={this.state.subject}
                            category="subject"
                        />
                        <Recommended
                            recents={this.state.recommendedByGenre}
                            recentExists={this.state.recentExists}
                            categoryTitle={this.state.genre}
                            category="genre"
                        />

                        <AlbumCoverContainer header={'rapStudy Favorites'} items={this.state.rsPlaylists} itemType={'playlist'} rows={1} />
                    </div>
                </MainBody>
            </div>
        );
    }
}

export default withFirebase(HomeBase);
