import Annotation from './Annotation.js';

/**
 * An array of Annotation objects and its associated methods.
 */
class AnnotationDict {
    constructor() {
        this.indToRef = {};
        this.annotationLookup = {};
        this.numberOfRefs = 0;
        this.name = '';
        this.authorId = '';
        this.authorName = '';
    }

    /**
     * Return indToRef
     */
    getIndToRef() {
        return this.indToRef;
    }

    /**
     * Return annotationLookup
     */
    getAnnotationLookup() {
        return this.annotationLookup;
    }

    /**
     * Return numberOfRefs
     */
    getNumberOfRefs() {
        return this.numberOfRefs;
    }

    /**
     * Return name
     */
    getName() {
        return this.name;
    }

    /**
     * set the name of the annotation set
     * @param {string} name
     */
    setName(name) {
        if (!(typeof name === 'string')) {
            throw Error('Failed precondition: "name" is not a string');
        }
        this.name = name;
    }

    getAuthorId() {
        return this.authorId;
    }

    setAuthorId(id) {
        if (!id) throw Error('Author id was falsy');
        this.authorId = id;
    }

    getAuthorName() {
        return this.authorName;
    }

    setAuthorName(name) {
        if (!name) throw Error('Author name was falsy');
        this.authorName = name;
    }

    /**
     * Takes in documentSnapshot and initializes lookup tables
     * @param {DocumentSnapshot} docSnap
     */
    setAnnotation(docSnap) {
        var docId = docSnap.id;
        var data = docSnap.data();
        var start = data.start;
        var end = data.end;

        this.annotationLookup[docId] = data;

        for (var i = start; i <= end; i++) {
            this.indToRef[i] = docSnap.ref;
        }
        this.numberOfRefs++;
    }

    /**
     * Add annotation
     * @param {number} start
     * @param {number} end
     * @param {DocumentReference} randomRef
     */
    addAnnotation(start, end, randomRef) {
        for (var i = start; i <= end; i++) {
            this.indToRef[i] = randomRef;
        }

        this.annotationLookup[randomRef.id] = new Annotation('', start, end, null, null, null, null, null, null);
        this.numberOfRefs++;
    }

    /**
     * Delete annotation
     * @param {string} docId
     */
    deleteAnnotation(docId) {
        var timestamps = this.annotationLookup[docId].getStartAndEnd();
        var start = timestamps[0];
        var end = timestamps[1];

        for (var i = start; i <= end; i++) {
            delete this.indToRef[i];
        }
        delete this.annotationLookup[docId];
        this.numberOfRefs--;
    }

    /**
     * Remove image of a particular annotation given by docId
     * @param {string} docId
     */
    removeImage(docId) {
        if (docId in this.annotationLookup) {
            this.annotationLookup[docId].removeImage();
        }
    }

    /**
     * Set image to unsplash
     * @param {string} curId
     * @param {string} imgLink
     * @param {string} uid
     * @param {string} name
     */
    setUnsplashImage(curId, imgLink, uid, name) {
        this.annotationLookup[curId].setUnsplashImage(imgLink, uid, name);
    }

    /**
     * Setting image to firebase image
     * @param {string} curId
     * @param {string} filename
     * @param {url} url
     */
    setFirebaseImage(curId, filename, url) {
        this.annotationLookup[curId].setFirebaseImage(filename, url);
    }

    /**
     * Change description of annoation
     * @param {string} curId
     * @param {event} e
     */
    setDescription(curId, e) {
        if (curId in this.annotationLookup) {
            this.annotationLookup[curId].setDescription(e.target.value);
        }
    }

    /**
     * Get a particular annotation => if getting by id, set index = null.
     * @param {string} docId
     * @param {number} index
     */
    getAnnotation(docId, index) {
        if (index == null) {
            return this.annotationLookup[docId];
        } else {
            return this.annotationLookup[this.indToRef[index].id];
        }
    }

    /**
     * Return docId of annotation of index
     * @param {number} index
     */
    getId(index) {
        return this.indToRef[index].id;
    }

    /**
     * returns if id exists in annotationLookup
     * @param {string} id
     */
    annotationExists(id) {
        return id in this.annotationLookup;
    }
}

export default AnnotationDict;
