import * as ROUTES from '../../../constants/routes';

import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { isChrome, isFirefox } from 'react-device-detect';
import {
    setAudioSrc,
    setBackground,
    setCurrentTime,
    setDisplayShare,
    setEnded,
    setPlay,
    setSongDetails,
    setSongLyrics,
} from '../../../redux/slices/audioPlayer';

import Blanks from './Blanks';
import Genius from './Genius';
import Karaoke from './Karaoke';
import Loading from '../../UI-Library/Misc/Loading';
import Review from './Review';
import SongBanner from './SongBanner';
import { connect } from 'react-redux';
import pdfdict from './pdflink.js';
import styled from 'styled-components';
import { subjectColors } from '../../../constants/colors';
import { withAlert } from 'react-alert';
import { withFirebase } from '../../Firebase';

const MainWrapper = styled.div`
    height: auto;
`;

const FullscreenBackground = styled.img`
    position: fixed;
    left: 0;
    right: 0;
    z-index: -999;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    /* Handling blurring across browsers */
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px);
    z-index: -99;
`;

const infoPDF = pdfdict;

/**
 * SongView is the component for the entire SongView page.
 */
class SongViewBase extends Component {
    constructor(props) {
        super(props);

        this.playOrPause = this.playOrPause.bind(this);
        this.pause = this.pause.bind(this);
        this.setTime = this.setTime.bind(this);
        this.reset = this.reset.bind(this);
        this.play = this.play.bind(this);
        this.incrementSongCount = this.incrementSongCount.bind(this);

        this.completeFirstBreakdown = this.completeFirstBreakdown.bind(this);
        this.completeFirstBlanks = this.completeFirstBlanks.bind(this);
        this.completeFirstReview = this.completeFirstReview.bind(this);
        this.completeFirstKaraoke = this.completeFirstKaraoke.bind(this);
        this.clickFullScreen = this.clickFullScreen.bind(this);
        this.selectVariation = this.selectVariation.bind(this);

        this.updateCurrSetId = this.updateCurrSetId.bind(this);
        this.logAnalyticsShare = this.logAnalyticsShare.bind(this);
        this.copyLink = this.copyLink.bind(this);

        this.state = {
            id: null,
            src: null,
            time: null,
            speed: null,
            volume: null,
            counter: 0,
            duration: 0,
            newTitle: '',
            unit: { name: '', unitRef: '' },
            subject: { name: '', subjectRef: '' },
            topic: { name: '', topicRef: '' },
            ended: false,
            ready: false,
            inLib: null,
            error: null,
            fullscreen: false,
            audioLoaded: false,
            firstBreakdown: false,
            firstBlanks: false,
            firstReview: false,
            firstKaraoke: false,
            origArtist: null,
            hasClickedPlay: false,
            completedFeedback: true,
            currSetId: null,
            currFeature: '',
            timeTracker: 0, //milliseconds
            displayShare: false,
            reduxPromises: [],
        };

        this.myRef = React.createRef();
        this.nextSongId = null;
    }

    async componentDidUpdate(prevProps) {
        // if the user checks out a song view feature, we want to reset the state
        if (this.props.location !== prevProps.location) {
            this.reset();
        }
    }

    async fetchData(id) {
        try {
            let songId = id;
            let resStorage;
            let subtopicDoc;
            let res;
            if (this.props.guest) {
                res = await this.props.firebase.getSongFromInvite(id, this.props.firebase.auth.currentUser.email);
                songId = res[0].songId;
                const mp3 = res[2];
                const img = res[3];
                subtopicDoc = res[4];
                resStorage = [mp3, img];
            } else {
                const song = this.props.firebase.getSong(songId).then((doc) => doc.data());
                const json = this.props.firebase.getJson(songId);
                res = await Promise.all([song, json]);
                const subtopicRef = res[0].subtopic.subtopicRef;
                const subtopicPr = subtopicRef.get();
                const mp3 = this.props.firebase.getMp3(songId);
                const img = this.props.firebase.getSongCover(subtopicRef.id);
                resStorage = await Promise.all([mp3, img, subtopicPr]);
                subtopicDoc = resStorage[2].data();
            }
            const link = infoPDF[String(songId)];
            const userData = this.props.userData;
            console.log('location: ', this.props.location);
            this.state.reduxPromises.push(
                this.props.setSongDetails({
                    reset: this.props.location.state ? this.props.location.state.reset : true,
                    songID: id,
                    coverImage: resStorage[1],
                    originalTitle: res[0].origTitle,
                    origArtist: res[0].origArtist[0],
                    newTitle: res[0].newTitle,
                    background: subjectColors[res[0].subject.name],
                    audioSrc: resStorage[0],
                    songLyrics: res[1],
                }),
            );
            this.setState({
                ...res[0],
                ...{
                    play: false,
                    ended: false,
                    duration: 0,
                    audioLoaded: false,
                    id: id,
                    src: resStorage[0],
                    time: 0,
                    speed: 1.0,
                    volume: 1.0,
                    img: resStorage[1],
                    ready: true,
                    firstBreakdown: userData?.firstBreakdown,
                    firstBlanks: userData?.firstBlanks,
                    firstReview: userData?.firstReview,
                    firstKaraoke: userData?.firstKaraoke,
                    backgroundColor: subjectColors[res[0].subject.name],
                    json: res[1],
                    songArr: subtopicDoc.songArr,
                    pdfLink: link,
                    resume: false,
                },
            });

            // TODO SongView: Abstract into helper
            ['fullscreenchange', 'mozfullscreenchange', 'webkitfullscreenchange'].forEach((evt) =>
                this.myRef.current.addEventListener(evt, () => {
                    if (!document.fullscreenElement) {
                        this.setState({ fullscreen: false });
                    } else {
                        this.setState({ fullscreen: true });
                    }
                }),
            );
        } catch (err) {
            console.log(err);
            this.setState({ error: err });
        }
    }

    incrementSongCount(songId) {
        this.props.firebase
            .incrementSongCount(songId)
            .then((completedFeedback) => {
                this.setState({ completedFeedback: completedFeedback });
            })
            .catch((err) => {
                this.setState({ error: err });
            });
        this.setState({ hasClickedPlay: true });
    }

    async componentDidMount() {
        try {
            const urlParams = new URLSearchParams(this.props.location.search);
            const songId = urlParams.get('songId');
            if (!this.props.guest) {
                if (!this.props.location.state || (this.props.location.state && this.props.location.state.reset)) {
                    this.incrementSongCount(songId);
                }
            }
            await this.fetchData(songId);

            this.props.firebase.analytics.logEvent('new song loaded', {
                songId: songId,
                from_variation_dropdown: false,
            });
            await Promise.all(this.state.reduxPromises);
            if (!this.props.guest) {
                const userId = this.props.firebase.auth.currentUser.uid;

                const songsSnapshot = await this.props.firebase.db.collection('users').doc(userId).collection('songs').get();
                // Map the song documents into an array of song data and include the document id
                const songs = songsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                const songListened = songs.find((song) => song.id === songId);
                if (!songListened || songListened.count < 2) {
                    this.props.setDisplayShare(true);
                    this.setState({ displayShare: true });
                }
            } else {
                this.props.firebase.deleteSongInvite(songId, this.props.firebase.auth.currentUser.email);
            }
        } catch (err) {
            this.setState({ error: err });
        }
    }

    componentWillUnmount() {
        try {
            //Log in event 'karaoke_played' [Firebase Analytics]
            this.props.firebase.analytics.logEvent('karaoke_played', {
                time: this.state.timeTracker / 1000,
                song_id: this.state.id,
            });
        } catch (err) {
            console.log('ERROR ON SONGVIEW UNMOUNT: ', err);
        }
    }

    async selectVariation(id) {
        try {
            this.props.history.push(ROUTES.KARAOKE + '?songId=' + id);
            this.pause();
            this.setState({ ready: false }, async () => {
                await this.fetchData(id);
                this.props.firebase.analytics.logEvent('new song loaded', {
                    songId: id,
                    from_variation_dropdown: true,
                });
            });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    /**
     * Plays the audio and updates state.
     */
    play() {
        this.props.setPlay(true);
    }

    /**
     * Pauses the audio and updates state.
     */
    pause() {
        this.props.setPlay(false);
    }

    /**
     * Play and pause audio toggle.
     */
    playOrPause() {
        if (!this.state.hasClickedPlay && !this.props.guest) {
            this.props.firebase
                .incrementSongCount(this.state.id)
                .then((completedFeedback) => {
                    this.setState({ completedFeedback: completedFeedback, displayShare: !completedFeedback });
                    this.props.setDisplayShare(!completedFeedback);
                })
                .catch((err) => {
                    this.setState({ error: err });
                });
            this.setState({ hasClickedPlay: true });
        }
        if (this.state.play) {
            this.pause();
        } else {
            this.play();
        }
    }

    playback(speed) {
        this.audio.current.playbackRate = speed;
    }

    setVolume(val) {
        let sliderVal = val.value;
        this.audio.current.volume = parseFloat(sliderVal / 100);
        this.setState({ volume: sliderVal });
    }

    async setTime(time) {
        await this.props.setCurrentTime(time);
        // this.audio.current.currentTime = time;
        // this.setState({ time: this.audio.current.currentTime });
    }

    async reset() {
        await Promise.all([this.props.setCurrentTime(0), this.props.setPlay(false), this.props.setEnded(false)]);
    }

    clickFullScreen() {
        if (isChrome || isFirefox) {
            var requestFullscreen = function (ele) {
                if (!document.fullscreenElement) {
                    if (ele.requestFullscreen) {
                        ele.requestFullscreen();
                    } else if (ele.webkitRequestFullscreen) {
                        ele.webkitRequestFullscreen();
                    } else if (ele.mozRequestFullScreen) {
                        ele.mozRequestFullScreen();
                    } else if (ele.msRequestFullscreen) {
                        ele.msRequestFullscreen();
                    } else {
                        console.log('Fullscreen API is not supported.');
                    }
                } else {
                    this.setState({ fullscreen: false });
                    document.exitFullscreen();
                }
            }.bind(this);
            const node = this.myRef.current;
            requestFullscreen(node);
        } else {
            alert('Sorry, this browser does not support full screen at the moment. Please use Chrome or Firefox for the best rapStudy experience :))');
            return;
        }
    }

    completeFirstBreakdown() {
        this.props.firebase.completeFirstBreakdown();
        this.setState({ firstBreakdown: false });
    }

    completeFirstBlanks() {
        this.props.firebase.completeFirstBlanks();
        this.setState({ firstBlanks: false });
    }

    completeFirstReview() {
        this.props.firebase.completeFirstReview();
        this.setState({ firstReview: false });
    }

    completeFirstKaraoke() {
        this.props.firebase.completeFirstKaraoke();
        this.setState({ firstKaraoke: false });
    }

    updateCurrSetId(setId, featureName) {
        this.setState({ currSetId: setId, currFeature: featureName });
    }

    copyLink() {
        navigator.clipboard.writeText(window.location.href);
        this.props.alert.show(`Link to ${this.state.currFeature} copied to clipboard.`);
    }

    logAnalyticsShare() {
        this.props.firebase.analytics.logEvent('share-to-classroom', { contentType: this.state.currFeature });
    }

    render() {
        if (this.state.error) {
            console.log(this.state.pdfLink);
            throw this.state.error;
        }
        // console.log(this.state.link);
        // TODO SongView: Refactor into single song object instead of all its properties
        // TODO SongView (Secondary) : Form audio "engine" to pass in audio handlers
        return (
            <MainWrapper ref={this.myRef}>
                {this.state.fullscreen && <FullscreenBackground fullscreen={this.state.fullscreen} src={this.state.img}></FullscreenBackground>}

                {this.state.ready ? (
                    <div style={{ height: 'auto' }}>
                        <SongBanner
                            guest={this.props.guest}
                            backgroundColor={this.state.backgroundColor}
                            setTime={this.setTime}
                            imgSrc={this.state.img}
                            playOrPause={this.playOrPause}
                            setVolume={this.setVolume}
                            displayShare={this.state.displayShare}
                            play={this.state.play}
                            pause={this.state.pause}
                            volume={this.state.volume}
                            genre={this.state.genre}
                            newTitle={this.state.newTitle}
                            origTitle={this.state.origTitle}
                            origArtist={this.state.origArtist}
                            subject={this.state.subject}
                            unit={this.state.unit}
                            topic={this.state.topic}
                            currentTime={this.props.currentTime}
                            duration={this.state.duration}
                            id={this.state.id}
                            playback={this.playback}
                            inLib={this.state.inLib}
                            fullscreen={this.state.fullscreen}
                            audioLoaded={this.state.audioLoaded}
                            songArr={this.state.songArr}
                            userToken={this.props.userToken}
                            selectVariation={this.selectVariation}
                            currSetId={this.state.currSetId}
                            currFeature={this.state.currFeature}
                            copyLink={this.copyLink}
                            logAnalyticsShare={this.logAnalyticsShare}
                            pdfLink={this.state.pdfLink}
                        />

                        <Route
                            exact
                            path={[ROUTES.KARAOKE, ROUTES.INVITE_KARAOKE]}
                            render={(props) => (
                                <Karaoke
                                    {...props}
                                    currentTime={this.props.currentTime}
                                    id={this.state.id}
                                    play={this.props.play}
                                    ended={this.props.ended}
                                    reset={this.reset}
                                    clickFullScreen={this.clickFullScreen}
                                    fullscreen={this.state.fullscreen}
                                    firstKaraoke={this.state.firstKaraoke}
                                    completeFirstKaraoke={this.completeFirstKaraoke}
                                    json={this.state.json}
                                    completedFeedback={this.state.completedFeedback}
                                    updateCurrSetId={this.updateCurrSetId}
                                    userToken={this.props.userToken}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={[ROUTES.REVIEW, ROUTES.INVITE_REVIEW]}
                            style={{ fontSize: '30px' }}
                            render={(props) => (
                                <Review
                                    {...props}
                                    guest={this.props.guest}
                                    backgroundColor={this.state.backgroundColor}
                                    currentTime={this.props.currentTime}
                                    setTime={this.setTime}
                                    id={this.state.id}
                                    play={this.play}
                                    ended={this.props.ended}
                                    reset={this.reset}
                                    duration={this.state.duration}
                                    pause={this.pause}
                                    playing={this.props.play}
                                    nextSongId={this.nextSongId}
                                    unit={this.state.unit}
                                    newTitle={this.state.newTitle}
                                    topic={this.state.topic}
                                    clickFullScreen={this.clickFullScreen}
                                    fullscreen={this.state.fullscreen}
                                    firstReview={this.state.firstReview}
                                    completeFirstReview={this.completeFirstReview}
                                    json={this.state.json}
                                    updateCurrSetId={this.updateCurrSetId}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={[ROUTES.BLANKS, ROUTES.INVITE_BLANKS]}
                            render={(props) => (
                                <Blanks
                                    {...props}
                                    guest={this.props.guest}
                                    currentTime={this.props.currentTime}
                                    color={this.state.backgroundColor}
                                    id={this.state.id}
                                    playOrPause={this.playOrPause}
                                    pause={this.pause}
                                    play={this.props.play}
                                    ended={this.props.ended}
                                    reset={this.reset}
                                    newTitle={this.state.newTitle}
                                    topic={this.state.topic}
                                    clickFullScreen={this.clickFullScreen}
                                    fullscreen={this.state.fullscreen}
                                    firstBlanks={this.state.firstBlanks}
                                    completeFirstBlanks={this.completeFirstBlanks}
                                    json={this.state.json}
                                    updateCurrSetId={this.updateCurrSetId}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={[ROUTES.GENIUS, ROUTES.INVITE_GENIUS]}
                            render={(props) => (
                                <Genius
                                    {...props}
                                    guest={this.props.guest}
                                    color={this.state.backgroundColor}
                                    currentTime={this.props.currentTime}
                                    id={this.state.id}
                                    reset={this.reset}
                                    newTitle={this.state.newTitle}
                                    topic={this.state.topic}
                                    clickFullScreen={this.clickFullScreen}
                                    fullscreen={this.state.fullscreen}
                                    firstBreakdown={this.state.firstBreakdown}
                                    completeFirstBreakdown={this.completeFirstBreakdown}
                                    json={this.state.json}
                                    setTime={this.setTime}
                                    updateCurrSetId={this.updateCurrSetId}
                                />
                            )}
                        />
                    </div>
                ) : (
                    <Loading zoom={true} />
                )}
            </MainWrapper>
        );
    }
}

// maps the Redux state to this component's props
const mapStateToProps = (state) => ({ ...state.audioPlayer });

// maps actions to this component's props
const mapDispatchToProps = (dispatch) => ({
    setSongDetails: (songDetails) => dispatch(setSongDetails(songDetails)),
    setBackground: (color) => dispatch(setBackground(color)),
    setAudioSrc: (audioSrc) => dispatch(setAudioSrc(audioSrc)),
    setLyrics: (lyrics) => dispatch(setSongLyrics(lyrics)),
    setCurrentTime: (time) => dispatch(setCurrentTime(time)),
    setEnded: (ended) => dispatch(setEnded(ended)),
    setPlay: (play) => dispatch(setPlay(play)),
    setDisplayShare: (displayShare) => dispatch(setDisplayShare(displayShare)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(withFirebase(SongViewBase)));
