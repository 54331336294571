/**
 * Returns a Promise that resolves when a unit has been added to the user's
 * library collection in the user document. Assume that the unit is not already in
 * the library collection.
 *
 * @param {DocumentReference} unitRef
 * @returns Promise<Void>
 */
export async function addToLibrary(unitRef) {
    var unitId = unitRef.id;
    var libraryRef = this.db.collection('users').doc(this.auth.currentUser.uid).collection('library');
    var userUnitRef = libraryRef.doc(unitId);

    // new access case
    var unitSnap = await unitRef.get();
    if (!unitSnap.exists) throw this.NotFoundError;
    var unitData = unitSnap.data();
    await userUnitRef.set(unitData);
}

/**
 * Returns a Promise that resolves with a boolean expressing if the unit
 * exists in the collection library.
 * @param {string} unitId
 * @returns Promise<boolean>
 */
export async function inLibrary(unitId) {
    var unitSnap = await this.db.collection('users').doc(this.auth.currentUser.uid).collection('library').doc(unitId).get();

    return unitSnap.exists;
}

/**
 * Returns a Promise that resolves when a unit has been removed from the user's
 * library collection in the user document.
 * @param {string} unitId
 * @returns Promise<Void>
 */
export async function removeFromLibrary(unitId) {
    await this.db.collection('users').doc(this.auth.currentUser.uid).collection('library').doc(unitId).delete();
}

/**
 * Returns a Promise that resolves with the Library of the user.
 */
export async function getLibrary() {
    if (this.auth.currentUser.uid == null) return;
    var querySnap = await this.db.collection('users').doc(this.auth.currentUser.uid).collection('library').orderBy('subject').get();

    var result = this.sortLibrary(querySnap);
    return result;
}

export function sortLibrary(querySnap) {
    if (!querySnap || querySnap.docs.length === 0) return null;

    var datas = querySnap.docs.map((item) => {
        return item.data();
    });
    // sort into subjects
    var result = {};
    var tempArr = [];
    var currSubj = datas[0].subject;
    datas.forEach((data) => {
        if (data.subject === currSubj) {
            // base case - same subject
            tempArr.push(data);
        } else {
            // new subject case
            result[currSubj] = tempArr;
            tempArr = [];
            currSubj = data.subject;
            tempArr.push(data);
        }
    });
    result[currSubj] = tempArr;
    return result;
}
