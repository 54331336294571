import React, { Component } from 'react';
import * as ROUTES from '../../../../constants/routes';
import AlbumCoverCarouselController from '../../../UI-Library/ImageContainers/AlbumCoverCarouselController';
import { withRouter } from 'react-router';

/**
 * Returns the JSX that displays the default Browse content.
 * @param {any} data
 */
export class BrowseSubjects extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.subjectRef = [];
        this.subjectComparator = this.subjectComparator.bind(this);
    }

    viewAllSubject(subject) {
        this.props.history.push(ROUTES.BROWSE_UNITS + '/' + subject);
    }

    scrollLeft(index) {
        this.subjectRef[index].scrollLeft -= 600;
    }

    scrollRight(index) {
        this.subjectRef[index].scrollLeft += 600;
    }

    subjectComparator(subj1, subj2) {
        var len1 = this.props.data[subj1].length;
        var len2 = this.props.data[subj2].length;
        if (len1 < len2) {
            return 1;
        } else if (len1 === len2) {
            return 0;
        } else {
            return -1;
        }
    }

    render() {
        if (!this.props.data || this.props.data === null) return null;
        var sortedKeys = Object.keys(this.props.data).sort(this.subjectComparator);
        var result = sortedKeys.map((item, index) => {
            const unitArr = this.props.data[item];
            return (
                <div key={'SUBJECTCAROUSEL_' + item}>
                    <AlbumCoverCarouselController
                        header={item}
                        itemType={'unit'}
                        rows={1}
                        items={unitArr}
                        viewAll={() => this.viewAllSubject(item)}
                        canViewAll={true}
                    />{' '}
                </div>
            );
        });
        return result;
    }
}

export default withRouter(BrowseSubjects);
