import React, { Component } from 'react';

import PropTypes from 'prop-types';
import RsSlider from '../../../Forms/RsSlider';
import Speaker from '../../../Icons/Speaker';
import styled from 'styled-components';

const VolumeDiv = styled.div`
    display: flex;
    margin-left: 30px;
    align-items: center;
`;

/**
 * The volume icon, with a slider that appears on hover.
 */
class Volume extends Component {
    render() {
        return (
            <VolumeDiv>
                <Speaker hover={true} />
                <RsSlider
                    percentage={this.props.value}
                    width={'90px'}
                    ml={'10px'}
                    label="volume"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    secondary={true}
                />
            </VolumeDiv>
        );
    }
}

Volume.propTypes = {
    /**
     * A number between 1 and 100.
     */
    value: PropTypes.number,
    /**
     * The function that fires when the volume slider is moved.
     */
    onChange: PropTypes.func,
};

export default Volume;
