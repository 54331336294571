import React, { Component } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

const Circle = styled.div`
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--rsMajor);
    border: 2px solid var(--rsMajor);
`;

const Number = styled.p`
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    line-height: 18px;
    text-align: center;
`;

/**
 * The bubble to display and select an answer choice for a multiple choice
 * question.
 */
class NotificationsPending extends Component {
    render() {
        return (
            <Circle>
                <Number>{this.props.number}</Number>
            </Circle>
        );
    }
}

NotificationsPending.propTypes = {
    /**
     * The number shown inside the circle.
     */
    number: PropTypes.number.isRequired,
};

export default NotificationsPending;
