/**
 * An object representing the metadata for a study set.
 */
class StudySet {
    /**
     * Creates a new StudySet object.
     * @param {string} authorId
     * @param {Date} created
     * @param {Date} lastModified
     * @param {string} name
     * @param {string} setId
     * @param {string} songId
     * @param {string} featureName
     * @param {string} mode
     * @param {number} numItems
     */
    constructor(authorId, created, lastModified, name, setId, songId, featureName, mode, numItems) {
        if (!Object.keys(StudySet.featuresAndModes).includes(featureName))
            throw Error('Precondition Invalidated: featureName must be one of Review, Blanks, or Breakdown.');
        this.authorId = authorId;
        this.created = created;
        this.lastModified = lastModified;
        this.name = name;
        this.setId = setId;
        this.songId = songId;
        this.featureName = featureName;
        this.mode = mode;
        this.numItems = numItems;
    }

    static featuresAndModes = {
        Blanks: ['standard', 'free recall', 'word bank'],
        Review: ['easy', 'medium', 'hard'],
        Breakdown: [],
    };

    static blanksConverter = {
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            return new StudySet(
                data.author,
                data.created.toDate(),
                data.lastModified.toDate(),
                data.name,
                snapshot.ref.id,
                data.songId,
                'Blanks',
                data.mode ? data.mode : 'free recall',
                data.numItems,
            );
        },
    };

    static breakdownConverter = {
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            return new StudySet(
                data.author,
                data.created.toDate(),
                data.lastModified.toDate(),
                data.name,
                snapshot.ref.id,
                data.songId,
                'Breakdown',
                data.mode ? data.mode : null,
                data.numItems,
            );
        },
    };

    static reviewConverter = {
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            return new StudySet(
                data.author,
                data.created.toDate(),
                data.lastModified.toDate(),
                data.name,
                snapshot.ref.id,
                data.songId,
                'Review',
                data.mode ? data.mode : 'easy',
                data.numItems,
            );
        },
    };

    static toSetConverter = {
        toFirestore: function (studySet) {
            return {
                author: studySet.authorId,
                created: studySet.created,
                lastModified: studySet.lastModified,
                name: studySet.name,
                songId: studySet.songId,
            };
        },
    };

    getAuthorId() {
        return this.authorId;
    }

    getCreatedDate() {
        return this.created;
    }

    getLastModifiedDate() {
        return this.lastModified;
    }

    getName() {
        return this.name;
    }

    getSetId() {
        return this.setId;
    }

    getSongId() {
        return this.songId;
    }

    getFeatureName() {
        return this.featureName;
    }

    getMode() {
        return this.mode;
    }

    getNumItems() {
        return this.numItems;
    }

    setMode(mode) {
        if (!this.featureName) throw Error('Failed: cannot set mode for a set without a defined featureName');
        if (!StudySet.featuresAndModes[this.featureName].includes(mode)) throw Error('Invalid mode selection: mode is invalid for the current featureName.');

        this.mode = mode;
    }
}

export default StudySet;
