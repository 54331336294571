import SubtopicDict from './SubtopicDict';
class TopicDict {
    constructor(name, id, src, imgName, ref) {
        this.name = name;
        this.id = id;
        this.src = src;
        this.ref = ref;
        this.imgName = imgName;
        this.grades = new Set();
        this.standards = new Set();
        this.subtopics = {}; // Object of key: string (grade), value: Array<SubtopicDict>
    }

    /**
     * Returns the name of the topic.
     * @return {string}
     */
    getName() {
        return this.name;
    }

    /**
     * Adds a subtopic to end of corresponding array in this.subtopics
     * @param {SubtopicDict} subtopicDict
     */
    addSubtopic(subtopicDict) {
        if (!(subtopicDict instanceof SubtopicDict)) throw Error('addSubtopic: input is not a Subtopic object.');

        const grade = subtopicDict.getGrade();
        if (this.subtopics[grade]) {
            this.subtopics[grade].push(subtopicDict);
        } else {
            this.subtopics[grade] = [subtopicDict];
        }
        //need to add grades and standards
        this.grades.add(subtopicDict.getGrade());
        this.standards.add(subtopicDict.getStandard());

        // if (subtopicDict.getGrade() !== '') {
        //   this.grades.add(subtopicDict.getGrade());
        // }
        // if (subtopicDict.getStandard() !== '') {
        //   this.standards.add(subtopicDict.getStandard());
        // }
        return;
    }

    // getters

    /**
     * Returns a copied TopicDict that has been filtered by the given grades and standards
     * @param {Array} grades
     * @param {Array} standards
     * @return {TopicDict}
     */
    getFiltered(grades = [], standards = []) {
        var tempTopicDict = this.copyTopicDict();

        if (grades.length === 0 && standards.length === []) {
            return tempTopicDict;
        } else {
            //filtering by grade
            for (var grade in tempTopicDict.subtopics) {
                if (!grades.includes(grade) && grades.length !== 0) {
                    tempTopicDict.grades.delete(grade);
                    delete tempTopicDict.subtopics[grade];
                }
            }
            //filtering by standard
            for (var grade2 in tempTopicDict.subtopics) {
                const subtopicDictArr = tempTopicDict.subtopics[grade2];
                for (var i = subtopicDictArr.length - 1; i >= 0; i--) {
                    if (!standards.includes(subtopicDictArr[i].standard) && standards.length !== 0) {
                        subtopicDictArr.splice(i, 1);
                    }
                }
                //if all subtopics were removed under one grade, remove the entire grade
                if (subtopicDictArr.length === 0) {
                    delete tempTopicDict.subtopics[grade2];
                    tempTopicDict.grades.delete(grade2);
                }
            }
        }
        return tempTopicDict;
    }

    /**
     * Gets the array of standards for the topic.
     * @return {Array}
     */
    getStandards() {
        return Array.from(this.standards);
    }

    /**
     * Gets the array of grades for the topic.
     * @return {Array}
     */
    getGrades() {
        return Array.from(this.grades);
    }

    /**
     * Gets the list of subtopics for the topic. The object is of the form - key: string (grade), value: Array<SubtopicDict>
     * @return {Object}
     */
    getSubtopics() {
        return this.subtopics;
    }

    /**
     * Gets the reference of this topic.
     * @return {string}
     */
    getRef() {
        return this.ref;
    }

    /**
     * Gets the src of image for the topic.
     * @return {string}
     */
    getSrc() {
        return this.src;
    }

    /**
     * Gets the reference id for the topic.
     * @return {string}
     */
    getId() {
        return this.id;
    }

    /**
     * Sets the src of the image for the topic.
     * @param {string} src
     */
    setSrc(src) {
        this.src = src;
    }
    /**
     * Returns a copy of the current TopicDict
     * @return {TopicDict}
     */
    copyTopicDict() {
        var tempTopicDict = new TopicDict(this.name, this.id, this.src, this.imgName, this.ref);
        for (var grade in this.subtopics) {
            this.subtopics[grade].forEach((subtopicObj) => {
                const copiedSubtopic = subtopicObj.copySubtopic();
                tempTopicDict.addSubtopic(copiedSubtopic);
            });
        }
        return tempTopicDict;
    }

    static topicConverter = {
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new TopicDict(data.title, snapshot.ref.id, data.firebaseImgName, data.imgName, snapshot.ref);
        },
    };
}

export default TopicDict;
