import Question from '../Question';
import QuestionList from '../QuestionList';
import Song from '../Song';

/**
 * ReviewSubmission is a child class of QuestionList, and inherits all of its
 * properties and methods. It contains more information about the assignment
 * in which the question set is used.
 * @extends QuestionList
 */
class ReviewSubmission extends QuestionList {
    /**
     * Creates a new ReviewSubmission object.
     * @param {bool} readOnly
     * @param {string} setName
     * @param {string} status
     * @param {number} score
     * @param {number} length
     * @param {string} exerciseId
     * @param {string} teacherId
     * @param {string} studentId
     * @param {string} setId
     * @param {string} assignmentId
     * @param {string} submissionId
     * @param {string} classId
     * @param {string} songId
     * @param {Song} songData
     * @param {string} mode
     * @param {Date} releaseDate
     * @param {Date} dueDate
     * @param {number} index
     */
    constructor(
        readOnly,
        setName,
        status,
        score,
        length,
        exerciseId,
        teacherId,
        studentId,
        setId,
        assignmentId,
        submissionId,
        classId,
        songId,
        songData,
        mode,
        releaseDate,
        dueDate,
        index,
    ) {
        if (!['complete', 'not started', 'in progress'].includes(status)) {
            throw Error('ReviewSubmission constructor failed: status must be one of "complete", "in progress", or "not started".');
        }
        super(readOnly, score, length, setName, '', '', songId);
        this._status = status;
        this._studentId = studentId;
        this._teacherId = teacherId;
        this._exerciseId = exerciseId; // the id of the exercise under the assignment.
        this._setId = setId; // the id of the original underlying set.
        this._assignmentId = assignmentId; // the id of the assignment.
        this._submissionId = submissionId;
        this._classId = classId;
        this._songId = songId;
        this._songData = songData;
        this._mode = mode;
        this._releaseDate = releaseDate;
        this._dueDate = dueDate;
        this._index = index;
    }

    static setSubmissionConverter = {
        toFirestore: function (reviewSubmission) {
            return {
                type: 'Review',
                mode: reviewSubmission.getMode(),
                setName: reviewSubmission.getName(),
                status: reviewSubmission.getStatus(),
                score: reviewSubmission.getScore(),
                length: reviewSubmission.getLength(),
                exerciseId: reviewSubmission.getExerciseId(),
                assignmentId: reviewSubmission.getAssignmentId(),
                setId: reviewSubmission.getSetId(),
                studentId: reviewSubmission.getStudentId(),
                teacherId: reviewSubmission.getTeacherId(),
                classId: reviewSubmission.getClassId(),
                songId: reviewSubmission.getSongId(),
                songData: Song.songConverter.toFirestore(reviewSubmission.getSongData()),
                items: reviewSubmission.getQuestions().map((q) => Question.assignmentConverter.toFirestore(q)),
                releaseDate: reviewSubmission.getReleaseDate(),
                dueDate: reviewSubmission.getDueDate(),
                index: reviewSubmission.getIndex(),
            };
        },
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            const result = new ReviewSubmission(
                false,
                data.setName,
                data.status,
                data.score,
                data.length,
                data.exerciseId,
                data.teacherId,
                data.studentId,
                data.setId,
                data.assignmentId,
                snapshot.id,
                data.classId,
                data.songId,
                new Song(
                    data.songId,
                    data.songData.genre,
                    data.songData.newArtist,
                    data.songData.origArtist,
                    data.songData.origTitle,
                    data.songData.subject,
                    data.songData.unit,
                    data.songData.topic,
                    data.songData.subtopic,
                    data.songData.uploaded,
                ),
                data.mode,
                data.releaseDate.toDate(),
                data.dueDate.toDate(),
                data.index,
            );
            const questionArr = data.items.map((qData) => Question.assignmentConverterGet(qData));
            const sortedQuestions = questionArr.sort((q1, q2) => q1.getIndex() - q2.getIndex());
            result.addQuestionList(sortedQuestions);
            return result;
        },
    };

    /**
     * Returns the exercise id for this submission.
     * @return {string}
     */
    getExerciseId() {
        return this._exerciseId;
    }

    /**
     * Returns the underlying set id for this submission.
     * @return {string}
     */
    getSetId() {
        return this._setId;
    }

    /**
     * Returns the assignment id for this submission.
     * @return {string}
     */
    getAssignmentId() {
        return this._assignmentId;
    }

    /**
     * Returns the class id for this submission.
     * @return {string}
     */
    getClassId() {
        return this._classId;
    }

    /**
     * Returns the submission id.
     * @return {string}
     */
    getSubmissionId() {
        return this._submissionId;
    }

    /**
     * Sets the student id for this submission.
     */
    setStudentId(studentId) {
        if (!studentId) throw Error('Invalidated Precondition: studentId is falsy.');
        this._studentId = studentId;
    }

    /**
     * Returns the student id for this submission.
     * @return {string}
     */
    getStudentId() {
        return this._studentId;
    }

    /**
     * Returns the teacher id who created the assignment.
     * @returns {string}
     */
    getTeacherId() {
        return this._teacherId;
    }

    /**
     * Returns the song id for this submission.
     * @return {string}
     */
    getSongId() {
        return this._songId;
    }

    /**
     * Returns the Song object associated with this set submission.
     * @return {Song}
     */
    getSongData() {
        return this._songData;
    }

    /**
     * Sets the status of the submission.
     * @param {string} status
     */
    setStatus(status) {
        if (!['not started', 'in progress', 'complete'].includes(status))
            throw Error('Invalidated Precondition: status must be one of not started, in progress, or complete.');
        this._status = status;
    }

    /**
     * Returns the status of the submission.
     * @return {string}
     */
    getStatus() {
        return this._status;
    }

    /**
     * Returns the type of set this is. In this case, "Review".
     * @return {string}
     */
    getFeatureName() {
        return 'Review';
    }

    /**
     * Returns the mode of this set.
     * @return {string}
     */
    getMode() {
        return this._mode;
    }

    /**
     * Returns the maximum number of attempts per question.
     * @return {number}
     */
    getMaxAttempts() {
        if (this._mode === 'easy') return 0;
        if (this._mode === 'medium') return 2;
        if (this._mode === 'hard') return 1;
    }

    /**
     * Returns the release date for this submission.
     * @return {Date}
     */
    getReleaseDate() {
        return this._releaseDate;
    }

    /**
     * Sets the release date
     * @param {Date} release
     * */
    setReleaseDate(release) {
        this._releaseDate = release;
    }

    /**
     * Returns the due date for this submission.
     * @return {Date}
     */
    getDueDate() {
        return this._dueDate;
    }

    /**
     * Sets the due date
     * @param {Date} due
     * */
    setDueDate(due) {
        this._dueDate = due;
    }

    /**
     * Gets the index
     * @return {number}
     */
    getIndex() {
        return this._index;
    }
}

export default ReviewSubmission;
