import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BlanksSubmission from '../../../../Classes/Assignments/BlanksSubmission';

const MainTable = styled.table`
    width: 100%;
`;

const Heading = styled.th`
    color: var(--rsPrimary);
    padding-left: 10px;
    padding-right: 10px;
`;

const FirstBody = styled.td`
    color: var(--rsSecondary);
    padding: 10px;
    width: 5%;
    text-align: right;
`;

const Body = styled.td`
    color: ${(props) => (props.isCorrect ? 'var(--rsSecondary)' : 'red')};
    font-weight: ${(props) => (props.isCorrect ? 'normal' : 'bold')};
    border-left: 2px solid var(--rsSecondary);
    border-right: 2px solid var(--rsSecondary);
    padding: 10px;
    width: 28.33333%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 0;
`;

const LastBody = styled.td`
    color: var(--rsSecondary);
    padding: 10px;
    width: 10%;
`;

class BlanksSubmissionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableContent: [],
        };
    }

    render() {
        const { submissionObj } = this.props;
        const keywords = submissionObj.getKeywordsArray();
        const rows = [];
        for (let i = 0; i < submissionObj.getLength(); i++) {
            const keyword = keywords[i];
            const numAttempts = keyword.getAttempts();
            const attemptList = keyword.getAttemptList();
            const correctWord = keyword.getWord();
            const isCorrect = keyword.isCorrect();

            rows.push(
                <tr key={i}>
                    <FirstBody>{i + 1}.</FirstBody>
                    <Body isCorrect={true}>{correctWord}</Body>
                    <Body isCorrect={isCorrect}>{keyword.isPassed() ? attemptList.join(', ') : 'Missing'}</Body>
                    <LastBody>{numAttempts}</LastBody>
                </tr>,
            );
        }

        return (
            <MainTable>
                <thead>
                    <tr>
                        <Heading></Heading>
                        <Heading>Keyword</Heading>
                        <Heading>Student's answer</Heading>
                        <Heading>Attempts</Heading>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </MainTable>
        );
    }
}

BlanksSubmissionTable.propTypes = {
    /**
     * BlanksSubmission object the table is modeled from
     */
    submissionObj: PropTypes.instanceOf(BlanksSubmission),
};

export default BlanksSubmissionTable;
