import React, { Component } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

const Circle = styled.div`
    height: 26px;
    width: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => (props.selected ? 'var(--rsMajor)' : 'none')};
    border: ${(props) => (props.selected ? '2px solid var(--rsMajor)' : '2px solid var(--rsSecondary)')};
`;

const Choice = styled.p`
    color: ${(props) => (props.selected ? 'black' : 'var(--rsSecondary)')};
    font-weight: bold;
    margin: 0;
    line-height: 26px;
    text-align: center;
`;

/**
 * The bubble to display and select an answer choice for a multiple choice
 * question.
 */
class AnswerChoice extends Component {
    render() {
        const handleClick = this.props.selected ? null : this.props.onClick;
        return (
            <Circle selected={this.props.selected} onClick={handleClick}>
                <Choice selected={this.props.selected}>{this.props.letter}</Choice>
            </Circle>
        );
    }
}

AnswerChoice.propTypes = {
    /**
     * Is the answer choice selected?
     */
    selected: PropTypes.bool.isRequired,
    /**
     * The letter shown inside the circle.
     */
    letter: PropTypes.string.isRequired,
    /**
     * The function fired when the circle is clicked.
     */
    onClick: PropTypes.func,
};

export default AnswerChoice;
