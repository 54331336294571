import React, { Component } from 'react';
import styled from 'styled-components';

import { withFirebase } from '../../../Firebase';
import { withAlert } from 'react-alert';

import PlaylistShare from './PlaylistShare';
import SetShare from './SetShare';
import SongShare from './SongShare';
import EmptyShared from './EmptyShared';
import Loading from '../../../UI-Library/Misc/Loading';

const MainWrapper = styled.div`
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
`;

class SharedContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            songs: [],
            playlists: [],
            sets: [],
            ready: false,
        };
        this.removeSong = this.removeSong.bind(this);
        this.removePlaylist = this.removePlaylist.bind(this);
        this.removeSet = this.removeSet.bind(this);
    }

    async componentDidMount() {
        const songsPr = Promise.all(
            this.props.sharedSongs.map(async (songId) => {
                const snap = await this.props.firebase.db.collection('songs').doc(songId).get();
                const songData = snap.data();
                songData.id = snap.id;
                songData.src = await this.props.firebase.getSongCover(songData.subtopic.subtopicRef.id);
                return songData;
            }),
        );
        const resultPr = await this.props.firebase.getClassSharedContent(this.props.id);
        const resolved = await Promise.all([songsPr, resultPr]);
        const songs = resolved[0];
        const result = resolved[1];
        const songDictArr = result.sets.getSortedSongListByName();

        this.setState({
            playlists: result.playlists,
            sets: songDictArr,
            songDicts: result.sets,
            songs: songs,
            ready: true,
        });

        return;
    }

    async removeSong(songId) {
        this.props.firebase.removeContentFromClass('Song', songId, this.props.id).then(() => {
            this.props.alert.show('The song was successfully removed from the class.');
        });
        var newSongs = this.state.songs.filter((song) => {
            return song.id !== songId;
        });
        this.setState({ songs: newSongs });
    }

    async removePlaylist(playlistId) {
        this.props.firebase.removeContentFromClass('Playlist', playlistId, this.props.id).then(() => {
            this.props.alert.show('The playlist was successfully removed from the class.');
        });
        var newPlaylists = this.state.playlists.filter((play) => {
            return play.playlistId !== playlistId;
        });
        this.setState({ playlists: newPlaylists });
    }

    async removeSet(contentType, songId, setId) {
        this.props.firebase.removeContentFromClass(contentType, setId, this.props.id).then(() => {
            this.props.alert.show('The set was successfully removed from the class.');
        });
        var songDicts = this.state.songDicts;
        songDicts.deleteSet(contentType, songId, setId);
        const arr = songDicts.getSortedSongListByName();
        this.setState({ songDicts: songDicts, sets: arr });
    }

    render() {
        const isTeacher = this.props.userToken.claims.teacher;
        const emptySongs = !this.state.songs.length > 0;
        const emptyPlaylists = !this.state.playlists.length > 0;
        const emptySets = !this.state.sets.length > 0;
        if (!this.state.ready) return <Loading zoom={true} />;

        return (
            <MainWrapper>
                {!emptySongs && (
                    <div>
                        <SongShare songs={this.state.songs} remove={isTeacher ? this.removeSong : null} isAdmin={this.props.isAdmin} id={this.props.id} />
                    </div>
                )}
                {!emptyPlaylists && (
                    <div>
                        <PlaylistShare
                            playlists={this.state.playlists}
                            remove={isTeacher ? this.removePlaylist : null}
                            isAdmin={this.props.isAdmin}
                            id={this.props.id}
                        />
                    </div>
                )}
                {!emptySets && (
                    <div>
                        <SetShare
                            sets={this.state.sets}
                            remove={isTeacher ? this.removeSet : null}
                            isTeacher={isTeacher}
                            isAdmin={this.props.isAdmin}
                            id={this.props.id}
                        />
                    </div>
                )}
                <EmptyShared isAdmin={this.props.isAdmin} emptySongs={emptySongs} emptyPlaylists={emptyPlaylists} emptySets={emptySets} />
            </MainWrapper>
        );
    }
}

export default withAlert()(withFirebase(SharedContent));
