import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';

const ChildContainer = styled.div`
    width: 100%;
    height: auto;
`;

const MainWrapper = styled.div`
    width: 304px;
    padding: 5px;

    height: 111px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border: none;
    background: ${(props) => (props.hover && !props.active && props.clickable && !props.disabled ? props.hoverHex : props.backHex)};
    cursor: ${(props) => (props.disabled || props.active || !props.clickable ? 'default' : 'pointer')};
`;

const ImageColumn = styled.div`
    margin-top: 2%;
    margin-right: 10px;
`;

const InfoColumn = styled.div`
    width: 200px;
`;

const SongName = styled.p`
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0rem;
    text-align: left;
    margin-top: 3%;
    color: ${(props) => (props.disabled ? 'var(--rsSecondary)' : 'var(--rsPrimary)')};
`;

const SetName = styled.p`
    font-size: 18px;
    font-weight: bold;
    line-height: 33px;
    text-align: left;
    margin-bottom: 0px;
    color: ${(props) => (props.disabled ? 'var(--rsSecondary)' : 'var(--rsPrimary)')};
`;

const ThumbnailImg = styled.img`
    width: 82px;
    height: 82px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3%;
    opacity: ${(props) => (props.disabled ? 0.25 : 1)};
`;

const Row = styled.div`
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    display: flex;
    align-items: center;
`;

/**
 * A preview component for an exercise in an assignment.
 */
class ExerciseThumbnail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.onHover = this.onHover.bind(this);
        this.offHover = this.offHover.bind(this);
    }

    onHover(e) {
        this.setState({
            hover: true,
        });
    }

    offHover(e) {
        this.setState({
            hover: false,
        });
    }

    render() {
        const onClick = this.props.onClick;
        const backHex = this.props.active ? tinycolor(this.props.backgroundColor).toHexString() : 'var(--rsSurface)';
        const hoverHex = tinycolor(this.props.backgroundColor).darken();
        return (
            <MainWrapper
                onClick={this.props.disabled || this.props.active || !this.props.clickable ? null : onClick}
                disabled={this.props.disabled}
                clickable={this.props.clickable}
                backHex={backHex}
                hoverHex={hoverHex}
                hover={this.state.hover}
                active={this.props.active}
                onMouseOver={this.onHover}
                onMouseLeave={this.offHover}
            >
                <Row disabled={this.props.disabled}>
                    <ImageColumn>
                        <ThumbnailImg src={this.props.src} disabled={this.props.disabled} />
                    </ImageColumn>
                    <InfoColumn>
                        <SongName disabled={this.props.disabled} className="ellipsisWrap">
                            {this.props.songName}
                        </SongName>
                        <SetName disabled={this.props.disabled} className="ellipsisWrap">
                            {this.props.featureName}
                            <span className="secondary " style={{ fontSize: '14px', marginBottom: 0 }}>
                                : {this.props.setName}
                            </span>
                        </SetName>

                        <ChildContainer onClick={(e) => e.stopPropagation()}>{this.props.children}</ChildContainer>
                    </InfoColumn>
                </Row>
            </MainWrapper>
        );
    }
}

ExerciseThumbnail.propTypes = {
    /**
     * Is the exercise active
     */
    active: PropTypes.bool.isRequired,
    /**
     * Is the thumbnail clickable?
     */
    clickable: PropTypes.bool.isRequired,
    /**
     * Toggles disabled styling and disables the `onClick` function passed in.
     */
    disabled: PropTypes.bool,
    /**
     * Image src/url
     */
    src: PropTypes.string.isRequired,
    /**
     * The name of the feature.
     */
    featureName: PropTypes.oneOf(['Review', 'Breakdown', 'Blanks']).isRequired,
    /**
     * Song name of exercise
     */
    songName: PropTypes.string.isRequired,
    /**
     * Song id of exercise
     */
    songId: PropTypes.string.isRequired,
    /**
     * Set id of exercise
     */
    setId: PropTypes.string.isRequired,
    /**
     * The name of the set.
     */
    setName: PropTypes.string,
    /**
     * Background color of thumbnail when active. Supports hex or rgb(a), but not
     * CSS variables.
     */
    backgroundColor: PropTypes.string.isRequired,
    /**
     * On click behavior of thumbnail
     */
    onClick: PropTypes.func.isRequired,
};

export default ExerciseThumbnail;
