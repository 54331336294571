import React, { Component } from 'react';

import AlbumCoverCarousel from '../../../UI-Library/ImageContainers/AlbumCover';
import PropTypes from 'prop-types';
import RsLink from '../../../UI-Library/ButtonsOrLinks/RsLink';
import styled from 'styled-components';

const Title = styled.h2`
    font-weight: bold;
    line-height: 21px;
    color: var(--rsPrimary);
    margin: 0;
    margin-right: 5px;
    font-size: 20px;
`;

const MainWrapper = styled.div`
    margin-bottom: 0px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;

/**
 * AlbumCoverCarouselController calls the AlbumCoverCarousel component. The
 * child component is not in the Storybook. For details, look at the source
 * code. Currently only supports songs, playlists, and units.
 */
class AlbumCoverCarouselController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currSlide: 0,
            numSlides: 0,
        };
        this.scrollLeft = this.scrollLeft.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    scrollLeft() {
        if (this.state.currSlide === 0) return;
        const currSlide = this.state.currSlide;
        this.setState({
            currSlide: currSlide - 1,
        });
    }

    scrollRight() {
        if (this.state.currSlide + 1 >= this.state.numSlides) return;

        const currSlide = this.state.currSlide;
        this.setState({
            currSlide: currSlide + 1,
        });
    }

    onResize(numSlides) {
        this.setState({
            numSlides: numSlides,
        });
    }

    render() {
        return (
            <MainWrapper>
                <Header>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <RsLink onClick={this.props.viewAll}>
                            <Title>{this.props.header}</Title>
                        </RsLink>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px' }}>
                        {/* <ScrollButton clickable={clickableLeft} onClick={this.scrollLeft}>
              <Caret disabled={!clickableLeft} direction="left" onClick={this.scrollLeft} />
            </ScrollButton>
            <ScrollButton clickable={clickableRight} onClick={this.scrollRight}>
              <Caret disabled={!clickableRight} onClick={this.scrollRight} direction="right" />
            </ScrollButton> */}
                    </div>
                </Header>

                <AlbumCoverCarousel
                    items={this.props.items}
                    currSlide={this.state.currSlide}
                    guest={this.props.guest}
                    onResize={this.onResize}
                    rows={this.props.rows}
                    itemType={this.props.itemType}
                    remove={this.props.remove}
                />
            </MainWrapper>
        );
    }
}

AlbumCoverCarouselController.propTypes = {
    /**
     * The items that will be included in the carousel. See BoxPreview for detail
     * on the required props for that component. Depending on the type, the items
     * need different fields. For playlists: [name] replaces title, [playlistId]
     * replaces [id].
     */
    items: PropTypes.array.isRequired,
    /**
     * The number of rows in the carousel.
     */
    rows: PropTypes.number.isRequired,
    /**
     * The type of the elements, either song, unit, or playlist.
     */
    itemType: PropTypes.oneOf(['song', 'playlist', 'unit']).isRequired,
    /**
     * The function to remove an element from the list.
     */
    remove: PropTypes.func,
    /**
     * The header of the carousel.
     */
    header: PropTypes.string,
    /**
     * Show the view all link?
     */
    canViewAll: PropTypes.bool,
    /**
     * The function fired when the view all link is clicked.
     */
    viewAll: PropTypes.func,
};

export default AlbumCoverCarouselController;
