import React, { Component } from 'react';
import styled from 'styled-components';
import UnitFilter from '../UnitFilter';
import Topic from '../../../UI-Library/ItemDisplays/Topic';

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-between;
    flex-wrap: wrap;
`;

/**
 * UnitContent is the component for the main content in the "unitView" page in Internal. It
 * takes in props passed by UnitView to display its information. UnitContent does not contain any
 * asynchronous firebase code - all the information it needs are passed as props from UnitView.
 */
class UnitContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topics: this.props.unitDict.getTopics([], []),
            filterOpen: false,
        };
        this.toggleFilterCollapse = this.toggleFilterCollapse.bind(this);
        this.submitSelection = this.submitSelection.bind(this);
    }

    toggleFilterCollapse() {
        this.setState({ filterOpen: !this.state.filterOpen });
    }

    /**
     * Handles state setting for filter submission. Passed as prop into UnitFilter.
     * @param {Set} grades
     * @param {Set} standards
     */
    submitSelection(grades, standards) {
        if (grades.length === 0 && standards.length === 0) {
            this.setState({
                topics: this.props.unitDict,
            });
        } else {
            var result = [];
            // iterate over topics
            result = this.props.unitDict.getTopics(Array.from(grades), Array.from(standards));
            this.setState({
                topics: result,
            });
        }
    }

    render() {
        const topics = this.state.topics.map((item, key) => {
            return <Topic guest={this.props.guest} topic={item} backgroundColor={this.props.backgroundColor} key={item.getId()} />;
        });

        // This is necessary because in some units, grades are not defined, that is,
        // some topics have the empty string '' as a grade.
        const grades = this.props.unitDict.getGrades().filter((ele) => ele !== '');
        const standards = this.props.unitDict.getStandards().filter((ele) => ele !== '');
        // console.log(this.props.unitDict);
        return (
            <div>
                {grades > 0 || standards.length > 0 ? (
                    <UnitFilter
                        filterOpen={this.state.filterOpen}
                        toggleFilterCollapse={this.toggleFilterCollapse}
                        grades={grades}
                        standards={standards}
                        submitSelection={this.submitSelection}
                    />
                ) : null}
                <MainWrapper>{topics}</MainWrapper>
            </div>
        );
    }
}

export default UnitContent;
