import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ScorePill from '../../ButtonsOrLinks/ScorePill';
import GradesSummary from '../../../../Classes/Assignments/GradesSummary';

import { capitalizeFLetter } from '../../../../utility/stringFunctions';

const MainWrapper = styled.div`
    display: flex;
    overflow-x: auto;
    padding-bottom: 20px;
`;

const NameCol = styled.div`
    min-width: 200px;
`;

const ScoreCol = styled.div`
    margin-left: 20px;
    border-left: 2px solid var(--rsSecondary);
    border-right: ${(props) => (props.isLast ? '2px solid var(--rsSecondary)' : 'none')};
    padding-left: 10px;
    padding-right: 10px;
`;

const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    color: var(--rsSecondary);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: var(--rsPrimary);
    }
`;

const ScoreItemWrapper = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
`;

const ScoreHeader = styled.div`
    height: 100px;
`;

const NewTitle = styled.p`
    font-weight: bold;
    margin-bottom: 0;
`;

const OrigTitle = styled.p`
    font-size: 14px;
    color: var(--rsSecondary);
    margin-bottom: 10px;
`;

/**
 * Assignment Summary is the main table used to display the results of an
 * assignment for a teacher.
 */
class AssignmentSummary extends Component {
    /**
     * Renders the left name column.
     * @param {Array<IndivGradeSummary>} summaries
     */
    renderNames(summaries) {
        return summaries.map((indivSummary) => {
            const name = indivSummary.getStudentName();
            const studentId = indivSummary.getStudentId();
            return (
                <NameWrapper key={'NAME_' + studentId} onClick={() => this.props.onClickName(studentId)}>
                    <p className="ellipsisWrap noPadMar">{name}</p>
                </NameWrapper>
            );
        });
    }

    /**
     * Renders the header for a column.
     *
     * Precondition: summaries is not empty.
     * @param {Array<IndivGradeSummary>} summaries
     * @param {string} exId
     */
    renderScores(summaries, exId) {
        return summaries.map((indivSummary) => {
            const studentId = indivSummary.getStudentId();
            const submission = indivSummary.getSubmissionById(exId);
            return (
                <ScoreItemWrapper key={'SCORECOL_' + exId + '_STUDENT_' + submission.getStudentId()}>
                    <ScorePill
                        status={submission.getStatus()}
                        score={submission.getScore()}
                        total={submission.getLength()}
                        clickable={true}
                        onClick={() => this.props.onClickScore(studentId, exId)}
                    />
                </ScoreItemWrapper>
            );
        });
    }

    /**
     * Renders the header for a column.
     *
     * Precondition: summaries is not empty.
     * @param {Array<IndivGradeSummary>} summaries
     * @param {string} exId
     */
    renderHeader(summaries, exId) {
        const arbIndiv = summaries[0];
        if (arbIndiv.isEmpty()) return null;
        const submission = arbIndiv.getSubmissionById(exId);
        const songObj = submission.getSongData();
        const newTitle = songObj.subtopic.name;
        const origTitle = songObj.origTitle;
        const featureName = submission.getFeatureName();
        const setName = submission.getName();
        const mode = submission.getMode();

        return (
            <div>
                <NewTitle className="ellipsisWrap">{newTitle}</NewTitle>
                <OrigTitle className="ellipsisWrap">{origTitle}</OrigTitle>
                <p className="ellipsisWrap" style={{ fontSize: '14px', margin: '0' }}>
                    <span className="bold">{featureName}: </span>
                    <span className="secondary">{setName}</span>
                </p>
                <p className="ellipsisWrap" style={{ fontSize: '14px' }}>
                    <span className="bold">Mode: </span>
                    <span className="secondary">{capitalizeFLetter(mode)}</span>
                </p>
            </div>
        );
    }

    render() {
        const grades = this.props.grades;
        if (grades.isEmpty()) return null;
        const summaries = grades.getSortedSummaries(); // sorted by name, asc
        const exerciseIds = grades.getExerciseIds();
        if (exerciseIds.length === 0) return null;
        return (
            <MainWrapper>
                <NameCol>
                    <ScoreHeader />
                    {this.renderNames(summaries)}
                </NameCol>
                {exerciseIds.map((exId, index) => {
                    const isLast = index === exerciseIds.length - 1;
                    return (
                        <ScoreCol key={'SCORECOL_' + exId} isLast={isLast}>
                            <ScoreHeader>{this.renderHeader(summaries, exId)}</ScoreHeader>
                            {this.renderScores(summaries, exId)}
                        </ScoreCol>
                    );
                })}
            </MainWrapper>
        );
    }
}

AssignmentSummary.propTypes = {
    /**
     * The main representation of the grades.
     */
    grades: PropTypes.instanceOf(GradesSummary).isRequired,
    /**
     * The function fired when a score pill is clicked. Takes two parameters, the
     * `studentId`, and the `exerciseId`.
     */
    onClickScore: PropTypes.func.isRequired,
    /**
     * The function fired when a name is clicked. Takes only one parameter, the
     * `studentId`.
     */
    onClickName: PropTypes.func.isRequired,
};

export default AssignmentSummary;
