import React from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { withFirebase } from '../../Firebase';

const MainWrapper = styled.div`
    width: fit-content;
    height: 500px;
    padding: 0px 40px;
    display: flex;
    position: relative;
    border-radius: 10px;
    background: #000;
    box-shadow: 0px 0px 20px 0px #3d3d3d;
    flex-direction: column;
    justify-content: center;
`;

const ProvierWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: max-content;
    align-content: space-around;
`;

const CongratsText = styled.div`
    font-size: 30px;
    font-family: Nunito;
    align-self: center;
    text-align: center;
    width: max-content;
`;

const Icon = styled.img`
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '10px')};
`;

const SignInButton = styled.button`
    height: 80px;
    width: 600px;
    align-self: center;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 16px 0px;
    border-radius: 50px;
    background: ${(props) => tinycolor(props.bg).toString()};
    box-shadow: 0px 0px 20px 0px #3d3d3d;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background: ${(props) => tinycolor(props.bg).darken(10).toString()};
    }
`;

class LoginBox extends React.Component {
    handleSignIn = (provider) => {
        this.props.firebase.analytics.logEvent('login');
        switch (provider) {
            case 'google':
                this.props.signInGoogle();
                break;
            case 'microsoft':
                this.props.signInMicrosoft();
                break;
            default:
                throw Error('must be google or microsoft');
        }
    };

    render() {
        return (
            <MainWrapper>
                <ProvierWrapper>
                    <CongratsText>
                        <p style={{ marginBottom: '6px', fontWeight: 'bold' }}>{this.props.title}</p>
                        <p style={{ fontSize: '22px' }}>{this.props.caption}</p>
                    </CongratsText>
                    <SignInButton bg="B60114" onClick={() => this.handleSignIn('google')}>
                        Continue with Google
                        <Icon src="/images/icons/utility/google.svg" marginLeft="43.5px" />
                    </SignInButton>
                    <SignInButton bg="007BB6" onClick={() => this.handleSignIn('microsoft')}>
                        Continue with Microsoft
                        <Icon src="/images/icons/utility/microsoft.svg" />
                    </SignInButton>
                </ProvierWrapper>
            </MainWrapper>
        );
    }
}

export default withFirebase(LoginBox);
