import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledSpan = styled.span`
    font-size: 14px;
    color: var(--rsSecondary);
`;

const Pill = styled.button`
    background: none;
    outline: none;
    box-shadow: none;
    border: 2px solid var(--rsBlue);
    height: 26px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    text-align: center;
    color: var(--rsBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.clickable &&
        `
      cursor: pointer;
      &:hover {
        border: 2px solid rgba(var(--rsBlueRGB), 0.8);
        color: rgba(var(--rsBlueRGB), 0.8);
      }`}
`;

/**
 * Displays the result of an exercise or assignment.
 */
class ScorePill extends Component {
    render() {
        if (this.props.status === 'not started') {
            return <StyledSpan>Not Started</StyledSpan>;
        }
        if (this.props.status === 'in progress') {
            return <StyledSpan>In Progress</StyledSpan>;
        }
        return (
            <Pill onClick={this.props.onClick} clickable={this.props.clickable} disabled={!this.props.clickable}>
                <span>
                    {this.props.score}/{this.props.total}
                </span>
            </Pill>
        );
    }
}

ScorePill.propTypes = {
    /**
     * The status of the exercise.
     */
    status: PropTypes.oneOf(['not started', 'in progress', 'complete']),
    /**
     * The achieved score.
     */
    score: PropTypes.number,
    /**
     * The maximum possible score.
     */
    total: PropTypes.number,
    /**
     * The function fired when the pill is clicked.
     */
    onClick: PropTypes.func,
    /**
     * Can the pill be clicked?
     */
    clickable: PropTypes.bool.isRequired,
};

export default ScorePill;
