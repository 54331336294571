import React, { Component } from 'react';
import styled from 'styled-components';

import RsModal from '../RsModal';
import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';
import RsRadioButton from '../../Forms/RsRadioButton';

import PropTypes from 'prop-types';
import * as ROUTES from '../../../../constants/routes';
import { withRouter } from 'react-router-dom';

const Context = styled.p`
    font-size: 14px;
    margin-bottom: 10px;
`;

const ChoiceWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Desc = styled.span`
    margin-left: 10px;
`;

/**
 * The modal component for creating a new feature Set.
 *
 */
export class CreateAnySetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            featureName: 'Breakdown',
            disabled: true,
        };
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleTitleChange(e) {
        this.setState({ title: e.target.value });
        if (!e.target.value || e.target.value === '' || e.target.value === ' ') {
            this.setState({ disabled: true });
        } else if (this.state.disabled) {
            this.setState({ disabled: false });
        }
    }

    submit() {
        if (!this.state.featureName) return;
        if (this.state.featureName === 'Breakdown') {
            this.props.history.push(ROUTES.GENIUS + '?songId=' + this.props.songId + '&setId=null&name=' + this.state.title);
        } else if (this.state.featureName === 'Blanks') {
            this.props.history.push(ROUTES.BLANKS + '?songId=' + this.props.songId + '&setId=null&name=' + this.state.title);
        } else {
            this.props.history.push(ROUTES.REVIEW + '?songId=' + this.props.songId + '&setId=null&name=' + this.state.title);
        }
        this.props.toggle();
    }

    render() {
        return (
            <RsModal show={this.props.show} title={'Create a new set'} toggle={this.props.toggle} submit={this.submit}>
                <RsModal.Body>
                    <Context className="secondary ellipsisWrap">
                        {this.props.topicName} {' > '} {this.props.songName}
                    </Context>

                    <p style={{ marginBottom: 0 }} className="bold">
                        Choose a feature:
                    </p>
                    <ChoiceWrapper>
                        <RsRadioButton selected={this.state.featureName === 'Breakdown'} onSelect={() => this.setState({ featureName: 'Breakdown' })} />
                        <Desc className="secondary">Breakdown</Desc>
                    </ChoiceWrapper>
                    <ChoiceWrapper>
                        <RsRadioButton selected={this.state.featureName === 'Blanks'} onSelect={() => this.setState({ featureName: 'Blanks' })} />
                        <Desc className="secondary">Blanks</Desc>
                    </ChoiceWrapper>
                    <ChoiceWrapper>
                        <RsRadioButton selected={this.state.featureName === 'Review'} onSelect={() => this.setState({ featureName: 'Review' })} />
                        <Desc className="secondary">Review</Desc>
                    </ChoiceWrapper>
                    <p className="secondary" style={{ marginBottom: 0, marginTop: '20px' }}>
                        Title
                    </p>
                    <RsInput value={this.state.title} onChange={this.handleTitleChange} />
                </RsModal.Body>
                <RsModal.Footer>
                    <RsButton onClick={this.submit} disabled={this.state.disabled}>
                        Create
                    </RsButton>
                </RsModal.Footer>
            </RsModal>
        );
    }
}

CreateAnySetModal.propTypes = {
    /**
     * The topic name of the associated song.
     */
    topicName: PropTypes.string.isRequired,
    /**
     * The rapStudy song name of the associated song.
     */
    songName: PropTypes.string.isRequired,
    /**
     * The Firestore identifier of the associated song.
     */
    songId: PropTypes.string.isRequired,
    /**
     * The function to toggle the showing of the modal.
     */
    toggle: PropTypes.func.isRequired,
    /**
     * Is the modal showing?
     */
    show: PropTypes.bool.isRequired,
};

export default withRouter(CreateAnySetModal);
