import TopicDict from '../../Classes/TopicDict.js';
import UnitDict from '../../Classes/UnitDict.js';
import SubtopicDict from '../../Classes/SubtopicDict.js';

/**
 * Assumes that all ids within the collection group "units" are unique.
 * Returns the data fields of an indicated unit.
 * @param {string} unitID - id of the unit
 */
export async function getUnitData(unitID) {
    var query = this.db.collectionGroup('units').where('id', '==', unitID);
    var querySnap = await query.withConverter(UnitDict.unitConverter).get();
    if (querySnap.empty) throw this.NotFoundError;
    const doc = querySnap.docs[0];
    const unitDict = doc.data();

    // TODO: Just use firebaseImg field when db is updated.
    unitDict.setSrc(await this.getUnitImageURL(unitDict.imgName));
    return unitDict;
}

/**
 * Returns the list of topics within specified unit
 * @param {DocumentReference} unitRef
 */
export async function getTopicsInUnit(unitRef) {
    if (unitRef === null) return;
    const topicQuery = await unitRef.collection('topics').withConverter(TopicDict.topicConverter).get();

    if (topicQuery.empty) return [];
    return await Promise.all(
        topicQuery.docs.map(async (snap) => {
            const topicDict = snap.data();

            // TODO: Just use firebaseImg field when db is updated.
            topicDict.setSrc(await this.getTopicImageURL(topicDict.imgName));
            return topicDict;
        }),
    );
}

/**
 * Returns the list of subtopics within a specified topic
 */
export async function getSubtopicsInTopic(topicRef) {
    if (topicRef == null) return;
    const subtopicQuery = await topicRef.collection('subtopics').orderBy('name').withConverter(SubtopicDict.subtopicConverter).get();
    return subtopicQuery.docs.map((snap) => {
        const subtopicDict = snap.data();
        // TODO: put in convertor
        subtopicDict.ref = snap.ref;
        subtopicDict.id = snap.ref.id;
        return subtopicDict;
    });
}

/**
 * Retrieves the image corresponding to the imgName of unit
 * @param {string} imgName
 */
export async function getUnitImageURL(id) {
    try {
        var url = await this.storage.ref('images/units').child(id).getDownloadURL();
        return url;
    } catch (err) {
        return null;
    }
}

export async function getTopicImageURL(id) {
    if (typeof id == 'undefined') {
        return undefined;
    }
    var url;
    try {
        url = await this.storage.ref('images/topics').child(id).getDownloadURL();
    } catch (err) {
        url = null;
    }

    return url;
}
