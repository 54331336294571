import * as ROUTES from '../../../../../constants/routes';

import { Col, Row } from 'react-bootstrap';
import React, { Component } from 'react';

import DropdownMenu from '../../../ButtonsOrLinks/DropdownMenu';
import RsCheckbox from '../../../Forms/RsCheckbox';
import RsLink from '../../../ButtonsOrLinks/RsLink';
import Trash from '../../../Icons/Trash';
import styled from 'styled-components';

const ColStyle = {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0',
    paddingRight: '20px',
};

const RowStyle = {
    textAlign: 'left',
    margin: '0',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
};

const Subtitle = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: var(--rsSecondary);
`;

/**
 * Props:
 *
 * song
 * featureName
 * showDropdown
 * showRemove
 * isTeacher
 * shareModalToggle
 * deleteModalToggle
 * remove
 */
class FeatureColumn extends Component {
    render() {
        const routes = {
            Review: ROUTES.REVIEW,
            Breakdown: ROUTES.GENIUS,
            Blanks: ROUTES.BLANKS,
        };
        const setFields = {
            Review: 'reviewSets',
            Breakdown: 'breakdownSets',
            Blanks: 'blanksSets',
        };
        const route = routes[this.props.featureName];
        const setFieldName = setFields[this.props.featureName];

        return (
            <Col xs="4" style={ColStyle}>
                <div
                    style={{
                        height: '100%',
                    }}
                >
                    <Subtitle>{this.props.featureName}</Subtitle>
                    {this.props.song[setFieldName].map((set, index) => {
                        if (this.props.hideEmpty && set.getNumItems() === 0) return null;
                        return (
                            <Row key={this.props.featureName + '_row_' + index} style={RowStyle}>
                                <RsLink to={route + '?songId=' + this.props.song.songId + '&setId=' + set.getSetId()} bold={true}>
                                    {set.getName()}
                                </RsLink>
                                <div
                                    style={{
                                        marginLeft: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {this.props.showDropdown && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginLeft: '10px',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <DropdownMenu
                                                iconSize="small"
                                                isTeacher={this.props.isTeacher}
                                                direction="right"
                                                menuItems={[
                                                    {
                                                        toggleMenuItemAction: () => {
                                                            this.props.deleteModalToggle(this.props.featureName, this.props.song.songId, set.getSetId());
                                                        },
                                                        requiredRole: 'Both',
                                                        desc: 'Delete set',
                                                    },
                                                    {
                                                        toggleMenuItemAction: () => {
                                                            this.props.shareModalToggle(this.props.featureName, set.getSetId());
                                                        },
                                                        requiredRole: 'Teacher',
                                                        desc: 'Add to class',
                                                    },
                                                ]}
                                            />
                                        </div>
                                    )}
                                    {this.props.showRemove && (
                                        <Trash size="18px" onClick={() => this.props.remove(this.props.featureName, this.props.song.songId, set.getSetId())} />
                                    )}
                                    {this.props.showCheckbox && (
                                        <div style={{ display: 'flex', marginLeft: '10px' }}>
                                            <RsCheckbox
                                                selected={this.props.song.isSelected(set.getSetId())}
                                                onSelect={() => this.props.onSelectCheckbox(set.getSetId())}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Row>
                        );
                    })}
                </div>
            </Col>
        );
    }
}

export default FeatureColumn;
