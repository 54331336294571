export async function doSignUpContact(email) {
    await this.db.collection('signUps').doc(email).set({
        email: email,
        created: this.firebase.firestore.FieldValue.serverTimestamp(),
    });
    return;
}

export async function createInquiry(name, email, msg) {
    if (name.length === 0 || email.length === 0 || msg.length === 0) {
        throw Error('Precondition Invalidated: at least one of the inputs is empty');
    }
    await this.db.collection('inquiries').doc().set({
        email: email,
        name: name,
        msg: msg,
        created: this.firebase.firestore.FieldValue.serverTimestamp(),
    });
    return;
}

/**
 *
 * @param {string} firstName
 * @param {string} lastName
 * @param {Set} grades
 * @param {Set} subjects
 */
export async function completeOnboarding(firstName, lastName, grades, subjects) {
    const userDoc = this.db.collection('users').doc(this.auth.currentUser.uid);
    const profileDoc = this.db.collection('userProfiles').doc(this.auth.currentUser.uid);
    var gradesResult = Array.from(grades);
    var subjectsResult = Array.from(subjects);
    var number = [];
    var gradesAdmin = [];
    gradesResult.forEach((a) => {
        if (typeof a == 'number') number.push(a);
        else gradesAdmin.push(a);
    });
    gradesResult = gradesAdmin.concat(number.sort());
    const userDocPr = userDoc.update({
        first_name: firstName,
        last_name: lastName,
        grade: gradesResult,
        subject: subjectsResult,
        firstVisit: false,
    });
    const profilePr = profileDoc.update({
        first_name: firstName,
        last_name: lastName,
    });
    await Promise.all([userDocPr, profilePr]);
}

export async function setStudentSurvey(list) {
    if (!list) return;
    var data = list;
    data.timestamp = this.firebase.firestore.FieldValue.serverTimestamp();
    await this.db.collection('studentsSurvey').doc().set(data);
}

export async function setEducatorSurvey(list) {
    if (!list) return;
    var data = list;
    data.timestamp = this.firebase.firestore.FieldValue.serverTimestamp();
    await this.db.collection('educatorsSurvey').doc().set(data);
}

export async function completeFirstBreakdown() {
    const userDoc = await this.db.collection('users').doc(this.auth.currentUser.uid).get();
    if (!userDoc.exists) throw this.NotFoundError;
    userDoc.ref.update({ firstBreakdown: false });
}

export async function completeFirstBlanks() {
    const userDoc = await this.db.collection('users').doc(this.auth.currentUser.uid).get();
    if (!userDoc.exists) throw this.NotFoundError;
    userDoc.ref.update({ firstBlanks: false });
}

export async function completeFirstReview() {
    const userDoc = await this.db.collection('users').doc(this.auth.currentUser.uid).get();
    if (!userDoc.exists) throw this.NotFoundError;
    userDoc.ref.update({ firstReview: false });
}

export async function completeFirstKaraoke() {
    const userDoc = await this.db.collection('users').doc(this.auth.currentUser.uid).get();
    if (!userDoc.exists) throw this.NotFoundError;
    userDoc.ref.update({ firstKaraoke: false });
}

export async function setFeedback(content, email, first, last) {
    if (!email) return;
    var firstName = first;
    var lastName = last;
    var desc = content;
    if (!first) firstName = '';
    if (!last) lastName = '';
    if (!content) desc = '';
    await this.db.collection('feedback').doc().set({
        content: desc,
        date: this.firebase.firestore.Timestamp.now(),
        email: email,
        first_name: firstName,
        last_name: lastName,
        userId: this.auth.currentUser.uid,
    });
    return;
}

export async function getPilotAgreement() {
    const pilotUrl = await this.storage.ref('documents').child('rapStudy_F2020_pilot_agreement.pdf').getDownloadURL();
    return pilotUrl;
}

export async function getTutorialGifs() {
    const tutorial_names = [
        'clickHelp.gif',
        'breakdown1.gif',
        'breakdown2.gif',
        'breakdown3.gif',
        'breakdown4.gif',
        'blanks1.gif',
        'blanks2.gif',
        'blanks3.gif',
        'review1.gif',
        'review2.gif',
        'review3.gif',
        'reviewReorder.gif',
        'review4.gif',
        'karaoke1.gif',
        'karaoke2.gif',
        'karaoke3.gif',
        'karaoke4.gif',
    ];

    var result = await Promise.all(tutorial_names.map((str) => this.storage.ref('images/tutorial_gifs').child(str).getDownloadURL()));

    return result;
}

export async function generatePin(type) {
    const genPin = this.functions.httpsCallable('misc-generatePin');
    const pin = await genPin({ type: type });
    return pin.data;
}
