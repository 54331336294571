import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MainWrapper = styled.div`
    height: 140px;
    width: 140px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => (props.src ? '' : props.color ? props.color : 'var(--rsBlue)')};
    &:hover {
        ${(props) => {
            return (
                props.isClickable &&
                `opacity: 0.8;
        transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -webkit-transition: opacity 0.2s ease-in-out;`
            );
        }}
    }
`;

const Img = styled.img`
    height: 140px;
    width: 140px;
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    box-shadow: 5px 5px 12px #222222;
`;

const Icon = styled.img`
    height: 100px;
    width: auto;
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

/**
 * The standard square image. Either a source is used, or represents either a
 * playlist or a class, with a modifiable background color and link.
 */
class DefaultImg extends Component {
    renderInner() {
        if (this.props.src) {
            return <Img src={this.props.src} disabled={this.props.disabled} style={{ borderRadius: this.props.borderRadius ?? 5 }} />;
        } else {
            return (
                <Icon
                    src={
                        !this.props.type || this.props.type === 'playlist' ? '/images/icons/defaultImage/playlist.png' : '/images/icons/defaultImage/class.png'
                    }
                    disabled={this.props.disabled}
                />
            );
        }
    }

    render() {
        return this.props.to ? (
            <Link to={this.props.state ? { pathname: this.props.to, state: this.props.state } : this.props.to}>
                <MainWrapper color={this.props.color} src={this.props.src} isClickable={this.props.isClickable} disabled={this.props.disabled}>
                    {this.renderInner()}
                </MainWrapper>
            </Link>
        ) : (
            <MainWrapper color={this.props.color} src={this.props.src} isClickable={this.props.isClickable} disabled={this.props.disabled}>
                {this.renderInner()}
            </MainWrapper>
        );
    }
}

DefaultImg.propTypes = {
    /**
     * The background image to be used. If defined, this overrides the type
     * and color properties.
     */
    src: PropTypes.string,
    /**
     * The background color of the image. Defaults to rapStudy blue.
     */
    color: PropTypes.string,
    /**
     * The default image type. Supports `playlist` and `class`. If `src` is
     * defined, this property is irrelevant. Defaults to `playlist` with the
     * rapStudy note icon.
     */
    type: PropTypes.string,
    /**
     * The route that clicking the image links to.
     */
    to: PropTypes.string,
    /**
     * Is the image a clickable element? If true, applies hover styling.
     * Defaults to false.
     */
    isClickable: PropTypes.bool,
    /**
     * Does the image take on disabled styling?
     */
    disabled: PropTypes.bool,
};

export default DefaultImg;
