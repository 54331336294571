import React, { Component } from 'react';

import PropTypes from 'prop-types';

class Skip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const hover = typeof this.props.hover !== 'undefined' ? this.props.hover : this.state.hover;

        const color = this.props.disabled ? 'var(--rsDisabled)' : hover ? 'var(--rsPrimary)' : 'var(--rsSecondary)';
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    margin: '0 20px',
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg
                    width="30"
                    height="18"
                    viewBox="0 0 15 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    transform={this.props.direction === 'left' ? 'rotate(180)' : ''}
                >
                    <path
                        d="M13.8496 4.81345C14.183 4.62087 14.1828 4.13954 13.8492 3.94729L7.74966 0.432043C7.41633 0.239938 7 0.480522 7 0.86525V7.90376C7 8.28871 7.41676 8.52927 7.75009 8.33672L13.8496 4.81345Z"
                        fill={color}
                    />
                    <path
                        d="M6.84084 4.81331C7.17391 4.62066 7.17367 4.13975 6.84041 3.94743L0.749911 0.432755C0.416578 0.240397 0 0.480965 0 0.865819V7.90319C0 8.28827 0.417005 8.52881 0.750338 8.33601L6.84084 4.81331Z"
                        fill={color}
                    />
                </svg>
            </div>
        );
    }
}

Skip.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * What direction is the caret facing?
     */
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default Skip;
