// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import { withAlert } from 'react-alert';

import * as ROUTES from '../../../../constants/routes';

import Loading from '../../../UI-Library/Misc/Loading';
import FeatureStart from '../../../UI-Library/Features/FeatureStart';

import DeleteConfirmModal from '../../../UI-Library/Modals/DeleteConfirmModal';
import DiscardConfirmModal from '../../../UI-Library/Modals/DiscardConfirmModal';
import CreateSetModal from '../../../UI-Library/Modals/CreateSetModal';

import GeniusEditPopUp from './GeniusEditPopUp';
import GeniusPopUp from './GeniusPopUp';

import SelectionHeader from '../../../UI-Library/Features/SelectionHeader';

import { selectSet, createSet, deleteSet, findSetInArray } from '../setFunctions.js';

import Selection from '../../../../Classes/Selection';

import Lrc from '../../../../Classes/Lrc.js';
import AnnotationDict from '../../../../Classes/AnnotationDict.js';

import NoSet from '../NoSet';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';

const MainWrapper = styled.div`
    margin-top: 30px;
    padding-left: 5%;
    padding-right: 5%;

    ${({ fullscreen }) =>
        fullscreen &&
        `
    height: calc(100% - 10vh - 80px);
    margin-top: 5%;
    margin-bottom: 5%;
  `}
`;

const AppWrapper = styled.div`
    margin-top: 30px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    height: auto;
    &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }

    ${({ fullscreen }) =>
        fullscreen &&
        `
    height: calc(100% - 10vh - 80px);
    margin-top: auto;
    margin-bottom: auto;
    height: 75vh;
  `}
`;

const Span = styled.span`
    font-size: 2.5vh;
    font-weight: bold;
`;

const SpanKey = styled.span`
    font-size: 2.5vh;
    font-weight: bold;
    background-color: grey;
`;

const SpanKeyActive = styled.span`
    font-size: 2.5vh;
    font-weight: bold;
    background-color: var(--rsMajor);
    color: black;
`;

const Lyrics = styled.div`
    width: 40%;
    float: left;

    ${({ fullscreen }) =>
        fullscreen &&
        `
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
   &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  `}
`;

const VideoWrapper = styled.div`
    display: flex;
    margin-top: 30px;
    width: 100%;
    justify-content: center;
    margin-bottom: 30px;
`;

const VideoFrame = styled.div`
    width: 648.16px;
    height: 392.99px;
    max-width: 90%;
    background: grey;
    @media screen and (max-width: 1024px) {
        width: 600px;
        height: 320px;
    }
    @media screen and (max-width: 576px) {
        width: 400px;
        height: 250px;
    }
    @media screen and (max-width: 328px) {
        width: 280px;
        height: 180px;
    }
`;

// TODO Genius: Change all names and variables to Breakdown.

export class GeniusBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lrc: null,
            ready: false,
            annotationDict: new AnnotationDict(),
            numInLrc: null,
            currId: null,
            position: false,
            error: null,
            setId: null,
            userSets: null,
            setRefs: new Set(),
            numberOfRefs: 0,
            countClicked: 0,
            noSet: false,
            showTutorial: this.props.firstBreakdown,
            isAuthor: false,
            author: null,
            authorName: null,
            started: false,

            // Edit state properties
            edit: false,
            newHighlight: false,
            selection: null,
            start: null,
            end: null,
            showDiscard: false,
            showDelete: false,
            showImageChooseModal: false,
            showCreate: false,
        };

        this.prevRef = null;
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.createAnnotation = this.createAnnotation.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.selectSet = selectSet(ROUTES.GENIUS).bind(this);
        this.createSet = createSet(ROUTES.GENIUS, this.props.firebase.createCustomAnnotations.bind(this.props.firebase)).bind(this);
        this.deleteSet = deleteSet(ROUTES.GENIUS, this.props.firebase.deleteCustomAnnotations.bind(this.props.firebase)).bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.firebaseImage = this.firebaseImage.bind(this);
        this.deleteAnnotation = this.deleteAnnotation.bind(this);
        this.deleteModalToggle = this.deleteModalToggle.bind(this);
        this.discardModalToggle = this.discardModalToggle.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.toggleChooseImageModal = this.toggleChooseImageModal.bind(this);
        this.customContentModalToggle = this.customContentModalToggle.bind(this);
        this.toggleShowTutorial = this.toggleShowTutorial.bind(this);
        this.start = this.start.bind(this);
        this.props.firebase.analytics.logEvent('page_view');
    }

    async getResources(setId) {
        try {
            this.setState({ ready: false });

            let annotationDict = await this.props.firebase.getAnnotationDict(setId);
            const sets = await this.props.firebase.getAnnotationSetsForSong(this.props.id);
            this.setState({ userSets: sets });

            if (sets.length === 0 && annotationDict === null) {
                this.props.history.replace(ROUTES.GENIUS + '?songId=' + this.props.id);
                // show the create button
                this.setState({
                    noSet: true,
                    ready: true,
                });
                return;
            } else if (setId === 'null') {
                // get first set from keywordSet
                setId = sets[0].setId;
                this.props.history.replace(ROUTES.GENIUS + '?songId=' + this.props.id + '&setId=' + setId);
                annotationDict = await this.props.firebase.getAnnotationDict(setId);
            }

            let indToRefKeys = null;
            const refsExist = annotationDict != null && annotationDict.getNumberOfRefs() > 0;
            if (refsExist) {
                indToRefKeys = Object.keys(annotationDict.getIndToRef()).sort((a, b) => a - b);
            }

            this.props.reset();

            this.setState(
                {
                    lrc: new Lrc(this.props.json, null),
                    annotationDict: annotationDict,
                    numberOfRefs: annotationDict.getNumberOfRefs(),
                    currId: refsExist ? annotationDict.getId(indToRefKeys[0]) : null,
                    newHighlight: false,
                    selection: null,
                    start: null,
                    end: null,
                    edit: false,
                    setRefs: new Set(),
                    setId: setId,
                    author: annotationDict.getAuthorId(),
                    authorName: annotationDict.getAuthorName(),
                    isAuthor: annotationDict.getAuthorId() === this.props.firebase.auth.currentUser.uid,
                },
                () => {
                    this.props.updateCurrSetId(setId, 'Breakdown');
                    if (this.state.currId !== null) {
                        const copySetRef = this.state.setRefs;
                        copySetRef.add(this.state.currId);
                        this.setState({ setRefs: copySetRef });
                    }
                    this.setState({ ready: true });
                },
            );
        } catch (err) {
            this.setState({ error: err });
        }
    }

    /**
     * State after all promises are resolved:
     * lrc: Lrc,
     * ready: true,
     */
    async componentDidMount() {
        try {
            if (this.props.guest) return;
            this.props.updateCurrSetId(null, 'Breakdown');

            // Selection handling listener
            document.addEventListener('mouseup', this.handleMouseUp);
            // Scroll handler
            window.addEventListener('scroll', this.handleScroll);

            // Getters and state setting
            const urlParams = new URLSearchParams(this.props.location.search);
            const name = String(urlParams.get('name'));
            if (!(name === '' || name === 'null')) {
                await this.createSet(name, '');
                return;
            }
            let setId = urlParams.get('setId');
            setId = String(setId);
            this.setState({ setId: setId });
            await this.getResources(setId);
        } catch (err) {
            this.setState({ error: err });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('mouseup', this.handleMouseUp);
        if (this.state.noSet) return;
        this.logAnalytics();
    }

    logAnalytics() {
        // [Analytics] Log blanks activity
        const length = this.state.annotationDict.getNumberOfRefs();
        const completed = this.state.countClicked;
        this.props.firebase.analytics.logEvent('breakdown', {
            songId: this.props.id,
            length: length,
            completed: completed,
        });
    }

    handleScroll(event) {
        if (window.scrollY >= 300) {
            this.setState({ position: true });
        } else {
            this.setState({ position: false });
        }
    }

    // ? Revisit for refactoring/abstracting to own component
    handleMouseUp(event) {
        if (!this.state.edit) return;

        const selection = document.getSelection();
        const startEnd = Selection.getStartEndWord(selection);
        const indToRef = this.state.annotationDict.getIndToRef();
        if (startEnd === null || startEnd[0] in indToRef || startEnd[1] in indToRef) {
            this.setState({
                newHighlight: false,
                selection: selection,
                start: null,
                end: null,
            });
        } else {
            const start = startEnd[0];
            const end = startEnd[1];
            let indices = Object.keys(indToRef);

            // n operations
            indices = indices.map(Number);
            // ? unknown time complexity - need to check js implementation
            indices.push(start);
            indices.push(end);
            indices.sort(function (a, b) {
                return a - b;
            });
            // 2n operations in indexing search
            if (indices.indexOf(start) !== indices.indexOf(end) - 1 && start !== end) {
                this.setState({
                    // highlight is bad
                    newHighlight: false,
                    selection: selection,
                    start: null,
                    end: null,
                    currId: null,
                });
            } else {
                this.setState({
                    // highlight is good
                    newHighlight: true,
                    selection: selection,
                    start: startEnd[0],
                    end: startEnd[1],
                    currId: null,
                });
            }
        }
    }

    /**
     * The onclick function for new annotation button that creates a new annotation locally.
     */
    createAnnotation() {
        // show the annotation editor box

        //sets state for indToRef, annotationLookup
        if (!this.state.selection || !this.state.start || !this.state.end) {
            this.setState({ newHighlight: false });
            return;
        }
        const start = this.state.start;
        const end = this.state.end;

        const randomRef = this.props.firebase.db.collection('annotationSets').doc(this.state.setId).collection('annotations').doc();
        this.state.annotationDict.addAnnotation(start, end, randomRef);

        this.setState(
            {
                annotationDict: this.state.annotationDict,
                currId: randomRef.id,
                newHighlight: false,
            },
            () => {
                this.changeNum(randomRef, null);
            },
        );
    }

    /**
     * Removes the annotation with the given id from the local state.
     * @param {string} id
     */
    deleteAnnotation(id) {
        if (!(id in this.state.annotationDict.getAnnotationLookup())) return;
        this.state.annotationDict.deleteAnnotation(id);
        this.setState({
            annotationDict: this.state.annotationDict,
        });
    }

    async removeImage(id) {
        try {
            this.state.annotationDict.removeImage(id);
            this.setState({ annotationDict: this.state.annotationDict });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    handleDescChange(id, e) {
        this.state.annotationDict.setDescription(id, e);
        this.setState({ annotationDict: this.state.annotationDict });
    }

    handleTitleChange(e) {
        const res = this.state.annotationDict;
        res.setName(e.target.value);
        this.setState({ annotationDict: res });
    }

    deleteModalToggle() {
        this.setState({ showDelete: !this.state.showDelete });
    }

    discardModalToggle() {
        this.setState({ showDiscard: !this.state.showDiscard });
    }

    toggleChooseImageModal(index) {
        this.setState({
            showChooseImageModal: !this.state.showChooseImageModal,
        });
    }

    customContentModalToggle() {
        this.setState({ showCreate: !this.state.showCreate });
    }

    /**
     * Toggles between edit mode and view mode.
     */
    async toggleEdit() {
        // if edit mode, change to view mode, and save the keywords to firestore by calling updateCustomAnnotations.
        // if view mode, change to edit mode UNLESS the current set is the default rapstudy set.
        if (!this.state.edit && this.state.setId !== 'null') {
            this.setState({
                edit: true,
                ready: true,
                currId: false,
                numberOfRefs: this.state.annotationDict.getNumberOfRefs(),
                setRefs: new Set(),
            });
        } else if (this.state.edit) {
            this.setState({ ready: false });
            await this.props.firebase.updateCustomAnnotations(this.props.id, this.state.setId, this.state.annotationDict);
            const sets = this.state.userSets;
            const toChangeName = findSetInArray(sets, 'setId', this.state.setId);
            toChangeName.name = this.state.annotationDict.getName();
            // upload image to storage here, then add the field firebaseImg to the questions doc
            this.setState({
                edit: false,
                ready: true,
                currId: false,
                numberOfRefs: this.state.annotationDict.getNumberOfRefs(),
                setRefs: new Set(),
                userSets: sets,
            });
        }
    }

    async changeNum(docRef, index) {
        // * if this.state.setRefs is full, it doesnt matter what the input docRef is - even if its null. We know ex. is complete.
        if (window.getSelection && !this.state.edit) {
            if (window.getSelection().empty) {
                // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
                // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {
            // IE?
            document.selection.empty();
        }

        if (!docRef || docRef === null || !docRef.id) {
            this.setState({ currId: null });
            return;
        }

        if (index !== null) {
            // 'index' is 1-based, so have to subtract by 1
            this.props.setTime(this.state.lrc.getTimeWord(index, null));
        }
        this.setState({
            currId: docRef.id /*!this.state.currId ? docRef.id : null */,
            countClicked: this.state.countClicked + 1,
        });

        const copySetRef = this.state.setRefs;
        copySetRef.add(docRef.id);
        this.setState({ setRefs: copySetRef });
    }

    onHover(docRef) {
        const elements = document.getElementsByClassName(docRef.id);
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.backgroundColor = 'var(--rsMajor)';
            elements[i].style.color = 'black';
            elements[i].style.cursor = 'pointer';
        }
    }

    offHover(docRef) {
        const elements = document.getElementsByClassName(docRef.id);
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.backgroundColor = 'grey';
            elements[i].style.color = 'white';
        }
        return;
    }

    // hide IndToRef
    getContent() {
        const indToRef = this.state.annotationDict.getIndToRef();
        const result = [];
        if (this.state.lrc) {
            const lrc = this.state.lrc;

            for (let x = 0; x < lrc.blocks.length; x++) {
                const block = lrc.blocks[x];

                for (let y = 0; y < block.lines.length; y++) {
                    const line = block.lines[y];

                    for (let z = 0; z < line.words.length; z++) {
                        const wordObj = line.words[z];
                        // var id = this.state.annotationDict.getId(wordObj.numInLrc);

                        if (!(wordObj.numInLrc in indToRef)) {
                            result.push(
                                <Span
                                    key={'word_' + wordObj.numInLrc}
                                    id={'word_' + wordObj.numInLrc}
                                    className="bold"
                                    onClick={this.changeNum.bind(this, null, null)}
                                >
                                    {wordObj.word}
                                </Span>,
                            );
                        } else if (indToRef[wordObj.numInLrc].id === this.state.currId) {
                            result.push(
                                <SpanKeyActive
                                    className={'bold noselect ' + indToRef[wordObj.numInLrc].id}
                                    key={'word_' + wordObj.numInLrc}
                                    id={'word_' + wordObj.numInLrc}
                                >
                                    {wordObj.word}
                                </SpanKeyActive>,
                            );
                        } else if (indToRef[wordObj.numInLrc].id !== this.state.currId) {
                            result.push(
                                <SpanKey
                                    className={'bold noselect ' + indToRef[wordObj.numInLrc].id}
                                    key={'word_' + wordObj.numInLrc}
                                    id={'word_' + wordObj.numInLrc}
                                    onClick={this.changeNum.bind(this, indToRef[wordObj.numInLrc], wordObj.numInLrc)}
                                    onMouseOver={this.onHover.bind(this, indToRef[wordObj.numInLrc])}
                                    onMouseOut={this.offHover.bind(this, indToRef[wordObj.numInLrc])}
                                >
                                    {wordObj.word}
                                </SpanKey>,
                            );
                        }
                    }

                    result.push(<br key={'lineBreak' + y + x} />);
                }
                result.push(<br key={'blockBreak' + x} />);
            }
        }
        return result;
    }

    selectImage(index, imgLink, uid, name, imgObj) {
        this.state.annotationDict.setUnsplashImage(this.state.currId, imgLink, uid, name);
        this.props.firebase.downloadUnsplash(imgObj);
        this.setState({ annotationDict: this.state.annotationDict });
    }

    async firebaseImage(index, imgFile) {
        const type = imgFile.type.split('/')[1];
        const fileName = new Date().getTime().toString() + '.' + type;
        const url = await this.props.firebase.setCustomQuestionImg(imgFile, fileName);

        this.state.annotationDict.setFirebaseImage(this.state.currId, fileName, url);

        this.setState({ annotationDict: this.state.annotationDict });
    }

    toggleShowTutorial() {
        this.setState({ showTutorial: !this.state.showTutorial });
    }

    start() {
        this.setState({ started: true });
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        if (this.props.guest) {
            return (
                <VideoWrapper>
                    <VideoFrame>
                        <iframe
                            height="100%"
                            width="100%"
                            src={'https://www.youtube.com/embed/r0i1CVC8rPg'}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="video"
                        ></iframe>
                    </VideoFrame>
                </VideoWrapper>
            );
        }

        // check for null topic
        let topicName = null;
        if (this.props.topic) {
            topicName = this.props.topic.name;
        }
        if (!this.state.ready) return <Loading zoom={true} />;

        if (this.state.noSet) {
            return (
                <NoSet
                    show={this.state.showTutorial}
                    toggleShowTutorial={this.toggleShowTutorial}
                    firstTime={this.props.firstBreakdown}
                    complete={this.props.completeFirstBreakdown}
                    createSet={this.createSet}
                    topicName={topicName}
                    newTitle={this.props.newTitle}
                    featureName="Breakdown"
                />
            );
        }

        if (!this.state.started && !this.state.edit)
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px',
                    }}
                >
                    <FeatureStart
                        userSets={this.state.userSets}
                        selectSet={this.selectSet}
                        toggleEdit={this.toggleEdit}
                        customContentModalToggle={this.customContentModalToggle}
                        title={this.state.annotationDict.getName()}
                        isAuthor={this.state.isAuthor}
                        author={this.state.author}
                        authorName={this.state.authorName}
                        length={this.state.annotationDict.getNumberOfRefs()}
                        featureName={'Breakdown'}
                        start={this.start}
                    />
                    <CreateSetModal
                        show={this.state.showCreate}
                        create={this.createSet}
                        topicName={topicName}
                        songName={this.props.newTitle}
                        featureName={'Breakdown'}
                        toggle={this.customContentModalToggle}
                    />
                </div>
            );

        return (
            <MainWrapper fullscreen={this.props.fullscreen}>
                <div>
                    <SelectionHeader
                        newTitle={this.props.newTitle}
                        fullscreen={this.props.fullscreen}
                        edit={this.state.edit}
                        title={this.state.annotationDict.getName()}
                        topicName={topicName}
                        userSets={this.state.userSets}
                        setId={this.state.setId}
                        createSet={this.createSet}
                        handleTitleChange={this.handleTitleChange}
                        deleteModalToggle={this.deleteModalToggle}
                        discardModalToggle={this.discardModalToggle}
                        toggleEdit={this.toggleEdit}
                        clickFullScreen={this.props.clickFullScreen}
                        selectSet={this.selectSet}
                        featureName={'Breakdown'}
                        isAuthor={this.state.isAuthor}
                        author={this.state.author}
                        authorName={this.state.authorName}
                        customContentModalToggle={this.customContentModalToggle}
                    />

                    <CreateSetModal
                        show={this.state.showCreate}
                        create={this.createSet}
                        topicName={topicName}
                        songName={this.props.newTitle}
                        featureName={'Breakdown'}
                        toggle={this.customContentModalToggle}
                    />

                    <AppWrapper id="AppWrapper" fullscreen={this.props.fullscreen}>
                        <Lyrics fullscreen={this.props.fullscreen}>{this.getContent()}</Lyrics>
                        <div
                            className="noselect"
                            style={{
                                maxWidth: '35vw',
                                marginLeft: '50%',
                                width: '50%',
                                top: this.state.position ? 135 : 0,
                                position: this.state.position ? 'fixed' : 'sticky',
                                right: this.state.position ? '11.5%' : '15%',
                            }}
                        >
                            {this.state.edit ? (
                                <GeniusEditPopUp
                                    show={!this.state.currId ? false : true}
                                    id={this.state.currId}
                                    annotation={this.state.annotationDict.getAnnotation(this.state.currId, null)}
                                    handleDescChange={this.handleDescChange}
                                    selectImage={this.selectImage}
                                    firebaseImage={this.firebaseImage}
                                    deleteAnnotation={this.deleteAnnotation}
                                    toggleChooseImageModal={this.toggleChooseImageModal}
                                    showChooseImageModal={this.state.showChooseImageModal}
                                    removeImage={this.removeImage}
                                />
                            ) : (
                                <GeniusPopUp
                                    color={this.props.color}
                                    show={!this.state.currId ? false : true}
                                    id={this.state.currId}
                                    annotation={this.state.annotationDict.getAnnotation(this.state.currId, null)}
                                />
                            )}
                            {this.state.edit && this.state.newHighlight ? (
                                <RsButton className=" noselect" onClick={this.createAnnotation}>
                                    New annotation here
                                </RsButton>
                            ) : null}
                        </div>
                        {this.state.edit ? (
                            <div>
                                <DeleteConfirmModal
                                    title={'Delete Set?'}
                                    delete={() => {
                                        this.deleteSet(this.state.setId);
                                    }}
                                    show={this.state.showDelete}
                                    toggle={this.deleteModalToggle}
                                />
                                <DiscardConfirmModal
                                    discard={() => {
                                        this.selectSet(this.state.setId);
                                    }}
                                    show={this.state.showDiscard}
                                    toggle={this.discardModalToggle}
                                />
                            </div>
                        ) : null}
                    </AppWrapper>
                </div>
            </MainWrapper>
        );
    }
}

const Genius = withAlert()(withFirebase(GeniusBase));
export default Genius;
