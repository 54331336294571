import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import styled from 'styled-components';

const Span = styled.span`
    font-size: ${(props) => `${props.fontSize}px`};
    font-weight: bold;
`;

var ScrollElement = Scroll.ScrollElement;

class ScrollSpan extends Component {
    render() {
        return (
            <Span
                {...this.props}
                ref={(el) => {
                    this.props.parentBindings.domNode = el;
                }}
                fontSize={this.props.fontSize}
            >
                {this.props.children}
            </Span>
        );
    }
}

export default ScrollElement(ScrollSpan);
