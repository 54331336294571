import React, { Component } from 'react';

import PropTypes from 'prop-types';

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const hover = typeof this.props.hover !== 'undefined' ? this.props.hover : this.state.hover;

        const color = this.props.disabled ? 'var(--rsDisabled)' : hover ? 'var(--rsPrimary)' : 'var(--rsSecondary)';
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg
                    width={this.props.size ? this.props.size : '24px'}
                    height={this.props.size ? this.props.size : '24px'}
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 4C3.10457 4 4 3.10461 4 2C4 0.895386 3.10457 0 2 0C0.895432 0 0 0.895386 0 2C0 3.10461 0.895432 4 2 4ZM7 1C6.44772 1 6 1.44775 6 2C6 2.55225 6.44772 3 7 3H17C17.5523 3 18 2.55225 18 2C18 1.44775 17.5523 1 17 1H7ZM4 14C4 15.1046 3.10457 16 2 16C0.895432 16 0 15.1046 0 14C0 12.8954 0.895432 12 2 12C3.10457 12 4 12.8954 4 14ZM7 13C6.44772 13 6 13.4478 6 14C6 14.5522 6.44772 15 7 15H17C17.5523 15 18 14.5522 18 14C18 13.4478 17.5523 13 17 13H7ZM4 8C4 9.10461 3.10457 10 2 10C0.895432 10 0 9.10461 0 8C0 6.89539 0.895432 6 2 6C3.10457 6 4 6.89539 4 8ZM7 7C6.44772 7 6 7.44775 6 8C6 8.55225 6.44772 9 7 9H17C17.5523 9 18 8.55225 18 8C18 7.44775 17.5523 7 17 7H7Z"
                        fill={color}
                    />
                </svg>
            </div>
        );
    }
}

List.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default List;
