import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BannerNavLink from '../../ButtonsOrLinks/BannerNavLink';

const NavWrapper = styled.nav`
    align-items: center;
    width: 100%;
`;

const NavList = styled.ul`
    position: relative;
    width: 560px;
    border: 0px solid #3d3d3d;
    background: linear-gradient(0deg, var(--rsBackground) 0%, ${(props) => props.backgroundColor} 100%);
    border-radius: 50px;
    list-style-type: none;
    display: flex;
    box-shadow: 0px 0px 20px 0px rgba(55, 219, 246, 0.5);
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
`;

class BannerNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected,
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(e) {
        const selectedNav = e.currentTarget.id;
        this.setState({ selected: selectedNav });
        this.props.handleNavChange(selectedNav.replace('nav_', ''));
    }

    render() {
        const { selected } = this.state;

        return (
            <NavWrapper>
                <NavList backgroundColor={this.props.backgroundColor}>
                    {this.props.items.map((ele, index) => (
                        <BannerNavLink
                            id={`nav_${ele.name.toLowerCase()}`}
                            key={`BANNERNAV_${index}`}
                            replace={this.props.replace}
                            to={ele.to}
                            icon={ele.icon}
                            name={ele.name}
                            selected={selected === `nav_${ele.name.toLowerCase()}`}
                            onClick={this.handleSelect}
                        />
                    ))}
                </NavList>
            </NavWrapper>
        );
    }
}

BannerNav.propTypes = {
    /**
     * The items that go in the banner.
     * `icon` is the icon component.
     * `to` is the linked route.
     * `name` is the displayed text.
     */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            to: PropTypes.string,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    /**
     * Do the links replace the items in the history stack?
     */
    replace: PropTypes.bool,
    handleNavChange: PropTypes.func.isRequired,
    selected: PropTypes.string,
};

export default BannerNav;
