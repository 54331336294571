import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const FreemiumRoute = ({ component: Component, path, authUser, verified, verificationDone, ...rest }) => (
    <Route
        {...rest}
        path={path}
        render={(props) => {
            if (!verificationDone) {
                return null;
            }
            if (!authUser) {
                return <Redirect to={ROUTES.FORBIDDEN} />;
            }
            if (verified) {
                return <Redirect to={ROUTES.DASHBOARD} />;
            }

            return <Component {...props} {...rest} />;
        }}
    />
);

export default FreemiumRoute;
