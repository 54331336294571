import React, { Component } from 'react';
import * as ROUTES from '../../../../../constants/routes';
import BannerNav from '../../../../UI-Library/Navs/BannerNav';

import Note from '../../../../UI-Library/Icons/Note';
import Lightning from '../../../../UI-Library/Icons/Lightning';
import TextLines from '../../../../UI-Library/Icons/TextLines';
import PenAndPaper from '../../../../UI-Library/Icons/PenAndPaper';

class SongNav extends Component {
    render() {
        const featureMap = {
            karaoke: '/karaoke',
            genius: '/breakdown',
            blanks: '/blanks',
            review: '/review',
        };

        const redirectTo = (invite, feature) => {
            if (invite) {
                return ROUTES.INVITE_SONGVIEW + featureMap[feature] + '?songId=' + this.props.id;
            }
            return ROUTES.SONGVIEW + featureMap[feature] + '?songId=' + this.props.id;
        };

        const items = [
            {
                icon: Note,
                to: redirectTo(this.props.guest, 'karaoke'),
                name: 'Karaoke',
            },
            {
                icon: Lightning,
                to: redirectTo(this.props.guest, 'genius'),
                name: 'Breakdown',
            },
            {
                icon: TextLines,
                to: redirectTo(this.props.guest, 'blanks'),
                name: 'Blanks',
            },
            {
                icon: PenAndPaper,
                to: redirectTo(this.props.guest, 'review'),
                name: 'Review',
            },
        ];
        return <BannerNav items={items} replace={true} guest={this.props.guest} backgroundColor={this.props.backgroundColor} />;
    }
}

export default SongNav;
