class Song {
    /**
     *
     * The constructor to build a song object.
     *
     * @param {string} id
     * @param {Array<string>} genre
     * @param {Array<string>} newArtist
     * @param {Array<string>} origArtist
     * @param {string} origTitle
     * @param {any} subject
     * @param {any} unit
     * @param {any} topic
     * @param {any} subtopic
     */
    constructor(id, genre, newArtist, origArtist, origTitle, subject, unit, topic, subtopic, uploaded) {
        this.id = id;
        this.genre = genre;
        this.newArtist = newArtist;
        this.origArtist = origArtist;
        this.origTitle = origTitle;
        this.subject = subject;
        this.unit = unit;
        this.topic = topic;
        this.subtopic = subtopic;
        this.src = null;
        this.uploaded = uploaded.toDate();
    }

    static songConverter = {
        toFirestore: function (song) {
            return {
                genre: song.genre,
                newArtist: song.newArtist,
                origArtist: song.origArtist,
                origTitle: song.origTitle,
                subject: song.subject,
                unit: song.unit,
                topic: song.topic,
                subtopic: song.subtopic,
                uploaded: song.uploaded,
            };
        },
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            return new Song(
                snapshot.ref.id,
                data.genre,
                data.newArtist,
                data.origArtist,
                data.origTitle,
                data.subject,
                data.unit,
                data.topic,
                data.subtopic,
                data.uploaded,
            );
        },
    };
}

export default Song;
