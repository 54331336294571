import styled from 'styled-components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cross from '../../Icons/Cross';

const RemoveImageButtonStyle = styled.button`
    position: absolute;
    outline: none;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--rsSurface);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 2px;
    top: 2px;
`;

/**
 * The button uses absolute positioning to position itself at the top left of
 * the parent relative element.
 */
class RemoveImageButton extends Component {
    render() {
        return (
            <RemoveImageButtonStyle>
                <Cross
                    size="14px"
                    onClick={() => {
                        this.props.onClickFn(this.props.onClickArg);
                    }}
                />
            </RemoveImageButtonStyle>
        );
    }
}

RemoveImageButton.propTypes = {
    /**
     * The function that is called when the button is clicked.
     */
    onClickFn: PropTypes.func.isRequired,
};

export default RemoveImageButton;
