import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FeatureStartTools from './FeatureStartTools';
import FeatureStartMode from './FeatureStartMode';

const MainCard = styled.div`
    background: var(--rsSurface);
    border-radius: 25px;
    padding: 25px;
    max-width: 650px;
    width: 100%;
`;

const Hr = styled.hr`
    border: 1px solid var(--rsSecondary);
    margin: 20px 0px 15px 0px;
`;

class FeatureStart extends Component {
    render() {
        const { featureName, start } = this.props;
        return (
            <MainCard>
                <FeatureStartTools {...this.props} />
                <Hr />

                <FeatureStartMode start={start} featureName={featureName} />
            </MainCard>
        );
    }
}

FeatureStart.propTypes = {
    /**
     * The array of study sets for this feature.
     */
    userSets: PropTypes.array,
    /**
     * The function to select a set from the dropdown.
     */
    selectSet: PropTypes.func,
    /**
     * The function fired when the pencil icon is clicked.
     */
    toggleEdit: PropTypes.func,
    /**
     * The function fired when the plus icon is clicked.
     */
    customContentModalToggle: PropTypes.func,
    /**
     * The title of the active set.
     */
    title: PropTypes.string,
    /**
     * The author name of the active set.
     */
    authorName: PropTypes.string,
    /**
     * The length of the active set.
     */
    length: PropTypes.number,
    /**
     * The feature name.
     */
    featureName: PropTypes.string,
    /**
     * `true` if the user is the author of the set, `false` otherwise.
     */
    isAuthor: PropTypes.bool,
    /**
     * The function to begin the feature. Takes in one parameter, the string|null
     * representing the name of the mode selected.
     */
    start: PropTypes.func,
};

FeatureStart.defaultProps = {
    userSets: [],
    selectSet: () => null,
    toggleEdit: () => null,
    customContentModalToggle: () => null,
    start: () => null,
    title: '',
    authorName: '',
    length: 0,
    featureName: '',
    isAuthor: false,
};

export default FeatureStart;
