import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { withTheme } from '@material-ui/core';

const MainWrapper = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 3px;
    background: #485256;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background: #62747b;
        transition: all 0.3s ease 0s;
    }
`;

const InnerBox = styled.div`
    width: 12px;
    height: 12px;
    background: ${(props) => (props.selected ? 'var(--rsBlue)' : 'transparent')};
    border-radius: 3px;
`;

/**
 * The standard checkbox component.
 */
class RsCheckbox extends Component {
    render() {
        return (
            <MainWrapper onClick={this.props.onSelect}>
                <InnerBox selected={this.props.selected} />
            </MainWrapper>
        );
    }
}

RsCheckbox.propTypes = {
    /**
     * Is the checkbox selected?
     */
    selected: PropTypes.bool.isRequired,
    /**
     * The function that fires when the box is clicked.
     */
    onSelect: PropTypes.func.isRequired,
};

export default RsCheckbox;
