import React, { Component } from 'react';
import styled from 'styled-components';

const MainJumbo = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 75vh;
    text-align: center;
    background: var(--rsBackground);
`;

const JumboHR = styled.hr`
    border: 1px solid var(--rsPrimary);
`;

class MobileCatch extends Component {
    render() {
        return (
            <MainJumbo className="jumbotron">
                <h2 className="major">rapStudy is coming to mobile soon!</h2>

                <JumboHR />
                <p className="lead container" style={{ textAlign: 'center' }}>
                    In the meantime, open rapStudy on your computer and get to learning lyrically!
                </p>
            </MainJumbo>
        );
    }
}
export default MobileCatch;
