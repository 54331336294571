import React, { Component } from 'react';
import styled from 'styled-components';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import * as ROUTES from '../../../../constants/routes';
import { Link } from 'react-router-dom';
import EmptyContent from '../../../UI-Library/Misc/EmptyContent';

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

class EmptySets extends Component {
    render() {
        return (
            <MainWrapper>
                <EmptyContent src="/images/animationAssets/girlSitting.png" lead="You don't have any sets!">
                    <p>
                        To create a study set, go to any song and click on one of our features,
                        <br /> including <span className="major">Breakdown</span>
                        {', '}
                        <span className="major">Blanks </span>
                        {', or '}
                        <span className="major">Review{'.'}</span>
                    </p>
                </EmptyContent>
                <Link to={ROUTES.BROWSE_SUBJECTS}>
                    <RsButton large={true} mt="10px">
                        Browse
                    </RsButton>
                </Link>
            </MainWrapper>
        );
    }
}

export default EmptySets;
