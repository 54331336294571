import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import RsButton from '../../ButtonsOrLinks/RsButton';
import Cross from '../../Icons/Cross';

const Container = styled.div`
    padding-top: 29px;
    padding-right: 30px;
    padding-left: 34px;
`;

const ModalTitle = styled(Modal.Title)`
    font-weight: bold;
    font-size: 32px;
    margin: 0px;
`;

const ModalBody = styled(Modal.Body)`
    min-height: 300px;
`;

const ModalHeader = styled(Modal.Header)`
    align-items: center;
`;

export class TutorialModal extends Component {
    constructor(props) {
        super(props);
        this.handleSkipTutorial = this.handleSkipTutorial.bind(this);
    }

    async handleSkipTutorial() {
        if (this.props.firstTime) {
            this.props.complete();
        }
        this.props.onHide();
    }

    render() {
        const srcDict = {
            Karaoke: {
                src: 'https://www.youtube.com/embed/wQpHMZn0DGo',
                desc: "Karaoke Mode lets you listen to the song with our word tracker. It's a great way to follow along and learn the lyrics!",
            },
            Blanks: {
                src: 'https://www.youtube.com/embed/jtnpto51tV4',
                desc:
                    'Blanks Mode allows you to blank out certain lyrics, prompting students to recall what goes in the blank. While playing the song in Blanks Mode, the playback will pause on each blank until you hit resume.',
            },
            Breakdown: {
                src: 'https://www.youtube.com/embed/r0i1CVC8rPg',
                desc: 'Breakdown Mode allows you to annotate the song lyrics. Add any information that will enhance your students’ learning!',
            },
            Review: {
                src: 'https://www.youtube.com/embed/A5oQMT4KpYE',
                desc:
                    'Review Mode allows you to create multiple choice questions about the song’s material. Each question can be linked to a selection of the song’s lyrics, which will appear after the correct answer is chosen, or as an optional hint when an incorrect answer is chosen.',
            },
        };
        return (
            <Modal dialogClassName="border-radius-16-wide" show={this.props.show} onHide={this.props.onHide} centered>
                <Container>
                    <ModalHeader>
                        <ModalTitle>{this.props.feature}</ModalTitle>
                        <Cross size="18px" onClick={this.props.onHide} />
                    </ModalHeader>

                    <ModalBody>
                        <p className="secondary">{srcDict[this.props.feature].desc}</p>
                        <iframe
                            src={srcDict[this.props.feature].src}
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="video"
                            width="100%"
                            height="400px"
                        />
                    </ModalBody>
                    {this.props.showFooter && (
                        <Modal.Footer
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '30px',
                            }}
                        >
                            <RsButton onClick={this.props.onHide} secondary={true}>
                                Show again later
                            </RsButton>
                            <RsButton onClick={this.handleSkipTutorial}>Done</RsButton>
                        </Modal.Footer>
                    )}
                </Container>
            </Modal>
        );
    }
}

export default TutorialModal;
