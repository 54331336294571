import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import * as ROUTES from '../../../../../constants/routes';

import CreateAssignmentDetails from '../../../CreateAssignmentView/CreateAssignmentDetails';

import RsButton from '../../../../UI-Library/ButtonsOrLinks/RsButton';

const MainWrapper = styled.div`
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
`;

class AssignmentViewTeacherEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assignmentName: this.props.assignmentData.name,
            description: this.props.assignmentData.description,
            dueDate: this.props.assignmentData.dueDate,
            releaseDate: this.props.assignmentData.releaseDate,
            changing: false,
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.submit = this.submit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.changeDueDate = this.changeDueDate.bind(this);
        this.changeReleaseDate = this.changeReleaseDate.bind(this);
        this.submitAllowed = this.submitAllowed.bind(this);
    }

    handleNameChange(e) {
        this.setState({ assignmentName: e.target.value });
    }

    handleDescChange(e) {
        this.setState({ description: e.target.value });
    }

    assertDates(release, due) {
        if (!release || !due)
            return {
                success: false,
                msg: 'Invalid dates, please re-enter your dates and try again.',
            };
        if (due < release) {
            return {
                success: false,
                msg: 'The assignment cannot be due before it is released.',
            };
        }
        return {
            success: true,
            msg: '',
        };
    }

    submitAllowed() {
        return this.assertDates(this.state.releaseDate, this.state.dueDate).success && this.state.assignmentName.trim().length > 0;
    }

    async submit() {
        try {
            this.setState({ changing: true });
            await this.props.editDetails(this.state.assignmentName, this.state.description, this.state.releaseDate, this.state.dueDate);
            this.props.alert.show('Successfully edited assignment details.');
            this.props.history.replace(ROUTES.CLASSVIEW + '/' + this.props.classId + '/assignments/' + this.props.assignmentId + '/teacher/summary');
        } catch (err) {
            this.setState({ err: err });
        }
    }

    goBack() {
        this.props.history.replace(ROUTES.CLASSVIEW + '/' + this.props.classId + '/assignments/' + this.props.assignmentId + '/teacher/summary');
    }

    changeDueDate(date) {
        this.setState({ dueDate: date });
    }

    changeReleaseDate(date) {
        this.setState({ releaseDate: date });
    }

    render() {
        if (this.state.err) {
            throw this.state.err;
        }
        const assertedDates = this.assertDates(this.state.releaseDate, this.state.dueDate);
        return (
            <MainWrapper>
                <CreateAssignmentDetails
                    dueDate={this.state.dueDate}
                    releaseDate={this.state.releaseDate}
                    assignmentName={this.state.assignmentName}
                    description={this.state.description}
                    handleNameChange={this.handleNameChange}
                    handleDescChange={this.handleDescChange}
                    changeDueDate={this.changeDueDate}
                    changeReleaseDate={this.changeReleaseDate}
                    assertedDates={assertedDates}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <RsButton secondary={true} onClick={this.goBack}>
                        Back
                    </RsButton>
                    <RsButton onClick={this.submit} disabled={!this.submitAllowed() || this.state.changing}>
                        Submit
                    </RsButton>
                </div>
            </MainWrapper>
        );
    }
}

export default withAlert()(withRouter(AssignmentViewTeacherEdit));
