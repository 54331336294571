import React, { Component } from 'react';
import styled from 'styled-components';
import CreatePlaylistModal from '../CreatePlaylistModal';
import Create from '../../../UI-Library/Icons/Create';

const MainWrapper = styled.div`
    background-color: transparent !important;
`;

const IconWrapper = styled.div`
    margin-right: 13px;
`;

// const MainHeader = styled.p`
//   vertical-align: middle;
//   display: inline-block;
//   font-weight: bold;
//   margin-bottom: 0;
//   color: var(--rsTertiary);
//   font-size: 14px;
//   transition: color 0.3s ease;
//   &:hover {
//     color: var(--rsPrimary);
//   }
//   margin-top: 0;
// `;

const TogglerModal = styled.a`
    :hover {
        cursor: pointer;
        text-decoration: none;
    }
    background-color: transparent;
    color: var(--rsSecondary) !important;
    text-decoration: none;
`;

// const LinkWrapper = styled.div`
//   text-align: center;
// `;

export class CreatePlaylistModalToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHide: false,
        };
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
    }

    handleModalShowHide() {
        this.setState({
            showHide: !this.state.showHide,
        });
    }

    render() {
        return (
            <MainWrapper>
                <TogglerModal onClick={this.handleModalShowHide} className="vertNav-link">
                    <div style={{ display: 'flex' }}>
                        <IconWrapper style={{ paddingTop: '4px' }}>
                            <Create style={{ verticalAlign: 'middle' }} size="14px" hover={false} />
                        </IconWrapper>
                        <div style={{ verticalAlign: 'middle' }}></div>
                    </div>
                </TogglerModal>

                <CreatePlaylistModal willRedirect={this.props.willRedirect} show={this.state.showHide} toggle={this.handleModalShowHide} />
            </MainWrapper>
        );
    }
}

export default CreatePlaylistModalToggle;
