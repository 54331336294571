import React from 'react';

function SearchError() {
    return (
        <div>
            <p style={{ marginTop: '10px' }}>
                Looks like Search is currently unavailable. Our team has been notified and we're working to bring it back online as soon as possible!
            </p>
        </div>
    );
}

export default SearchError;
