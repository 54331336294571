import React, { Component } from 'react';
import styled from 'styled-components';
import RsButton from '../../../../UI-Library/ButtonsOrLinks/RsButton';
import CreateSetModal from '../../../../UI-Library/Modals/CreateSetModal';
import EmptyContent from '../../../../UI-Library/Misc/EmptyContent';

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
    padding-top: 2%;
`;

const Highlight = styled.span`
    color: var(--rsMajor);
`;

class CreateSetPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHide: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ showHide: !this.state.showHide });
    }

    render() {
        const data = {
            Breakdown: {
                highlight: 'Breakdown Mode',
                intro: "allows you to annotate the song lyrics. Add any information that will enhance your students' learning!",
                tip: 'To view the tutorial for this mode again, click on Help in the bottom-left of the screen.',
            },
            Blanks: {
                highlight: 'Blanks Mode',
                intro:
                    'allows you to blank out certain lyrics, prompting students to recall what goes in the blank. While playing the song in Blanks Mode, the playback will pause on each blank until you hit resume.',
                tip: 'To view the tutorial for this mode again, click on Help in the bottom-left of the screen.',
            },
            Review: {
                highlight: 'Review Mode',
                intro:
                    "allows you to create multiple choice questions about the song's material. Each question can be linked to a selection of the song's lyrics, which will appear after the correct answer is chosen, or as an optional hint when an incorrect answer is chosen.",
                tip: 'To view the tutorial for this mode again, click on Help in the bottom-left of the screen.',
            },
        };

        const current = data[this.props.featureName];

        return (
            <MainWrapper>
                <EmptyContent src="/images/animationAssets/girlWaving.png" lead={'Create a ' + this.props.featureName + ' study set!'}>
                    <p>
                        <Highlight>{current.highlight}</Highlight> {current.intro}
                    </p>
                    <p>
                        <span className="bold">Tip: </span> {current.tip}
                    </p>
                </EmptyContent>

                <RsButton mt={'20px'} large={true} onClick={this.toggle}>
                    Create a Set
                </RsButton>
                <CreateSetModal
                    show={this.state.showHide}
                    create={this.props.create}
                    topicName={this.props.topicName}
                    songName={this.props.newTitle}
                    featureName={this.props.featureName}
                    toggle={this.toggle}
                />
            </MainWrapper>
        );
    }
}

export default CreateSetPrompt;
