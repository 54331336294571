import React, { Component } from 'react';

import PropTypes from 'prop-types';

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        // const hover =
        //   typeof this.props.hover !== 'undefined'
        //     ? this.props.hover
        //     : this.state.hover;

        // const color = this.props.disabled
        //   ? 'var(--rsDisabled)'
        //   : hover
        //   ? 'var(--rsPrimary)'
        //   : 'var(--rsSecondary)';
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg
                    width={this.props.size ? this.props.size : '24px'}
                    height={this.props.size ? this.props.size : '24px'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                >
                    <circle cx="9" cy="9" r="7.72" fill="black" stroke="var(--rsBlue)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <line x1="9" y1="5.9" x2="9" y2="12.39" fill="none" stroke="var(--rsBlue)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <line
                        x1="5.76"
                        y1="9.14"
                        x2="12.24"
                        y2="9.14"
                        fill="none"
                        stroke="var(--rsBlue)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </svg>
            </div>
        );
    }
}

Create.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default Create;
