import React, { Component } from 'react';
import styled from 'styled-components';
import UnsplashLink from '../../../../UI-Library/ButtonsOrLinks/UnsplashLink';

const Annotations = styled.div`
    max-height: 60vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    border: none;
    box-shadow: 5px 5px 15px black;
    border-radius: 16px;
    padding: 40px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }
`;

const Description = styled.p`
    width: 100%;
    font-size: 24px;
`;

const Image = styled.img`
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-shrink: 1;
    max-width: 100%;
    max-height: 250px;
    height: auto;
`;

class GeniusPopUp extends Component {
    render() {
        var color = 'green';
        var fade = '#000000';
        if (this.props.color) {
            color = this.props.color;
        }
        if (!this.props.annotation || !this.props.show) {
            return <div>{null}</div>;
        } else {
            return (
                <Annotations style={{ background: `linear-gradient(${color}, ${fade})` }}>
                    <Description>{this.props.annotation.desc}</Description>
                    {this.props.annotation.firebaseImg === null ? null : (
                        <div className="text-center">
                            <Image src={this.props.annotation.firebaseImg}></Image>
                        </div>
                    )}
                    {this.props.annotation.unsplash === null ? null : (
                        <div className="text-center">
                            <Image src={this.props.annotation.unsplash}></Image>
                            <UnsplashLink uid={this.props.annotation.unsplashUid} name={this.props.annotation.unsplashName} />
                        </div>
                    )}
                </Annotations>
            );
        }
    }
}

export default GeniusPopUp;
