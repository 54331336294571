import React, { Component } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-bootstrap';

import { withFirebase } from '../../../Firebase';
import TagSelect from '../../Forms/TagSelect';

import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';

const MainWrapper = styled.div`
    width: 100%;
`;

const Footer = styled.div`
    height: auto;
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
`;

const CarouselItem = styled(Carousel.Item)`
    height: 100% !important;
    min-height: 250px;
    width: 100% !important;
    margin-bottom: auto;
    overflow: visible !important;
`;

const Star = styled.img`
    width: 48px;
    height: 48px;
    margin-top: 50px;
`;

const TOSLink = styled.a`
    text-decoration: none;
    border-bottom: 2px solid var(--rsBlue);
    &:hover {
        color: rgba(var(--rsBlueRGB), 0.8);
        text-decoration: none;
    }
`;

export class OnboardingModalBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            direction: null,
            firstName: '',
            lastName: '',
            grades: ['K', 1, 2, 3, 4, 5, 6, 7, 8, 'Administrator'],
            gradesSelected: [],
            subjects: ['Math', 'English', 'Science', 'Language', 'Social Studies', 'ELA', 'Technology', 'Art', 'Administrator'],
            subjectsSelected: [],
            submitting: false,
            maxIndex: -1,
        };
        this.toggleCarousel = this.toggleCarousel.bind(this);
        this.handleFirstChange = this.handleFirstChange.bind(this);
        this.handleLastChange = this.handleLastChange.bind(this);
        this.handleGradeToggle = this.handleGradeToggle.bind(this);
        this.handleSubjectToggle = this.handleSubjectToggle.bind(this);
        this.submitResults = this.submitResults.bind(this);
    }

    toggleCarousel = (direction) => {
        let index = this.state.index;
        const [min, max] = [0, 3];

        //Log in onboarding events onboarding_name, onboarding_grade,
        //onboarding_subject, onboarding_privacy  [Firebase Analytics]
        if (direction === 'next') {
            if (index === 0 && index > this.state.maxIndex) {
                this.props.firebase.analytics.logEvent('onboarding_name');
            } else if (index === 1 && index > this.state.maxIndex) {
                this.props.firebase.analytics.logEvent('onboarding_grade');
            } else if (index === 2 && index > this.state.maxIndex) {
                this.props.firebase.analytics.logEvent('onboarding_subject');
            }
            this.setState({ maxIndex: index });
            index++;
        } else if (direction === 'prev') {
            index--;
        }

        if (index > max) {
            // at max, start from top
            index = max;
        }

        if (index < min) {
            // at min, start from max
            index = min;
        }
        this.setState({
            direction,
            index,
        });
    };

    handleFirstChange(e) {
        this.setState({ firstName: e.target.value });
    }

    handleLastChange(e) {
        this.setState({ lastName: e.target.value });
    }

    handleGradeToggle(selected) {
        this.setState({ gradesSelected: selected });
    }

    handleSubjectToggle(selected) {
        this.setState({ subjectsSelected: selected });
    }

    submitResults() {
        this.setState({ submitting: true });
        this.props.completeOnboarding(this.state.firstName, this.state.lastName, this.state.gradesSelected, this.state.subjectsSelected);
        //Set User Property grade [Firebase Analytics]
        var result = Array.from(this.state.gradesSelected);
        if (result.length === 0) {
            this.props.firebase.analytics.setUserProperties({
                grade: 'Not Selected',
            });
        } else {
            this.props.firebase.analytics.setUserProperties({
                grade: result.toString(),
            });
        }
        //Log in onboarding event onboarding_privacy [Firebase Analytics]
        this.props.firebase.analytics.logEvent('onboarding_privacy');
    }

    render() {
        const invalid = this.state.firstName.trim().length === 0 || this.state.lastName.trim().length === 0 || this.state.submitting;
        return (
            <MainWrapper>
                <Carousel
                    indicators={false}
                    controls={false}
                    activeIndex={this.state.index}
                    direction={this.state.direction}
                    style={{
                        height: '100%',
                        width: '100%',
                        overflow: 'visible !important',
                    }}
                >
                    <CarouselItem>
                        <p className="bold">1. What's your name?</p>{' '}
                        <p className="secondary bold" style={{ marginBottom: '0', fontSize: 14 }}>
                            First name
                        </p>
                        <RsInput value={this.state.firstName} onChange={this.handleFirstChange} mb={'10px'}></RsInput>
                        <p className="secondary bold" style={{ marginBottom: '0', fontSize: 14 }}>
                            Last name
                        </p>
                        <RsInput value={this.state.lastName} onChange={this.handleLastChange}></RsInput>
                    </CarouselItem>
                    <CarouselItem>
                        <p className="bold">2. Please select your grade level(s).</p>
                        <TagSelect selection={this.state.grades} selected={this.state.gradesSelected} onChange={this.handleGradeToggle} />
                    </CarouselItem>
                    <CarouselItem>
                        <p className="bold">3. Please select your class subject(s).</p>
                        <TagSelect selection={this.state.subjects} selected={this.state.subjectsSelected} onChange={this.handleSubjectToggle} />
                    </CarouselItem>
                    <CarouselItem>
                        <p className="bold">4. Almost there!</p>
                        <p className="bold">
                            By continuing, you agree to our{' '}
                            <TOSLink
                                className="major"
                                href={
                                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20PP.pdf?alt=media&token=ac72e73b-1f95-4b69-a46b-de02d0bea869'
                                }
                                download="rapStudy Privacy Policy"
                                target="_blank"
                            >
                                Privacy Policy
                            </TOSLink>{' '}
                            and{' '}
                            <TOSLink
                                className="major"
                                href={
                                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20TOU.pdf?alt=media&token=ff00b065-3e73-43c3-8e02-0cf2b338596f'
                                }
                                download="rapStudy Terms of Use"
                                target="_blank"
                            >
                                Terms of use
                            </TOSLink>
                            .
                        </p>
                        <p className="bold" style={{ marginBottom: 0 }}>
                            In short:
                        </p>
                        <p style={{ fontSize: '14px' }}>
                            1. Please don’t try to reverse-engineer our code. <br />
                            2. Please don’t distribute our content, or upload anything bad to our platform. <br />
                            3. If you are under 13 years old, you must have parental or school consent to use our platform.
                        </p>
                    </CarouselItem>
                    <CarouselItem>
                        <p style={{ textAlign: 'center' }}>
                            <Star src="/images/icons/emoji/star.png"></Star>
                        </p>
                        <p className="bold">You’re ready to rock ‘n’ roll!</p>
                        <p className="secondary">
                            To get started, find a topic you teach using the search bar, or head over to Browse via the left side navigation bar to view our
                            entire collection.
                        </p>
                    </CarouselItem>
                </Carousel>
                <Footer>
                    {this.state.index === 0 ? (
                        <div style={{ width: '150px' }}></div>
                    ) : (
                        <RsButton secondary onClick={() => this.toggleCarousel('prev')}>
                            Previous
                        </RsButton>
                    )}
                    {this.state.index === 3 ? (
                        <RsButton onClick={this.submitResults} disabled={invalid}>
                            Continue
                        </RsButton>
                    ) : (
                        <RsButton onClick={() => this.toggleCarousel('next')} disabled={invalid}>
                            Next
                        </RsButton>
                    )}
                </Footer>
            </MainWrapper>
        );
    }
}

const OnboardingModal = withFirebase(OnboardingModalBase);
export default OnboardingModal;
