import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import { withRouter, Link } from 'react-router-dom';
import Loading from '../../../UI-Library/Misc/Loading';
import AssignmentSetView from '../AssignmentSetView';
import ThumbnailScroll from '../ThumbnailScroll';

import { subjectColors } from '../../../../constants/colors';
import SimpleBanner from '../../../UI-Library/Containers/SimpleBanner';
import BackArrow from '../../../UI-Library/ButtonsOrLinks/BackArrow';
import EmptyContent from '../../../UI-Library/Misc/EmptyContent';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

class AssignmentViewStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submissions: null,
            ready: false,
            activeExerciseId: null,
            error: null,
        };
        this.switchExercise = this.switchExercise.bind(this);
        this.submitExercise = this.submitExercise.bind(this);
        this.saveSubmissionAndRerender = this.saveSubmissionAndRerender.bind(this);
    }

    async componentDidMount() {
        try {
            const submissions = await this.props.firebase.getSubmissionsForStudent(this.props.assignmentId, this.props.firebase.auth.currentUser.uid);

            const params = this.props.match.params;
            let exerciseId = params.exerciseId;
            if (!exerciseId || exerciseId === 'null') {
                exerciseId = submissions[0].getExerciseId();
                this.props.history.replace(`/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/student/${exerciseId}`);
            }

            await Promise.all(
                submissions.map(async (sub) => {
                    const songObj = sub.getSongData();
                    songObj.src = await this.props.firebase.getSongCover(songObj.subtopic.subtopicRef.id);
                }),
            );

            this.setState({
                submissions: submissions,
                ready: true,
                activeExerciseId: exerciseId,
            });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    isDone() {
        let isDone = true;
        const submissions = this.state.submissions;
        submissions.forEach((sub) => (isDone = isDone && sub.getStatus() === 'complete'));
        return isDone;
    }

    switchExercise(exerciseId) {
        this.props.history.replace(`/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/student/${exerciseId}`);
        this.setState({ activeExerciseId: exerciseId });
    }

    async submitExercise(submission) {
        try {
            submission.setStatus('complete');
            await this.saveSubmissionAndRerender(submission, false);
            this.props.firebase.analytics.logEvent('setSubmission_submit', {
                songId: submission.getSongData().id,
                featureName: submission.getFeatureName(),
                length: submission.getLength(),
                mode: submission.getMode(),
            });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    async saveSubmissionAndRerender(submission, wait) {
        try {
            if (wait) {
                await this.props.firebase.saveSubmission(submission);
            } else {
                this.props.firebase.saveSubmission(submission);
            }
            this.setState({ submissions: this.state.submissions });
            return;
        } catch (err) {
            this.setState({ error: err });
        }
    }

    render() {
        if (this.state.error) throw this.state.error;
        if (!this.state.ready) return <Loading zoom={true} />;

        const isDone = this.isDone();

        const submissions = this.state.submissions;
        return (
            <MainWrapper>
                <SimpleBanner color={subjectColors[this.props.assignmentData.subject]}>
                    <BackArrow />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                width: '50%',
                                paddingRight: '10px',
                            }}
                        >
                            <h1 className="bold ellipsisWrap">{this.props.assignmentData.name}</h1>
                            <h3>{this.props.classData.title}</h3>
                            <p className="secondary">Due: {this.props.assignmentData.dueDate.toLocaleString()}</p>
                        </div>
                        <div style={{ width: '50%' }}>
                            <ThumbnailScroll
                                submissions={this.state.submissions}
                                isDone={isDone}
                                switchExercise={this.switchExercise}
                                activeExerciseId={this.state.activeExerciseId}
                            />
                        </div>
                    </div>
                </SimpleBanner>

                {isDone ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '40px',
                        }}
                    >
                        <EmptyContent lead="Congratulations!" src="/images/animationAssets/girlsLaptop.png">
                            <div className="text-center">
                                You have completed "{this.props.assignmentData.name}". Give yourself a pat on the back, you deserve it!
                                <br />
                                <Link to={'/internal/classView/' + this.props.classId + '/assignments'}>
                                    <RsButton mt={'30px'} large={true}>
                                        Back to Class
                                    </RsButton>
                                </Link>
                            </div>
                        </EmptyContent>
                    </div>
                ) : (
                    <AssignmentSetView
                        submissions={submissions}
                        assignmentId={this.props.assignmentId}
                        assignmentData={this.props.assignmentData}
                        classId={this.props.classId}
                        activeExerciseId={this.state.activeExerciseId}
                        saveSubmissionAndRerender={this.saveSubmissionAndRerender}
                        submitExercise={this.submitExercise}
                        switchExercise={this.switchExercise}
                    />
                )}
            </MainWrapper>
        );
    }
}

export default withRouter(withFirebase(AssignmentViewStudent));
