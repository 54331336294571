import React from 'react';

/**
 * Returns the JSX that displays an empty result.
 */
function SearchEmpty() {
    return (
        <div>
            <p style={{ marginTop: '10px' }}>Oops! Looks like there's nothing here. Refine your search and try again.</p>
        </div>
    );
}

export default SearchEmpty;
