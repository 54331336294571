import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const NavItem = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 10px;
`;

const Name = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.disabled ? 'var(--rsSecondary)' : 'white')};
  text-decoration: none;
  margin-right: 20px;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => (props.active ? 'border-bottom: 2px solid var(--rsBlue);' : '')}
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')}
`;

const FeatureDiv = styled.div`
    margin-right: 10px;
    marginbottom: 2px;
`;

class BannerNavLinkControlled extends Component {
    render() {
        const WrappedIcon = this.props.icon;
        return (
            <NavItem onClick={this.props.onClick}>
                {this.props.icon && (
                    <FeatureDiv>
                        <WrappedIcon size="18px" hover={this.props.disabled ? false : true} />
                    </FeatureDiv>
                )}

                <Name active={this.props.active} disabled={this.props.disabled} className={this.props.disabled ? '' : 'songBannerLink'}>
                    {this.props.name}
                </Name>
            </NavItem>
        );
    }
}

BannerNavLinkControlled.propTypes = {
    /**
     * The icon component used.
     */
    icon: PropTypes.any,
    /**
     * Is the link active?
     */
    active: PropTypes.bool,
    /**
     * The text displayed in the link.
     */
    name: PropTypes.string.isRequired,
    /**
     * The function fired when the link is clicked.
     */
    onClick: PropTypes.func,
    /**
     * Is the link disabled?
     */
    disabled: PropTypes.bool,
};

export default BannerNavLinkControlled;
