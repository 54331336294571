import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BackArrow from '../../ButtonsOrLinks/BackArrow';
import DefaultImg from '../../ImageContainers/DefaultImg';

const ImageColumn = styled.div`
    display: flex;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    margin-right: 20px;
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    min-width: 0;
`;

const BannerWrapper = styled.div`
    height: 225px;
    background: linear-gradient(0deg, var(--rsBackground) 0%, ${(props) => props.backgroundColor} 100%);
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    width: 100%;
    margin: 0;
`;

const BannerRow = styled.div`
    display: flex;
`;

/**
 * A banner that takes in backArrow behavior and an image.
 * Preferably, uses SimpleBanner as a composite component, but
 * formatting is a bit weird, so re-adding the SimpleBanner components in here.
 *
 */
class ImageInfoBanner extends Component {
    render() {
        return (
            <BannerWrapper backgroundColor={this.props.backgroundColor}>
                <BackArrow onClick={this.props.onClick} to={this.props.to} />
                <BannerRow>
                    <ImageColumn>
                        <DefaultImg src={this.props.url} type={this.props.imgType} color={this.props.backgroundColor} />
                    </ImageColumn>
                    <InfoColumn>{this.props.children}</InfoColumn>
                </BannerRow>
            </BannerWrapper>
        );
    }
}

ImageInfoBanner.propTypes = {
    /**
     * The optional back arrow onClick behavior.
     */
    onClick: PropTypes.func,
    /**
     * The optional route to redirect to after clicking the button.
     */
    to: PropTypes.string,
    /**
     * The banner background color.
     */
    backgroundColor: PropTypes.string.isRequired,
    /**
     * The image url.
     */
    url: PropTypes.string,
    /**
     * The default image type. Supports `playlist` and `class`. If `url` is
     * defined, this property is irrelevant. Defaults to `playlist`.
     */
    imgType: PropTypes.oneOf(['playlist', 'class']),
};

export default ImageInfoBanner;
