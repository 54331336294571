import CustomSetDict from '../../Classes/CustomSetDict';
import Song from '../../Classes/Song';
import StudySet from '../../Classes/StudySet';
import { paletteList } from '../../constants/colors';

export async function changeClassColor(classId, hex) {
    var classRef = this.db.collection('classes').doc(classId);
    await classRef.update({ color: hex });
}

export async function editClassDetails(classId, name, desc) {
    var classRef = this.db.collection('classes').doc(classId);
    await classRef.update({ title: name, description: desc });
}

/**
 * Delete the class with the class id @classId.
 * Throws PermissionDenied if user attempting to delete the class is not the creator.
 * @param {String} classId - the id of the class to be deleted.
 */
export async function deleteClass(classId) {
    var classRef = this.db.collection('classes').doc(classId);
    var querySnap = await classRef.get();

    if (!querySnap.exists) throw this.NotFoundError;

    var classData = querySnap.data();
    var classAdmin = classData.admin;

    if (classAdmin !== this.auth.currentUser.uid) throw this.PermissionDenied;

    await classRef.delete();
}

//TODO: Refactor the three getClasses functions. They are all identical with only on parameter changed.

/**
 * Returns a Promise that resolves with an array of classes that the user is in.
 * @return {Promise<Array>}
 */
export async function getPendingClasses() {
    var querySnap = await this.db.collection('classes').where('pending', 'array-contains', this.auth.currentUser.uid).get();
    if (querySnap.empty) return [];
    var classResults = querySnap.docs.map((item) => {
        var itemData = item.data();
        itemData.classId = item.id;
        return itemData;
    });
    return classResults;
}

/**
 * Returns a Promise that resolves with an array of classes that the user is in.
 * @return {Promise<Array>}
 */
export async function getMembershipClasses() {
    var querySnap = await this.db.collection('classes').where('students', 'array-contains', this.auth.currentUser.uid).get();
    if (querySnap.empty) return [];
    var classResults = querySnap.docs.map((item) => {
        var itemData = item.data();
        itemData.classId = item.id;
        return itemData;
    });
    return classResults;
}

/**
 * Returns a Promise that resolves with an array of classes that the user is
 * the admin of.
 * @return {Promise<Array>}
 */
export async function getAdminClasses() {
    var querySnap = await this.db.collection('classes').where('admin', '==', this.auth.currentUser.uid).get();
    if (querySnap.empty) return [];
    var classResults = querySnap.docs.map((item) => {
        var itemData = item.data();
        itemData.classId = item.id;
        return itemData;
    });
    return classResults;
}

/**
 * Returns a Promise that resolves with the object representing the data from
 * the class document, and the additional key 'id' mapped to the classId.
 * If there is no such document, throws the NotFoundError exception.
 * @param {string} classId
 * @return {Promise<>}
 */
export async function getClass(classId) {
    var classDoc = this.db.collection('classes').doc(classId);
    var querySnap = await classDoc.get();
    if (querySnap.exists) {
        var classData = querySnap.data();
        classData.ref = querySnap.ref;
        return classData;
    } else {
        throw this.NotFoundError;
    }
}

/**
 * Returns a Promise that resolves when a user has joined the class as a student.
 * If there is no such class, throws the NotFoundError exception.
 * If the student is already enrolled in the class, returns the class id
 * @return {Promise<string>}
 */
export async function joinClassAsStudent(pin) {
    const joinClassAsStudent = this.functions.httpsCallable('classes-joinClassAsStudent');
    const result = await joinClassAsStudent({
        pin: pin,
    });
    const data = result.data;

    if (!data.result && data.failure === 'not-found') {
        throw this.NotFoundError;
    } else if (!data.result && data.failure === 'already-pending') {
        throw this.PendingStudentError;
    } else if (!data.result && data.failure === 'already-enrolled') {
        throw this.EnrolledStudentError;
    } else {
        return data.classId;
    }
}

export async function createClass(title, description) {
    const uid = this.auth.currentUser.uid;
    const classesRef = this.db.collection('classes');
    const newClassDoc = classesRef.doc();
    const newClassId = newClassDoc.id;
    const pin = await this.generatePin('classes');

    await newClassDoc.set({
        title: title,
        totalStudents: 0,
        admin: uid,
        pin: pin,
        description: description,
        sharedSongs: [],
        students: [],
        pending: [],
        color: paletteList[Math.floor(Math.random() * paletteList.length)],
    });

    return { classId: newClassId, classPin: pin };
}

export async function approveDenyStudentToJoinClass(studentId, classId, bool) {
    try {
        if (bool) {
            const addStudent = this.functions.httpsCallable('classes-addStudentToClass');
            await addStudent({
                studentId: studentId,
                classId: classId,
            });
        } else {
            const classRef = this.db.collection('classes').doc(classId);
            classRef.update({
                pending: this.firebase.firestore.FieldValue.arrayRemove(studentId),
            });
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function removeStudentFromClass(studentId, classId) {
    const removeStudent = this.functions.httpsCallable('classes-removeStudentFromClass');
    await removeStudent({
        studentId: studentId,
        classId: classId,
    });
}

/**
 * Returns a Promise that resolves with true when the content is added to the class.
 * If the content already exists in the class, it does nothing on the database
 * and resolves with false.
 * @param {string} contentType //Playlist, Song, Review, Breakdown, Blanks
 * @param {string} contentId
 * @param {string} classId
 * @return {Promise<boolean>}
 */
export async function addContentToClass(contentType, contentId, classId) {
    var querySnap;
    if (contentType === 'Playlist') {
        querySnap = await this.db.collection('classPlaylists').where('classId', '==', classId).where('playlistId', '==', contentId).get();
        if (!querySnap.empty) return false;
        await this.db.collection('classPlaylists').add({
            classId: classId,
            playlistId: contentId,
            dateFollowed: this.firebase.firestore.FieldValue.serverTimestamp(),
        });

        //Log in event 'content_shared' [Firebase Analytics]
        this.analytics.logEvent('content_shared', {
            content: 'playlist',
        });

        return true;
    } else if (contentType === 'Song') {
        await this.db
            .collection('classes')
            .doc(classId)
            .update({
                sharedSongs: this.firebase.firestore.FieldValue.arrayUnion(contentId),
            });

        //Log in event 'content_shared' [Firebase Analytics]
        this.analytics.logEvent('content_shared', {
            content: 'song',
        });

        return true;
    } else {
        if (!['Review', 'Blanks', 'Breakdown'].includes(contentType)) throw Error('Invalidated Precondition: contentType is invalid');
        querySnap = await this.db
            .collection('classSets')
            .where('classId', '==', classId)
            .where('setId', '==', contentId)
            .where('type', '==', contentType)
            .get();
        if (!querySnap.empty) return false;
        await this.db.collection('classSets').add({
            classId: classId,
            setId: contentId,
            dateFollowed: this.firebase.firestore.FieldValue.serverTimestamp(),
            type: contentType,
        });

        //Log in event 'content_shared' [Firebase Analytics]
        this.analytics.logEvent('content_shared', {
            content: 'set',
        });

        return true;
    }
}

/**
 * Returns a Promise that resolves when the content is removed from the class.
 * If the content does not exist in the class, raises the this.NotFoundError.
 * @param {string} contentType
 * @param {string} contentId
 * @param {string} classId
 */
export async function removeContentFromClass(contentType, contentId, classId) {
    var querySnap;
    if (contentType === 'Playlist') {
        querySnap = await this.db.collection('classPlaylists').where('classId', '==', classId).where('playlistId', '==', contentId).get();
        if (querySnap.empty) throw this.NotFoundError;
        await Promise.all(
            querySnap.docs.map(async (docSnap) => {
                await docSnap.ref.delete();
            }),
        );
    } else if (contentType === 'Song') {
        await this.db
            .collection('classes')
            .doc(classId)
            .update({
                sharedSongs: this.firebase.firestore.FieldValue.arrayRemove(contentId),
            });
    } else {
        if (!['Review', 'Blanks', 'Breakdown'].includes(contentType)) throw Error('Invalidated Precondition: contentType is invalid');
        querySnap = await this.db
            .collection('classSets')
            .where('classId', '==', classId)
            .where('setId', '==', contentId)
            .where('type', '==', contentType)
            .get();
        if (querySnap.empty) throw this.NotFoundError;
        await Promise.all(
            querySnap.docs.map(async (docSnap) => {
                await docSnap.ref.delete();
            }),
        );
    }
}

/**
 * Returns a Promise that resolves with the shared playlists and sets of the
 * class. The shared songs are not included, because they are in the class
 * document.
 * @param {string} classId
 */
export async function getClassSharedContent(classId) {
    const result = {
        playlists: [],
        sets: new CustomSetDict(),
    };
    const converters = {
        questionSets: StudySet.reviewConverter,
        annotationSets: StudySet.breakdownConverter,
        keywordSets: StudySet.blanksConverter,
    };
    const playlistPr = this.db.collection('classPlaylists').where('classId', '==', classId).get();
    const setPr = this.db.collection('classSets').where('classId', '==', classId).get();
    const resolved = await Promise.all([playlistPr, setPr]);
    const playlistQuerySnap = resolved[0];
    const setQuerySnap = resolved[1];

    const prStage2 = [];
    // We can safely do this even if playlistQuerySnap is null, as
    // this.playlistsOfPlaylistsFollowed returns empty array if query snap is empty.
    prStage2.push(this.playlistsOfPlaylistsFollowed(playlistQuerySnap));
    if (!setQuerySnap.empty) {
        const sets = await Promise.all(
            setQuerySnap.docs.map(async (classSetDoc) => {
                const setId = classSetDoc.get('setId');
                const type = classSetDoc.get('type');
                const collName = type === 'Blanks' ? 'keywordSets' : type === 'Breakdown' ? 'annotationSets' : 'questionSets';
                const snap = await this.db.collection(collName).doc(setId).withConverter(converters[collName]).get();
                if (!snap.exists) {
                    console.log('DATABASE MISMATCH: Tried to access ' + collName + ' set with id ' + setId + ' but it did not exist.');
                    throw this.NotFoundError;
                }
                const data = snap.data();
                return data;
            }),
        );
        result.sets.addArrayOfStudySets(sets);
        const songIds = result.sets.getSongIds();
        const songsPr = Promise.all(
            songIds.map(async (songId) => {
                return await this.db.collection('songs').doc(songId).withConverter(Song.songConverter).get();
            }),
        );
        prStage2.push(songsPr);
        const resolved2 = await Promise.all(prStage2);
        const playlists = resolved2[0];
        result.playlists = playlists;
        const songs = resolved2[1];

        result.sets.setSongDatas(songs);
        const songDicts = result.sets.getSortedSongListByName();
        await Promise.all(
            songDicts.map(async (songDict) => {
                const data = songDict.getSongData();
                const url = await this.getSongCover(data.subtopic.subtopicRef.id);
                data.src = url;
            }),
        );
    } else {
        const resolved2 = await Promise.all(prStage2);
        const playlists = resolved2[0];
        result.playlists = playlists;
    }
    return result;
}
