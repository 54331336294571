import React, { Component } from 'react';

import RsModal from '../RsModal';
import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';

/**
 * Props:
 * submit (string,string) -> any
 * toggle () -> any
 * name string
 * desc string
 */
export class FormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.name,
            description: this.props.desc,
        };

        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    submit() {
        this.props.submit(this.state.title, this.state.description);
        this.props.toggle();
    }

    handleTitleChange(e) {
        this.setState({
            title: e.target.value,
        });
    }

    handleDescChange(e) {
        this.setState({
            description: e.target.value,
        });
    }

    render() {
        return (
            <RsModal show={this.props.show} toggle={this.props.toggle} title={'Edit Details'} submit={this.submit}>
                <RsModal.Body>
                    <p style={{ marginBottom: 0 }}>Name</p>
                    <RsInput value={this.state.title} onChange={this.handleTitleChange} mb={'10px'} />
                    <p style={{ marginBottom: 0 }}>Description</p>
                    <RsInput value={this.state.description} onChange={this.handleDescChange} />
                </RsModal.Body>
                <RsModal.Footer>
                    <RsButton disabled={!this.state.title || this.state.title.trim().length === 0} onClick={this.submit}>
                        Save
                    </RsButton>
                </RsModal.Footer>
            </RsModal>
        );
    }
}

export default FormModal;
