import './HomeBanner.css';

import * as ROUTES from '../../../../constants/routes';

import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import SimpleBanner from '../../../UI-Library/Containers/SimpleBanner';
import { withAlert } from 'react-alert';
import { withFirebase } from '../../../Firebase';
import { withRouter } from 'react-router-dom';

export class HomeBannerBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerCompleted: true,
            surveyQuestion: '',
            subMessage: '',
            surveyChoices: {},
            surveyChoicesCol1: [],
            surveyChoicesCol2: [],
            surveyChoicesCol3: [],
            surveyResults: {},
            selected: {},
            selectedCount: 0,
            surveyId: '',
            showGraph: false,
            componentReady: false,
            validDisplayBars: true,
            isCheckbox: true,
            postMessage: '',
            subPostMessage: '',
            partialOpacity: 0,
            stage: 0,
            banners: [],
            cover:
                'https://firebasestorage.googleapis.com/v0/b/rapstudy-staging.appspot.com/o/images%2Fsubtopics%2FR5YNMZxFm9t9vP6cjL9G.png?alt=media&token=5dd8fcf4-8271-43b5-8d85-092071e5fc73.png',
            title: '',
            fading: false,
            load: true,
            artist: {},
        };
        this.updateSurveyResult = this.updateSurveyResult.bind(this);
        this.onSelectCheckbox = this.onSelectCheckbox.bind(this);
        this.displayGraphTimer = this.displayGraphTimer.bind(this);
        this.displayInitialBannerTimer = this.displayInitialBannerTimer.bind(this);
        this.changeStage = this.changeStage.bind(this);
        this.startBanner = this.startBanner.bind(this);
        this.transition = this.transition.bind(this);
        this.startBanner();
    }

    async componentDidMount() {
        try {
            const bannerQuery = await this.props.firebase.getBanners();
            this.setState({ banners: bannerQuery });
            const artist = await this.props.firebase.getArtist('Billie Eilish');
            this.setState({ artist: artist });
        } catch (e) {
            console.log(e);
            console.log('Banner Error');
        }

        this.displayInitialBannerTimer();
    }

    startBanner() {
        if (this.state.stage >= 5) {
            this.setState({ stage: 0 });
        }
        setTimeout(() => this.changeStage(), 20000);
        setTimeout(() => this.startBanner(), 20000);
    }

    onSelectCheckbox(choice) {
        if (this.state.isCheckbox) {
            const newSelected = this.state.selected;
            newSelected[choice] = !this.state.selected[choice];
            let newSelectedCount = this.state.selectedCount + (newSelected[choice] ? 1 : -1);
            this.setState({
                selected: newSelected,
                selectedCount: newSelectedCount,
            });
        } else {
            let newSelected = this.state.selected;
            Object.keys(this.state.selected).forEach((choiceName) => {
                if (choiceName === choice) {
                    newSelected[choiceName] = true;
                } else {
                    newSelected[choiceName] = false;
                }
            });
            this.setState({
                selected: newSelected,
                selectedCount: 1,
            });
        }
        return;
    }

    displayInitialBannerTimer() {
        let count = 0;
        this.interval = setInterval(() => {
            if (count === 200) {
                clearInterval(this.interval);
                return;
            }
            count += 1;
            this.setState({
                partialOpacity: this.state.partialOpacity + 0.005,
            });
        }, 1);
    }

    displayGraphTimer() {
        let count = 0;
        this.interval = setInterval(() => {
            if (count === 99) {
                clearInterval(this.interval);
                return;
            }
            count += 1;
            this.setState({
                partialOpacity: this.state.partialOpacity + 0.01,
            });
        }, 1);
    }

    async updateSurveyResult(selectedChoices, surveyId, surveyQuestion, surveyChoices) {
        //creates the user doc to keep track of whether the user
        //completed the survey or not
        await this.props.firebase.completeBannerSurvey(surveyId, selectedChoices, surveyQuestion, surveyChoices);
        // Update the cumulative survey results with the user's answer choices
        let choices = this.state.surveyChoices;
        Object.keys(selectedChoices).forEach((choice) => {
            if (selectedChoices[choice]) {
                choices[choice] = choices[choice] + 1;
            }
        });
        this.setState({
            surveyResults: choices,
            selectedCount: 0,
        });
        let numInvalidBars = 0;

        Object.keys(choices).forEach((choice) => {
            if (choices[choice] === 0) {
                numInvalidBars += 1;
            }
        });
        if (numInvalidBars >= Math.ceil(Object.keys(choices).length / 2)) {
            this.setState({
                validDisplayBars: false,
            });
        }
        this.setState({
            bannerCompleted: false,
            showGraph: true,
            partialOpacity: 0.1,
        });

        this.displayGraphTimer();
        return;
    }

    changeStage() {
        // const currentStage = this.state.stage + 1;
        // if (currentStage >= 5) {
        //   this.transition();
        //   this.getSong(this.state.banners[0].song);
        //   setTimeout(() => this.setState({ stage: 0 }), 1000);
        //   setTimeout(() => this.transition(), 3000);
        // } else {
        //   this.transition();
        //   this.getSong(this.state.banners[currentStage].song);
        //   setTimeout(() => this.setState({ stage: this.state.stage + 1 }), 1280);
        //   setTimeout(() => this.transition(), 3000);
        // }
    }

    async getSong(id) {
        const song = this.props.firebase.getSong(id);
        const json = this.props.firebase.getJson(id);
        var res = await Promise.all([song, json]);
        const subtopicRef = res[0].data().subtopic.subtopicRef;
        const subtopicPr = subtopicRef.get();
        const mp3 = this.props.firebase.getMp3(id);
        const img = this.props.firebase.getSongCover(subtopicRef.id);
        var resStorage = await Promise.all([mp3, img, subtopicPr]);
        this.setState({ cover: resStorage[1] });
    }

    getCover() {
        return this.state.cover;
    }

    transition() {
        this.setState({ fading: !this.state.fading });
    }

    render() {
        const userData = this.props.userData;
        const banner = this.state.banners;

        var color = '--rsBlue';
        var text = 'Welcome to rapStudy!';
        var link = '/internal/songView/karaoke?songId=d100';
        var back = '';
        var icons = '/images/banners/star.png';

        try {
            color = banner[this.state.stage].color;
            text = banner[this.state.stage].text;
            link = '/internal/songView/karaoke?songId=' + String(banner[this.state.stage].song);
        } catch (e) {
            console.log('Error retrieving song details');
        }

        if (this.state.stage === 0) {
            back = 'https://www.nme.com/wp-content/uploads/2022/02/Billie-Eilish-4.jpg';
            icons = '';
        }

        var bannerStyle = {
            top: '0px',
            height: '200px',
            textOverflow: 'ellipsis',
        };

        if (this.state.fading) {
            bannerStyle = {
                animation: 'blur 4s ease 0s infinite',
                top: '0px',
                height: '200px',
            };
        }

        if (this.state.load) {
            bannerStyle = {
                filter: 'blur(30px)',
                animation: 'blurLanding 2s ease 0s 1',
                top: '0px',
                height: '200px',
            };
            setTimeout(() => this.setState({ load: false }), 1000);
            if (!this.state.load) {
                bannerStyle = {
                    top: '0px',
                    height: '200px',
                };
            }
        }
        // const isTeacher = this.props.userToken.claims.teacher;
        /*if (this.state.componentReady && isTeacher)*/
        return (
            <>
                <div style={bannerStyle}>
                    <SimpleBanner color={color} height={'200px'} icons={icons} src={back}>
                        <div style={{ overflow: 'ellipsis', textOverflow: 'ellipsis' }}>
                            <div
                                style={{
                                    display: 'inline-block',
                                    textOverflow: 'ellipsis',
                                    position: 'absolute',
                                    overflow: 'ellipsis',
                                }}
                            >
                                <h1
                                    style={{
                                        fontSize: '40px',
                                        fontWeight: 700,
                                        paddingTop: '20px',
                                    }}
                                >
                                    Hi, {userData.first_name}!
                                </h1>
                                {this.state.stage !== 0 ? (
                                    <p
                                        className="bold primary"
                                        style={{
                                            fontSize: '25px',
                                            fontWeight: 600,
                                            overflow: 'ellipsis',
                                        }}
                                    >
                                        {text}
                                    </p>
                                ) : (
                                    <p
                                        className="bold primary"
                                        style={{
                                            fontSize: '25px',
                                            fontWeight: 500,
                                            textOverflow: 'ellipsis',
                                            overflowX: 'ellipsis',
                                            paddingRight: '400px',
                                            height: '45px',
                                            overflowY: 'hidden',
                                        }}
                                    >
                                        {text}
                                    </p>
                                )}
                                {this.state.stage !== 0 ? (
                                    <a href={!this.props.guest ? link : '#/'}>
                                        <RsButton
                                            pageLocation="banner"
                                            buttonColor={color}
                                            onClick={() => {
                                                this.props.firebase.analytics.logEvent('link_open_home_banner', {
                                                    link_name: 'song redirect',
                                                });
                                                // window.open(link, "_blank");
                                            }}
                                        >
                                            Listen Now
                                        </RsButton>
                                    </a>
                                ) : (
                                    <Link
                                        to={{
                                            pathname: ROUTES.BROWSE_PROFILE,
                                            state: {
                                                artist: this.state.artist,
                                            },
                                        }}
                                    >
                                        <RsButton>Listen Now</RsButton>
                                    </Link>
                                )}
                            </div>
                            <div
                                style={{
                                    display: 'inline-flex',
                                    float: 'right',
                                    marginRight: '40px',
                                }}
                            >
                                {this.state.stage === 0 ? (
                                    <img width="280px" height="178px" src="/images/banners/covers.png" alt="" style={{}} />
                                ) : (
                                    <img
                                        width="180px"
                                        height="180px"
                                        src={this.getCover()}
                                        alt="covers"
                                        style={{
                                            boxShadow: '5px 5px 12px #3d3d3d',
                                            borderRadius: '5px',
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </SimpleBanner>
                </div>
            </>
        );
    }
}

export default withAlert()(withRouter(withFirebase(HomeBannerBase)));
