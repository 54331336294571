import React, { Component } from 'react';

const TimeContext = React.createContext(0);

export class TimeProvider extends Component {
    state = {
        currentSong: 'd100',
        currentTime: 0,
        currentPlay: false,
        inSongView: true,
    };

    setCurrentTime = (currentTime) => {
        this.setState({ currentTime: currentTime });
    };

    setCurrentSong = (currentSong) => {
        this.setState({ currentSong: currentSong });
    };

    setCurrentPlay = (play) => {
        this.setState({ currentPlay: play });
    };

    setInSongView = (bool) => {
        this.setState({ inSongView: bool });
    };

    render() {
        const { children } = this.props;
        const { currentTime, currentSong, currentPlay, inSongView } = this.state;
        const { setCurrentTime, setCurrentSong, setCurrentPlay, setInSongView } = this;

        return (
            <TimeContext.Provider
                value={{
                    currentTime,
                    currentSong,
                    currentPlay,
                    inSongView,
                    setCurrentTime,
                    setCurrentSong,
                    setCurrentPlay,
                    setInSongView,
                }}
            >
                {children}
            </TimeContext.Provider>
        );
    }
}

export default TimeContext;
