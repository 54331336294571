import React, { Component } from 'react';
import styled from 'styled-components';
import * as ROUTES from '../../../../constants/routes';
import RsLink from '../../../UI-Library/ButtonsOrLinks/RsLink';
// import { Typography } from "@material-ui/core";
import { withFirebase } from '../../../Firebase';

import PropTypes from 'prop-types';

const SongNav = styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0;
`;

class SubtopicList extends Component {
    constructor(props) {
        super(props);
        this.state = { images: [], loading: true };
    }

    async topicCover() {
        const songArrays = this.props.img;
        let allImages = [];

        for (let songArray of songArrays) {
            for (let song of songArray) {
                try {
                    const id = song.songId;
                    const data = await this.props.firebase.getSong(id);
                    const subtopicRef = data.data().subtopic.subtopicRef;
                    const img = await this.props.firebase.getSongCover(subtopicRef.id);
                    allImages.push(img);
                } catch (err) {
                    console.log('error in topicCover');
                    console.log(err);
                }
            }
        }
        this.setState({ images: allImages });
    }

    async componentWillMount() {
        try {
            await this.topicCover();

            this.setState({ loading: false });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        var gradesSorted = this.props.grades.sort();

        const fetchImage = (index) => {
            try {
                return this.state.images[index];
            } catch (err) {
                return '/images/icons/defaultImage/playlist.png';
            }
        };

        var subtopicDivsList = gradesSorted.map((grade) => {
            var subtopicToList = this.props.subtopics[grade];
            return subtopicToList.map((item, index) => (
                <div key={'LINK_' + item.getId()} style={{ paddingBottom: '10px' }}>
                    <div style={{ display: 'inline-block' }}>
                        <img style={{ borderRadius: '8px' }} src={fetchImage(index)} alt="cover" height="70px" width="70px" />
                    </div>
                    <div
                        style={{
                            display: 'inline-block',
                            paddingLeft: '10px',
                            overflow: 'hidden',
                            maxWidth: '80%',
                            verticalAlign: 'middle',
                            marginTop: '5px',
                        }}
                    >
                        <RsLink key={'LINK_' + item.getId()} to={!this.props.guest && ROUTES.KARAOKE + '?songId=' + item.getFirstSongId()}>
                            <p style={{ fontSize: '20px', overflow: 'hidden' }}>{item.getName()}</p>
                            {parseInt(item.songId.substring(1)) > 200 && (
                                <div style={{ marginTop: '-15px' }}>
                                    <img src="/images/icons/utility/new.png" alt="new_song" />
                                </div>
                            )}
                        </RsLink>
                    </div>
                </div>
            ));
        });
        if (!this.state.loading) {
            return (
                <div>
                    {subtopicDivsList.map((subtopicDiv, index) => {
                        return (
                            <SongNav key={this.props.topicId + '_' + this.props.grades[index] + '_SongNav'}>
                                <p
                                    className="bold"
                                    style={{
                                        marginBottom: '5px',
                                        marginTop: '5px',
                                        fontSize: '14px',
                                    }}
                                >
                                    {this.props.grades[index]}
                                </p>
                                {subtopicDiv}
                            </SongNav>
                        );
                    })}
                </div>
            );
        } else {
            return <div>loading</div>;
        }
    }
}

SubtopicList.propTypes = {
    /**
     * The identifier for the topic
     */
    topicId: PropTypes.string,
    /**
     * List of subtopics
     */
    subtopics: PropTypes.any,
    /**
     * Grades the topic is suitable for
     */
    grades: PropTypes.array,
    /**
     * The color of the bullet
     */
    backgroundColor: PropTypes.string,

    img: PropTypes.array,
};

export default withFirebase(SubtopicList);
