import React, { Component } from 'react';
import styled from 'styled-components';

import ClassNav from '../ClassNav';
import DropdownMenu from '../../../UI-Library/ButtonsOrLinks/DropdownMenu';
import PinFocusModal from '../../../UI-Library/Modals/PinFocusModal';

import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import Tooltip from '../../../UI-Library/Misc/Tooltip';
import ImageInfoBanner from '../../../UI-Library/Containers/ImageInfoBanner';

import { withAlert } from 'react-alert';
import Copy from '../../../UI-Library/Icons/Copy';
import GoogleShareToClassRoom from 'google-classroom-share';

const Row = styled.div`
    display: flex;
`;

const TitleP = styled.h1`
    margin-bottom: 10px;
    @media screen and (max-width: 1090px) {
        font-size: 24px;
    }
`;

const ClassInfo = styled.p`
    margin-bottom: 10px;
    color: var(--rsSecondary);
`;

const ClassDescription = styled.p`
    color: var(--rsSecondary);
`;

const Header = styled.div`
    margin-bottom: 10px;
`;

const IconWrapper = styled.span`
    margin: 0;
    margin-right: 10px;
    height: 32px;
`;

class ClassBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedColor: '',
            showPinModal: false,
        };

        this.copyPinToClipboard = this.copyPinToClipboard.bind(this);
        this.togglePinModal = this.togglePinModal.bind(this);
    }

    copyPinToClipboard() {
        navigator.clipboard.writeText(this.props.pin);
        this.props.alert.show('Pin copied to clipboard.');
    }

    togglePinModal() {
        this.setState({ showPinModal: !this.state.showPinModal });
    }

    render() {
        const MenuItems = [
            {
                desc: 'Choose colors',
                requiredRole: 'Teacher',
                toggleMenuItemAction: () => {
                    this.props.colorPickerToggle();
                },
            },
            {
                desc: 'Edit details',
                requiredRole: 'Teacher',
                toggleMenuItemAction: () => {
                    this.props.toggleEditClassModal();
                },
            },
            {
                desc: 'Delete class',
                requiredRole: 'Teacher',
                toggleMenuItemAction: () => {
                    this.props.deleteClassToggle();
                },
            },
        ];

        return (
            <ImageInfoBanner backgroundColor={this.props.color} imgType={'class'}>
                <div style={{ width: '100%' }}>
                    <Header style={{ display: 'flex', minWidth: 0 }}>
                        <div
                            style={{
                                flexGrow: 1,
                                minWidth: 0,
                                overflow: 'hidden',
                                marginRight: '10px',
                            }}
                        >
                            <ClassInfo>Your Classes</ClassInfo>
                            <TitleP className="bold ellipsisWrap">{this.props.title}</TitleP>
                        </div>

                        {this.props.isAdmin && (
                            <div>
                                <Row
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        overflow: 'wrap',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <IconWrapper>
                                        <Tooltip placement="bottom" text="Share PIN to Google Classroom">
                                            <GoogleShareToClassRoom
                                                body={`To join our rapStudy class, click the 'Join Class' button and type in the pin: ${this.props.pin}.`}
                                                itemType="announcement"
                                                url={`${window.location.origin}/internal/classes`}
                                                title={`Join our rapStudy class "${this.props.title}"!`}
                                                theme="dark"
                                                onShare={(type) => console.log(`GoogleShareToClassRoom:${type}`)}
                                                onShareComplete={() => {
                                                    console.log('GoogleShareToClassRoom:onShareComplete');
                                                    this.props.logAnalyticsPinShare();
                                                }}
                                                onShareStart={() => console.log('GoogleShareToClassRoom:onShareStart')}
                                            />
                                        </Tooltip>
                                    </IconWrapper>
                                    <IconWrapper>
                                        <Tooltip placement="bottom" text="Copy PIN">
                                            <Copy size={'32px'} onClick={this.copyPinToClipboard} />
                                        </Tooltip>
                                    </IconWrapper>

                                    <DropdownMenu direction="left" iconSize="large" menuItems={MenuItems} isTeacher={this.props.userToken.claims.teacher} />
                                </Row>
                                <div>
                                    <RsButton onClick={this.togglePinModal}>{this.props.pin}</RsButton>
                                </div>
                            </div>
                        )}
                    </Header>
                    <ClassDescription className="ellipsisWrap">{this.props.description}</ClassDescription>
                </div>
                <Row className="justify-content-between">
                    <ClassNav classId={this.props.classId} />
                </Row>
                <PinFocusModal show={this.state.showPinModal} toggle={this.togglePinModal} pin={this.props.pin} />
            </ImageInfoBanner>
        );
    }
}

export default withAlert()(ClassBanner);
