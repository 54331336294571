// eslint-disable-next-line no-unused-vars
import * as Scroll from 'react-scroll';

import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

import ExerciseCompletion from '../../../UI-Library/Modals/ExerciseCompletionModal';
import Loading from '../../../UI-Library/Misc/Loading';
import Lrc from '../../../../Classes/Lrc.js';
import ScrollSpan from '../../../UI-Library/AudioOrLyrics/ScrollSpan';
import SelectionHeader from '../../../UI-Library/Features/SelectionHeader';
import SongFeedbackModal from '../../../UI-Library/Modals/SongFeedbackModal';
import TutorialModal from '../../../UI-Library/Modals/TutorialModal';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';

export const Block = styled.div`
    margin-bottom: 3em;
    text-align: left;
`;

const Line = styled.p`
    margin-bottom: 5px;
`;

const MainWrapper = styled.div`
    position: relative;
    margin-top: 30px;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    ${({ fullscreen }) =>
        fullscreen &&
        `
    height: calc(100% - 10vh - 80px);
    margin-top: 5%;
    margin-bottom: 5%;

    display: flex;
    justify-content: left;
    align-items: left;
  `}
`;

const AppWrapper = styled.div`
    height: 70vh;
    margin-top: -30px;

    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }
`;

const Span = styled.span`
    font-size: ${(props) => `${props.fontSize}px` || '32px'};
    font-weight: bold;
`;

Span.displayName = 'Span';

export class KaraokeBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elrc: null,
            ready: false,
            error: null,
            hover: null,
            hasSubmittedFeedback: false,
            showTutorial: false, //this.props.firstKaraoke,
            fontSize: 25,
        };
        this.props.firebase.analytics.logEvent('page_view');
        this.result = null;
        this.currentWord = null;
        this.appWrapper = React.createRef();
        this.completeFeedback = this.completeFeedback.bind(this);
        this.toggleShowTutorial = this.toggleShowTutorial.bind(this);
        this.handleFontSizeChange = this.handleFontSizeChange.bind(this);
    }

    completeFeedback(id, rating, message, isTeacher) {
        this.setState({
            hasSubmittedFeedback: true,
        });
        this.props.firebase.completeFeedback(id, rating, message, isTeacher);
    }

    handleFontSizeChange = (value) => {
        const possibleFontSizes = [20, 22, 25, 28, 30, 32, 36, 38, 40];

        const closestFontSize = possibleFontSizes.reduce((prev, curr) => {
            return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
        });
        this.setState({ fontSize: closestFontSize });
    };

    async componentDidMount() {
        this.props.updateCurrSetId(null, 'Karaoke');
        this.setState({
            ready: true,
            elrc: new Lrc(this.props.json, null),
        });
    }

    componentDidUpdate() {
        if (this.state.ready) {
            if (this.props.play && this.currentWord !== null) {
                var appWrapHeight = this.appWrapper.current.clientHeight;
                var offset = (appWrapHeight / 3) * -1;
                scroller.scrollTo('currentWord', {
                    duration: 100,
                    delay: 0,
                    smooth: true,
                    containerId: 'AppWrapper',
                    // ! need dynamic solution
                    offset: offset,
                });
            }
        }
    }

    getContent() {
        if (!this.state.elrc) return;
        var result = null;
        result = this.state.elrc.blocks.map((blockObj, blockIndex) => {
            return (
                <Block key={blockIndex}>
                    {blockObj.lines.map((lineObj, lineIndex) => {
                        return (
                            <Line key={lineObj.pos}>
                                {lineObj.words.map((wordObj, wordIndex) => {
                                    return this.currentWord === wordObj.numInLrc ? (
                                        <ScrollSpan className="major" id="currentWord" key={wordObj.numInLrc} fontSize={this.state.fontSize}>
                                            {wordObj.word}
                                        </ScrollSpan>
                                    ) : (
                                        <Span key={wordObj.numInLrc} fontSize={this.state.fontSize}>
                                            {wordObj.word}
                                        </Span>
                                    );
                                })}
                            </Line>
                        );
                    })}
                </Block>
            );
        });

        return result;
    }

    toggleShowTutorial() {
        //this.setState({ showTutorial: !this.state.showTutorial });
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        if (!this.state.ready) {
            return <Loading zoom={true} />;
        }
        this.currentWord = this.state.elrc.getCurrentWord(this.props.currentTime);
        this.result = this.getContent();
        return (
            <MainWrapper fullscreen={this.props.fullscreen}>
                <SelectionHeader
                    id={this.props.id}
                    newTitle={this.props.newTitle}
                    fullscreen={this.props.fullscreen}
                    edit={false}
                    fontSizeControl={true}
                    fontSize={this.state.fontSize}
                    handleFontSizeChange={this.handleFontSizeChange}
                    title={this.props.newTitle}
                    topicName={null}
                    userSets={null}
                    createSet={null}
                    handleTitleChange={null}
                    deleteModalToggle={null}
                    discardModalToggle={null}
                    toggleEdit={null}
                    userToken={this.props.userToken}
                    selectSet={this.selectSet}
                    clickFullScreen={this.props.clickFullScreen}
                    featureName={'Karaoke'}
                />
                <AppWrapper id="AppWrapper" fullscreen={this.props.fullscreen} ref={this.appWrapper}>
                    <div style={{ marginTop: '30px' }} id="LyricWrapper">
                        {this.result}
                    </div>

                    {this.props.completedFeedback || this.state.hasSubmittedFeedback ? (
                        <ExerciseCompletion show={this.props.ended} reset={this.props.reset} id={this.props.id} exerciseType="karaoke" />
                    ) : (
                        <SongFeedbackModal
                            show={this.props.ended}
                            reset={this.props.reset}
                            id={this.props.id}
                            completeFeedback={this.completeFeedback}
                            firebase={this.props.firebase}
                            userToken={this.props.userToken}
                        />
                    )}
                </AppWrapper>
                <TutorialModal
                    show={false}
                    feature={'Karaoke'}
                    onHide={this.toggleShowTutorial}
                    firstTime={this.props.firstKaraoke}
                    complete={this.props.completeFirstKaraoke}
                    showCross={false}
                    showFooter={true}
                />
            </MainWrapper>
        );
    }
}

export default withFirebase(KaraokeBase);
