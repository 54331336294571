export async function getRecentSongs(limit) {
    var userDoc = this.db.collection('users').doc(this.auth.currentUser.uid);
    var querySnap = await userDoc.collection('songs').orderBy('lastAccess', 'desc').limit(limit).get();
    if (querySnap.empty) return [];
    var result = querySnap.docs.map((item) => {
        var obj = item.data();
        obj.id = item.id;
        obj.ref = item.ref;
        return obj;
    });
    return result;
}

export async function getNewReleases() {
    const grades = {
        'Pre K': ['Pre K'],
        'Elementary School': ['K', 1, 2, 3, 4, 5],
        'Middle School': [6, 7, 8],
    };
    let userGrades;
    try {
        userGrades = (await this.getUserDoc()).data().grade;
    } catch (e) {
        userGrades = [];
    }
    const isAdministrator = userGrades.includes('Administrator');
    var colRef = this.db.collection('songs');
    var querySnap = await colRef
        .orderBy('uploaded', 'desc')
        .limit(isAdministrator ? 20 : 50)
        .get();
    if (querySnap.empty) return [];
    var result = querySnap.docs.map((item) => {
        var obj = item.data();
        obj.id = item.id;
        obj.ref = item.ref;
        return obj;
    });

    if (isAdministrator) {
        return result;
    } else {
        const filteredResultsPromises = result.map(async (item) => {
            const subtopics = await item.subtopic.subtopicRef.get();
            const subtopicGrade = subtopics.data().grade;
            if (subtopicGrade === undefined) return null;
            for (const grade of userGrades) {
                if (grades[subtopicGrade]?.includes(grade)) return item;
            }
            return null;
        });
        const filteredResults = await Promise.all(filteredResultsPromises);
        return filteredResults.filter((item) => item !== null);
    }
}

export async function getRecommendedBySubject() {
    const userDoc = this.db.collection('users').doc(this.auth.currentUser.uid);
    const recentSongs = await userDoc
        .collection('songs')
        .orderBy('lastAccess', 'desc')
        .limit(50)
        .get()
        .then((res) => res.docs);

    if (recentSongs.length === 0) return [];
    const mostPopularUnit = recentSongs.reduce((acc, song) => {
        const unit = song.data()?.unit?.name || '';
        if (acc[unit]) {
            acc[unit] += 1;
        } else {
            acc[unit] = 1;
        }
        return acc;
    }, {});
    const mostPopularUnitName = Object.keys(mostPopularUnit).reduce((a, b) => (mostPopularUnit[a] > mostPopularUnit[b] ? a : b));
    if (mostPopularUnitName === '') return { subject: null, songs: [] };

    const allSongs = await this.db
        .collection('songs')
        .where('unit.name', '==', mostPopularUnitName)
        .get()
        .then((res) => res.docs);

    const recommendedSongsBySongs = allSongs.map((song) => {
        const data = song.data();
        data.id = song.id;
        data.ref = song.ref;
        return data;
    });

    return { subject: mostPopularUnitName, songs: recommendedSongsBySongs };
}

export async function getRecommendedByArtist() {
    const userDoc = this.db.collection('users').doc(this.auth.currentUser.uid);
    const recentSongs = await userDoc
        .collection('songs')
        .orderBy('lastAccess', 'desc')
        .limit(50)
        .get()
        .then((res) => res.docs);

    if (recentSongs.length === 0) return [];

    const popularArtists = recentSongs.reduce((acc, song) => {
        const artists = song.data()?.origArtist || [];
        artists.forEach((artist) => {
            if (acc[artist]) acc[artist]++;
            else acc[artist] = 1;
        });
        return acc;
    }, {});
    const popularArtist = Object.keys(popularArtists).reduce((a, b) => {
        return popularArtists[a] > popularArtists[b] ? a : b;
    }, '');

    const allSongs = await this.db
        .collection('songs')
        .where('origArtist', 'array-contains', popularArtist)
        .get()
        .then((res) => res.docs);

    const recommendedSongsByArtist = allSongs.map((song) => {
        const data = song.data();
        data.id = song.id;
        data.ref = song.ref;
        return data;
    });

    if (popularArtist === '') return { artist: null, songs: [] };
    return { artist: popularArtist, songs: recommendedSongsByArtist };
}

export async function getRecommendedByGenre() {
    const userDoc = this.db.collection('users').doc(this.auth.currentUser.uid);
    const recentSongs = await userDoc
        .collection('songs')
        .orderBy('lastAccess', 'desc')
        .limit(50)
        .get()
        .then((res) => res.docs);

    if (recentSongs.length === 0) return [];
    const popularGenres = recentSongs.reduce((acc, song) => {
        const genres = song.data()?.genre || [];
        genres.forEach((genre) => {
            if (acc[genre]) {
                acc[genre] += 1;
            } else {
                acc[genre] = 1;
            }
        });
        return acc;
    }, {});
    const mostPopularGenre = Object.keys(popularGenres).reduce((a, b) => {
        return popularGenres[a] > popularGenres[b] ? a : b;
    }, '');
    if (mostPopularGenre === '') return { genre: null, songs: [] };

    const allSongs = await this.db
        .collection('songs')
        .where('genre', 'array-contains', mostPopularGenre)
        .get()
        .then((res) => res.docs);

    const recommendedSongsByGenre = allSongs.map((song) => {
        const data = song.data();
        data.id = song.id;
        data.ref = song.ref;
        return data;
    });

    return { genre: mostPopularGenre, songs: recommendedSongsByGenre };
}

export async function getBanners() {
    const bannerRef = this.db.collection('banners');
    const res = await bannerRef.get();
    const banners = res.docs.map((banner) => banner.data());
    return banners;
}
