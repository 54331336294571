export async function getUserDoc() {
    const userDoc = await this.db.collection('users').doc(this.auth.currentUser.uid).get();
    if (!userDoc.exists) throw this.NotFoundError;
    return userDoc;
}

export async function getUserSchool() {
    const userDoc = await this.db.collection('users').doc(this.auth.currentUser.uid).get();
    if (!userDoc.exists) throw this.NotFoundError;
    const userData = userDoc.data();
    const schoolRef = userData.schoolRef;
    if (!schoolRef) return null;
    const schoolDoc = await schoolRef.get();
    return schoolDoc;
}

export async function getName() {
    var data = await this.db.collection('users').doc(this.auth.currentUser.uid).get();

    if (!data.exists) throw this.NotFoundError;

    var first = data.get('first_name');
    if (first === undefined) throw this.NotFoundError;

    var last = data.get('last_name');
    if (last === undefined) throw this.NotFoundError;

    return first + ' ' + last;
}

export async function getFirstName() {
    var user = await this.db.collection('users').doc(this.auth.currentUser.uid).get();
    if (!user.exists) throw this.NotFoundError;
    const fn = user.get('first_name');
    if (fn === undefined) throw this.NotFoundError;
    return user.get('first_name');
}

export async function editUserInfo(firstName, lastName, grade, subject, color) {
    var gradesResult = Array.from(grade);
    var subjectsResult = Array.from(subject);
    var number = [];
    var gradesAdmin = [];
    gradesResult.forEach((a) => {
        if (typeof a == 'number') number.push(a);
        else gradesAdmin.push(a);
    });
    gradesResult = gradesAdmin.concat(number.sort());
    const userPr = this.db.collection('users').doc(this.auth.currentUser.uid).update({
        first_name: firstName,
        last_name: lastName,
        grade: gradesResult,
        subject: subjectsResult,
        color: color,
    });
    const profilePr = this.db.collection('userProfiles').doc(this.auth.currentUser.uid).update({
        first_name: firstName,
        last_name: lastName,
        color: color,
    });
    await Promise.all([userPr, profilePr]);
    return;
}

/**
 * Returns a Promise that resolves when the user document's lastAccess field
 * has been updated with the latest server time. Fails if the user document
 * does not exist.
 */
export async function updateLastAccess() {
    if (!this.auth.currentUser) return;
    const userRef = this.db.collection('users').doc(this.auth.currentUser.uid);
    await userRef.update({
        lastAccess: this.firebase.firestore.FieldValue.serverTimestamp(),
    });
    return;
}

/**
 * Returns a Promise that resolves with the user's public profile as an object.
 * @param {string} uid
 */
export async function getUserProfile(uid) {
    const snap = await this.db.collection('userProfiles').doc(uid).get();
    const data = snap.data();
    data.uid = uid;
    return data;
}
