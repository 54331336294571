import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding-bottom: 0;
  font-size: 18px;
  font-family: Nunito;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.primary};
  overflow-x: hidden;
}

:root {
  /* THEME COLORS */
  --rsBackground: ${({ theme }) => theme.background};
  --rsPrimary: ${({ theme }) => theme.primary};
  --rsSecondary: ${({ theme }) => theme.secondary};
  --rsTertiary: ${({ theme }) => theme.tertiary};
  --rsDisabled: ${({ theme }) => theme.disabled};
  --rsSurface: ${({ theme }) => theme.surface};
  --rsSurfaceHover: ${({ theme }) => theme.surfaceHover};
  --rsMajor: ${({ theme }) => theme.majorHighlight};
  --rsMinor: ${({ theme }) => theme.minorHighlight};

  /* PRIMARY COLORS */
  --rsBlue: #37dbf6;
  --rsMagenta: #a7225b;
  --rsBlueRGB: 55, 219, 246;
  --rsMagentaRGB: 167, 34, 91;

  /* SECONDARY COLORS */
  --rsOrange: #f06449;
  --rsLightBlue: #aed4e6;
  --rsPeach: #f9bfa6;
  --rsYellow: #ffe99b;

  /* UTILITY COLORS */
  --rsForm: rgba(160, 194, 209, 0.3);
  --rsDropdown: #323232;
}

/* TYPOGRAPHY */
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 1.14em;
}
p {
}


#root {
  height: 100%;
}

.bold {
  font-weight: bold;
}

.major {
  font-weight: bold;
  color: var(--rsMajor);
}

.secondary {
  color: var(--rsSecondary);
}

.ellipsisWrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

hr {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

textarea,
input,
select {
  font-family: inherit;
  font-size: inherit;
}

/* BUTTONS */

button {
  outline: none !important;
  font-family: inherit;
  font-size: inherit;
}

.btn {
  border: 0;
}

.hidden {
  display: none;
}

.underlineOnHover:hover {
  text-decoration: underline;
}

/* ======== LINKS =============================== */

.noDecoration {
  text-decoration: none !important;
}

.vertNav-link:hover {
  color: var(--rsPrimary) !important;
  transition: all 0.3s ease 0s;
}

.link:hover {
  text-decoration: underline;
}

a {
  text-decoration-color: white !important;
}

.linkChoice:hover {
  border-bottom: 2px solid rgba(var(--rsBlueRGB), 0.3) !important;
}

.linkChoice {
  border-bottom: 2px solid #000000 !important;
  padding-top: 3px;
}


.selectedLink {
  background-color: black;
  padding: 3px;
  box-shadow: 0 0 15px #37dbf6;
  border-radius: 40px;
  padding-left: 15px;
}


/* ======== BOOTSTRAP NECESSARY STYLING ========= */
.border-radius-16 div {
  border-radius: 16px;
  border: 0;
  background-color: #121212;
}

.border-radius-16-wide {
  border-radius: 16px;
  border: 0;
  width: 60vw !important;
  max-width: 800px !important;
  height: 40vh !important;
  max-height: 500px !important;
}

.border-radius-16-wide div {
  border-radius: 16px;
  border: 0;
  background-color: #121212;
}

.border-radius-16-full {
  border-radius: 16px;
  border: 0;
  width: 60vw !important;
  max-width: 800px !important;
  height: auto !important;
  max-height: calc(100vh - 40px) !important;
}

.border-radius-16-full div {
  border-radius: 16px;
  border: 0;
  background-color: #121212;
}

@media screen and (max-width: 800px) {
  .border-radius-16 div {
    border-radius: 16px;
    border: 0;
    background-color: #121212;
    margin-right: 5px;
    margin-left: 5px;
  }
}

.modal-dialog {
  max-width: 450px;
  margin: 1.75em auto;
}

.modal-content {
  box-shadow: 5px 5px 12px black;
}

.modal-backdrop {
  width: 300vh !important;
  height: 300vh !important;
}

.modal-close-button {
  background: none;
  border: 0;
  outline: none;
}

.modal-close-button:focus {
  outline: none;
}

.modal-close-svg {
  height: 16px;
  width: 16px;
  outline: none;
}

/* ======== FOR FEATURES =============================== */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}

.unselectable span {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.fillVW {
  width: 100%;
  height: 100%;
}

.noPadMar {
  margin: 0 !important;
  padding: 0 !important;
}

.albumCover {
  height: 180px;
  width: 180px;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 5px 12px #222222;
}

.custom-scrollbars > div:last-child {
  opacity: 0;
  transition: opacity 200ms ease;
  overflowX : hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-scrollbars:hover > div:last-child {
  opacity: 0;
}

.playlistAndLib::-webkit-scrollbar { display: none; }

.chooseImage-track-horizontal {
  position: absolute;
  height: 6px;
  right: 2px;
  bottom: 0px;
  left: 2px;
  border-radius: 3px;
}

.ghost {
  border: none;
  outline: none;
  background: none;
}

`;
