class SubjectDict {
    constructor(id, ref) {
        this.id = id;
        this.ref = ref;
    }
    /**
     * Gets id of the subject reference
     * @return {string}
     */
    getId() {
        return this.id;
    }

    /**
     * Gets the reference of this subject.
     * @return {string}
     */
    getRef() {
        return this.ref;
    }

    static subjectConverter = {
        fromFirestore: (snapshot, options) => {
            return new SubjectDict(snapshot.id, snapshot.ref);
        },
    };
}

export default SubjectDict;
