import React, { Component } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RsInput from '../../../UI-Library/Forms/RsInput';
import DateTimePicker from 'react-datetime-picker';

import './calendar.css';
import './datepicker.css';

const MainWrapper = styled.div`
    width: 100%;
`;

const Header = styled.h2`
    margin-bottom: 5px;
`;

const StyledRow = styled(Row)`
    margin-bottom: 20px;
`;

class CreateAssignmentDetails extends Component {
    render() {
        return (
            <MainWrapper>
                <Header className="bold">Assignment Details:</Header>
                <p className="secondary" style={{ fontSize: '14px', marginBottom: '30px' }}>
                    After creating the assignment, you can change the due date and release date on the assignment page, but you cannot change the exercises that
                    you've added.
                </p>
                <StyledRow>
                    <Col xs={2}>Assignment Name</Col>
                    <Col xs={5}>
                        <RsInput value={this.props.assignmentName} onChange={this.props.handleNameChange} />
                    </Col>
                </StyledRow>
                <StyledRow>
                    <Col xs={2}>Description</Col>
                    <Col xs={5}>
                        <RsInput textarea={true} value={this.props.description} onChange={this.props.handleDescChange} />
                    </Col>
                </StyledRow>
                <StyledRow>
                    <Col xs={2}>Release Date</Col>
                    <Col xs={5}>
                        <DateTimePicker
                            value={this.props.releaseDate}
                            onChange={this.props.changeReleaseDate}
                            disableClock={true}
                            minDate={new Date()}
                            calendarIcon={null}
                            clearIcon={null}
                            className="surfaceBackground"
                            calendarClassName="surfaceBackground"
                        />
                    </Col>
                </StyledRow>
                <StyledRow>
                    <Col xs={2}>Due Date</Col>
                    <Col xs={5}>
                        <DateTimePicker
                            value={this.props.dueDate}
                            onChange={this.props.changeDueDate}
                            disableClock={true}
                            minDate={new Date()}
                            calendarIcon={null}
                            clearIcon={null}
                            className="surfaceBackground"
                            calendarClassName="surfaceBackground"
                        />
                    </Col>
                </StyledRow>
                <p style={{ fontSize: '14px', color: 'red' }}>{this.props.assertedDates.msg}</p>
            </MainWrapper>
        );
    }
}

export default CreateAssignmentDetails;
