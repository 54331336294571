import * as ROUTES from '../../../constants/routes';
import * as React from 'react';

import AlbumCover from '../../UI-Library/ImageContainers/AlbumCover';
import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';
import { Link } from 'react-router-dom';
import RsInput from '../../UI-Library/Forms/RsInput';
import SimpleBanner from '../../UI-Library/Containers/SimpleBanner';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';

const BrowseHeader = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    color: white;
`;

function BrowseArtists(props) {
    const [artists, setArtists] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [partition, setPartition] = React.useState([]); //partitions artists by genre in form [(genre, artists)]

    React.useEffect(() => {
        const fetchArtists = async () => {
            const artists = await props.firebase.getArtists();
            setArtists((arr) => artists);
            sortByGenre(artists, search);
        };
        fetchArtists();
    }, [search, props.firebase]);

    const handleInputChange = (e) => {
        e.persist();
        setSearch(() => e.target.value);
        sortByGenre(artists, e.target.value);
    };

    const sortByGenre = (artists, filter) => {
        const partition = {};
        const genres = [];
        //DIVIDES ARTISTS BY GENRE
        artists.forEach((artist) => {
            if (genres.includes(artist?.genre)) {
                if (artist?.name.toLowerCase().includes(filter.toLowerCase())) {
                    partition[artist?.genre].push(artist);
                }
            } else {
                genres.push(artist?.genre);
                if (artist?.name.toLowerCase().includes(filter.toLowerCase())) {
                    partition[artist?.genre] = [artist];
                } else {
                    partition[artist?.genre] = [];
                }
            }
        });

        setPartition(() => Object.entries(partition));
    };

    return (
        <>
            <SimpleBanner color={'#2E2E2E'}>
                <BackArrow />
                <BrowseHeader style={{ paddingBottom: '20px' }}>
                    <div style={{ display: 'inline-block', paddingRight: '40px' }}>Artists</div>
                    <div style={{ display: 'inline-block', color: '#bebeb6' }}>
                        |
                        <Link
                            to={ROUTES.BROWSE_SUBJECTS}
                            style={{
                                textDecoration: 'none',
                                color: '#bebeb6',
                                justifyContent: 'space-between',
                                paddingLeft: '40px',
                            }}
                        >
                            Subjects
                        </Link>
                    </div>
                </BrowseHeader>
                <RsInput value={search} onChange={(e) => handleInputChange(e)} width="60%" placeholder={'Search For Your Favorite Artist!'} />
            </SimpleBanner>
            <div style={{ marginLeft: '45px' }}>
                {partition.map((genre) => {
                    if (genre[1].length > 10) {
                        return (
                            <>
                                <AlbumCover items={genre[1].slice(0, genre[1].length / 2)} itemType={'artist'} header={genre[0]} />
                                <AlbumCover items={genre[1].slice(genre[1].length / 2)} itemType={'artist'} />
                            </>
                        );
                    }
                    return genre[1].length ? <AlbumCover items={genre[1]} itemType={'artist'} header={genre[0]} /> : null;
                })}
            </div>
        </>
    );
}

export default withFirebase(BrowseArtists);
