import React, { Component } from 'react';
class PlayButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }
    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    renderPath(color) {
        return (
            <svg width="28" height="28" viewBox="0 0 28 28" fill={this.state.hover ? 'var(--rsBlue)' : 'none'} xmlns="http://www.w3.org/2000/svg">
                <circle cx="14" cy="14" r="13.5" stroke="#37DBF6" />
                <path d="M12 17.9214L16.0071 14.2785L12 11" stroke={color} stroke-width="2.18571" stroke-linecap="round" />
            </svg>
        );
    }

    render() {
        // const hover =
        //   typeof this.props.hover !== 'undefined'
        //     ? this.props.hover
        //     : this.state.hover;

        const color = this.state.hover ? 'white' : 'var(--rsBlue)';

        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0',
                    marginLeft: '0px',
                }}
            >
                {this.renderPath(color)}
            </div>
        );
    }
}
export default PlayButton;
