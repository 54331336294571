import React from 'react';

export default function BlueCheck() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM14.0556 5.15556C13.9696 5.06965 13.8304 5.06965 13.7444 5.15556L12.25 6.65L10.6 8.3L8.00556 10.8944C7.91965 10.9804 7.78035 10.9804 7.69444 10.8944L7.3 10.5L5.25556 8.45556C5.16965 8.36965 5.03035 8.36965 4.94444 8.45556L4.15556 9.24444C4.06965 9.33035 4.06965 9.46965 4.15556 9.55556L6.2 11.6L7.69444 13.0944C7.78035 13.1804 7.91965 13.1804 8.00556 13.0944L10.05 11.05L12.25 8.85L14.8444 6.25556C14.9304 6.16965 14.9304 6.03035 14.8444 5.94444L14.45 5.55L14.0556 5.15556Z"
                fill="#37DBF6"
            />
        </svg>
    );
}
