import React, { Component } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-height: ${(props) => (props.minHeight ? props.minHeight : '52px')};
    color: black;
    font-weight: bold;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: gray;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: gray;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: gray;
    }
    &:focus {
        outline: none;
    }
`;

const StyledArea = styled.textarea`
    border: 1px solid black;
    margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-height: ${(props) => (props.minHeight ? props.minHeight : '52px')};
    color: black;
    font-weight: bold;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: gray;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: gray;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: gray;
    }
`;

class ContactInput extends Component {
    render() {
        if (this.props.textarea) {
            return (
                <StyledArea
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    mb={this.props.mb}
                    minHeight={this.props.minHeight}
                    value={this.props.value}
                    onChange={this.props.onChange}
                />
            );
        }

        return (
            <StyledInput
                name={this.props.name}
                placeholder={this.props.placeholder}
                mb={this.props.mb}
                minHeight={this.props.minHeight}
                value={this.props.value}
                onChange={this.props.onChange}
            />
        );
    }
}

export default ContactInput;
