import KeywordDict from '../../Classes/KeywordDict';
import Keyword from '../../Classes/Keyword';

export async function getKeywordSetsForSong(songId) {
    const keywordsRef = this.db.collection('keywordSets');
    const querySnap = await Promise.all([
        keywordsRef.where('author', '==', this.auth.currentUser.uid).where('songId', '==', songId).orderBy('lastModified', 'desc').get(),
        keywordsRef.where('author', '==', process.env.REACT_APP_RAPSTUDY_UID).where('songId', '==', songId).orderBy('lastModified', 'desc').get(),
    ]);

    const mergedSnapshots = querySnap.reduce((acc, snap) => acc.concat(snap.docs), []);
    if (mergedSnapshots.length === 0) return [];

    return mergedSnapshots.map((docSnap) => {
        const obj = docSnap.data();
        obj.setId = docSnap.id;
        return obj;
    });
}

/**
 * Creates a new document for keyword sets content
 * @param {string} songId
 * @param {string} name
 * @return {string} Firestore id of the set document.
 */
export async function createCustomKeywords(songId, name, unused) {
    // ? Why the extra parameter?
    const keywordsRef = this.db.collection('keywordSets');
    const setRef = await keywordsRef.add({
        name: name,
        created: this.firebase.firestore.FieldValue.serverTimestamp(),
        lastModified: this.firebase.firestore.FieldValue.serverTimestamp(),
        songId: songId,
        author: this.auth.currentUser.uid,
    });
    //Log in event 'set_created' [Firebase Analytics]
    this.analytics.logEvent('set_created', {
        feature: 'blanks',
    });
    return setRef.id;
}

/**
 * Deletes the given keyword set document.
 * @param {string} songId
 * @param {string} set_id
 */
export async function deleteCustomKeywords(songId, set_id) {
    const deleteKeywordSet = this.functions.httpsCallable('featureSets-deleteFeatureSet');
    await deleteKeywordSet({ setId: set_id, featureName: 'Blanks' });
}

/**
 * Updates an existing keyword set's content
 * @param {string} set_id
 * @param {KeywordDict} keywordDict
 */
export async function updateCustomKeywords(set_id, keywordDict) {
    if (!set_id || !keywordDict) {
        throw Error('Update keyword called with invalid parameters.');
    }

    const setRef = this.db.collection('keywordSets').doc(set_id);
    const keywordsRef = setRef.collection('keywords');

    const query = await keywordsRef.get();
    const kDocs = query.docs;
    const firebaseRefs = kDocs.map((doc) => {
        return doc.ref;
    }); // array of firebase document references

    // 1. delete refs in firestore but not in local
    await Promise.all(
        firebaseRefs.map(async (ref) => {
            if (!keywordDict.hasId(ref.id)) {
                await ref.delete();
            }
        }),
    );

    // 2. for all local refs, set to create new OR overwrite
    const keywords = keywordDict.getKeywords();
    await Promise.all(
        Object.values(keywords).map(async (keyword) => {
            await keywordsRef.doc(keyword.getId()).withConverter(Keyword.keywordConverter).set(keyword);
        }),
    );

    // 3. update name and numItems
    await setRef.update({
        name: keywordDict.getName(),
        numItems: keywordDict.getLength(),
        lastModified: this.firebase.firestore.FieldValue.serverTimestamp(),
    });
    return;
}

/**
 */
export async function getKeywordDict(set_id) {
    let keywordDict = null;
    if (set_id !== 'null') {
        const setRef = this.db.collection('keywordSets').doc(set_id);

        const setPr = setRef.get();
        const keywordsPr = setRef.collection('keywords').withConverter(Keyword.keywordConverter).get();

        keywordDict = new KeywordDict();
        const resources = await Promise.all([setPr, keywordsPr]);
        const setSnap = resources[0];
        if (!setSnap.exists) throw this.NotFoundError;
        const keywordDocs = resources[1].docs;
        keywordDocs.forEach((keyword) => {
            keywordDict.addKeywordInstance(keyword.data());
        });

        // Metadata handling
        const name = setSnap.get('name');
        const authorId = setSnap.get('author');
        const authorProfileSnap = await this.db.collection('userProfiles').doc(authorId).get();
        const authorName = authorProfileSnap.get('first_name') + ' ' + authorProfileSnap.get('last_name');

        keywordDict.setName(name);
        keywordDict.setAuthorId(authorId);
        keywordDict.setAuthorName(authorName);
        keywordDict.setSongId(setSnap.get('songId'));
    }
    return keywordDict;
}
