import React, { Component } from 'react';

import { Collapse } from 'react-bootstrap';
import CreatePlaylistToggle from '../../../UI-Library/Modals/CreatePlaylistToggle';
import { NavLink } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';

const PlaylistHeader = styled.p`
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0;
    color: var(--rsTertiary);
    font-size: 14px;
    transition: color 0.3s ease;
    &:hover {
        color: var(--rsPrimary);
    }
`;

const PlaylistLink = {
    verticalAlign: 'middle',
    color: 'white', //"var(--rsSecondary)",
    textDecoration: 'none',
    marginLeft: '0px',
    marginTop: '5px',
    marginBottom: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    width: '80%',
    fontSize: '14px',
};

const Toggler = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const MainWrapper = styled.div`
    margin-left: 10px;
`;

/**
 * YourPlaylists component is simply the component for the title of
 * the playlists section in the vertical nav bar.
 */
class YourPlaylists extends Component {
    render() {
        return (
            <div style={{ marginLeft: '0px' }}>
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <Toggler className="noPadMar ghost" onClick={this.props.toggle} aria-controls="playlists" aria-expanded={this.props.open}>
                        {/* <img src="/images/icons/utility/user.png" alt="pl" /> */}
                        <PlaylistHeader style={{ color: '#37DBF6', marginLeft: '0px', fontSize: '15px' }}>Playlists</PlaylistHeader>
                        {/* <Caret
            src="/images/icons/utility/caretgrey.png"
            open={this.props.open}
          ></Caret> */}
                    </Toggler>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '-5px', marginLeft: '8px' }}>
                    <CreatePlaylistToggle in={this.props.open} id="playlists" willRedirect={true} className="noPadMar ghost" />
                </div>
            </div>
        );
    }
}

/**
 * Playlists is the component for the "Playlist" <ul> in VerticalNav. It contains
 * a list of the user custom playlists. Uses the firebase function
 * getPlaylists to get the list of playlist names.
 */
class PlaylistsBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
        this.toggle = this.toggle.bind(this);
        this.renderLinks = this.renderLinks.bind(this);
    }

    toggle() {
        this.setState({ open: !this.state.open });
    }

    renderLinks() {
        if (!this.props.playlists) {
            return null;
        }
        const playlistDivs = this.props.playlists.map(function (playlist, index) {
            return (
                <NavLink className="navitem vertNav-link" style={PlaylistLink} key={index} to={'/internal/playlistView/' + playlist.playlistId}>
                    {playlist.name}
                </NavLink>
            );
        });
        return playlistDivs;
    }

    render() {
        return (
            <MainWrapper>
                <YourPlaylists open={this.state.open} toggle={this.toggle} />
                <Collapse in={this.state.open} id="playlists">
                    <div style={{}}>
                        <Scrollbars onScroll={this.handleScroll} style={{ width: 160, height: 80 }}>
                            {this.renderLinks()}
                        </Scrollbars>
                    </div>
                </Collapse>
                {/* <div style={{ paddingTop: "20px" }}>
          <CreatePlaylistToggle
            in={this.state.open}
            id="playlists"
            willRedirect={true}
            className="noPadMar ghost"
          />
        </div> */}
            </MainWrapper>
        );
    }
}

const Playlists = withFirebase(PlaylistsBase);

export default Playlists;
