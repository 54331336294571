import React, { Component } from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import Person from '../../../UI-Library/Icons/Person';
import Checklist from '../../../UI-Library/Icons/Checklist';

import PreviewCard from '../../../UI-Library/Containers/PreviewCard';

const ClassInfo = styled.p`
    color: var(--rsSecondary);
    margin: 0;
`;

const IconWrapper = styled.div`
    margin-right: 13px;
`;

const FlexMiddle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

const ClassPinContainer = styled.div`
    display: flex;
    align-items: center;
    color: var(--rsSecondary);
    float: right;
    margin-top: 53px;
    magin-bottom: 0px;
`;

const ClassPin = styled.div`
    color: var(--rsSecondary);
    margin: 0;
    margin-right: 10px;
`;

class ClassPreviewCard extends Component {
    constructor(props) {
        super(props);
        this.determineGoToClassPage = this.determineGoToClassPage.bind(this);
    }

    copyPinToClipboard() {
        navigator.clipboard.writeText(this.props.classInfo.pin);
        this.props.alert.show('Pin copied to clipboard.');
    }

    determineGoToClassPage(e) {
        this.props.history.push('/internal/classView/' + this.props.classInfo.classId + '/sharedContent');
    }

    render() {
        const assignmentsFollowed = this.props.assignmentsFollowed;
        const activeAssignments = this.props.activeAssignments;
        const isTeacher = this.props.userToken.claims.teacher;
        let todoAssignments = 0;
        if (!isTeacher && assignmentsFollowed) {
            assignmentsFollowed.forEach((assignment) => {
                if (
                    assignment.classId === this.props.classInfo.classId &&
                    assignment.releaseDate.toDate() < new Date() &&
                    assignment.dueDate.toDate() > new Date() &&
                    !assignment.complete
                ) {
                    todoAssignments++;
                }
            });
        } else if (activeAssignments) {
            activeAssignments.forEach((assignment) => {
                if (assignment.classId === this.props.classInfo.classId) {
                    todoAssignments++;
                }
            });
        }
        return (
            <PreviewCard onClick={this.determineGoToClassPage} color={this.props.classInfo.color} defaultImgType="class" title={this.props.classInfo.title}>
                <div>
                    <FlexMiddle>
                        <IconWrapper>
                            <Person size={'18px'} hover={false} />
                        </IconWrapper>
                        <ClassInfo>{this.props.classInfo.totalStudents} students</ClassInfo>
                    </FlexMiddle>
                    <FlexMiddle>
                        <IconWrapper>
                            <Checklist size={'18px'} hover={false} />
                        </IconWrapper>

                        <ClassInfo>{!isTeacher ? `${todoAssignments} incomplete assignments` : `${todoAssignments} active assignments`}</ClassInfo>
                    </FlexMiddle>

                    <ClassPinContainer>
                        <ClassPin>PIN: {this.props.classInfo.pin}</ClassPin>
                    </ClassPinContainer>
                </div>
            </PreviewCard>
        );
    }
}

export default withAlert()(withRouter(ClassPreviewCard));
