import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pencil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const hover = typeof this.props.hover !== 'undefined' ? this.props.hover : this.state.hover;

        const color = this.props.disabled ? 'var(--rsDisabled)' : hover ? 'var(--rsPrimary)' : 'var(--rsSecondary)';
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg
                    width={this.props.size ? this.props.size : '24px'}
                    height={this.props.size ? this.props.size : '24px'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                >
                    <polyline
                        points="15.26 6.32 6.75 14.84 3.16 11.25 11.68 2.74"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <polygon
                        points="1.89 16.11 3.19 11.27 6.73 14.81 1.89 16.11"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        d="M11.46,3c1.23-1.23,3-1.42,4-.43s.8,2.79-.43,4Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </svg>
            </div>
        );
    }
}

Pencil.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default Pencil;
