import React, { Component } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

//import Button from "@material-ui/core/Button";

const MainWrapper = styled.div`
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    height: 225px;
    ${(props) =>
        props.src
            ? `
    background: url(${props.src}), rgba(0,0,0,0.75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 47%;
    `
            : `
    background: linear-gradient(
    0deg,
    var(--rsBackground) 0%,
    ${props.backgroundColor} 100%
    );
  `}
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const Lead = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
`;

const Secondary = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
`;

const Description = styled.p`
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
`;

/**
 * A basic banner to go at the top of pages, with the ability to render child
 * elements inside of it and/or a standard title. At this time, the default
 * color is rapStudy blue.
 *
 */
class SimpleBanner extends Component {
    render() {
        var bannerStyle = {};
        if (this.props.height) {
            bannerStyle = {
                height: this.props.height,
            };
        }
        return (
            <MainWrapper backgroundColor={this.props.color ? this.props.color : 'var(--rsBlue)'} src={this.props.src} style={bannerStyle}>
                {this.props.link}
                <div style={{ display: 'inline-flex' }}>
                    {this.props.image && <img src={this.props.image} height="150px" width="150px" alt="" style={{ marginRight: '20px' }} />}
                    {this.props.icons && (
                        <img src={this.props.icons} height="45px" width="45px" alt="" style={{ position: 'absolute', left: '17%', top: '70%' }} />
                    )}
                    <div>
                        {this.props.icons && (
                            <img src={this.props.icons} height="45px" width="45px" alt="" style={{ position: 'absolute', left: '27%', top: '15%' }} />
                        )}
                        {this.props.icons && (
                            <img src={this.props.icons} height="45px" width="45px" alt="" style={{ position: 'absolute', left: '73%', top: '45%' }} />
                        )}
                        {this.props.lead && <Lead>{this.props.lead}</Lead>} {this.props.sub && <Secondary className="secondary">{this.props.sub}</Secondary>}
                        {this.props.icons && (
                            <img src={this.props.icons} height="45px" width="45px" alt="" style={{ position: 'absolute', left: '59%', top: '19%' }} />
                        )}
                        {this.props.description && <Description>{this.props.description}</Description>}
                    </div>
                    {this.props.icons && (
                        <img src={this.props.icons} height="45px" width="45px" alt="" style={{ position: 'absolute', left: '43%', top: '65%' }} />
                    )}
                </div>

                {this.props.children}
            </MainWrapper>
        );
    }
}

SimpleBanner.propTypes = {
    /**
     * The optional title.
     */
    lead: PropTypes.string,
    /**
     * The optional subtitle.
     */
    sub: PropTypes.string,
    /**
     * The background color. Defaults to rapStudy Blue.
     */
    color: PropTypes.string,
    /**
     * The background image source. If defined, color is not used.
     */
    src: PropTypes.string,
};

export default SimpleBanner;
