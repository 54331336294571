import React, { Component } from 'react';
import BlanksView from '../../../../UI-Library/Features/BlanksView';

class KeywordsSetView extends Component {
    constructor(props) {
        super(props);

        this.setPassed = this.setPassed.bind(this);
        this.giveUp = this.giveUp.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        // Failsafe to check for an all passed but not submitted set.
        const keywordDict = this.props.blanksSubmission;
        if (!keywordDict || keywordDict.getStatus() === 'complete') return;
        const isComplete = keywordDict.countPassed() === keywordDict.getLength();
        if (isComplete) {
            this.props.submitExercise(keywordDict);
            return;
        }
    }

    setPassed(numInLrc, makeCorrect) {
        const keywordDict = this.props.blanksSubmission;
        keywordDict.setPassed(numInLrc);
        if (makeCorrect) {
            // Then we are in standard mode, and we end the exercise if all keywords are passed.
            keywordDict.makeCorrect(numInLrc);
            const isComplete = keywordDict.countPassed() === keywordDict.getLength();
            if (isComplete) {
                setTimeout(() => {
                    this.props.pause();
                    this.props.submitExercise(keywordDict);
                }, 500);
                return;
            }
        }
        this.props.saveSubmissionAndRerender(keywordDict, false);
    }

    giveUp(numInLrc) {
        const keywordDict = this.props.blanksSubmission;
        keywordDict.setPassed(numInLrc);

        const isComplete = keywordDict.countPassed() === keywordDict.getLength();
        if (!isComplete) {
            setTimeout(() => {
                this.props.playOrPause();
            }, 500);
        } else {
            setTimeout(() => {
                this.props.pause();
                this.props.submitExercise(keywordDict);
            }, 500);
            return;
        }
        this.props.saveSubmissionAndRerender(keywordDict, false);
    }

    onSelect(numInLrc, word) {
        const keywordDict = this.props.blanksSubmission;
        const maxAttempts = keywordDict.getMaxAttempts();
        const kObj = keywordDict.getKeyword(numInLrc);
        const correct = keywordDict.makeAttempt(numInLrc, word);
        if (kObj.isCorrect() || kObj.getAttempts() === maxAttempts) {
            keywordDict.setPassed(numInLrc);
            const isComplete = keywordDict.countPassed() === keywordDict.getLength();
            if (!isComplete) {
                setTimeout(() => {
                    this.props.playOrPause();
                }, 500);
            } else {
                setTimeout(() => {
                    this.props.pause();
                    this.props.submitExercise(keywordDict);
                }, 500);
                return correct;
            }
        }
        this.props.saveSubmissionAndRerender(keywordDict, false);
        return correct;
    }

    render() {
        const { blanksSubmission, lrc, currentTime, play, playOrPause, pause } = this.props;
        const currentWord = lrc.getCurrentWord(currentTime);
        return (
            <BlanksView
                elrc={lrc}
                keywordDict={blanksSubmission}
                currentWord={currentWord}
                play={play}
                pause={pause}
                setPassed={this.setPassed}
                onSelect={this.onSelect}
                giveUp={this.giveUp}
                playOrPause={playOrPause}
                mode={blanksSubmission.getMode()}
            />
        );
    }
}

export default KeywordsSetView;
