import React from 'react';
import styled from 'styled-components';

const SliderWrapper = styled.div`
    height: 300px;
    width: 50px;
    margin-top: 20px;
    justify-content: center;
    position: absolute;
    right: ${(props) => (props.right ? props.right : '30.5px')};
    top: ${(props) => (props.top ? props.top : '0px')};
`;

const TriangleTrack = styled.div`
    position: absolute;
    height: 100%;
    width: 50%;
    background: linear-gradient(to top, transparent, #2e2e2e);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    z-index: 1;
`;

const SliderBall = styled.div`
    position: absolute;
    bottom: ${(props) => props.bottom}%;
    left: 25%;
    width: 25px;
    height: 25px;
    z-index: 2;
    border-radius: 50%;
    background-color: #ffffff;
    transform: translateX(-50%);
`;

const Slider = styled.input`
  -webkit-appearance: slider-vertical;
  height: 100%;
  width: 50%;  
  opacity: 0.001;
  position: relative;
  z-index: 3;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &::-ms-thumb {
    display: none;
  }
  &::-moz-range-thumb {
    display: none;
  }
}`;

class FontSizeControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = { fontSize: this.props.fontSize };
    }

    handleChange = (event) => {
        this.setState({ fontSize: event.target.value });
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    };

    render() {
        const { fontSize } = this.state;
        const percentage = Math.max(0, ((fontSize - 20) / (40 - 20)) * 100 - 5);

        return (
            <SliderWrapper right={this.props.right} top={this.props.top}>
                <TriangleTrack />
                <SliderBall bottom={percentage} />
                <Slider type="range" min="20" max="40" value={fontSize} onChange={this.handleChange} />
            </SliderWrapper>
        );
    }
}

export default FontSizeControl;
