import React, { Component } from 'react';
import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';
import SongCard from '../../../UI-Library/ItemDisplays/SongCard';
import ExerciseThumbnail from '../../../UI-Library/ItemDisplays/ExerciseThumbnail';
import StudySet from '../../../../Classes/StudySet';
import TextToggler from '../../../UI-Library/ButtonsOrLinks/TextToggler';
import DropdownMenu from '../../../UI-Library/ButtonsOrLinks/DropdownMenu';
import RsLink from '../../../UI-Library/ButtonsOrLinks/RsLink';
import AssignmentModeInfoModal from '../../../UI-Library/Modals/AssignmentModeInfoModal';
import { capitalizeFLetter } from '../../../../utility/stringFunctions';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragHandle from '../../../UI-Library/Icons/DragHandle';

const MainWrapper = styled.div`
    width: 100%;
`;

const Header = styled.h2`
    margin-bottom: 20px;
    margin-top: 20px;
`;

const ThumbnailSet = styled.div`
    margin-bottom: 40px;
`;

const ThumbnailWrapper = styled.div`
    margin-right: 20px;
    margin-bottom: 20px;
`;

const Dragger = SortableHandle(() => (
    <span>
        <DragHandle />
    </span>
));

const SortableItem = SortableElement(({ thumbnail }) => thumbnail);

const SortableList = SortableContainer(({ thumbnails }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {thumbnails.map((thumbnail, index) => {
                return <SortableItem thumbnail={thumbnail.jsx} key={thumbnail.key} index={index} />;
            })}
        </div>
    );
});

class CreateAssignmentSets extends Component {
    constructor(props) {
        super(props);
        this.toggleInfo = this.toggleInfo.bind(this);
        this.state = {
            showInfo: false,
        };
    }

    toggleInfo() {
        this.setState({ showInfo: !this.state.showInfo });
    }

    render() {
        const customSetDict = this.props.customSetDict;
        const songSetDicts = customSetDict.getSortedSongListByRecent();
        const selectedSets = customSetDict.getSelectedSetsArr();
        const flattenedJSX = selectedSets.map((studySet, index) => {
            const songSetDict = customSetDict.getSongSetsDict(studySet.getSongId());
            const songData = songSetDict.getSongData();
            return {
                jsx: (
                    <ThumbnailWrapper key={'EXTHUMB_' + studySet.getSetId()}>
                        <ExerciseThumbnail
                            backgroundColor="000000" // unused prop
                            featureName={studySet.getFeatureName()}
                            onClick={() => null} // unused prop
                            setId={studySet.getSetId()}
                            songId={studySet.getSongId()}
                            setName={studySet.getName()}
                            songName={songData.subtopic.name}
                            active={false}
                            clickable={false}
                            src={songData.src}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingRight: '5px',
                                }}
                            >
                                <DropdownMenu
                                    menuItems={StudySet.featuresAndModes[studySet.getFeatureName()].map((mode) => {
                                        return {
                                            toggleMenuItemAction: () => this.props.handleSelectMode(studySet.getSetId(), mode),
                                            requiredRole: 'Both',
                                            desc: capitalizeFLetter(mode),
                                        };
                                    })}
                                    isTeacher={true}
                                    direction={'center'}
                                >
                                    <TextToggler clickable={true}>
                                        <span style={{ color: 'white' }}>{capitalizeFLetter(studySet.getMode())}</span>
                                        <img src="/images/icons/utility/caretgrey.png" style={{ marginLeft: '5px' }} alt="" />
                                    </TextToggler>
                                </DropdownMenu>
                                <Dragger />
                            </div>
                        </ExerciseThumbnail>
                    </ThumbnailWrapper>
                ),
                key: studySet.getSetId(),
            };
        });

        return (
            <MainWrapper>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '20px',
                    }}
                >
                    <h2 className="bold">Sets added:</h2>
                    <RsLink onClick={this.toggleInfo} secondary={true} small={true}>
                        What are these options for my study sets?
                    </RsLink>
                </div>

                <ThumbnailSet>
                    {flattenedJSX.length === 0 ? (
                        <p className="secondary">
                            Nothing here yet! Select study sets you want to copy into your assignment from below, by clicking on a song, then toggling a
                            checkbox. After creating the assignment, you{' '}
                            <span className="bold">cannot change the study sets or edit the questions within them.</span>
                        </p>
                    ) : (
                        <SortableList useDragHandle axis="xy" thumbnails={flattenedJSX} onSortEnd={this.props.reorder} />
                    )}
                </ThumbnailSet>
                <Header className="bold">Available Study Sets:</Header>

                <Accordion>
                    {songSetDicts.map((song, index) => {
                        return (
                            <SongCard
                                key={'card_' + song.songId}
                                song={song}
                                index={index}
                                showCreate={false}
                                showDropdown={false}
                                showRemove={false}
                                showCheckbox={true}
                                onSelectCheckbox={this.props.handleSelect}
                                hideEmpty={true}
                            ></SongCard>
                        );
                    })}
                </Accordion>
                <AssignmentModeInfoModal toggle={this.toggleInfo} show={this.state.showInfo} />
            </MainWrapper>
        );
    }
}

export default CreateAssignmentSets;
