import React, { Component } from 'react';
import styled from 'styled-components';
import { device } from '../../../constants/breakpoints';
import * as ROUTES from '../../../constants/routes';

const Section = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10px;
    padding-left: 5%;
    padding-right: 5%;
    background: #121212;

    @media ${device.tablet} {
        padding-left: 1%;
        padding-right: 1%;
    }
`;

const FooterPhoto = styled.img`
    height: 80px;
    width: auto;
    margin-right: 62.78%;
    margin-top: 45px;
    margin-bottom: 17px;
    margin-left: -3px;
    @media screen and (max-width: 576px) {
        margin-top: 25px;
    }
`;

const SocialMediaLogo = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
`;

const Line = styled.div`
    border: 0.3px solid rgba(255, 255, 255, 0.78);
`;

const Info = styled.div`
    font-size: 16px;
    color: rgba(255, 255, 255, 0.78);
    display: flex;
    // justify-content: flex-start;
    // padding-left: 20%;
`;

const RowInfo = styled.div`
    position: relative;
    margin-bottom: 60px;
`;

const Bottom = styled.div`
    margin-top: 20px;
    padding-bottom: 50px;
`;

const ContentsLink = styled.a`
    color: rgba(255, 255, 255, 0.78);
    &:hover {
        color: rgba(255, 255, 255);
        text-decoration: none;
    }
`;

const TOSLink = styled(ContentsLink)`
&
  text-decoration: none;
  margin-left: 30px;
`;
class LandingFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Section>
                <div>
                    <FooterPhoto src={'/images/logos/rapstudyblueminus.png'} alt="RapStudy" />
                </div>
                <RowInfo>
                    <Info className="row justify-content-md-center">
                        <div className="col-md-4">
                            <p>
                                <ContentsLink href={ROUTES.LANDING}>Home</ContentsLink>
                            </p>
                            <p>
                                <ContentsLink href={ROUTES.ABOUT}>About</ContentsLink>
                            </p>
                            <p>
                                <ContentsLink href={ROUTES.CONTACT}>Contact Us</ContentsLink>
                            </p>
                            <p>
                                <ContentsLink href={ROUTES.SIGNIN}>Sign In</ContentsLink>
                            </p>
                        </div>

                        <div className="col-md-auto">
                            <p>
                                <b>Follow Us</b>
                            </p>
                            <p>
                                <a
                                    href="https://www.instagram.com/rapstudy/"
                                    style={{
                                        color: 'rgba(255,255,255,0.78)',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <SocialMediaLogo src={'/images/landing/instagram.svg'} alt="https://www.instagram.com/rapstudy/" />
                                    Instagram
                                </a>
                            </p>
                            <p>
                                <a
                                    href="https://www.linkedin.com/company/rapstudy/"
                                    style={{
                                        color: 'rgba(255,255,255,0.78)',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <SocialMediaLogo src={'/images/landing/linkedin.svg'} alt="" />
                                    LinkedIn
                                </a>
                            </p>
                            <p>
                                <a
                                    href="https://www.facebook.com/RapStudy-110562207266080/"
                                    style={{
                                        color: 'rgba(255,255,255,0.78)',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <SocialMediaLogo src={'/images/landing/facebook.svg'} alt="" />
                                    Facebook
                                </a>
                            </p>
                        </div>
                    </Info>
                </RowInfo>
                <Line />

                <Bottom>
                    <Info style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>© 2022 RapStudy Inc.</p>
                        <div>
                            <TOSLink
                                secondary={true}
                                href={
                                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20PP.pdf?alt=media&token=ac72e73b-1f95-4b69-a46b-de02d0bea869'
                                }
                                download="rapStudy Privacy Policy"
                                target="_blank"
                            >
                                Privacy
                            </TOSLink>
                            <TOSLink
                                secondary={true}
                                href={
                                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20TOU.pdf?alt=media&token=ff00b065-3e73-43c3-8e02-0cf2b338596f'
                                }
                                download="rapStudy Terms of Use"
                                target="_blank"
                            >
                                Terms
                            </TOSLink>
                        </div>
                    </Info>
                </Bottom>
            </Section>
        );
    }
}

export default LandingFooter;
