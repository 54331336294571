import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SubtopicList from '../SubtopicList';
import TopicDict from '../../../../Classes/TopicDict';
import { withFirebase } from '../../../Firebase';

const BannerWrapper = styled.div`
    margin: 2.5vh 0;
    min-height: calc(180px + 4rem);
    margin-left: 90px;
    margin-right: 20px;
    background: var(--rsSurface);
    border-radius: 10px;
    @media (max-width: 1350px) {
        width: 100%;
    }
    width: 38%;
`;

const BannerRow = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
`;

const BoxImage = styled.img`
    margin-top: 0;
    position: absolute;
    top: 2rem;
    left: -90px;
`;

const SongWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;
    padding-left: calc(5% + 90px);
    padding-right: 10px;
    padding-top: 2rem;
    padding-bottom: 2rem;
`;

const Lead = styled.p`
    font-weight: bold;
    margin-bottom: 0;
`;

const LeadHR = styled.hr`
    border-top: 1px solid white;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 10px;
`;

class Topic extends Component {
    constructor(props) {
        super(props);
        this.renderUnitSubtitle = this.renderUnitSubtitle.bind(this);
        this.state = { songCover: null };
    }

    /**
     * Returns the string for the unit card subtitle
     * @param {Array} array of standards
     * @returns a string.
     */
    renderUnitSubtitle(standards) {
        const result = standards.filter((standard) => standard !== '').join();
        return result;
    }

    render() {
        const topic = this.props.topic;

        const getSongs = () => {
            const subtopics = Object.getOwnPropertyNames(topic.getSubtopics());
            const obj = topic.getSubtopics();

            const songs = [];
            subtopics.forEach((subtopic) => {
                // console.log(obj[subtopic]);
                songs.push(obj[subtopic]);
            });

            return songs;
        };

        return (
            <BannerWrapper id={topic.getId()}>
                <BannerRow>
                    <BoxImage src={topic.getSrc()} className="albumCover" />

                    <SongWrapper>
                        <div style={{ overflow: 'hidden' }}>
                            <Lead className="ellipsisWrap">
                                {topic.getName()}
                                <br />
                                <span className="secondary ellipsisWrap" style={{ fontSize: '14px', marginBottom: '5px' }}>
                                    {this.renderUnitSubtitle(topic.getStandards())}
                                </span>
                            </Lead>
                            <LeadHR />

                            <SubtopicList
                                guest={this.props.guest}
                                topicId={topic.getId()}
                                subtopics={topic.getSubtopics()}
                                grades={topic.getGrades()}
                                backgroundColor={this.props.backgroundColor}
                                img={getSongs()}
                            />
                        </div>
                    </SongWrapper>
                </BannerRow>
            </BannerWrapper>
        );
    }
}

Topic.propTypes = {
    /**
     * The topic data structure
     */
    topic: PropTypes.instanceOf(TopicDict),
    /**
     * The color for the bullets
     */
    backgroundColor: PropTypes.string,
};

export default withFirebase(Topic);
