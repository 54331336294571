import * as ROUTES from '../../constants/routes';

import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import { Route, Switch } from 'react-router-dom';

import ArtistPage from './BrowseArtists/ArtistPage';
import AssignmentView from './AssignmentView';
import AudioBar from '../UI-Library/AudioOrLyrics/AudioBar';
import Browse from './Browse';
import BrowseArtists from './BrowseArtists';
import ClassView from './ClassView';
import Classes from './Classes';
import ContentManager from './ContentManager';
import CreateAssignmentView from './CreateAssignmentView';
import ErrorCatch from '../ErrorCatch';
import Home from './Home';
import LessonView from './LessonView';
import Loading from '../UI-Library/Misc/Loading';
import MobileCatch from '../MobileCatch';
import NotFound from '../404';
import PlaylistView from './PlaylistView';
import Settings from './Settings';
import SongView from './SongView';
import Standards from './Standards';
import UnitView from './UnitView';
import VerticalNav from './VerticalNav';
import ViewAllArtists from './BrowseArtists/ViewAllArtists';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { withFirebase } from '../Firebase';

const PageWrapper = styled.div`
    height: 100%;
`;

const VerticalNavWrapper = styled.div`
    padding-right: 0;
`;

const ContentWrapper = styled.div`
    padding-left: 0;
    height: 100%;
    margin-left: 165px;
`;

/**
 * Internal is the parent component for the main featured app.
 *
 * It contains the side vertical navigation, as well as the content on the right.
 */
class Internal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            userData: null,
            assignmentsFollowed: null,
            guest: this.props.guest,
            // assignments: null
        };
    }

    async componentDidMount() {
        if (this.state.guest) {
            const email = this.props.userToken.claims.email;
            const getGuestData = async () => {
                const inviteesRef = this.props.firebase.db.collection('invitees');
                const inviteeQuery = await inviteesRef.where('email', '==', email).get();
                if (!inviteeQuery.empty) {
                    const userData = inviteeQuery.docs[0].data();
                    this.setState({ ready: true, userData: userData });
                }
            };
            await getGuestData();
            return;
        }
        const userToken = this.props.userToken;
        const isTeacher = userToken.claims.teacher;
        this.userListener = this.props.firebase.db
            .collection('users')
            .doc(this.props.firebase.auth.currentUser.uid)
            .onSnapshot(async (docSnap) => {
                if (!docSnap.exists) return;
                const userData = docSnap.data();
                this.setState({ ready: true, userData: userData });
            });
        if (!isTeacher) {
            this.assignmentsFollowedListener = this.props.firebase.db
                .collection('assignmentsFollowed')
                .where('studentId', '==', this.props.firebase.auth.currentUser.uid)
                .where('dueDate', '>=', new Date())
                .onSnapshot((querySnap) => {
                    this.setState({
                        assignmentsFollowed: querySnap.docs.map((docSnap) => docSnap.data()),
                    });
                });
            // this.assignments = this.props.firebaes.db.collection('assignments').where('createdBy', '==', this.props.firebase.auth.currentUser.uid )
        } else {
            this.assignmentsFollowedListener = () => null;
        }
    }

    componentWillUnmount() {
        this.userListener();
        this.assignmentsFollowedListener();
    }

    render() {
        if (!this.state.ready && !this.state.guest) return <Loading />;
        return isMobile ? (
            <MobileCatch />
        ) : (
            <PageWrapper>
                <VerticalNavWrapper>
                    <VerticalNav
                        guest={this.state.guest}
                        signOut={!this.state.guest && this.props.signOut}
                        userToken={this.props.userToken}
                        userData={this.state.userData}
                        assignmentsFollowed={!this.state.guest && this.state.assignmentsFollowed}
                    />
                </VerticalNavWrapper>
                <ContentWrapper>
                    <Switch>
                        <Route
                            exact
                            path={ROUTES.INTERNAL}
                            render={(props) => (
                                <ErrorCatch>
                                    <Home {...props} guest={this.state.guest} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            exact
                            path={ROUTES.DASHBOARD}
                            render={(props) => (
                                <ErrorCatch>
                                    <Home {...props} guest={this.state.guest} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.STANDARDS}
                            render={(props) => (
                                <ErrorCatch>
                                    <Standards {...props} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.BROWSE}
                            render={(props) => (
                                <ErrorCatch>
                                    <Browse {...props} guest={this.state.guest} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            exact
                            path={ROUTES.BROWSE_ARTISTS}
                            render={(props) => (
                                <ErrorCatch>
                                    <BrowseArtists {...props} guest={this.state.guest} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            exact
                            path={ROUTES.BROWSE_PROFILE}
                            render={(props) => (
                                <ErrorCatch>
                                    <ArtistPage {...props} guest={this.state.guest} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            exact
                            path={ROUTES.BROWSE_ARTISTS_VIEWALL}
                            render={(props) => (
                                <ErrorCatch>
                                    <ViewAllArtists />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            exact
                            path={ROUTES.CONTENTMANAGER}
                            render={(props) => (
                                <ErrorCatch>
                                    <ContentManager {...props} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            exact
                            path={ROUTES.LESSON_VIEW}
                            render={(props) => (
                                <ErrorCatch>
                                    <LessonView />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={[ROUTES.SONGVIEW, ROUTES.INVITE_SONGVIEW]}
                            render={(props) => (
                                <ErrorCatch>
                                    <SongView {...props} guest={this.state.guest} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.PLAYLISTVIEW + '/:playlistId'}
                            render={(props) => (
                                <ErrorCatch>
                                    <PlaylistView {...props} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.UNITVIEW + '/:unitId'}
                            render={(props) => (
                                <ErrorCatch>
                                    <UnitView {...props} guest={this.state.guest} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.SETTINGS}
                            render={(props) => (
                                <ErrorCatch>
                                    <Settings {...props} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.CREATEASSIGNMENT}
                            render={(props) => (
                                <ErrorCatch>
                                    <CreateAssignmentView {...props} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.ASSIGNMENT}
                            render={(props) => (
                                <ErrorCatch>
                                    <AssignmentView {...props} userToken={this.props.userToken} userData={this.state.userData} />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.CLASSES}
                            render={(props) => (
                                <ErrorCatch>
                                    <Classes
                                        {...props}
                                        userToken={this.props.userToken}
                                        userData={this.state.userData}
                                        assignmentsFollowed={this.state.assignmentsFollowed}
                                    />
                                </ErrorCatch>
                            )}
                        />
                        <Route
                            path={ROUTES.CLASSVIEW + '/:classId'}
                            render={(props) => (
                                <ErrorCatch>
                                    <ClassView
                                        {...props}
                                        userToken={this.props.userToken}
                                        userData={this.state.userData}
                                        assignmentsFollowed={this.state.assignmentsFollowed}
                                    />
                                </ErrorCatch>
                            )}
                        />
                        <Route render={(props) => <NotFound {...props} userToken={this.props.userToken} userData={this.state.userData} />} />
                    </Switch>
                    <AudioBar guest={this.state.guest} userToken={this.props.userToken} />
                </ContentWrapper>
            </PageWrapper>
        );
    }
}

export default withFirebase(Internal);
