import React, { Component } from 'react';
import styled from 'styled-components';

import { withFirebase } from '../Firebase';
import RsButton from '../UI-Library/ButtonsOrLinks/RsButton';
import RsInput from '../UI-Library/Forms/RsInput';

const PageWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -80px;
`;

const MainWrapper = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            requested: false,
            disabled: true,
            invalid: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({ [evt.target.name]: value, disabled: value === '' });
    }

    async submit() {
        var success = await this.props.firebase.resetPassword(this.state.email);

        if (success) {
            this.setState({ requested: true, invalid: false });
        } else {
            this.setState({ requested: false, invalid: true });
        }
    }

    render() {
        return !this.state.requested ? (
            <PageWrapper>
                <MainWrapper>
                    <h2 className="bold" style={{ marginBottom: '20px' }}>
                        Forgot your password?
                    </h2>
                    <p style={{ width: '100%', marginBottom: '20px' }} className="secondary bold text-center">
                        We're happy to help! Enter your email below.
                    </p>
                    <RsInput name="email" value={this.state.email} onChange={this.handleChange} mb={'20px'}></RsInput>
                    {this.state.invalid ? (
                        <p style={{ color: 'red', fontSize: '14px', width: '100%' }} className="text-center">
                            We couldn't find that email in our system.
                        </p>
                    ) : null}
                    <RsButton disabled={this.state.disabled} onClick={this.submit}>
                        Next
                    </RsButton>
                </MainWrapper>
            </PageWrapper>
        ) : (
            <PageWrapper>
                <MainWrapper>
                    <h2 className="bold">
                        Email sent to <span className="major">{this.state.email}</span>.
                    </h2>
                    <p style={{ width: '100%' }} className="secondary bold text-center">
                        It may be in your spam. Follow those instructions to reset your password.
                    </p>
                </MainWrapper>
            </PageWrapper>
        );
    }
}

export default withFirebase(ResetPassword);
