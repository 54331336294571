import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import RsButton from '../../ButtonsOrLinks/RsButton';
import Cross from '../../Icons/Cross';

const Container = styled.div`
    //padding-top: 2px;
    //padding-right: 30px;
    //padding-left: 34px;
`;

const ModalTitle = styled(Modal.Title)`
    font-weight: bold;
    font-size: 32px;
    margin: 0px;
`;

const ModalBody = styled(Modal.Body)`
    min-height: 100%;
    min-width: 50%;
`;

const ModalHeader = styled(Modal.Header)`
    align-items: center;
    margin-bottom: 1px;
`;

export class PdfWindowForStandardAlignment extends Component {
    render() {
        return (
            <Modal dialogClassName="border-radius-16-wide" show={this.props.show} onHide={this.props.onHide} centered>
                <Container>
                    <ModalHeader>
                        <ModalTitle>{this.props.feature}</ModalTitle>
                        <Cross size="18px" onClick={this.props.onHide} />
                    </ModalHeader>

                    <ModalBody>
                        <center>
                            <iframe id="iframePdf" src={this.props.pdfLink} frameBorder="0" allowFullScreen title="pdf" width="80%" height="800px" />
                        </center>
                    </ModalBody>
                    {this.props.showFooter && (
                        <Modal.Footer
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '30px',
                            }}
                        >
                            <div style={{ marginLeft: '600px' }}>
                                <RsButton onClick={this.props.onHide}>Done</RsButton>
                            </div>
                        </Modal.Footer>
                    )}
                </Container>
            </Modal>
        );
    }
}

export default PdfWindowForStandardAlignment;
