import React, { Component } from 'react';

import DropdownMenu from '../../../ButtonsOrLinks/DropdownMenu';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Playback = styled.span`
    font-size: 0.8em;
    line-height: normal;
    color: white;
    font-family: Nunito;
    @media screen and (max-width: 1090px) {
    }
    margin-left: 10px;
    margin-right: 10px;
`;

/**
 * The dropdown for changing the speed playback. Based on the DropdownMenu
 * component.
 */
class SpeedDropdown extends Component {
    render() {
        const menuItems = [
            {
                desc: '2x',
                toggleMenuItemAction: () => this.props.changeValue('2x', 2.0),
                requiredRole: 'Both',
            },
            {
                desc: '1.5x',
                toggleMenuItemAction: () => this.props.changeValue('1.5x', 1.5),
                requiredRole: 'Both',
            },
            {
                desc: 'Normal Speed',
                toggleMenuItemAction: () => this.props.changeValue('Normal Speed', 1),
                requiredRole: 'Both',
            },
            {
                desc: '0.75x',
                toggleMenuItemAction: () => this.props.changeValue('0.75x', 0.75),
                requiredRole: 'Both',
            },
            {
                desc: '0.5x',
                toggleMenuItemAction: () => this.props.changeValue('0.5x', 0.5),
                requiredRole: 'Both',
            },
        ];
        return (
            <DropdownMenu isTeacher={true} iconSize={'small'} menuItems={menuItems} direction={'top'} width={80}>
                <Playback>{this.props.playback}</Playback>
            </DropdownMenu>
        );
    }
}

SpeedDropdown.propTypes = {
    /**
     * The string of the playback speed. Ex. 'Slower', 'Slowest', etc.
     */
    playback: PropTypes.oneOf(['Normal Speed', '2x', '1.5x', '0.75x', '0.5x']),
    /**
     * The function to change the speed of the audio. Must take two parameters,
     * the string of the speed, and the playback number.
     */
    changeValue: PropTypes.func,
};

export default SpeedDropdown;
