import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BlanksSideDiv from '../BlanksSideDiv';
import RsButton from '../../../ButtonsOrLinks/RsButton';
import Keyword from '../../../../../Classes/Keyword';
import KeywordDict from '../../../../../Classes/KeywordDict';

import AnimatedInput from './AnimatedInput';

const InputWrap = styled.div`
    padding: 5px 0 5px 0;
`;

const Attempts = styled.p`
    color: ${(props) => (props.disabled ? 'var(--rsTertiary)' : 'var(--rsSecondary)')};
    font-size: 14px;
    margin: 5px 0 5px 0;
    transition: all 0.3s ease 0s;
`;

class WordInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            animationFinished: false,
            animating: false,
            isCorrect: false,
        };
        this.change = this.change.bind(this);
        this.submit = this.submit.bind(this);
        this.giveUp = this.giveUp.bind(this);
    }

    change(e) {
        if (this.props.disabled) return;
        if (e.target.value === null || e.target.value === undefined) return;
        const cleaned = Keyword.cleanWord(e.target.value);
        this.setState({ text: cleaned });
    }

    startStopAnimation = (correct) => {
        this.setState({ animating: !this.state.animating, isCorrect: correct });
    };

    onAnimationStart = () => {
        this.setState({
            animationFinished: false,
            animating: true,
        });
    };

    onAnimationEnd = () => {
        this.setState({
            animationFinished: true,
            animating: false,
            isCorrect: false,
        });
    };

    submit(e) {
        const kObj = this.props.keywordDict.getKeyword(this.props.currentWord);
        if (kObj.isCorrect()) return;
        const correct = this.props.onSelect(this.props.currentWord, this.state.text);
        this.startStopAnimation(correct);
        this.setState({ text: '' });
        return correct;
    }

    giveUp() {
        this.props.giveUp(this.props.currentWord);
        return;
    }

    render() {
        const { text, animating, animationFinished, isCorrect } = this.state;
        const {
            keywordDict,
            // onSelect,
            disabled,
            currentWord,
            maxAttempts,
        } = this.props;

        const kObj = currentWord ? keywordDict.getKeyword(currentWord) : null;
        const attempts = kObj ? kObj.getAttempts() : 0;

        return (
            <BlanksSideDiv disabled={disabled} title={'Free recall'} sub={'When the song pauses, type in the missing lyric!'} color={this.props.color}>
                <InputWrap>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {maxAttempts > 0 && <Attempts disabled={disabled}>Attempts remaining: {maxAttempts - attempts}</Attempts>}
                        <AnimatedInput
                            onChange={this.change}
                            value={text}
                            animating={animating}
                            animationFinished={animationFinished}
                            correct={isCorrect}
                            onAnimationEnd={this.onAnimationEnd}
                            onAnimationStart={this.onAnimationStart}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            marginTop: '10px',
                        }}
                    >
                        {attempts > 0 && !disabled ? (
                            <RsButton width="125px" paddingHor="10px" secondary={true} onClick={this.giveUp}>
                                Don't know
                            </RsButton>
                        ) : (
                            <div></div>
                        )}

                        <RsButton width="125px" disabled={disabled || text.length === 0} onClick={this.submit}>
                            Submit
                        </RsButton>
                    </div>
                </InputWrap>
            </BlanksSideDiv>
        );
    }
}

WordInput.propTypes = {
    /**
     * The main keyword dictionary data structure.
     */
    keywordDict: PropTypes.instanceOf(KeywordDict),
    /**
     * The function fired when a choice is selected. Takes two parameters,
     * the number representing the numInLrc of the keyword, and the string
     * representing the actual text of the keyword.
     */
    onSelect: PropTypes.func,
    /**
     * Is selection disabled?
     */
    disabled: PropTypes.bool,
    /**
     * The current numInLrc word.
     */
    currentWord: PropTypes.number,
    /**
     * The number of attempts. If 0, there are unlimited attempts.
     */
    maxAttempts: PropTypes.number,
    /**
     * The function to give up on the current keyword.
     */
    giveUp: PropTypes.func,
    /**
     * Background color of the song
     */
    color: PropTypes.string,
};

WordInput.defaultProps = {
    keywordDict: null,
    onSelect: () => null,
    disabled: false,
    currentWord: null,
    maxAttempts: 0,
    giveUp: () => null,
};

export default WordInput;
