import ReviewSubmission from './ReviewSubmission';
import BlanksSubmission from './BlanksSubmission';

/**
 * IndivGradeSummary is a representation of a student's submissions within
 * an assignment.
 */
class IndivGradeSummary {
    /**
     * Creates a new IndivGradeSummary object.
     * @param {string} studentId
     * @param {string} studentName
     * @param {string} assignmentId
     */
    constructor(studentId, studentName, assignmentId) {
        this._studentId = studentId;
        this._studentName = studentName;
        this._assignmentId = assignmentId;
        this._submissions = {
            // exerciseId : ReviewSubmission
        };
    }

    /**
     * Returns a positive number if the first summary has a name in higher sort
     * order than the second, a negative number if lower, and 0 if equal.
     * @param {IndivGradeSummary} summary1
     * @param {IndivGradeSummary} summary2
     */
    static nameCompare(summary1, summary2) {
        return summary1.getStudentName().localeCompare(summary2.getStudentName());
    }

    /**
     * Returns true if the individual has no set submissions, false otherwise.
     */
    isEmpty() {
        return Object.keys(this._submissions).length === 0;
    }

    /**
     * Returns the firebase user id of the student.
     * @return {string}
     */
    getStudentId() {
        return this._studentId;
    }

    /**
     * Returns the name of the student.
     * @return {string}
     */
    getStudentName() {
        return this._studentName;
    }

    /**
     * Returns an object representing the submissions for this individual.
     * @return { {string: ReviewSubmission | BlanksSubmission} }
     */
    getSubmissions() {
        return this._submissions;
    }

    /**
     * Returns the submission object (ReviewSubmission) that links to the exerciseId.
     */
    getSubmissionById(exerciseId) {
        if (!(exerciseId in this._submissions)) throw Error('Precondition Invalidated: exerciseId does not point to a valid submission.');
        return this._submissions[exerciseId];
    }

    /**
     * Adds a submission object to the structure. If a submission with the same
     * submission id already exists, it throws an error.
     */
    addSubmission(submissionObj) {
        if (!(submissionObj instanceof ReviewSubmission || submissionObj instanceof BlanksSubmission)) {
            throw Error('Precondition Invalidated: submissionObj is not a valid subjmission object.');
        }
        const exerciseId = submissionObj.getExerciseId();
        this._submissions[exerciseId] = submissionObj;
    }

    /**
     * Returns an array of exercise ids.
     * @return {Array<string>}
     */
    getExerciseIds() {
        if (this.isEmpty()) return [];
        return Object.keys(this._submissions);
    }
}

export default IndivGradeSummary;
