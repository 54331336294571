import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import Loading from '../../UI-Library/Misc/Loading';
import BrowseUnits from './BrowseUnits';
import SearchBar from './SearchBar';
import BrowseSubjects from './BrowseSubjects';
import BrowseSearch from './BrowseSearch';
import SimpleBanner from '../../UI-Library/Containers/SimpleBanner';
import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';
import { Link } from 'react-router-dom';

const PageWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding-top: 0;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 120px;
`;

const BrowseHeader = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    color: white;
`;

/**
 * Browse is the component for the "Browse" page in Internal.
 */
export class BrowseBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: null,
            result: null,
        };
    }

    async componentDidMount() {
        try {
            const queryResult = await this.props.firebase.getBrowse();
            this.setState({ ready: true, result: queryResult });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }
        if (!this.state.ready) {
            return <Loading zoom={true} />;
        }

        return (
            <PageWrapper>
                <SimpleBanner color={'#2E2E2E'} style={{ overflow: 'hidden', zindex: '9' }}>
                    <BackArrow />
                    <BrowseHeader style={{ paddingBottom: '20px', paddingTop: '42px' }}>
                        <div style={{ display: 'inline-flex', color: '#bebeb6' }}>
                            <Link
                                style={{
                                    textDecoration: 'none',
                                    color: '#bebeb6',
                                    paddingRight: '40px',
                                }}
                                to={ROUTES.BROWSE_ARTISTS}
                            >
                                Artists
                            </Link>
                            |
                        </div>
                        <div
                            style={{
                                display: 'inline-flex',
                                paddingLeft: '40px',
                            }}
                        >
                            Subjects
                        </div>
                    </BrowseHeader>
                    <SearchBar />
                </SimpleBanner>

                <MainWrapper>
                    <Route exact path={ROUTES.BROWSE_SUBJECTS} render={(props) => <BrowseSubjects guest={this.props.guest} data={this.state.result} />} />
                    <Route
                        exact
                        path={ROUTES.BROWSE_UNITS + '/:subject'}
                        render={(props) => <BrowseUnits guest={this.props.guest} data={this.state.result} />}
                    />
                    <Route exact path={ROUTES.BROWSE_SEARCH + '/:query'} render={(props) => <BrowseSearch guest={this.props.guest} />} />
                </MainWrapper>
            </PageWrapper>
        );
    }
}

const Browse = withFirebase(BrowseBase);
export default Browse;
