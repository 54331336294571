import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';

import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';

import { Provider as AlertProvider, positions, transitions } from 'react-alert';
import Firebase, { FirebaseContext } from './components/Firebase';

import App from './components/App';
import CustomAlert from './components/CustomAlert';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { TimeProvider } from './components/Contexts/CurrentTime';
import store from './redux/store';

const alertOptions = {
    position: positions.BOTTOM_CENTER,
    timeout: 2750,
    offset: '30px',
    transition: transitions.FADE,
};

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: 'rapstudy@' + process.env.REACT_APP_VERSION,
});

ReactDOM.render(
    <Router>
        <AlertProvider template={CustomAlert} {...alertOptions}>
            <FirebaseContext.Provider value={new Firebase()}>
                <TimeProvider>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </TimeProvider>
            </FirebaseContext.Provider>
        </AlertProvider>
    </Router>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
