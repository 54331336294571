import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';

import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';
import Cross from '../../Icons/Cross';

const MainWrapper = styled.div`
    background-color: transparent !important;
`;

const ModalTitle = styled(Modal.Title)`
    font-weight: bold;
`;

const ModalBody = styled(Modal.Body)`
    padding-left: 60px;
    padding-right: 60px;
    max-height: 250px;
    padding-top: 0;
`;

const ModalHeader = styled(Modal.Header)`
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
`;

const ModalFooter = styled(Modal.Footer)`
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ImageContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    height: 200px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 0 !important;
    overflow-x: auto;
`;

const Thumbnail = styled.img`
    margin: 10px;
    max-height: 80%;
    width: auto;
    border: none;
    flex: 0 0 auto;
    box-shadow: 4px 4px 15px black;
    cursor: pointer;
`;

const Divider = styled.h2`
    width: 100%;
    text-align: center;
    border-bottom: 2px solid white;
    line-height: 0.1em;
    margin: 10px 0 20px;
`;

const DividerSpan = styled.span`
    background: var(--rsSurface);
    padding: 0 10px;
`;

/**
 * The modal component for searching and selecting an image from Splash.
 * This is a reusable component for all features.
 *
 * Props:
 * - [function]: selectImage
 *
 */
export class ChooseImageModalBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            imageArray: null,
            fileName: '',
            msg: null,
            uploading: false,
            searchQuery: '',
            searching: false,
        };
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
        this.fileInput = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.submit = this.submit.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    handleModalShowHide() {
        this.props.toggle(this.props.index);
        this.reset();
    }

    reset() {
        this.setState({
            disabled: true,
            imageArray: null,
            fileName: '',
            msg: null,
            uploading: false,
            searchQuery: '',
        });
    }

    handleChange(e) {
        this.setState({ searchQuery: e.target.value });
        if (!e.target.value || e.target.value === '' || e.target.value === ' ') {
            this.setState({ disabled: true });
        } else if (this.state.disabled) {
            this.setState({ disabled: false });
        }
    }

    async submit(value) {
        this.setState({ disabled: true, searching: true });
        try {
            const result = await this.props.firebase.cloudSearchUnsplash(value);
            this.setState({ imageArray: result, disabled: false, searching: false });
        } catch (err) {
            console.log(err);
            this.setState({
                disabled: false,
                searching: false,
                msg: 'The Unsplash image service is unavailable. For now, try uploading your own image.',
            });
        }
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            this.submit(e.target.value);
        }
    }

    handleSelect(url, uid, name, imgObj) {
        this.props.selectImage(this.props.index, url, uid, name, imgObj);
        this.handleModalShowHide();
    }

    renderThumbnails() {
        var result = null;
        result = this.state.imageArray.map((imageObj, index) => (
            <Thumbnail src={imageObj[1]} key={'THUMBNAIL_' + index} onClick={() => this.handleSelect(imageObj[2], imageObj[3], imageObj[4], imageObj[0])} />
        ));
        return result;
    }

    handleClick(event) {
        this.fileInput.current.click();
    }

    async handleUpload(event) {
        try {
            const file = event.target.files[0];
            var type = file.type.split('/')[1];
            if (type === 'jpeg' || type === 'png') {
                this.setState({
                    msg: file.name,
                    uploading: true,
                    disabled: true,
                });

                await this.props.firebaseQImage(this.props.index, file);
                this.handleModalShowHide();
            } else {
                this.setState({
                    msg: 'Invalid file type. Please upload a .jpg or .png image!',
                    disabled: false,
                    uploading: false,
                });
            }
        } catch (err) {
            // TODO ErrorHandling: Log err manually to Sentry as it will be caught here.
            console.log(err);
            this.setState({
                msg:
                    'The file upload failed. Please ensure that the image size is less than 1MB. If the problem persists, please contact us at support@rapstudy.com',
                disabled: false,
                uploading: false,
            });
        }
    }

    render() {
        return (
            <MainWrapper>
                <div onKeyDown={(e) => e.stopPropagation()}>
                    <Modal dialogClassName="border-radius-16-wide" show={this.props.show} centered onHide={this.handleModalShowHide}>
                        <ModalHeader>
                            <ModalTitle className="text-center">Search for an image</ModalTitle>

                            <Cross size="18px" onClick={this.handleModalShowHide} />
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ width: '100%' }} className="row">
                                <div className="col">
                                    <RsInput value={this.state.searchQuery} onChange={this.handleChange} handleKeyPress={this.onKeyDown} />
                                </div>

                                <RsButton
                                    className="col"
                                    ml={'10px'}
                                    type="submit"
                                    onClick={() => {
                                        this.submit(this.state.searchQuery);
                                    }}
                                    disabled={this.state.disabled}
                                >
                                    Search
                                </RsButton>
                            </div>

                            {this.state.searching ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        height: '200px',
                                        width: '100%',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Spinner as="span" animation="border" role="status"></Spinner>
                                </div>
                            ) : null}

                            {this.state.imageArray && !this.state.searching ? <ImageContainer>{this.renderThumbnails()}</ImageContainer> : null}
                        </ModalBody>
                        <Divider>
                            <DividerSpan>OR</DividerSpan>
                        </Divider>

                        <ModalFooter className="text-center">
                            <RsButton onClick={this.handleClick} disabled={this.state.uploading} secondary={true}>
                                {this.state.uploading ? 'Uploading...' : 'Upload your own'}
                            </RsButton>
                            <input type="file" ref={this.fileInput} style={{ display: 'none' }} onChange={this.handleUpload} />
                            <span className="bold">{this.state.msg ? this.state.msg : ''}</span>
                        </ModalFooter>
                    </Modal>
                </div>
            </MainWrapper>
        );
    }
}

const ChooseImageModal = withFirebase(ChooseImageModalBase);
export default ChooseImageModal;
