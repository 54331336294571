import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

const ErrorJumbo = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 75vh;
    text-align: center;
    background: var(--rsBackground);
`;

const JumboHR = styled.hr`
    border: 1px solid var(--rsPrimary);
`;

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };

        if (this.props.showError === false) {
            this.state.error = null;
            this.state.errorInfo = null;
        }
    }

    componentDidMount() {
        const { history } = this.props;

        this.unlisten = history.listen(() => {
            if (this.state.error) {
                this.setState({
                    error: null,
                });
            }
        });
    }

    componentDidCatch = (error, info) => {
        console.log(error, info);
        Sentry.withScope((scope) => {
            scope.setExtras(info);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId, info });
        });
        this.setState({ error: error, errorInfo: info });
    };

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        // No error
        if (!this.state.errorInfo || !this.state.error) {
            return this.props.children;
        }

        // Firebase error
        else if (this.state.error.name === 'FirebaseError') {
            return (
                <ErrorJumbo className="jumbotron">
                    <h1 className="major">Server Error</h1>
                    <JumboHR />
                    <p className="lead container" style={{ textAlign: 'center' }}>
                        Oops! Something went wrong. Check your internet connection and try again. If the problem persists, please email us at{' '}
                        <span className="major">support@rapstudy.com</span>.
                    </p>
                </ErrorJumbo>
            );
        }

        // AssetNotFound error
        else if (this.state.error.name === 'Document not found') {
            return (
                <ErrorJumbo className="jumbotron">
                    <h1 className="major">Error: Invalid query</h1>
                    <JumboHR />
                    <p className="lead container" style={{ textAlign: 'center' }}>
                        Oops! Seems like we couldn't find what you were looking for. Perhaps the url is incorrect, or what you're trying to access no longer
                        exists. If the problem persists, contact us at <span className="major">support@rapstudy.com</span>.
                    </p>
                </ErrorJumbo>
            );
        }

        // Javascript error
        else {
            return (
                <ErrorJumbo className="jumbotron">
                    <h1 className="major">Error</h1>
                    <JumboHR />
                    <p className="lead container" style={{ textAlign: 'center' }}>
                        Oops! Something went wrong.
                        {/* Our team has been notified and we're
            working to fix it as soon as possible.  */}
                        Check your internet connection and try again. If the problem persists, please email us at{' '}
                        <span className="major">support@rapstudy.com</span>.
                    </p>
                </ErrorJumbo>
            );
        }
    }
}

export default withRouter(ErrorBoundary);
