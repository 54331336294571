import React, { Component } from 'react';
import styled from 'styled-components';

const Btn = styled.button`
    color: ${(props) => (props.clicked ? 'var(--rsDisabled)' : 'var(--rsSecondary)')};
`;

class ActionButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({ clicked: true });
        this.props.onClick();
    }

    render() {
        const disabled = this.state.clicked || this.props.disabled;
        return (
            <Btn className="ghost underlineOnHover" clicked={disabled} onClick={disabled ? null : this.handleClick} disabled={disabled}>
                {this.props.children}
            </Btn>
        );
    }
}

export default ActionButton;
