export default async function getArtists() {
    const artistRef = this.db.collection('artists');
    const res = await artistRef.get();
    const artists = res.docs.map((artist) => artist.data());
    return artists;
}

export async function getArtist(name) {
    const artistRef = this.db.collection('artists');
    const res = await artistRef.where('name', '==', name).limit(1).get();
    let artist = null;
    if (!res.empty) {
        artist = res.docs[0].data();
    }
    return artist;
}
