import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

import {
    addContentToClass,
    approveDenyStudentToJoinClass,
    changeClassColor,
    createClass,
    deleteClass,
    editClassDetails,
    getAdminClasses,
    getClass,
    getClassSharedContent,
    getMembershipClasses,
    getPendingClasses,
    joinClassAsStudent,
    removeContentFromClass,
    removeStudentFromClass,
} from './classes.js';
import {
    addPlaylist,
    addPlaylistSong,
    editPlaylist,
    getAuthoredPlaylistNameArray,
    getAuthoredPlaylists,
    getFollowedPlaylists,
    getPlaylist,
    getPlaylistSongs,
    getPlaylistSongsById,
    playlistCompareByCreated,
    playlistsOfPlaylistsFollowed,
    removePlaylist,
    removePlaylistSong,
    updatePlaylistSongsOrder,
} from './playlists.js';
import { addToLibrary, getLibrary, inLibrary, removeFromLibrary, sortLibrary } from './library.js';
import {
    authStateChangedListener,
    codeSubscriberCheck,
    createUserWithEmail,
    doSignInEmail,
    doSignInGoogle,
    doSignInMicrosoft,
    doSignOut,
    emailSubscriberCheck,
    getUserToken,
    resendVerification,
    resetPassword,
} from './auth.js';
import { checkBannerSurveyStatus, completeBannerSurvey } from './bannersurvey.js';
import { cloudSearchAlgolia, getBrowse, getSongs } from './browse.js';
import { cloudSearchUnsplash, downloadUnsplash } from './unsplash';
import {
    completeFirstBlanks,
    completeFirstBreakdown,
    completeFirstKaraoke,
    completeFirstReview,
    completeOnboarding,
    createInquiry,
    doSignUpContact,
    generatePin,
    getPilotAgreement,
    getTutorialGifs,
    setEducatorSurvey,
    setFeedback,
    setStudentSurvey,
} from './misc.js';
import {
    createAssignment,
    deleteAssignment,
    editAssignment,
    getAllClassAssignments,
    getAssignment,
    getPastAssignmentsFollowed,
    getSetsForAssignment,
    getSubmissionsForAssignment,
    getSubmissionsForStudent,
    saveSubmission,
} from './assignments.js';
import { createCustomAnnotations, deleteCustomAnnotations, getAnnotationDict, getAnnotationSetsForSong, updateCustomAnnotations } from './breakdown.js';
import { createCustomKeywords, deleteCustomKeywords, getKeywordDict, getKeywordSetsForSong, updateCustomKeywords } from './blanks.js';
import {
    createCustomQuestions,
    createSongInvite,
    deleteCustomQuestions,
    deleteSongInvite,
    getQuestionList,
    getQuestionSetsForSong,
    getSongFromInvite,
    sendInvites,
    setCustomQuestionImg,
    updateCustomQuestions,
} from './review.js';
import { editUserInfo, getFirstName, getName, getUserDoc, getUserProfile, getUserSchool, updateLastAccess } from './user.js';
import getArtists, { getArtist } from './artists.js';
import { getBanners, getNewReleases, getRecentSongs, getRecommendedByArtist, getRecommendedByGenre, getRecommendedBySubject } from './recents.js';
import { getDescriptions, getGradeRefs, getStandardsInGrades, getSubStandards, getSubTopics, getSubjects, getTopics, getUnits } from './standards.js';
import { getJson, getMp3, getSong, getSongCover, incrementSongCount, initUserSongDoc } from './songview.js';
import { getSubtopicsInTopic, getTopicImageURL, getTopicsInUnit, getUnitData, getUnitImageURL } from './unitview.js';

import app from 'firebase/app';
import { completeFeedback } from './karaoke.js';
import { getCustomSongs } from './contentmanager.js';
import getLessons from './lessons';

import appendToWaitlist from './waitlist.js';

const config = {
    apiKey: 'AIzaSyAvn_k2oXIzk_LGoIvwyf-WlmYmXCCDTns',
    authDomain: 'rapstudy-staging.firebaseapp.com',
    databaseURL: 'https://rapstudy-staging.firebaseio.com',
    projectId: 'rapstudy-staging',
    storageBucket: 'rapstudy-staging.appspot.com',
    messagingSenderId: '1038546948383',
    appId: '1:1038546948383:web:676ae3dd48d7365e30a02a',
    measurementId: 'G-KLXYP0K76P',
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.firebase = app;
        this.analytics = app.analytics();

        this.db = app.firestore();
        this.auth = app.auth();
        this.storage = app.storage();
        this.functions = app.functions();

        this.NotFoundError = {
            name: 'Document not found',
            message: 'Asset not found.',
        };
        //New errors to the pop up
        this.EnrolledStudentError = {
            name: 'Already Enrolled',
        };
        this.PendingStudentError = {
            name: 'Already Requested',
        };

        this.PermissionDenied = {
            name: 'Permission Denied',
        };

        //this.doSignIn = this.doSignIn.bind(this);
    }
}

// AUTH ASSIGNMENT
Firebase.prototype.doSignOut = doSignOut;
Firebase.prototype.doSignInGoogle = doSignInGoogle;
Firebase.prototype.doSignInMicrosoft = doSignInMicrosoft;
Firebase.prototype.getUserToken = getUserToken;
Firebase.prototype.doSignInEmail = doSignInEmail;
Firebase.prototype.createUserWithEmail = createUserWithEmail;
Firebase.prototype.authStateChangedListener = authStateChangedListener;
Firebase.prototype.emailSubscriberCheck = emailSubscriberCheck;
Firebase.prototype.codeSubscriberCheck = codeSubscriberCheck;
Firebase.prototype.resendVerification = resendVerification;
Firebase.prototype.resetPassword = resetPassword;

// BROWSE
Firebase.prototype.getBrowse = getBrowse;
Firebase.prototype.getSubjects = getSubjects;
Firebase.prototype.getGradeRefs = getGradeRefs;
Firebase.prototype.getStandardsInGrades = getStandardsInGrades;
Firebase.prototype.getSubjects = getSubjects;
Firebase.prototype.getUnits = getUnits;
Firebase.prototype.getTopics = getTopics;
Firebase.prototype.getSubTopics = getSubTopics;
Firebase.prototype.getSubStandards = getSubStandards;
Firebase.prototype.getDescriptions = getDescriptions;
Firebase.prototype.cloudSearchAlgolia = cloudSearchAlgolia;
Firebase.prototype.getSongs = getSongs;

// UNITVIEW
Firebase.prototype.getUnitData = getUnitData;
Firebase.prototype.getTopicsInUnit = getTopicsInUnit;
Firebase.prototype.getUnitImageURL = getUnitImageURL;
Firebase.prototype.getTopicImageURL = getTopicImageURL;
Firebase.prototype.getSubtopicsInTopic = getSubtopicsInTopic;

// REVIEW
Firebase.prototype.getQuestionList = getQuestionList;
Firebase.prototype.updateCustomQuestions = updateCustomQuestions;
Firebase.prototype.createCustomQuestions = createCustomQuestions;
Firebase.prototype.deleteCustomQuestions = deleteCustomQuestions;
Firebase.prototype.setCustomQuestionImg = setCustomQuestionImg;
Firebase.prototype.getQuestionSetsForSong = getQuestionSetsForSong;
Firebase.prototype.createSongInvite = createSongInvite;
Firebase.prototype.deleteSongInvite = deleteSongInvite;
Firebase.prototype.getSongFromInvite = getSongFromInvite;
Firebase.prototype.sendInvites = sendInvites;

// LIBRARY
Firebase.prototype.addToLibrary = addToLibrary;
Firebase.prototype.inLibrary = inLibrary;
Firebase.prototype.removeFromLibrary = removeFromLibrary;
Firebase.prototype.getLibrary = getLibrary;
Firebase.prototype.sortLibrary = sortLibrary;

// BREAKDOWN
Firebase.prototype.deleteCustomAnnotations = deleteCustomAnnotations;
Firebase.prototype.updateCustomAnnotations = updateCustomAnnotations;
Firebase.prototype.createCustomAnnotations = createCustomAnnotations;
Firebase.prototype.getAnnotationDict = getAnnotationDict;
Firebase.prototype.getAnnotationSetsForSong = getAnnotationSetsForSong;

// BLANKS
Firebase.prototype.getKeywordSetsForSong = getKeywordSetsForSong;
Firebase.prototype.deleteCustomKeywords = deleteCustomKeywords;
Firebase.prototype.createCustomKeywords = createCustomKeywords;
Firebase.prototype.updateCustomKeywords = updateCustomKeywords;
Firebase.prototype.getKeywordDict = getKeywordDict;

// CUSTOM CONTENT
Firebase.prototype.getCustomSongs = getCustomSongs;

// SONGVIEW
Firebase.prototype.getMp3 = getMp3;
Firebase.prototype.getSong = getSong;
Firebase.prototype.getJson = getJson;
Firebase.prototype.getSongCover = getSongCover;
Firebase.prototype.initUserSongDoc = initUserSongDoc;
Firebase.prototype.incrementSongCount = incrementSongCount;

// KARAOKE
Firebase.prototype.completeFeedback = completeFeedback;

// UNSPLASH
Firebase.prototype.cloudSearchUnsplash = cloudSearchUnsplash;
Firebase.prototype.downloadUnsplash = downloadUnsplash;

// PLAYLISTS
Firebase.prototype.getAuthoredPlaylistNameArray = getAuthoredPlaylistNameArray;
Firebase.prototype.getPlaylist = getPlaylist;
Firebase.prototype.getPlaylistSongs = getPlaylistSongs;
Firebase.prototype.getPlaylistSongsById = getPlaylistSongsById;
Firebase.prototype.addPlaylist = addPlaylist;
Firebase.prototype.removePlaylist = removePlaylist;
Firebase.prototype.addPlaylistSong = addPlaylistSong;
Firebase.prototype.removePlaylistSong = removePlaylistSong;
Firebase.prototype.editPlaylist = editPlaylist;
Firebase.prototype.updatePlaylistSongsOrder = updatePlaylistSongsOrder;
Firebase.prototype.playlistCompareByCreated = playlistCompareByCreated;
Firebase.prototype.playlistsOfPlaylistsFollowed = playlistsOfPlaylistsFollowed;
Firebase.prototype.getAuthoredPlaylists = getAuthoredPlaylists;
Firebase.prototype.getFollowedPlaylists = getFollowedPlaylists;

// MISC
Firebase.prototype.doSignUpContact = doSignUpContact;
Firebase.prototype.createInquiry = createInquiry;
Firebase.prototype.completeOnboarding = completeOnboarding;
Firebase.prototype.setStudentSurvey = setStudentSurvey;
Firebase.prototype.setEducatorSurvey = setEducatorSurvey;
Firebase.prototype.completeFirstBreakdown = completeFirstBreakdown;
Firebase.prototype.completeFirstBlanks = completeFirstBlanks;
Firebase.prototype.completeFirstReview = completeFirstReview;
Firebase.prototype.completeFirstKaraoke = completeFirstKaraoke;
Firebase.prototype.setFeedback = setFeedback;
Firebase.prototype.getPilotAgreement = getPilotAgreement;
Firebase.prototype.getTutorialGifs = getTutorialGifs;
Firebase.prototype.generatePin = generatePin;
Firebase.prototype.getBanners = getBanners;

// USER
Firebase.prototype.getUserDoc = getUserDoc;
Firebase.prototype.getUserSchool = getUserSchool;
Firebase.prototype.getName = getName;
Firebase.prototype.getFirstName = getFirstName;
Firebase.prototype.editUserInfo = editUserInfo;
Firebase.prototype.updateLastAccess = updateLastAccess;
Firebase.prototype.getUserProfile = getUserProfile;

// RECENTS
Firebase.prototype.getRecentSongs = getRecentSongs;
Firebase.prototype.getNewReleases = getNewReleases;
Firebase.prototype.getRecommendedBySubject = getRecommendedBySubject;
Firebase.prototype.getRecommendedByArtist = getRecommendedByArtist;
Firebase.prototype.getRecommendedByGenre = getRecommendedByGenre;

// CLASSES
Firebase.prototype.getClass = getClass;
Firebase.prototype.getMembershipClasses = getMembershipClasses;
Firebase.prototype.joinClassAsStudent = joinClassAsStudent;
Firebase.prototype.createClass = createClass;
Firebase.prototype.approveDenyStudentToJoinClass = approveDenyStudentToJoinClass;
Firebase.prototype.addContentToClass = addContentToClass;
Firebase.prototype.removeContentFromClass = removeContentFromClass;
Firebase.prototype.getClassSharedContent = getClassSharedContent;
Firebase.prototype.removeStudentFromClass = removeStudentFromClass;
Firebase.prototype.getAdminClasses = getAdminClasses;
Firebase.prototype.getPendingClasses = getPendingClasses;
Firebase.prototype.deleteClass = deleteClass;
Firebase.prototype.changeClassColor = changeClassColor;
Firebase.prototype.editClassDetails = editClassDetails;

//ASSIGNMENTS
Firebase.prototype.getSetsForAssignment = getSetsForAssignment;
Firebase.prototype.getAllClassAssignments = getAllClassAssignments;
Firebase.prototype.getAssignment = getAssignment;
Firebase.prototype.getSubmissionsForStudent = getSubmissionsForStudent;
Firebase.prototype.getSubmissionsForAssignment = getSubmissionsForAssignment;
Firebase.prototype.createAssignment = createAssignment;
Firebase.prototype.saveSubmission = saveSubmission;
Firebase.prototype.editAssignment = editAssignment;
Firebase.prototype.getPastAssignmentsFollowed = getPastAssignmentsFollowed;
Firebase.prototype.deleteAssignment = deleteAssignment;
Firebase.prototype.getLessons = getLessons;

// BANNER SURVEY
Firebase.prototype.completeBannerSurvey = completeBannerSurvey;
Firebase.prototype.checkBannerSurveyStatus = checkBannerSurveyStatus;

//ARTISTS
Firebase.prototype.getArtists = getArtists;
Firebase.prototype.getArtist = getArtist;

//WAITLIST
Firebase.prototype.appendToWaitlist = appendToWaitlist;

export default Firebase;
