import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LI = styled.div`
    ${(props) =>
        props.disabled
            ? `
      color: var(--rsTertiary);
      `
            : `
      transition: all 0.3s ease 0s;
      cursor: pointer;
      font-weight: bold;
      color: var(--rsSecondary);
      &:hover {
        background: var(--rsSurfaceHover);
        color: var(--rsPrimary);
      }
        `}
    margin: 5px 0px 5px 0px;
    border-radius: 8px;
    padding: 5px 10px 5px 10px;
    border: 3px solid transparent;
    width: 50%;

    ${(props) => {
        if (!props.animating) return;
        const animation = props.correct ? 'feedbackRight' : 'feedbackWrong';
        return `

    @keyframes feedbackRight {
      0% {
        border: 3px solid transparent;
      }
      25% {
        border: 3px solid green;
      }
      50% {
        border: 3px solid green;
      }
      75% {
        border: 3px solid green;
      }
    }

    @keyframes feedbackWrong {
      0% {
        border: 3px solid transparent;
      }
      25% {
        border: 3px solid red;
      }
      50% {
        border: 3px solid red;
      }
      75% {
        border: 3px solid red;
      }
    }

    animation: ${animation} 1s forwards;
    
    
`;
    }}
`;

export default class ListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animationFinished: false,
            animating: false,
            isCorrect: false,
        };
    }

    startStopAnimation = (correct) => {
        this.setState({ animating: !this.state.animating, isCorrect: correct });
    };

    onAnimationStart = () => {
        this.setState({
            animationFinished: false,
            animating: true,
        });
    };

    onAnimationEnd = () => {
        this.setState({
            animationFinished: true,
            animating: false,
            isCorrect: false,
        });
    };

    render() {
        const { animationFinished, animating, isCorrect } = this.state;
        return (
            <LI
                key={'correct_' + isCorrect + '_' + animating}
                onClick={
                    this.props.disabled && animationFinished
                        ? () => null
                        : () => {
                              const correct = this.props.choose(this.props.word);
                              if (correct === null) return; // only animate on a valid click.
                              this.startStopAnimation(correct);
                          }
                }
                animating={animating}
                correct={isCorrect}
                disabled={this.props.disabled}
                onAnimationEnd={this.onAnimationEnd}
                onAnimationStart={this.onAnimationStart}
            >
                {this.props.word}
            </LI>
        );
    }
}

ListItem.propTypes = {
    word: PropTypes.string,
    disabled: PropTypes.bool,
    choose: PropTypes.func,
};
