import * as ROUTES from '../../../../constants/routes';

import React, { Component } from 'react';

import AlbumCoverContainer from '../../../UI-Library/ImageContainers/AlbumCover';
import { Link } from 'react-router-dom';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import styled from 'styled-components';

const EmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Lead = styled.p`
    font-size: 24px;
`;

class Recommended extends Component {
    renderEmpty() {
        return (
            <EmptyWrapper>
                <Lead className="secondary">We’re happy to have you on board! Find a topic that you want to learn and start jamming.</Lead>
                <Link to={ROUTES.BROWSE_SUBJECTS}>
                    <RsButton>Browse</RsButton>
                </Link>
            </EmptyWrapper>
        );
    }
    render() {
        if (!this.props.recentExists) {
            return null;
            // return this.renderEmpty();
        }

        var suffix = 'null';
        if (this.props.category === 'subject') {
            suffix = this.props.categoryTitle;
        } else if (this.props.category === 'artist') {
            suffix = this.props.categoryTitle;
        } else if (this.props.category === 'genre') {
            suffix = this.props.categoryTitle;
        } else {
            suffix = this.props.category;
        }

        if (this.props.category === 'subject') {
            return suffix != null ? <AlbumCoverContainer header={'Learn More About ' + suffix} items={this.props.recents} itemType={'song'} rows={1} /> : null;
        } else if (this.props.category === 'artist') {
            return suffix != null ? <AlbumCoverContainer header={'Because You Like ' + suffix} items={this.props.recents} itemType={'song'} rows={1} /> : null;
        } else if (this.props.category === 'genre') {
            return suffix != null ? (
                <AlbumCoverContainer header={'Recommended for You by Genre: ' + suffix} items={this.props.recents} itemType={'song'} rows={1} />
            ) : null;
        } else {
            return null;
        }
    }
}
export default Recommended;
