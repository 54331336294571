import React, { Component } from 'react';
import styled from 'styled-components';

import RsButton from '../UI-Library/ButtonsOrLinks/RsButton';

import { withFirebase } from '../Firebase';
import RsInput from '../UI-Library/Forms/RsInput';

const PageWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 2%;
    padding-right: 2%;
`;

const MainWrapper = styled.div`
    max-width: 600px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div`
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
`;

const ProviderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const InputHeader = styled.p`
    color: var(--rsSecondary);
    text-align: left;
    margin-bottom: 2px;
`;

const InputWrapper = styled.div`
    width: 100%;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
`;

const ErrMsg = styled.div`
    width: 100%;
`;

const Err = styled.p`
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 15px;
`;

/**
 * signInGoogle
 * signInEmail
 * forgotPassword
 */
class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmail: false,
            email: '',
            password1: '',
            password2: '',
            errMsg: '',
            hasAttemptedSubmit: false,
            signingIn: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.createUser = this.createUser.bind(this);
    }

    isValidEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    isValidPassword(pass1, pass2) {
        if (pass1.length < 6) return false;
        if (pass1 !== pass2) return false;
        return true;
    }

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({ [evt.target.name]: value });
    }

    async createUser() {
        try {
            this.setState({ signingIn: true });
            await this.props.createUser(this.state.email, this.state.password1);
            //Log in event 'sign_up' [Firebase Analytics]
            this.props.firebase.analytics.logEvent('sign_up');
        } catch (err) {
            if (err.code === 'auth/email-already-in-use') {
                this.setState({ errMsg: 'already-exists' });
            } else {
                this.setState({ errMsg: 'bad request.' });
            }
        }
        this.setState({ signingIn: false });
    }

    render() {
        var emailErr = !this.isValidEmail(this.state.email);
        var passErr = !this.isValidPassword(this.state.password1, this.state.password2);

        return (
            <PageWrapper>
                <MainWrapper>
                    <Title>
                        <h1 className="bold">Register</h1>
                    </Title>
                    <p className="secondary" style={{ fontSize: '14px', marginBottom: '20px' }}>
                        Were you a teacher in our Fall Pilot? With the addition of student accounts, you'll have to register your account again. Please use the
                        same email as the one you used in the fall!
                    </p>
                    {!this.state.showEmail && (
                        <ProviderWrapper>
                            <RsButton
                                large={true}
                                width={'100%'}
                                mb={'10px'}
                                onClick={() => {
                                    //Log in event 'sign_up' [Firebase Analytics]
                                    this.props.firebase.analytics.logEvent('sign_up');
                                    this.props.signInGoogle();
                                }}
                            >
                                Continue with Google
                                <p className="secondary text-center " style={{ fontSize: '14px', marginBottom: 0 }}>
                                    (preferred method)
                                </p>
                            </RsButton>

                            <p className="bold secondary">OR</p>
                            <RsButton
                                large={true}
                                secondary={true}
                                width={'100%'}
                                onClick={() => {
                                    this.setState({ showEmail: true });
                                }}
                            >
                                Continue with Email
                            </RsButton>
                        </ProviderWrapper>
                    )}

                    {this.state.showEmail && (
                        <ProviderWrapper>
                            <InputWrapper>
                                <InputHeader>Email</InputHeader>
                                <RsInput mb={'10px'} name="email" value={this.state.email} onChange={this.handleChange}></RsInput>
                            </InputWrapper>

                            <InputWrapper>
                                <InputHeader>
                                    Choose a password <span style={{ fontSize: '14px' }}>(minimum of 6 characters)</span>
                                </InputHeader>
                                <RsInput name="password1" value={this.state.password1} onChange={this.handleChange}></RsInput>
                            </InputWrapper>

                            <InputWrapper>
                                <InputHeader>Repeat password</InputHeader>
                                <RsInput name="password2" value={this.state.password2} onChange={this.handleChange}></RsInput>
                            </InputWrapper>

                            <ButtonWrapper>
                                <RsButton
                                    secondary={true}
                                    onClick={() => {
                                        this.setState({ showEmail: false });
                                    }}
                                >
                                    Back
                                </RsButton>
                                <RsButton disabled={emailErr || passErr || this.state.signingIn} onClick={this.createUser}>
                                    {this.state.signingIn ? 'Working...' : 'Register'}
                                </RsButton>
                            </ButtonWrapper>
                            <ErrMsg>
                                {this.state.errMsg && this.state.errMsg !== 'already-exists' && (
                                    <Err>
                                        Sign up failed. Check your credentials and try again. Make sure the email is valid and the password is greater than 6
                                        characters. If this problem persists, let us know at support@rapstudy.com
                                    </Err>
                                )}
                                {this.state.errMsg === 'already-exists' && (
                                    <Err>An account already exists with these credentials. Click 'Sign in' to start using rapStudy!</Err>
                                )}
                            </ErrMsg>
                        </ProviderWrapper>
                    )}
                </MainWrapper>
            </PageWrapper>
        );
    }
}

export default withFirebase(SignUp);
