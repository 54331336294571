class Keyword {
    /**
     * Creates a new Keyword object.
     * @param {string} songId
     * @param {string} word
     * @param {string} definition
     * @param {string | null} unsplash
     * @param {string | null} unsplashUid
     * @param {string | null} unsplashName
     * @param {string | null} firebaseImg
     * @param {string | null} firebaseImgName
     * @param {boolean} passed
     * @param {string} keywordId
     * @param {number} numInLrc
     * @param {number} attempts
     * @param {Array<string>} attemptList
     */
    constructor(
        songId,
        word,
        definition,
        unsplash,
        unsplashUid,
        unsplashName,
        firebaseImg,
        firebaseImgName,
        passed,
        keywordId,
        numInLrc,
        attempts,
        attemptList,
    ) {
        if (!word) throw Error('Invalid argument: word');

        if (!unsplash) {
            unsplash = null;
            unsplashUid = null;
            unsplashName = null;
        }
        if (!unsplashUid || !unsplashName) {
            unsplashUid = null;
            unsplashName = null;
        }
        if (!firebaseImg) {
            firebaseImg = null;
            firebaseImgName = null;
        }
        this.songId = songId;
        this.word = word;
        this.definition = definition;
        this.unsplash = unsplash;
        this.unsplashUid = unsplashUid;
        this.unsplashName = unsplashName;
        this.firebaseImg = firebaseImg;
        this.firebaseImgName = firebaseImgName;
        this.passed = passed;
        this.keywordId = keywordId;
        this.numInLrc = numInLrc;
        this.attempts = attempts;
        this.attemptList = attemptList; // list of words of attempts
    }

    static keywordConverter = {
        toFirestore: function (keyword) {
            return {
                songId: keyword.getSongId(),
                word: keyword.getWord(),
                definition: keyword.getDefinition(),
                unsplash: keyword.unsplash,
                unsplashUid: keyword.unsplashUid,
                unsplashName: keyword.unsplashName,
                firebaseImg: keyword.firebaseImg,
                firebaseImgName: keyword.firebaseImgName,
                numInLrc: keyword.getNumInLrc(),
            };
        },
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            const keyword = new Keyword(
                data.songId,
                data.word,
                data.definition,
                data.unsplash,
                data.unsplashUid,
                data.unsplashName,
                data.firebaseImg,
                data.firebaseImgName,
                false,
                snapshot.id,
                data.numInLrc,
                0,
                [],
            );
            return keyword;
        },
    };

    static assignmentConverter = {
        toFirestore: function (keyword) {
            return {
                keywordData: {
                    songId: keyword.getSongId(),
                    word: keyword.getWord(),
                    definition: keyword.getDefinition(),
                    unsplash: keyword.unsplash,
                    unsplashUid: keyword.unsplashUid,
                    unsplashName: keyword.unsplashName,
                    firebaseImg: keyword.firebaseImg,
                    firebaseImgName: keyword.firebaseImgName,
                    numInLrc: keyword.getNumInLrc(),
                    passed: keyword.isPassed(),
                    attempts: keyword.getAttempts(),
                    attemptList: keyword.getAttemptList(),
                },
                keywordId: keyword.getId(),
                songId: keyword.getSongId(),
            };
        },
    };

    /**
     * A constructor built specifically for the assignment set submission
     * data format.
     * @param {any} data
     * @returns {Keyword}
     */
    static assignmentConverterGet(data) {
        const kD = data.keywordData;
        return new Keyword(
            kD.songId,
            kD.word,
            kD.definition,
            kD.unsplash,
            kD.unsplashUid,
            kD.unsplashName,
            kD.firebaseImg,
            kD.firebaseImgName,
            kD.passed,
            data.keywordId,
            kD.numInLrc,
            kD.attempts,
            kD.attemptList,
        );
    }

    /**
     * Cleans the string by removing all punctuation and whitespace.
     * @param {string} str
     * @returns {string}
     */
    static cleanWord(str) {
        // regex: https://stackoverflow.com/questions/4328500/how-can-i-strip-all-punctuation-from-a-string-in-javascript-using-regex
        const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
        return str
            .replace(regex, '')
            .replace(/\s{2,}/g, ' ')
            .trim()
            .toLowerCase();
    }

    /**
     * Sets the pass status for the Keyword.
     * @param {boolean}
     */
    setPassed(bool) {
        this.passed = bool;
    }

    /**
     * Make an attempt on a keyword. Does some minor regex preprocessing on the
     * attempt. See the static method cleanWord in Keyword for the specifics of
     * the regex pattern.
     * @param {string} str
     * @return {boolean}
     */
    makeAttempt(str) {
        if (this.isCorrect()) throw Error('Attempt cannot be made for keyword that is already correct.');
        const cleaned = Keyword.cleanWord(str);
        if (!cleaned || cleaned.trim().length === 0) throw Error('Attempt cannot be empty.');
        this.attempts += 1;
        this.attemptList.push(cleaned);
        return cleaned === this.word;
    }

    /**
     * Forces the keyword to be correct. If the keyword is already correct,
     * nothing changes.
     */
    makeCorrect() {
        if (this.isCorrect()) return;
        this.attempts += 1;
        this.attemptList.push(this.word);
    }

    /**
     * Returns true if the Keyword has a correct attempt, false otherwise.
     * @returns {boolean}
     */
    isCorrect() {
        return this.getSelected() === this.word;
    }

    /**
     * Returns true if the Keyword is passed, false otherwise.
     * @returns {boolean}
     */
    isPassed() {
        return this.passed;
    }

    /**
     * Returns the id for the Keyword.
     * @returns {string}
     */
    getId() {
        return this.keywordId;
    }

    /**
     * Returns the song id for the Keyword.
     * @returns {string}
     */
    getSongId() {
        return this.songId;
    }

    /**
     * Returns the word for the Keyword.
     * @returns {string}
     */
    getWord() {
        return this.word;
    }

    /**
     * Returns the definition for the Keyword.
     * @returns {string}
     */
    getDefinition() {
        return this.definition;
    }

    /**
     * Returns the index of the keyword in the lyrics (one indexed).
     * @returns {number}
     */
    getNumInLrc() {
        return this.numInLrc;
    }

    /**
     * Returns the word chosen by the user.
     * @returns {string | null}
     */
    getSelected() {
        if (this.attemptList.length === 0) return null;
        const [lastItem] = this.attemptList.slice(-1);
        return lastItem;
    }

    /**
     * Returns the number of attempts for the Keyword.
     * @returns {number}
     */
    getAttempts() {
        return this.attempts;
    }

    /**
     * Returns the attempt list for the Keyword.
     * @returns {Array<string>}
     */
    getAttemptList() {
        return this.attemptList;
    }

    /**
     * Resets the Keyword to have no attempts and unpassed.
     */
    reset() {
        this.attemptList = [];
        this.attempts = 0;
        this.passed = false;
    }

    /**
     * (This is a currently unused method - written in preparation for future
     * feature extensions.)
     * Removes the current image from the question.
     */
    deleteImage() {
        this.unsplash = null;
        this.unsplashUid = null;
        this.unsplashName = null;
        this.firebaseImg = null;
        this.firebaseImgName = null;
    }

    /**
     * (This is a currently unused method - written in preparation for future
     * feature extensions.)
     * Returns the source url of the current loaded image. If no image is loaded,
     * returns null.
     */
    getSrc() {
        if (this.firebaseImg) {
            // case 2: firebase storage URL
            return this.firebaseImg;
        } else if (this.unsplash) {
            // case 3: unsplash url=
            return this.unsplash;
        } else {
            // case 4: no image
            return null;
        }
    }

    /**
     * Sets the image fields to be the unsplash properties. Overwrites the
     * firebase storage fields.
     * @param {string} imgLink
     * @param {string} uid
     * @param {string} name
     */
    setUnsplash(imgLink, uid, name) {
        this.unsplash = imgLink;
        this.unsplashUid = uid;
        this.unsplashName = name;
        this.firebaseImg = null;
        this.firebaseImgName = null;
    }

    /**
     * Sets the image fields to be the firebase upload properties. Overwrites the
     * unsplash fields.
     * @param {string} url
     * @param {string} fileName
     */
    setUpload(url, fileName) {
        this.unsplash = null;
        this.unsplashUid = null;
        this.unsplashName = null;
        this.firebaseImg = url;
        this.firebaseImgName = fileName;
    }
}

export default Keyword;
