import { BROWSE_PROFILE, KARAOKE, PLAYLISTVIEW, UNITVIEW } from '../../../../constants/routes';

import BoxPreview from '../BoxPreview';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.h2`
    font-size: 20px;
    font-weight: bold;
    text-align: left;
`;

const StyledContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
`;

const StyledItemList = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    position: relative;
    height: ${(props) => props.rows * 190}px;
    padding-bottom: 20px;
`;

const itemConfig = {
    song: { url: KARAOKE + '?songId=', idFieldName: 'id' },
    playlist: { url: PLAYLISTVIEW + '/', idFieldName: 'playlistId' },
    unit: { url: UNITVIEW + '/', idFieldName: 'id' },
    artist: { url: BROWSE_PROFILE + '/' },
};

const InlineItemsDisplay = ({ header, items, onSeeAllClick, itemType, guest, remove, rows, wrap }) => {
    const { url, idFieldName } = itemConfig[itemType] || {};
    return (
        <StyledContainer>
            <StyledHeader>{header}</StyledHeader>
            {/* <StyledSeeAllButton onClick={onSeeAllClick}>See All</StyledSeeAllButton> */}
            <StyledItemList rows={rows} wrap={wrap}>
                {items.map((item, index) => {
                    const title = item.origTitle || item.name || item.title;
                    const subTitle = itemType === 'song' ? item.origArtist : '';
                    const bgColor = itemType === 'playlist' && item.color ? item.color : 'var(--rsBlue)';
                    const borderRadius = itemType === 'artist' ? '360px' : '5px';
                    const src = item.src || item.firebaseImg || item.image || null;
                    return (
                        <BoxPreview
                            title={title}
                            subtitle={subTitle}
                            borderRadius={borderRadius}
                            image={src}
                            bgColor={bgColor}
                            type={itemType}
                            center={itemType === 'artist'}
                            mr={`0px`}
                            ml={index !== 0 && '55px'}
                            artistImage={item.artistImage}
                            expand={true}
                            to={!guest && (!(itemType === 'artist') ? `${url}${item[idFieldName]}` : `${url}`)}
                            state={itemType === 'artist' ? { artist: item } : null}
                            remove={remove}
                            id={item[idFieldName]}
                            key={`BoxPreview_${index}`}
                        />
                    );
                })}
            </StyledItemList>
        </StyledContainer>
    );
};

InlineItemsDisplay.propTypes = {
    header: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            name: PropTypes.string,
            origTitle: PropTypes.string,
            origArtist: PropTypes.string,
            color: PropTypes.string,
            src: PropTypes.string,
            firebaseImg: PropTypes.string,
            artistImage: PropTypes.string,
            id: PropTypes.string,
            playlistId: PropTypes.string,
        }),
    ).isRequired,
    onSeeAllClick: PropTypes.func.isRequired,
    itemType: PropTypes.oneOf(['song', 'playlist', 'unit']),
    guest: PropTypes.bool,
    remove: PropTypes.bool,
    rows: PropTypes.number,
};

export default InlineItemsDisplay;
