import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavItem = styled.li`
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    min-height: 50px;
    justify-content: center;
    text-align: center;
    border-radius: 50px;
    padding: 0;
`;

const ActiveLinkStyle = {
    backgroundColor: 'black',
    textDecoration: 'none',
    boxShadow: '0 0 15px #37dbf6',
    borderRadius: '40px',
};

const LinkStyle = {
    color: 'white',
    textDecoration: 'none',
    borderBottom: '2px solid transparent',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    textOverflow: 'ellipsis',
    justifyContent: 'center',
};

const FeatureDiv = styled.div`
    margin-right: 9px;
    display: flex;
    align-items: center;
`;

class BannerNavLink extends Component {
    render() {
        const WrappedIcon = this.props.icon;
        return (
            <NavItem>
                <NavLink to={this.props.to} style={LinkStyle} activeStyle={ActiveLinkStyle} replace={this.props.replace}>
                    <span>
                        {this.props.icon && (
                            <FeatureDiv>
                                <WrappedIcon size="18px" hover={true} />
                            </FeatureDiv>
                        )}
                    </span>
                    <span>{this.props.name}</span>
                </NavLink>
            </NavItem>
        );
    }
}

BannerNavLink.propTypes = {
    /**
     * The icon component used.
     */
    icon: PropTypes.any,
    /**
     * The route to link to.
     */
    to: PropTypes.string.isRequired,
    /**
     * The text displayed in the link.
     */
    name: PropTypes.string.isRequired,
    /**
     * Does the link exhibit replace behavior?
     */
    replace: PropTypes.bool,
};

export default BannerNavLink;
