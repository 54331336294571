// import firebase from 'firebase';

/**
 * Returns a void Promise that updates the survey results when a user completes
 * it.
 */
export async function completeBannerSurvey(currentSurveyId, selectedChoices, surveyQuestion, surveyChoices) {
    try {
        const uid = this.auth.currentUser.uid;
        const selectedArr = Object.keys(selectedChoices).filter((key) => selectedChoices[key]);
        await this.db.collection('completeBannerSurveyUsers').add({
            userId: uid,
            surveyId: currentSurveyId,
            selectedChoices: selectedArr,
            question: surveyQuestion,
            choices: Object.keys(surveyChoices),
        });
    } catch (err) {
        console.log(err);
        throw err;
    }
}

/**
 * Returns a Promise that resolves with a boolean expressing if the user has
 * completed the current survey or not.
 *
 * @param {string} currentSurveyId
 * @returns Promise<boolean>
 */
export async function checkBannerSurveyStatus(currentSurveyId) {
    const uid = this.auth.currentUser.uid;
    const docSnap = await this.db.collection('completeBannerSurveyUsers').where('userId', '==', uid).where('surveyId', '==', currentSurveyId).get();

    if (docSnap.empty) {
        return false;
    }
    return true;
}
