import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BlueRectangle = styled.div`
    margin-left: 4px;
    margin-right: 4px;
    height: 5px;
    width: 40px;
    background-color: var(--rsMajor);
`;

const GreyRectangle = styled.div`
    margin-left: 4px;
    margin-right: 4px;
    height: 5px;
    width: 40px;
    background-color: var(--rsTertiary);
`;

const RectangleDiv = styled.div`
    display: flex;
    padding-bottom: 30px;
`;

class RectangleProgress extends Component {
    renderRectangles() {
        let result = [];
        let questionsRemaining = this.props.length - this.props.activeNum;
        for (let i = 0; i < this.props.activeNum + 1; i++) {
            result.push(<BlueRectangle key={'blue' + i} />);
        }

        for (let i = 1; i < questionsRemaining; i++) {
            result.push(<GreyRectangle key={'grey' + i} />);
        }

        return result;
    }

    render() {
        return <RectangleDiv>{this.renderRectangles()}</RectangleDiv>;
    }
}

RectangleProgress.propTypes = {
    /**
     * The length of the total number of rectangles / questions we have
     */
    length: PropTypes.number.isRequired,
    /**
     * The current rectangle / question we are on
     */
    activeNum: PropTypes.number.isRequired,
};

RectangleProgress.defaultProps = {
    length: 0,
    activeNum: 0,
};

export default RectangleProgress;
