import React, { Component } from 'react';
import { withFirebase } from '../../Firebase';
import styled from 'styled-components';
import Loading from '../../UI-Library/Misc/Loading';
import UnitContent from './UnitContent';

import { subjectColors } from '../../../constants/colors';

import ImageInfoBanner from '../../UI-Library/Containers/ImageInfoBanner';

const LibraryButton = styled.button`
    border: 0;
    color: var(--rsSecondary);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        text-decoration: underline;
    }
    background-color: transparent;
`;

/**
 * UnitViewBase is the component for the "unitView" page in Internal.
 */
export class UnitViewBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inLibrary: null,
            unitDict: null,
            ready: false,
            error: null,
        };
        this.toggleLibrary = this.toggleLibrary.bind(this);
    }

    /**
     * Assumes that IDs are pairwise distinct.
     * Future optimization: may implement a lookup table for songs and their ids
     * to avoid repeated computation.
     */
    async componentDidMount() {
        try {
            const params = this.props.match.params;
            const unitId = params.unitId;
            await this.fetchData(unitId);
        } catch (err) {
            this.setState({ error: err });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        try {
            const params = this.props.match.params;
            if (!this.props.match) return;

            if (params.unitId !== prevProps.match.params.unitId) {
                this.setState({ ready: false, unitId: params.unitId });
                await this.fetchData(params.unitId);
                this.setState({ ready: true });
            }
        } catch (err) {
            this.setState({ error: err });
        }
    }

    async fetchData(id) {
        // Resolves with a UnitDict object.
        const unitDataProm = this.props.firebase.getUnitData(id);
        const inLibraryProm = !this.props.guest && this.props.firebase.inLibrary(id);

        var results = await Promise.all([unitDataProm, inLibraryProm]);

        const unitDict = results[0];

        // TODO: Move to getter instead of directly referencing properties
        const unitRef = unitDict.getRef();
        const inLibrary = results[1];

        // An array of TopicDict objects.
        const topics = await this.props.firebase.getTopicsInUnit(unitRef);
        await Promise.all(
            topics.map(async (topicDict) => {
                var subtopicRefs = await this.props.firebase.getSubtopicsInTopic(
                    // TODO: Use getter from TopicDict
                    topicDict.getRef(),
                );

                subtopicRefs.forEach((subtopicDict) => {
                    topicDict.addSubtopic(subtopicDict);
                });

                unitDict.addTopic(topicDict);
            }),
        );

        this.setState({ unitDict: unitDict, inLibrary: inLibrary, ready: true });
    }

    async toggleLibrary() {
        if (this.props.guest) return;
        if (this.state.inLibrary) {
            await this.props.firebase.removeFromLibrary(this.state.unitDict.getId());
        } else {
            await this.props.firebase.addToLibrary(this.state.unitDict.getRef());
        }
        this.setState({ inLibrary: !this.state.inLibrary });
    }

    render() {
        if (this.state.error) {
            console.log(this.state.error);
            throw this.state.error;
        }
        if (!this.state.ready) {
            return <Loading zoom={true} />;
        }
        const unitDict = this.state.unitDict;
        const color = subjectColors[unitDict.getSubject()];

        return (
            <div style={{}}>
                <ImageInfoBanner backgroundColor={color} url={unitDict.getSrc()}>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '0px',
                            }}
                        >
                            <h1 className="bold" style={{ marginBottom: 0 }}>
                                {unitDict.getName()}
                            </h1>
                            {!this.props.guest && (
                                <LibraryButton onClick={this.toggleLibrary}>
                                    {this.state.inLibrary ? <span>Remove from Library</span> : <span>Add to Library</span>}
                                </LibraryButton>
                            )}
                        </div>
                        <p className="secondary">{unitDict.getSubject()}</p>
                    </div>
                </ImageInfoBanner>
                <UnitContent guest={this.props.guest} unitDict={unitDict} backgroundColor={color} />
                <div style={{ height: '100px' }} />
            </div>
        );
    }
}

const UnitView = withFirebase(UnitViewBase);
export default UnitView;
