import React, { Component } from 'react';
import { withFirebase } from '../../../Firebase';

import CreatePlaylistModal from '../CreatePlaylistModal';
import AddShareModal from '../AddShareModal';

export class AddToPlaylistModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playlists: null,
            showNewPlaylistModal: false,
            error: null,
            hasFetched: false,
        };
        this.addToPlaylist = this.addToPlaylist.bind(this);
        this.returnPlaylist = this.returnPlaylist.bind(this);
        this.submit = this.submit.bind(this);
        this.toggleNewPlaylistModal = this.toggleNewPlaylistModal.bind(this);
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
    }

    async componentDidMount() {
        if (this.props.show) {
            const playlists = await this.props.firebase.getAuthoredPlaylists(this.props.firebase.auth.currentUser.uid);
            this.setState({ playlists: playlists });
        }
    }

    async componentDidUpdate() {
        if (this.state.hasFetched) return;
        if (!this.props.show) return;
        const playlists = await this.props.firebase.getAuthoredPlaylists(this.props.firebase.auth.currentUser.uid);
        this.setState({ playlists: playlists, hasFetched: true });
    }

    async returnPlaylist(playlistObj) {
        const playlists = this.state.playlists;
        playlists.unshift(playlistObj);
        this.setState({ playlists: playlists });
    }

    /**
     * Adds a song to a playlist.
     * @param {string} playlistId
     * @param {string} songId
     * @returns true if success, null if not.
     */
    async addToPlaylist(playlistId) {
        let success = null;
        this.setState({ error: false });

        const songArr = await this.props.firebase.getPlaylistSongsById(playlistId);
        for (let i = 0; i < songArr.length; i++) {
            if (songArr[i].id === this.props.id) {
                this.setState({ error: true });
            }
        }
        if (!this.state.error) {
            success = await this.props.firebase.addPlaylistSong(playlistId, this.props.id);
        }
        return success;
    }

    submit() {
        this.setState({ showNewPlaylistModal: false });
        this.props.toggle();
    }

    toggleNewPlaylistModal() {
        this.props.toggle();
        this.setState({
            showNewPlaylistModal: !this.state.showNewPlaylistModal,
            error: null,
        });
    }

    handleModalShowHide() {
        this.props.toggle();
        this.setState({
            error: null,
            showNewPlaylistModal: false,
        });
    }

    render() {
        let errorMsg;
        if (this.state.error) {
            errorMsg = 'This song is already in your playlist';
        }
        return (
            <AddShareModal
                add={this.addToPlaylist}
                title="Add to Playlist"
                type="Playlist"
                items={this.state.playlists}
                toggle={this.handleModalShowHide}
                itemIdName="playlistId"
                errorMsg={errorMsg}
                show={this.props.show}
                nameOrTitle="name"
                submit={this.submit}
                toggleNew={this.toggleNewPlaylistModal}
                newPrompt={'Create Playlist'}
            >
                <CreatePlaylistModal
                    show={this.state.showNewPlaylistModal}
                    willRedirect={false}
                    returnPlaylist={this.returnPlaylist}
                    toggle={this.toggleNewPlaylistModal}
                />
            </AddShareModal>
        );
    }
}

export default withFirebase(AddToPlaylistModal);
