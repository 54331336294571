export const paletteList = [
    '#ff7a55',
    '#ffc952',
    '#5db508',
    '#408651',
    '#4bbba6',
    '#528fff',
    '#c838ff',
    '#9c2e7b',
    '#fd4c1b',
    '#fcb210',
    '#438800',
    '#2a5437',
    '#008169',
    '#4725d7',
    '#b500fb',
    '#8c2155',
    '#ffad93',
    '#ffe177',
    '#9dd665',
    '#65c375',
    '#94d0c6',
    '#aed4e6',
    '#e093fe',
    '#d464b2',
];

export const columnsPaletteList = [
    ['#fd4c1b', '#ff7a55', '#ffad93'],
    ['#fcb210', '#ffc952', '#ffe177'],
    ['#438800', '#5db508', '#9dd665'],
    ['#2a5437', '#408651', '#65c375'],
    ['#008169', '#4bbba6', '#94d0c6'],
    ['#4725d7', '#528fff', '#aed4e6'],
    ['#b500fb', '#c838ff', '#e093fe'],
    ['#8c2155', '#9c2e7b', '#d464b2'],
];

export const rowsPaletteList = [
    ['#fd4c1b', '#fcb210', '#438800', '#2a5437', '#008169', '#4725d7', '#b500fb', '#8c2155'],
    ['#ff7a55', '#ffc952', '#5db508', '#408651', '#4bbba6', '#528fff', '#c838ff', '#9c2e7b'],
    ['#ffad93', '#ffe177', '#9dd665', '#65c375', '#94d0c6', '#aed4e6', '#e093fe', '#d464b2'],
];

export const subjectColors = {
    Science: 'rgba(72, 49, 212, 0.7)',
    'Social Studies': 'rgba(252, 204, 84, 0.7)',
    'Life Skills': 'rgba(167, 34, 91, 0.7)',
    Math: 'rgba(4, 152, 138, 0.7)',
    Technology: 'rgba(4, 92, 204, 0.7)',
    'English Language Arts': 'rgba(148, 36, 140, 0.7)',
};
