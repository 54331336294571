import React, { useState } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../Firebase';

const FormContainer = styled.div`
    background-color: #000000;
    padding: 20px;
    border-radius: 10px;
    width: 1000px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    margin-top: 20px;
`;

const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const FormGroup = styled.div`
    flex: 1;
    margin-top: 20px;
    margin-right: ${(props) => (props.last ? '0px' : '50px')};
`;

const OptionalFormGroup = styled.div`
    flex: 1;
    margin-top: 20px;
    margin-right: 7.5px;
`;

const FormGroupName = styled.div`
    flex: 1;
    margin-top: 20px;
    margin-right: ${(props) => (props.last ? '0px' : '50px')};
`;

const FormGroupZip = styled.div`
    flex: 1;
    width: 100px;
    margin-top: 20px;
    margin-right: ${(props) => (props.last ? '0px' : '50px')};
`;

const SubjectFormGroup = styled.div`
    flex: 1;
    max-width: 1000px;
    margin-top: 20px;
    margin-left: -250px;
    margin-right: ${(props) => (props.last ? '0px' : '50px')};
`;

const Label = styled.label`
    font-size: 1.2em;
    display: block;
    margin-bottom: 13px;
    font-weight: bold;
    color: white;
`;

const Input = styled.input`
    width: 100%;
    height: 30px;
    padding-left: 16px;
    border: none;
    border-radius: 50px;
    background-color: #000000;
    box-shadow: 0 0 5px 3px rgba(236, 236, 236, 0.4);
    color: white;
    font-size: 1em;
`;

const NameInput = styled.input`
    width: 330px;
    height: 30px;
    padding-left: 16px;
    border: none;
    border-radius: 50px;
    background-color: #000000;
    box-shadow: 0 0 5px 3px rgba(236, 236, 236, 0.4);
    color: white;
    font-size: 1em;
`;

const EmailInput = styled.input`
    width: 395px;
    height: 30px;
    padding-left: 16px;
    border: none;
    border-radius: 50px;
    background-color: #000000;
    box-shadow: 0 0 5px 3px rgba(236, 236, 236, 0.4);
    color: white;
    font-size: 1em;
`;

const ZipInput = styled.input`
    width: 127px;
    padding-left: 16px;
    height: 30px;
    border: none;
    border-radius: 50px;
    background-color: #000000;
    box-shadow: 0 0 5px 3px rgba(236, 236, 236, 0.4);
    color: white;
    font-size: 1em;
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;
    max-width: 800px;
    width: 100%;
    flex-wrap: wrap;
`;

const GradeButtonGroup = styled.div`
    display: flex;
    gap: 10px;
    max-width: 400px;
    flex-wrap: wrap;
`;

const Button = styled.button`
    background-color: ${(props) => (props.selected ? '#00d9ff' : '#000000')};
    border: none;
    padding: 4px 20px;
    border-radius: 50px;
    font-weight: bold;
    color: ${(props) => (props.selected ? '#ffffff' : '#37DBF6')};
    cursor: pointer;
    font-size: 1em;
    transition: all 0.3s;
    box-shadow: 0 0 5px 3px rgba(236, 236, 236, 0.4);

    &:hover {
        background-color: ${(props) => (props.selected ? '#00d9ff' : '#444')};
        color: ${(props) => (props.selected ? '#111' : '#00d9ff')};
    }
`;

const SubjectButton = styled.button`
    background-color: ${(props) => (props.selected ? '#00d9ff' : '#000000')};
    border: none;
    text-align: center;
    width: 100px;
    height: 29px;
    margin-right: 8px;
    margin-bottom: 10px;
    border-radius: 50px;
    font-weight: bold;
    color: ${(props) => (props.selected ? '#ffffff' : '#37DBF6')};
    cursor: pointer;
    font-size: 1em;
    transition: all 0.3s;
    box-shadow: 0 0 5px 3px rgba(236, 236, 236, 0.4);

    &:hover {
        background-color: ${(props) => (props.selected ? '#00d9ff' : '#444')};
        color: ${(props) => (props.selected ? '#111' : '#00d9ff')};
    }
`;

const GradeButton = styled.button`
    background-color: ${(props) => (props.selected ? '#00d9ff' : '#000000')};
    border: none;
    width: 50px;
    height: 29px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    font-weight: bold;
    color: ${(props) => (props.selected ? '#ffffff' : '#37DBF6')};
    cursor: pointer;
    font-size: 1em;
    transition: all 0.3s;
    box-shadow: 0 0 5px 3px rgba(236, 236, 236, 0.4);

    &:hover {
        background-color: ${(props) => (props.selected ? '#00d9ff' : '#444')};
        color: ${(props) => (props.selected ? '#111' : '#00d9ff')};
    }
`;

const SubmitButtonContainer = styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: -500px;
    justify-content: right;
    margin-top: -100px;
    margin-right: 7.5px;
`;

const SubmitButton = styled(Button)`
    height: 40px;
    margin-bottom: -100px;
    text-align: center;
    width: 130px;
    background-color: #00d9ff;
    color: #ffffff;

    &:hover {
        background-color: #00c3e0;
    }
`;

const OptionalInput = styled(Input)`
    background-color: ;
`;

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: #888; /* Updated to gray */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 400px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
`;

const BottomDiv = styled.div`
    padding-top: 80px;
    justify-content: center;
    text-align: center;
`;

const ModalButton = styled.button`
    background-color: #00d9ff;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    color: #111;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;

    &:hover {
        background-color: #444;
        color: #00d9ff;
    }
`;

const ThankYouModal = ({ onClose }) => {
    return (
        <ModalBackground>
            <ModalContent>
                <h2>Thank You for Signing Up!</h2>
                <p>Your information has been successfully submitted.</p>
                <ModalButton onClick={onClose}>Close</ModalButton>
            </ModalContent>
        </ModalBackground>
    );
};

const Form = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        zip: '',
        selectedGrades: [],
        selectedSubjects: [],
        music: '',
        extra: '',
    });

    const [showModal, setShowModal] = useState(false);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const toggleSelection = (type, value) => {
        setFormData((prevState) => {
            const updatedSelection = prevState[type].includes(value) ? prevState[type].filter((item) => item !== value) : [...prevState[type], value];
            return { ...prevState, [type]: updatedSelection };
        });
    };

    const handleSubmit = async () => {
        /**
         * Send this data to a Firebase Collection
         * name: formData.name,
                schoolAddress: formData.email, // Assuming school address is email here
                zipcode: formData.zip,
                grade: formData.selectedGrades.join(','), // Converting array to comma-separated string
                subject: formData.selectedSubjects.join(','), // Converting array to comma-separated string
                music: formData.music,
                extra: formData.extra, // Sending the extra field data
         */

        try {
            props.firebase.appendToWaitlist({
                name: formData.name,
                email: formData.email,
                zip: formData.zip,
                selectedGrades: formData.selectedGrades,
                selectedSubjects: formData.selectedSubjects,
                music: formData.music,
                extra: formData.extra,
            });
            
            setShowModal(true);

            setFormData({
                name: '',
                email: '',
                zip: '',
                selectedGrades: [],
                selectedSubjects: [],
                music: '',
                extra: '',
            });
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const grades = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9+'];
    const subjects = ['ELA', 'Math', 'Science', 'History', 'Arts', 'SEL', 'Language', 'Tech', 'Other', 'Admin'];

    return (
        <FormContainer>
            <FormRow>
                <FormGroupName>
                    <Label htmlFor="name">First and last name 📩</Label>
                    <NameInput type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} placeholder="Travis Swift" />
                </FormGroupName>
                <FormGroup>
                    <Label htmlFor="email">School email address 📧</Label>
                    <EmailInput type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="mrteacher@k12.edu" />
                </FormGroup>
                <FormGroupZip last>
                    <Label htmlFor="zip">Zip code 🏫</Label>
                    <ZipInput type="text" id="zip" name="zip" value={formData.zip} onChange={handleInputChange} placeholder="01234" />
                </FormGroupZip>
            </FormRow>

            {/* Second Row: Grades and Subjects */}
            <FormRow>
                <FormGroup>
                    <Label>What grade(s) do you teach? 👩‍🏫</Label>
                    <GradeButtonGroup>
                        {grades.map((grade) => (
                            <GradeButton
                                type="button"
                                key={grade}
                                selected={formData.selectedGrades.includes(grade)}
                                onClick={() => toggleSelection('selectedGrades', grade)}
                            >
                                {grade}
                            </GradeButton>
                        ))}
                    </GradeButtonGroup>
                </FormGroup>
                <SubjectFormGroup last>
                    <Label>What subject(s) do you teach? 📚</Label>
                    <ButtonGroup>
                        {subjects.map((subject) => (
                            <SubjectButton
                                type="button"
                                key={subject}
                                selected={formData.selectedSubjects.includes(subject)}
                                onClick={() => toggleSelection('selectedSubjects', subject)}
                            >
                                {subject}
                            </SubjectButton>
                        ))}
                    </ButtonGroup>
                </SubjectFormGroup>
            </FormRow>

            <OptionalFormGroup style={{ paddingTop: '20px'}}>
                <Label htmlFor="music">Whose music do you want on rapStudy? (optional) 🧑‍🎤</Label>
                <OptionalInput
                    type="text"
                    id="music"
                    name="music"
                    placeholder="Please list your favorite artists and songs! We’ll do our best to make some bangers. 😎"
                    value={formData.music}
                    onChange={handleInputChange}
                />

                <BottomDiv>
                    <img id="nav_home" src="/images/logos/notelogo.png" alt="Logo" width="20px" height="50px" style={{ marginTop: '10px', marginBottom: '10px' }} />
                    <br/>
                    <strong style={{ color: 'grey', fontSize: '18px', textAlign: 'center', paddingBottom: '50px', paddingTop: '50px' }}>Launching 2025.</strong>
                </BottomDiv>
            </OptionalFormGroup>

            {/* <FormGroup>
                <Label htmlFor="extra">Additional Information (optional) 📝</Label>
                <OptionalInput
                    type="text"
                    id="extra"
                    name="extra"
                    placeholder="Any additional details you want to share."
                    value={formData.extra}
                    onChange={handleInputChange}
                />
            </FormGroup> */}

            <SubmitButtonContainer>
                <SubmitButton type="button" onClick={handleSubmit}>
                    Submit
                </SubmitButton>
            </SubmitButtonContainer>

            {showModal && <ThankYouModal onClose={() => setShowModal(false)} />}
        </FormContainer>
    );
};

export default withFirebase(Form);
