import React, { Component } from 'react';
import styled from 'styled-components';

import AssignmentSummary from '../../../../UI-Library/ItemDisplays/AssignmentSummary';
import { withRouter } from 'react-router-dom';

const MainWrapper = styled.div`
    padding: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
`;

class AssignmentViewTeacherSummary extends Component {
    constructor(props) {
        super(props);
        this.handleClickName = this.handleClickName.bind(this);
        this.handleClickScore = this.handleClickScore.bind(this);
    }

    handleClickName(studentId) {
        this.props.history.push(`/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/teacher/individual/${studentId}`);
    }

    handleClickScore(studentId, exId) {
        this.props.history.push(`/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/teacher/individual/${studentId}`);
    }

    render() {
        return (
            <MainWrapper>
                <h2>All Grades</h2>
                {this.props.gradesSummary.isEmpty() ? (
                    <p>No students yet! Add students to your class to get started.</p>
                ) : (
                    <AssignmentSummary
                        grades={this.props.gradesSummary}
                        onClickScore={this.handleClickScore}
                        onClickName={this.handleClickName}
                    ></AssignmentSummary>
                )}
            </MainWrapper>
        );
    }
}

export default withRouter(AssignmentViewTeacherSummary);
