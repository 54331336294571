/**
 * Lrc is a class that represents the lyrics of a song.
 * @field duration: a string representing the length of the song in seconds.
 * @field blocks: an array of Block objects.
 * @field firstTime: a string representing the time of the first word.
 * @field numBlocks: total number of blocks.
 * @field numLines: total number of lines.
 */
class Lrc {
    /**
     * Creates a new Lrc object given an json string.
     * @param {string} json_txt
     * @precondition json_txt is a valid JSON string with the proper format.
     * @precondition There must be a non-empty text before every #
     */
    constructor(json_txt) {
        var fields = JSON.parse(json_txt);
        this._blocks = [];
        this._lines = [];
        this._words = [];

        var blockObj = new Block(0);
        var lineObj;
        var wordObj;

        var numInBlock = 0;
        var numInLrc = 0;

        var specNum = 0;
        var ind = 0;
        var lineInd = 0;

        if (fields.lines.length !== 0) {
            if (fields.markers[0].text === '#') {
                throw new Error('Lyrics cannot start with a #');
            } else if (fields.markers[fields.markers.length - 1].text !== '#') {
                throw new Error('Lyrics have to end with #');
            } else {
                // separate line array into blocks using special char
                // each block is terminated by a special char in its own line
                for (var i = 0; i < fields.markers.length; i++) {
                    //there needs to be a # at the very end of the song
                    if (fields.markers[i].text === '#') {
                        if (i === 0) throw new Error('Lyrics cannot start with a #');
                        blockObj.start = fields.markers[ind].position;
                        blockObj.end = fields.markers[i].position;
                        blockObj.startLine = ind - specNum;
                        blockObj.endLine = i - 1 - specNum;
                        ind = i + 1;
                        this.addBlock(blockObj);
                        numInBlock = 0;
                        blockObj = new Block(ind);
                        specNum++;
                    } else {
                        //add line objects to blockObj
                        lineObj = new Line(i);
                        lineObj._start = fields.markers[i].position;
                        lineObj._end = fields.markers[i + 1].position;
                        lineObj._pos = lineInd++;
                        var words = fields.markers[i].text.split(' ');
                        for (var j = 0; j < words.length - 1; j++) {
                            numInBlock++;
                            numInLrc++;
                            var start = fields.wordTimings[i][j];
                            wordObj = new Word(words[j] + ' ', numInBlock, numInLrc, start);
                            wordObj._end = fields.wordTimings[i][j + 1];
                            lineObj.addWord(wordObj);
                            // numInLrc is 1-index!!
                            this._words.push(wordObj);
                        }
                        numInBlock++;
                        numInLrc++;
                        var start2 = fields.wordTimings[i][j];
                        wordObj = new Word(words[j] + ' ', numInBlock, numInLrc, start2);
                        wordObj._end = fields.markers[i + 1].position;
                        this._words.push(wordObj);
                        lineObj.addWord(wordObj);
                        this._lines.push(lineObj);
                        blockObj.addLine(lineObj);
                    }
                }

                this._firstTime = fields.markers[0].position;
                this._numLines = fields.lines.length - specNum;
                this._numBlocks = this._blocks.length;
                this._duration = fields.markers[fields.markers.length - 1].position;
            }
        }
    }

    get blocks() {
        return this._blocks;
    }

    set blocks(blockArr) {
        this._blocks = blockArr;
    }

    get words() {
        return this._words;
    }

    set words(wordArr) {
        this._words = wordArr;
    }

    get lines() {
        return this._lines;
    }

    set lines(lineArr) {
        this._lines = lineArr;
    }

    get duration() {
        return this._duration;
    }

    set duration(time) {
        this._duration = time;
    }

    get firstTime() {
        return this._firstTime;
    }

    set firstTime(time) {
        this._firstTime = time;
    }

    /**
     * Appends a Block object to the end of the blocks array.
     * @param {Block} block - a valid non-empty Block object.
     */
    addBlock(block) {
        if (!(block instanceof Block)) throw Error('addBlock: input is not a Block object.');
        this._blocks.push(block);
    }

    /**
     * Retrieves start and end time of words at given 1-based indices
     * @param {number} index1 - index of first word
     * @param {number | null} index2 - index of second word. null if not given
     * @return {number | Array<number>}
     */
    getTimeWord(index1, index2) {
        if (index2 == null) {
            return this._words[index1 - 1].start;
        }
        return [this._words[index1 - 1].start, this._words[index2 - 1].end];
    }

    /**
     * Retrieves start and end time of lines at given 1-based indices
     * @param {number} index1 - index of first line
     * @param {number | null} index2 - index of second line. null if not given
     * @return {number | Array<number>}
     */
    getTimeLine(index1, index2) {
        if (index2 == null) {
            return this._lines[index1 - 1].start;
        }
        return [this._lines[index1 - 1].start, this._lines[index2 - 1].end];
    }

    /**
     * Returns the current word's numInLrc based on currentTime. Null if a word
     * cannot be found.
     * @param {number} currentTime
     * @return {number} the index (1 based) of the current word.
     */
    getCurrentWord(currentTime) {
        if (!currentTime || currentTime < 0) {
            return null;
        }
        var left = 0;
        var right = this._words.length - 1;
        var wordObj = null;
        while (left < right) {
            //left.end < right.start
            var m = Math.floor((left + right) / 2);
            wordObj = this._words[m];
            if (currentTime <= wordObj.end) right = m;
            else if (currentTime >= wordObj.start) left = m + 1;
        }
        if (!(currentTime <= this._words[left].end && currentTime >= this._words[left].start)) return null;
        return this._words[left].numInLrc; //wordObj.numInLrc;
    }

    /**
     * Returns the word object at the numInLrc provided. Null if there are no words
     * in the Lrc object.
     * @param {number} numInLrc
     * @return {Word}
     */
    getWordAtNumInLrc(numInLrc) {
        if (this._words.length === 0) return null;
        return this._words[numInLrc - 1];
    }
}

/**
 * Block is a class that represents a block of lines (a chorus, verse, etc).
 * @field start: a string representing the start time of the block in seconds.
 * @field end: a string representing the end time of the block in seconds.
 * @field startLine: number representing index of first line in block.
 * @field endLine: number representing index of last line in block.
 * @field lines: an array of Line objects.
 */
class Block {
    /**
     * Creates a new Block object with an empty array of lines.
     * @param {number} startLine - index of first line in block.
     */
    constructor(startLine) {
        this._lines = [];
        this._start = null;
        this._end = null;
        this._startLine = startLine;
        this._endLine = null;
    }

    get lines() {
        return this._lines;
    }

    set lines(linesArr) {
        this._lines = linesArr;
    }

    get start() {
        return this._start;
    }

    set start(time) {
        this._start = time;
    }

    get end() {
        return this._end;
    }

    set end(time) {
        this._end = time;
    }

    get startLine() {
        return this._startLine;
    }

    set startLine(startLine) {
        this._startLine = startLine;
    }

    get endLine() {
        return this._endLine;
    }

    set endLine(endLine) {
        this._endLine = endLine;
    }

    /**
     * Appends a Line object to the end of the lines array.
     * @param {Line} line - a valid non-empty Line object.
     */
    addLine(line) {
        if (!(line instanceof Line)) throw Error('addLine: input is not a Line object.');
        this._lines.push(line);
    }
}

/**
 * Line is a class that represents a list of words in a song.
 * @field start: a string representing the start time of the line in seconds.
 * @field end: a string representing the end time of the line in seconds.
 * @field words: an array of Word objects.
 * @field pos: the position of the line in the lyrics. Index begins at 0.
 */
class Line {
    /**
     * Creates a new Line object with an empty array of words.
     * @param {number} pos - the index of the line in the lyrics.
     * @precondition pos >= 0.
     */
    constructor(pos) {
        this._words = [];
        this._start = null;
        this._end = null;
        this._pos = pos;
    }

    get words() {
        return this._words;
    }

    set words(wordArr) {
        this._words = wordArr;
    }

    get start() {
        return this._start;
    }

    set start(time) {
        this._start = time;
    }

    get end() {
        return this._end;
    }

    set end(time) {
        this._end = time;
    }

    get pos() {
        return this._pos;
    }

    set pos(n) {
        this._pos = n;
    }

    /**
     * Pushes the word on to a the list of words in this line
     * @param {Word} word - a valid non-empty Word object.
     */
    addWord(word) {
        if (!(word instanceof Word)) throw Error('addWord: input is not a Word object.');
        this._words.push(word);
    }
}

/**
 * Word is a class that represents a word in a song.
 * @field start: a string representing the start time of the block in seconds.
 * @field end: a string representing the end time of the block in seconds.
 * @field word: an array of Line objects.
 * @field numInBlock: the index of the word in the parent Block, is greater than 0.
 */
class Word {
    /**
     * Creates a new Word object.
     * @param {string} word - the text of the word.
     * @param {number} numInBlock - the index of the word in the block.
     * @param {string} start: the start time of the word in seconds.
     */
    constructor(word, numInBlock, numInLrc, start) {
        this._word = word;
        this._numInBlock = numInBlock;
        this._numInLrc = numInLrc;
        this._start = start;
        this._end = null;
        this.state = { contentDocRef: null };
    }

    get word() {
        return this._word;
    }

    get numInBlock() {
        return this._numInBlock;
    }

    get numInLrc() {
        return this._numInLrc;
    }

    set word(wordStr) {
        this._word = wordStr;
    }

    set numInBlock(num) {
        this._numInBloc = num;
    }

    set numInLrc(num) {
        this._numInLrc = num;
    }

    get start() {
        return this._start;
    }

    set start(time) {
        this._start = time;
    }

    get end() {
        return this._end;
    }

    set end(time) {
        this._end = time;
    }
}

export default Lrc;
