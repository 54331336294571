import React, { Component } from 'react';

import CircleDots from '../../Icons/CircleDots';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DropDownContainer = styled('div')`
  position: relative;
  width: ${(props) => (props.width ? String(props.width) + 'px;' : 'fit-content;')}
  text-align: center;
  padding-bottom: 4px;
`;

const DropDownHeader = styled('div')`
    cursor: pointer; /* Mouse pointer on hover */
`;

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: 999;
  text-align: left;
  width: ${(props) => (props.width ? String(props.width) + 'px;' : '200px;')}
  bottom: ${(props) => (props.direction === 'top' ? 'calc(100% + 5px);' : 'unset;')}
  ${(props) =>
      props.direction === 'left'
          ? 'right: calc(100% - 5px);'
          : props.direction === 'center'
          ? 'left: calc(-' + String(props.width / 2) + 'px  + 50%);'
          : 'left: calc(100% - 5px);'}
`;

const DropDownList = styled('ul')`
    font-size: 14px;
    padding: 0;
    margin: 0;
    background: var(--rsDropdown);
`;

const ListItem = styled('li')`
    :hover {
        background: rgba(255, 255, 255, 0.12);
    }
    padding: 10px;
    list-style: none;
    cursor: pointer;
`;

/**
 * DropdownMenu is the generalized dropdown menu component. If a child component
 * is defined, it becomes the toggle. Otherwise, it defaults to the circle with
 * dots icon.
 */
class DropdownMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.dropdownRef = React.createRef();
        this.handleDropdownToggle = this.handleDropdownToggle.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleDropdownToggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        const userRole = this.props.isTeacher ? 'Teacher' : 'Student';
        const direction = this.props.direction ? this.props.direction : 'left';
        const size = this.props.iconSize === 'small' ? '24px' : this.props.iconSize === 'large' ? '32px' : '24px';
        return (
            <DropDownContainer onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} ref={this.dropdownRef}>
                <DropDownHeader onClick={() => this.handleDropdownToggle()}>
                    {!this.props.children ? <CircleDots size={size} onClick={() => null} /> : this.props.children}
                </DropDownHeader>
                {this.state.isOpen && (
                    <DropDownListContainer direction={direction} width={this.props.width}>
                        <DropDownList>
                            {this.props.menuItems.map((item, index) => {
                                let classNames = '';
                                if (userRole !== item.requiredRole && item.requiredRole !== 'Both') {
                                    classNames = 'hidden';
                                }
                                return (
                                    <ListItem
                                        className={classNames}
                                        key={'DROPDOWN_' + index + '_' + item.desc}
                                        onClick={() => {
                                            this.handleDropdownToggle();
                                            item.toggleMenuItemAction();
                                        }}
                                    >
                                        {item.desc}
                                    </ListItem>
                                );
                            })}
                        </DropDownList>
                    </DropDownListContainer>
                )}
            </DropDownContainer>
        );
    }
}

DropdownMenu.propTypes = {
    /**
     * The authorization of the user. If true, [menuItems] will be filtered
     * accordingly.
     */
    isTeacher: PropTypes.bool,
    /**
     * The width of the list in pixels. Defaults to 200.
     */
    width: PropTypes.number,
    /**
     * The direction relative to the parent towards which the dropdown menu goes.
     * Defaults to right.
     */
    direction: PropTypes.oneOf(['left', 'center', 'right', 'top']),
    /**
     * The function that is called when the mouse enters the component.
     */
    onMouseEnter: PropTypes.func,
    /**
     * The function that is called when the mouse leaves the component.
     */
    onMouseLeave: PropTypes.func,
    /**
     * The size of the icon. Either small, 24px, or large, 32px.
     */
    iconSize: PropTypes.oneOf(['small', 'large']),
    /**
     * The items to be rendered in the dropdown.
     * [toggleMenuItemAction] is the function that contains the logic once you click on the dropdown menu item.
     * [requiredRole] is the required role to view the menu item. It must be either "Teacher" or "Student" or "Both".
     * [desc] is the menu item name that will display in the menu.
     */
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            toggleMenuItemAction: PropTypes.func,
            requiredRole: PropTypes.oneOf(['Teacher', 'Student', 'Both']).isRequired,
            desc: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
};

export default DropdownMenu;
