import Song from './Song';
import SongSetsDict from './SongSetsDict';
import StudySet from './StudySet';

/**
 * CustomSetDict is a dictionary of song id keys to `SongSetsDict` values.
 */
class CustomSetDict {
    constructor() {
        this.songDicts = {};
        this.selected = [];
    }

    /**
     * Adds a list of StudySet objects to the dictionary. The array
     * can contain sets of any type.
     * @param {Array<StudySet>} objList
     */
    addArrayOfStudySets(objList) {
        if (!objList) throw Error('Invalidated Precondition: docList is undefined');
        if (objList.length === 0) return;
        objList.forEach((data) => {
            if (!(data instanceof StudySet)) throw new Error('Invalidated Precondition: data is not a StudySet object.');
            const songId = data.getSongId();
            const type = data.getFeatureName();
            if (!songId || !type) throw Error('Invalidated Precondition: tried to add a set but songId or feature was falsy.');
            if (!this.songDicts.hasOwnProperty(songId)) {
                this.songDicts[songId] = new SongSetsDict(songId);
            }
            if (type === 'Review') {
                this.songDicts[songId].addReviewSet(data);
            } else if (type === 'Breakdown') {
                this.songDicts[songId].addBreakdownSet(data);
            } else {
                this.songDicts[songId].addBlanksSet(data);
            }
        });
    }

    /**
     * Adds a query snapshot of feature sets as `SongSetsDicts` to the dictionary.
     * @param {QuerySnapshot} querySnapshot
     * @param {string} type
     */
    addGeneral(querySnapshot, type) {
        if (!querySnapshot) throw Error('Invalidated Precondition: querySnapshot is undefined');
        if (querySnapshot.empty) return;
        querySnapshot.docs.forEach((docSnap) => {
            const data = docSnap.data(); //data is always defined for a query.
            if (!(data instanceof StudySet)) throw new Error('Invalidated Precondition: data is not a StudySet object.');
            const songId = data.getSongId();
            if (!songId) throw Error('Invalidated Precondition: tried to add a set but songId was falsy.');
            if (!this.songDicts.hasOwnProperty(songId)) {
                this.songDicts[songId] = new SongSetsDict(songId);
            }
            if (type === 'Review') {
                this.songDicts[songId].addReviewSet(data);
            } else if (type === 'Breakdown') {
                this.songDicts[songId].addBreakdownSet(data);
            } else {
                this.songDicts[songId].addBlanksSet(data);
            }
        });
    }

    /**
     * Adds review sets to the data structure.
     * @param {QuerySnapshot} querySnapshot
     */
    addReviewSets(querySnapshot) {
        this.addGeneral(querySnapshot, 'Review');
    }

    /**
     * Adds breakdown sets to the data structure.
     * @param {QuerySnapshot} querySnapshot
     */
    addBreakdownSets(querySnapshot) {
        this.addGeneral(querySnapshot, 'Breakdown');
    }

    /**
     * Adds blanks sets to the data structure.
     * @param {QuerySnapshot} querySnapshot
     */
    addBlanksSets(querySnapshot) {
        this.addGeneral(querySnapshot, 'Blanks');
    }

    /**
     * Returns the `SongSetsDict` in the dictionary linked to the `songId`. If
     * no such element exists, throws an exception.
     * @param {string} songId
     * @return {SongSetsDict}
     */
    getSongSetsDict(songId) {
        if (!(songId in this.songDicts)) throw Error('Invalidated Precondition: the songId cannot be found.');
        return this.songDicts[songId];
    }

    /**
     * Returns an array of the song ids associated with these sets. No duplicates.
     * @return {Array<string>}
     */
    getSongIds() {
        const res = Object.keys(this.songDicts);
        return res;
    }

    /**
     * Sets the song data in the song dictionaries.
     * @param {Array<DocumentSnapshot>} docSnaps
     */
    setSongDatas(docSnaps) {
        if (!docSnaps) throw Error('Invalidated Precondition: docSnaps was falsy.');
        if (docSnaps.length === 0) return;
        docSnaps.forEach((docSnap) => {
            // docSnap is possibly non-existent since this is not a query result.
            if (docSnap.exists) {
                const songData = docSnap.data();
                if (!(songData instanceof Song)) throw Error('Invalidated Precondition: song data must be a Song object.');

                if (!this.songDicts.hasOwnProperty(songData.id))
                    throw Error('Invalidated Precondition: tried to add song data but song was not found in dict.');
                this.songDicts[songData.id].setSongData(songData);
            }
        });
    }

    /**
     * Returns an array of `SongSetsDict`, sorted by most recent access. If there
     * are no sets, returns the empty array.
     * @return {Array<SongSetsDict>}
     */
    getSortedSongListByRecent() {
        if (Object.keys(this.songDicts).length === 0) return [];
        return Object.values(this.songDicts).sort(SongSetsDict.recentCompare);
    }

    /**
     * Returns an array of `SongSetsDict`, sorted by the rapStudy name of the
     * song, (ex. Living Things). If there are no sets, returns the empty array.
     * @return {Array<SongSetsDict>}
     */
    getSortedSongListByName() {
        if (Object.keys(this.songDicts).length === 0) return [];
        return Object.values(this.songDicts).sort(SongSetsDict.newTitleCompare);
    }

    /**
     * Returns an array of `SongSetsDict`, sorted by the name of the unit,
     * (ex. Life Sciences). If there are no sets, returns the empty array.
     * @return {Array<SongSetsDict>}
     */
    getSortedSongListByUnit() {
        if (Object.keys(this.songDicts).length === 0) return [];
        return Object.values(this.songDicts).sort(SongSetsDict.unitCompare);
    }

    /**
     * Removes the set from the data structure. If there are no more sets in the
     * child dictionary, that dictionary is deleted. `feature` is one of 'Review',
     * 'Breakdown', or 'Blanks' (case sensitive).
     * @param {string} feature
     * @param {string} songId
     * @param {string} setId
     */
    deleteSet(feature, songId, setId) {
        const songDict = this.songDicts[songId];
        songDict.deleteSet(feature, setId);
        // We do not have to toggle selection here because it is handled in SongSetsDict.
        if (songDict.isEmpty()) {
            delete this.songDicts[songId];
        }
    }

    /**
     * Is the set empty of any sets?
     * @return {bool}
     */
    isEmpty() {
        return Object.keys(this.songDicts).length === 0;
    }

    /**
     * Returns an Array of selected StudySet objects.
     * @return {Array<StudySet>}
     */
    getSelectedSetsArr() {
        const tempRecord = {};
        for (var songId in this.songDicts) {
            const songDict = this.songDicts[songId];
            const selectedIds = songDict.getSelectedSets();
            selectedIds.forEach((setId) => (tempRecord[setId] = songDict.getSetData(setId)));
        }
        return this.selected.map((setId) => tempRecord[setId]);
    }

    /**
     * Reorders the selection.
     * @param {number} source
     * @param {number} dest
     */
    reorderSelected(source, dest) {
        const [removed] = this.selected.splice(source, 1);
        this.selected.splice(dest, 0, removed);
    }

    /**
     * Toggle the selection of a set. Leverages the `SongSetsDict` toggle methods.
     * @param {string} setId
     * @return {bool} true if the set is now selected, false otherwise.
     */
    toggleSelected(setId) {
        for (var songId in this.songDicts) {
            const songDict = this.songDicts[songId];
            if (songDict.hasSet(setId)) {
                const addToSelected = songDict.toggleSetSelection(setId);
                const index = this.selected.indexOf(setId);
                if (index < 0 && !addToSelected) {
                    throw Error('Toggle failed: set found in custom set dict but not in selected array.');
                } else if (index < 0 && addToSelected) {
                    this.selected.push(setId);
                } else if (!addToSelected) {
                    this.selected.splice(index, 1);
                } else {
                    throw Error('Toggle failed: set needs to be added to the selected array, but is already present.');
                }
                return addToSelected;
            }
        }
        throw Error('Toggle failed: Set not found in CustomSetDict.');
    }

    /**
     * Sets the mode of a set.
     */
    selectMode(setId, mode) {
        for (var songId in this.songDicts) {
            const songDict = this.songDicts[songId];
            if (songDict.hasSet(setId)) {
                songDict.selectMode(setId, mode);
                return;
            }
        }
        throw Error('Failed: Set not found in CustomSetDict.');
    }
}

export default CustomSetDict;
