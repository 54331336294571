import React, { Component } from 'react';

import LoginBox from '../UI-Library/LoginBox';
import styled from 'styled-components';
import { withFirebase } from '../Firebase';

const PageWrapper = styled.div`
    width: 100vw;
    display: block;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 90px;
    padding-left: 10px;
`;

const Logo = styled.img`
    position: absolute;
    cursor: pointer;
    bottom: 20px;
    width: 200px;
    height: 90px;
`;

class InviteLogin extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedNav: 'signin' };
        this.signInEmail = this.signInEmail.bind(this);
        this.handleNavChange = this.handleNavChange.bind(this);
    }

    handleNavChange = (selectedNav) => {
        this.setState({ selectedNav: selectedNav });
    };

    signInEmail = async (email, password) => {
        try {
            await this.props.createUser(email, password);
            this.props.firebase.analytics.logEvent('login');
        } catch (err) {
            console.log(err);
            this.setState({ errMsg: 'bad request.' });
        }
        this.setState({ signingIn: false });
    };

    render() {
        return (
            <PageWrapper>
                {/* <Navbar handleNavChange={this.handleNavChange} selected={'nav_' + this.state.selectedNav} /> */}
                <ContentWrapper>
                    <LoginBox
                        title={`Congrats! 🥳 ${this.props.inviter ?? 'Someone'} gifted you a song! 🎁`}
                        caption="Please continue with the email address to which it was shared. 📧"
                        signInGoogle={this.props.signInGoogle}
                        signInMicrosoft={this.props.signInMicrosoft}
                    />
                    <Logo id="nav_home" src="/images/logos/newlogo2.png" alt="Logo" />
                </ContentWrapper>
            </PageWrapper>
        );
    }
}

export default withFirebase(InviteLogin);
