var pdfdict = {
    d375: 'http://drive.google.com/uc?export=view&id=1HxzUp5gW_zOlXG2voxoxA0_egNfX2ggw',
    d374: 'http://drive.google.com/uc?export=view&id=1UO_Zu-Qi4PaScxPHT53jUaLXjl59h-1M',
    d373: 'http://drive.google.com/uc?export=view&id=1pHzRTYowj8QFcrzDmGEWaJ6JAXf4GyMI',
    d372: 'http://drive.google.com/uc?export=view&id=1vuP0QOaskqhNmf_R_c-iWXYkVF4dwLB_',
    d376: 'http://drive.google.com/uc?export=view&id=1KAP5J0T1OcCb86qOZUAJFoXnBWEc6FT7',
    d384: 'http://drive.google.com/uc?export=view&id=1mIHgKC5Kvw0zeHpXOPc1tbOj4s5X0Yp9',
    d387: 'http://drive.google.com/uc?export=view&id=1I3CoT1IYEqFnnnM_PgCHawATRaivfget',
    d388: 'http://drive.google.com/uc?export=view&id=1nT5jFdJSYVKGQHwJJBqAImiTRbCTHnVc',
    d389: 'http://drive.google.com/uc?export=view&id=14Yroqjqu1pHzTQ2lrQB-OX2toWUlVM61',
    d395: 'http://drive.google.com/uc?export=view&id=1WVrX7LIpOaPWj05cRj7LEKrK1l-5E26e',
    d398: 'http://drive.google.com/uc?export=view&id=1ypA4uS7_efCexgZIRR_wRvmbkStc4_kU',
    d400: 'http://drive.google.com/uc?export=view&id=1N_Ec3QsT6wb-0IZymG0FdaeY4tBKRa_A',
    d401: 'http://drive.google.com/uc?export=view&id=1JCzGNmLW6D8xGrrB5Z3iDeNUdOrjce7V',
    d404: 'http://drive.google.com/uc?export=view&id=1YazFV31gnSFwES0enkgNJnYn3BcECvZk',
    d410: 'http://drive.google.com/uc?export=view&id=1fmenGhlJx9Ar_gtcBK6uuMPsxeEm9myK',
    d379: 'http://drive.google.com/uc?export=view&id=16fOiUMY0-uDfBXQ_A0uhzOTJLqfKErLw',
    d367: 'http://drive.google.com/uc?export=view&id=1raL73vTpLtOYmsyAaZa5nEqf8tYasVHr',
    d364: 'http://drive.google.com/uc?export=view&id=1IHIRRgo9y70Qp6f3DvAgKf7ERh-_Tns2',
    d361: 'http://drive.google.com/uc?export=view&id=1SZV_llkqdu77QcKBravecoRNoT6DZEVK',
    d360: 'http://drive.google.com/uc?export=view&id=1PBCBVAlsxXeDipnGpeG24DcbIW5Zwr6n',
    d356: 'http://drive.google.com/uc?export=view&id=1TiFgBS8DdWnhV7YAElYwZ3vTO3-wTCTY',
    d349: 'http://drive.google.com/uc?export=view&id=1wL2sGCVNn7nnVDaYfijF1n3LNCW6Ee6A',
    d343: 'http://drive.google.com/uc?export=view&id=1aPWubz0SHUCwNlpaJdt9f9aJ8Ow0PGm2',
    d342: 'http://drive.google.com/uc?export=view&id=1ZqnuaLlMqWO21cmP3_jr0CMAtRVvT-_a',
    d341: 'http://drive.google.com/uc?export=view&id=1F4eSgNxxISat3hKaJGeJ5W25oWE4y89t',
    d340: 'http://drive.google.com/uc?export=view&id=149TXaX0GVQI_9x1P8Ti4Zw4we7I2Uief',
    d339: 'http://drive.google.com/uc?export=view&id=1qlGyUeSnmnhyTP7hGLi4UpIHMmS_4zuS',
    d338: 'http://drive.google.com/uc?export=view&id=1zScpLyi3SrBaW0Z8D5ARfbhgzxIli7Kj',
    d336: 'http://drive.google.com/uc?export=view&id=1clid0y5-Kpyc-cQeTBD9InZ1GwU27BKF',
    d334: 'http://drive.google.com/uc?export=view&id=1o3xhZOHKPZwJ9uT21eM52_BeuCnngCUe',
    d333: 'http://drive.google.com/uc?export=view&id=1BIsCMG46NYeAC-hcrZgj7q1vRD51-9uR',
    d332: 'http://drive.google.com/uc?export=view&id=1HuV8M1rvVy4eeCxvYSNfVb9bOBTHCIGA',
    d331: 'http://drive.google.com/uc?export=view&id=1YHnC_HQdrROpFirg7vstYnT8nse43GR3',
    d329: 'http://drive.google.com/uc?export=view&id=1PP7kFvhezTwFBYXVxJh8pYttw4u7Kiwp',
    d327: 'http://drive.google.com/uc?export=view&id=1P-_lk3cR11p_NopuTqoNO6Y20F8w36AD',
    d325: 'http://drive.google.com/uc?export=view&id=1u6BBcNAUvBSsxgv1T7efJUrpcvjPcKI1',
    d321: 'http://drive.google.com/uc?export=view&id=1wtmQmGe_6dCdo4veqFGyjYV8CTIt6A9m',
    d319: 'http://drive.google.com/uc?export=view&id=1NI8jLZXQk6-geRj64wyLxy5ogKcxVnii',
    d318: 'http://drive.google.com/uc?export=view&id=1ge50Q0eu9HgiNc0VVaDtuHB37FEFQPuU',
    d317: 'http://drive.google.com/uc?export=view&id=1O13NxUCQTVNLeTQzm4jjd_SLwscyyw1Z',
    d316: 'http://drive.google.com/uc?export=view&id=1qsXlN5dZtHgA5U4Frh4zrrkxAnAmfGB2',
    d312: 'http://drive.google.com/uc?export=view&id=10hcn_U3d1kJzkzd42FsApDIRS_IgGnr4',
    d311: 'http://drive.google.com/uc?export=view&id=1oVXPQu4BNukaHC5ykzaBHyn3YJPzRi80',
    d310: 'http://drive.google.com/uc?export=view&id=18EO3rNV0CqBToUkedfyG6kHFduSQ6To5',
    d309: 'http://drive.google.com/uc?export=view&id=1DvrbApT_eg9URm1mKUnHKRj9ipONmNkM',
    d308: 'http://drive.google.com/uc?export=view&id=1462J5IA_l8iOlqfWDmQkGkw6FySiN_iC',
    d307: 'http://drive.google.com/uc?export=view&id=1ENJAwT_JLsIW9lAdBmahLPFvagko3wZA',
    d305: 'http://drive.google.com/uc?export=view&id=15_CNrXKIbpOKQRmxbKyps9LfNZS8xYEo',
    d304: 'http://drive.google.com/uc?export=view&id=1Hr0XDFEVrKOu6swssm9G3uBxWuQjQvjM',
    d303: 'http://drive.google.com/uc?export=view&id=1kBM5JTVaEY_zx_kqKQTNi-IrDlFDNNXr',
    d302: 'http://drive.google.com/uc?export=view&id=1ZMSuBmJMFxPkA3mL4xkeIKrUbjT62K9X',
    d301: 'http://drive.google.com/uc?export=view&id=1vZKh56Vxtxc0eIfEK9gu1Abe6C9dJDsH',
    'd300(1)': 'http://drive.google.com/uc?export=view&id=1Ar7gvNaQuBlrxMQ6mDJvubOBl62wCCSd',
    d299: 'http://drive.google.com/uc?export=view&id=1qmzqyEFm-Rdtjtpo3UZpf_mZqE40x9GA',
    d297: 'http://drive.google.com/uc?export=view&id=1lXEa1Bk-cA2nY-tpuRv9Qo3N5P8RLM6R',
    d296: 'http://drive.google.com/uc?export=view&id=1eKEAdqGuK47_JfcQMg_NQdea4-KVpe1R',
    d295: 'http://drive.google.com/uc?export=view&id=1U3M47XGe8laVij-_FBkOTlfJkGozyVlw',
    d294: 'http://drive.google.com/uc?export=view&id=1eUymwUHPa5M35iMPh-GxYmJkmjNNVPeR',
    d293: 'http://drive.google.com/uc?export=view&id=1RcMWdVYYO1QsXLmt-C5mGE86APb0RgiT',
    d290: 'http://drive.google.com/uc?export=view&id=1xAEuqR91NxA2r9GEIjvb_PQnT8hpyWDT',
    d289: 'http://drive.google.com/uc?export=view&id=1idZV-05pCTl63X2W7ST5tPnohMrdgZYZ',
    d287: 'http://drive.google.com/uc?export=view&id=1UCj366kZob5U_x-_N54afFAfghbHvS1V',
    d286: 'http://drive.google.com/uc?export=view&id=11YV8caVnSFC2jdr_uLF8QRCHzDfpBAdq',
    d285: 'http://drive.google.com/uc?export=view&id=1FfTzPWtcItPozoMyeqlyT8T4ywbblYz_',
    d284: 'http://drive.google.com/uc?export=view&id=11JZeYS2zCp3mkZajZ8OaMjXVfwj4ZQj3',
    d283: 'http://drive.google.com/uc?export=view&id=1-qjSpvogBUmk9sMx1wdwHYVC0i2uJX51',
    d282: 'http://drive.google.com/uc?export=view&id=1Ts9qrE7JD0iCjz2ZhzRLTrxBLmefN3h-',
    'd280(1)': 'http://drive.google.com/uc?export=view&id=1FNSYZTE0dvHgVO_Uacvgg90EiJkntb7s',
    d276: 'http://drive.google.com/uc?export=view&id=1ruCMTaEsUyJnPNrYA8oWWJoXj1WGKZP0',
    d275: 'http://drive.google.com/uc?export=view&id=19dM7Rff5pQML9tNe2creXiWQo7uw_0xC',
    d273: 'http://drive.google.com/uc?export=view&id=1tzZkp6HojTuQbmg8FuJgqeoRp4jwmGhd',
    d272: 'http://drive.google.com/uc?export=view&id=1BRJA4oMYRkzm9DlfXo2Z9pb4BP1NI-3e',
    d270: 'http://drive.google.com/uc?export=view&id=1dX0iI58Ch6BJmqjk5O7P8wF-BJvBRJew',
    d269: 'http://drive.google.com/uc?export=view&id=1uC7zE8g0wd_u17Pzh-FHuIxfRo2Jxby6',
    d267: 'http://drive.google.com/uc?export=view&id=15Mn6vjynSRnkLeTQYUo8b6n0PQioFe5p',
    d266: 'http://drive.google.com/uc?export=view&id=1NI--_aITmhg-sktA_A4sHt4A3RivNpUL',
    d264: 'http://drive.google.com/uc?export=view&id=1Db2Ukcz0eur0Bxsb9BjyTUyoXImjnndS',
    d263: 'http://drive.google.com/uc?export=view&id=1ZFp1B_0t9BBL5YuT1jGKD_J76Z_XfPPG',
    d257: 'http://drive.google.com/uc?export=view&id=1hFMd6wkfYtzUoUexVTOa8_gcZzGlIR2_',
    d256: 'http://drive.google.com/uc?export=view&id=1mSDSwRozrMK6AyywnHZuiaita6D0Oqiy',
    d253: 'http://drive.google.com/uc?export=view&id=1c1U6I05rRHYw-jakiT1xQ-mPV7WA0INY',
    d249: 'http://drive.google.com/uc?export=view&id=1FkXk4AXIvPv1zgK43LSRjq1wQNzxi9Vc',
    d248: 'http://drive.google.com/uc?export=view&id=1jxUh-QU0xYxoWldTGspR0SyhJMnNfI30',
    d247: 'http://drive.google.com/uc?export=view&id=1HK5oErrVDPjH1ga4Yr-OGVSGvLfpfnMt',
    d244: 'http://drive.google.com/uc?export=view&id=1SaV3tty176JmjZNsoVlY70KNn3ZrJAip',
    d243: 'http://drive.google.com/uc?export=view&id=1qK7O8oUczDo9PqPGLCAj4rfpv8ych1Wu',
    d241: 'http://drive.google.com/uc?export=view&id=1FsSJozdza8tmFAE78k3tKap0gRkJbCI1',
    d239: 'http://drive.google.com/uc?export=view&id=10SN4UwI-wHruZJ8BhWuirCQjv3G5Q7cj',
    d238: 'http://drive.google.com/uc?export=view&id=1YRXlCwd5xMsSrq2PC6vmWY7Tq96wghct',
    d236: 'http://drive.google.com/uc?export=view&id=1GT59HB0wuZvK0VXkFRjH8ls91X5SusJQ',
    d235: 'http://drive.google.com/uc?export=view&id=1Gbg6kPPRsVV8kva75j3HLlmgTh8GMj0p',
    d232: 'http://drive.google.com/uc?export=view&id=1OJT-rfxyvyxZ2gkcVk57_7I3Ob4euo5U',
    d231: 'http://drive.google.com/uc?export=view&id=14B4mmiLv-fwM3mUNtbQG3Nwud3P16GPi',
    d229: 'http://drive.google.com/uc?export=view&id=1pvEkDWR3pCVA2dX7q5QRjyl8y1bNkYiS',
    d227: 'http://drive.google.com/uc?export=view&id=1LJ9ruBSbsNqiSpouyR-ZR99WuIZE4vq4',
    d226: 'http://drive.google.com/uc?export=view&id=1ocOVF-JtQ7-5DEMH6HTyroMtxqZ2xLkG',
    d222: 'http://drive.google.com/uc?export=view&id=1pGRL9qFFnbVv9to6Yl9atEJfLFn5ccWO',
    d221: 'http://drive.google.com/uc?export=view&id=1yEc8Hjo_6JKlHTNSv6E9sNmkeRI6sME9',
    d219: 'http://drive.google.com/uc?export=view&id=1FcB2RgkdnJwBC1HaEC9GjlIdw3yhtsxP',
    d217: 'http://drive.google.com/uc?export=view&id=1DxWEnHjZfhVKQd_CuoRvBbzY_IPIJo74',
    d213: 'http://drive.google.com/uc?export=view&id=1QdJt0kNZgWV4CTQEFiZL15YTZV1Fko_z',
    d212: 'http://drive.google.com/uc?export=view&id=1c-_SDR74OUkMN9jAVacS1GDir2qJ02LY',
    'd211(1)': 'http://drive.google.com/uc?export=view&id=1N8a3gqFiAF45usAzLd7-GeRVxfR4tbBD',
    d210: 'http://drive.google.com/uc?export=view&id=1CKqtoIWbMxZRqd3NF7GEBQAEgv8hhxM4',
    d209: 'http://drive.google.com/uc?export=view&id=1H76qRHo3ZQAQoToZXyM6vnQL1Rap1rD8',
    d206: 'http://drive.google.com/uc?export=view&id=18BdoFqntdl91n97fprW1WYoE5NhWQJuK',
    d199: 'http://drive.google.com/uc?export=view&id=1ITio-GGmNyDR43FDhk31uFrvlHKjDiN5',
    d198: 'http://drive.google.com/uc?export=view&id=1uUDIKzg_WkkwxKWifYe9Lt2A_U6X8WsG',
    d186: 'http://drive.google.com/uc?export=view&id=1CdLBda6t72bRsddkOGUX9aXBG0DFFPZD',
    d184: 'http://drive.google.com/uc?export=view&id=1jzhfC0GkbNEr7Z1c9JlFVK0LbdcV6ePL',
    d176: 'http://drive.google.com/uc?export=view&id=1ouqBThePvFAvYrrhrf14uwWbRiFwBzaQ',
    d175: 'http://drive.google.com/uc?export=view&id=1nGpqawfksTSkdWsxg3LFDY6Qf0b75hzn',
    d169: 'http://drive.google.com/uc?export=view&id=1GgkwAU058Fe6uCH5C6UlOt22oPyWHacn',
    d165: 'http://drive.google.com/uc?export=view&id=1lA6H7Tx_apV6d5Kk_UicTem0KmcceLSw',
    d160: 'http://drive.google.com/uc?export=view&id=1_GnvFK2qqESeJUZuOE3XgnNSOTt2Ihy-',
    d155: 'http://drive.google.com/uc?export=view&id=1RQS5GH45kZjHjUxfrD9pXL9DLBcjM0Sl',
    d139: 'http://drive.google.com/uc?export=view&id=1AfFUSImA30I8kOG8EVzi3KX4TOLyKRSQ',
    d138: 'http://drive.google.com/uc?export=view&id=1BjJfbYK6RqJtwCss7DQe8vW8vebEbIfV',
    d137: 'http://drive.google.com/uc?export=view&id=1iEAvD63uRmxgjj_KpZs2u2GUm-e-sekj',
    d111: 'http://drive.google.com/uc?export=view&id=1hQfYXQb3fe3gMoa2Y18TpVmplj9DIcK2',
    d106: 'http://drive.google.com/uc?export=view&id=1_O_xwhecoSgFnBH1ftiaJD6lajxbeSVl',
    d97: 'http://drive.google.com/uc?export=view&id=1I5jWiPNuy-P51aZOojoZTjAcyaoXqJU2',
    d84: 'http://drive.google.com/uc?export=view&id=12gCzoPyOIkJ5Ulpoks7R8_9TwaCiQJZk',
    d65: 'http://drive.google.com/uc?export=view&id=1ZMcvAnlcOts3q1mZGpsM6-EOgdCypE6P',
    d64: 'http://drive.google.com/uc?export=view&id=1t1IVf6t2l2WNX5q8Y6EOrkVWlKl6QVFQ',
    d63: 'http://drive.google.com/uc?export=view&id=1zGr_sAMbzfJ5RRQ7hMNIbpDPgag9jM_h',
    d56: 'http://drive.google.com/uc?export=view&id=1t946o2vZLWLfEbTUZ6c-2H1SSFAhCGuM',
    d52: 'http://drive.google.com/uc?export=view&id=1ohKSuLAH0Jl2dDTIqGF5zhF5i2KeIK4A',
    d42: 'http://drive.google.com/uc?export=view&id=1f1-ouWU-Q5YuicBwyHZzZriurKeo0Ht_',
    d40: 'http://drive.google.com/uc?export=view&id=1lWJj3VvrszkMUsh3w_flyw-oQWUcExTz',
    d38: 'http://drive.google.com/uc?export=view&id=1MezbgXXNrro_Qma3skHOAzGN5od9G4-k',
    d36: 'http://drive.google.com/uc?export=view&id=1Mm7LBAcfGF8KZnHRpIs1cAev865IyopU',
    d28: 'http://drive.google.com/uc?export=view&id=1ppXLQAMtSpSlJ6jEfQ_J3vgFq97HY9Jl',
    d25: 'http://drive.google.com/uc?export=view&id=1qQK7jrKXU8tQqSMzrVhEqlRmFCF3LTca',
    d62: 'http://drive.google.com/uc?export=view&id=1xHBm2nagdHh_ROjL_2n2vV2KXSu1k1Dn',
    d61: 'http://drive.google.com/uc?export=view&id=1BjQpSTetq3KwWKWZQXWi3wYc1NNlcEhn',
    d55: 'http://drive.google.com/uc?export=view&id=1ewzk3rflj-1TednSIlQ5Vhl-8Yh3PJBn',
    d49: 'http://drive.google.com/uc?export=view&id=1rju7BIvLLie-QPEIP9316zOaWuK8YTdL',
    d46: 'http://drive.google.com/uc?export=view&id=1VVG2dJ--VSXjV0xbOeXaaQPABy8U6e-X',
    d44: 'http://drive.google.com/uc?export=view&id=1q0pT_1k5RZvBLGL89e15MId-gEvL1gxt',
    d43: 'http://drive.google.com/uc?export=view&id=15r7X6e4W6FxxUB_pQOxacw6AEvhq5XXt',
    d41: 'http://drive.google.com/uc?export=view&id=1igAJw3Ti-H2zODrgcC7lhY_CxQVU9Cr_',
    d33: 'http://drive.google.com/uc?export=view&id=19-VjTSrOVieuNXMXcT8Z8gmy7KJw0Ebc',
    d67: 'http://drive.google.com/uc?export=view&id=1BtI1ZWOZHGroDRtxmCseXgjhsRz8dDcm',
    d70: 'http://drive.google.com/uc?export=view&id=1AZEQfXPCt5gZ-R5Qnt29YNSf2xAmDO9Z',
    d71: 'http://drive.google.com/uc?export=view&id=149__ps7EuzSyQUttN6jyUEw2WyhWMbVL',
    d74: 'http://drive.google.com/uc?export=view&id=1JstFBT8_E4DXp-qjJASuPGQQfroX2En3',
    d77: 'http://drive.google.com/uc?export=view&id=1Q0g4pcd-imZsZG2gZ3zSPDqgzm8EmDX9',
    d81: 'http://drive.google.com/uc?export=view&id=18b3WE_dDKojf7-YODF7MxzfBT0TS6Sci',
    d83: 'http://drive.google.com/uc?export=view&id=1jyA-5IA5fQyUaMOjyTqIT12mIzWjh1T2',
    d93: 'http://drive.google.com/uc?export=view&id=1PSDsBPKxxZYb8xFsrDDK_WmJ5imXgRVE',
    d94: 'http://drive.google.com/uc?export=view&id=1FwKGZy6jeRM7ntIKOzAzQdcKsV09Sz5B',
    d96: 'http://drive.google.com/uc?export=view&id=1lK27BQww4Mo6XpWWhxdD1YdZ3wuDFmrK',
    d98: 'http://drive.google.com/uc?export=view&id=1MCCIYUyCTOovFMy5HMEKkpM4mK3c3vaS',
    d99: 'http://drive.google.com/uc?export=view&id=16panGZIq5PpstJEID2GXpC_LSzapezzG',
    d117: 'http://drive.google.com/uc?export=view&id=1UBy7kUP2VtLoBuhRk5FTh3_6C2RFwYLP',
    d118: 'http://drive.google.com/uc?export=view&id=16DioYTlGCg2uyfEi4i7uzNyfkjqHcN0C',
    d119: 'http://drive.google.com/uc?export=view&id=1QmESxHWgAUbk3id5pvLvryFLbKA6nUoT',
    d120: 'http://drive.google.com/uc?export=view&id=1X-Btx-kBDO9ox11gLuJyK810vTV-irHW',
    d121: 'http://drive.google.com/uc?export=view&id=1zlh55nLktC9uuAlAXUEexEvZmeNv0dtc',
    d122: 'http://drive.google.com/uc?export=view&id=1z7lfYGSfUgBD40j9WgIdfZZdtCy1tdOi',
    d368: 'http://drive.google.com/uc?export=view&id=1lwfiZxH6kYW3UzY0KMCk2qjwQAiWT-kk',
    d37: 'http://drive.google.com/uc?export=view&id=14FI7IrefoviEk-AzXCqQIq_UwI1gY5hp',
    d47: 'http://drive.google.com/uc?export=view&id=18Ra2utAbjzpeSMJxaLXXV1dIcbkhvv2e',
    d53: 'http://drive.google.com/uc?export=view&id=1xWsLmsevdbWkSZvUCP9nNkYb0rYGyLPX',
    d68: 'http://drive.google.com/uc?export=view&id=1d98VewqPf0mkU-Oag9_AC7b3fUp15R44',
    d69: 'http://drive.google.com/uc?export=view&id=1s-UUlMSJUAX6unAheV6dfQcWEOsUtW28',
    d73: 'http://drive.google.com/uc?export=view&id=1fh7-9sUWgX_vrd_IxWTZt7hAGwu2BVU9',
    d82: 'http://drive.google.com/uc?export=view&id=15u_1OmeFgzzn5Bn0mp_YywMHpzf0keT4',
    d85: 'http://drive.google.com/uc?export=view&id=1DVRfdVXtTyId40efyA_ODa11znAD2E6l',
    d89: 'http://drive.google.com/uc?export=view&id=1Y12iP7ZUWtjSTRRpZpS5euHxXiza_9ft',
    d95: 'http://drive.google.com/uc?export=view&id=1498TOE9mzf-MJey6WwVchThOZB2qbJPE',
    d112: 'http://drive.google.com/uc?export=view&id=1kJXZOIvxPmSVsFri_jHse6J2CNBrPc-9',
    d114: 'http://drive.google.com/uc?export=view&id=1UfmzeGNgjTntIGRoJLxfs3oNmH9BPJLf',
    d125: 'http://drive.google.com/uc?export=view&id=1TeUlYtRklaZ6PUu4bU4_5RVV-9SH2Ev5',
    d132: 'http://drive.google.com/uc?export=view&id=1s4ecnlW6rtTOurL37rY683b3uFUAXdiY',
    d136: 'http://drive.google.com/uc?export=view&id=1p2SZ0S3cbWJnGUjz60ZNfbaTZonyCbMQ',
    d171: 'http://drive.google.com/uc?export=view&id=1ZyVvkv-XBGQORKdL0krgUmRV-ZeqLVej',
    d181: 'http://drive.google.com/uc?export=view&id=1iq6-_z7XdrFJKAvzKqFhxXQzPYidFGCk',
    d193: 'http://drive.google.com/uc?export=view&id=18-UI_-OURAyO8tmA8KwRhJLkvvuwxJF0',
    d202: 'http://drive.google.com/uc?export=view&id=1TntZgExfRCtC5T2aA38cWw37KZDdQYS9',
    d3: 'http://drive.google.com/uc?export=view&id=1qN5CeuAVJ9-Gme1_tsAyllO5GProbKCq',
    d24: 'http://drive.google.com/uc?export=view&id=1oKyBQJMz73h8AOeb9bnfXZiGucCn9OYW',
    d26: 'http://drive.google.com/uc?export=view&id=1sf2XQmt7tk2NznrjVlyZjf3PAujtw8-s',
    d39: 'http://drive.google.com/uc?export=view&id=1eS4mWtMj3gn5IoanRBQ6c2uRAoolaQnj',
    d45: 'http://drive.google.com/uc?export=view&id=1wSROTlisIKQO2NSPENnOqxA2MuhpoU_f',
    d75: 'http://drive.google.com/uc?export=view&id=1FEdm89KjLJMAo5ej8K46HZS2ciMJEBEp',
    d78: 'http://drive.google.com/uc?export=view&id=1xLWMjTqehpHWo75QbJIfjHe-gcQmolBg',
    d80: 'http://drive.google.com/uc?export=view&id=1OS9GmbEudRaa70T6yO64HM0OiwIJofOW',
    'd88 - Don_t Let Me Down': 'http://drive.google.com/uc?export=view&id=1ojR1TvfRm_8cz694wFCQUF6RrD8LVxfJ',
    d92: 'http://drive.google.com/uc?export=view&id=1FqaLefqSnnSqA-GAYIFZnXIE_AMA5RYt',
    d108: 'http://drive.google.com/uc?export=view&id=1TBRPPcjFkH2h4VENAzxy0o5sjFnSRz2z',
    d124: 'http://drive.google.com/uc?export=view&id=150dBn-FfMXbkmKmVyjSITU0MbH75BAE-',
    d134: 'http://drive.google.com/uc?export=view&id=1lKACjWYwW0yzq-Gt-k-ThFYh3FvYKPKj',
    d157: 'http://drive.google.com/uc?export=view&id=1JOBvwEdjFUMJQ0LEerd8p-4eHYoZKN4d',
    d159: 'http://drive.google.com/uc?export=view&id=157bIyFkGFov7FK5p8uZmjB4IA84q6X_A',
    d162: 'http://drive.google.com/uc?export=view&id=1w6cGMhA4_6ILkkX9SSInuGFQk90p58Sr',
    d166: 'http://drive.google.com/uc?export=view&id=1Cl1fD5rQoD1ePCYZtBvDWATD7x1W2Eii',
    d173: 'http://drive.google.com/uc?export=view&id=1lv_XeQxZFF4ARoyHmlZt6fV7EOHkF2GI',
    d179: 'http://drive.google.com/uc?export=view&id=1Ckp_m8JljhKRwF0qxG6klrgw3OObT4Ad',
    d183: 'http://drive.google.com/uc?export=view&id=1rLUkEeEJeOkHozPxE30DeUXAbbBVJ0R6',
    d191: 'http://drive.google.com/uc?export=view&id=1CjrRod7VeGjw7uIEG2a4HyJD48YQKsIo',
    d192: 'http://drive.google.com/uc?export=view&id=1_aXFhzMAT0Q6Q_nBYzWh9avfWa3EHelO',
    d194: 'http://drive.google.com/uc?export=view&id=1lzmyDpmyZ9k0mfRUSTfZewqAEMan9TWr',
    d197: 'http://drive.google.com/uc?export=view&id=1RXBZ_lBgO3OCHydPQ0mJ15cNS5mgHWMg',
    d203: 'http://drive.google.com/uc?export=view&id=18GLYi6m6J-Dhrz1LWbI0CEBWVjDmkG66',
    d12: 'http://drive.google.com/uc?export=view&id=1Uso1a3-etIZ6Y86jxCx4PUL7m3GeWs2o',
    d15: 'http://drive.google.com/uc?export=view&id=12sH_snmUnAknbBc6DjCXogouNmbY0fWs',
    d23: 'http://drive.google.com/uc?export=view&id=1F8_6mCNgSKXktE4bg1MbKv2y0Nto6d9Z',
};

export default pdfdict;
