import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Main = styled.div`
    background: var(--rsMinor);
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
`;

const ArrowUp = styled.div`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--rsMinor);
    margin-top: 3px;
`;

const ArrowDown = styled.div`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--rsMinor);
`;

const renderTooltip = (props) => {
    const isTop = props.placement === 'top';
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            {...props}
        >
            {!isTop && <ArrowUp />}
            <Main>{props.text}</Main>
            {isTop && <ArrowDown />}
        </div>
    );
};

export default function Tooltip(props) {
    return (
        <>
            <OverlayTrigger placement={props.placement} delay={{ show: 250, hide: 400 }} overlay={renderTooltip(props)}>
                <div style={{ width: 'fit-content', height: 'fit-content' }}>{props.children}</div>
            </OverlayTrigger>
        </>
    );
}

Tooltip.propTypes = {
    /**
     * The text that goes into the tooltip.
     */
    text: PropTypes.string,
    /**
     * The placement of the tooltip.
     */
    placement: PropTypes.oneOf(['top', 'bottom']),
};
