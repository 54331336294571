import React, { Component } from 'react';
import styled from 'styled-components';
import BannerNavLinkControlled from '../../ButtonsOrLinks/BannerNavLinkControlled';
// import BannerNav from '../../Navs/BannerNav';

import RsModal from '../RsModal';
import PenAndPaper from '../../Icons/PenAndPaper';
import TextLines from '../../Icons/TextLines';

const Links = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

const Desc = styled.p`
    margin: 0;
    color: var(--rsSecondary);
`;

const Item = styled.div`
    margin-bottom: 10px;
`;

class AssignmentModeInfoModal extends Component {
    constructor(props) {
        super(props);
        this.select = this.select.bind(this);
        this.state = { featureName: 'Blanks' };
    }

    select(featureName) {
        this.setState({ featureName: featureName });
    }

    render() {
        const icons = {
            Blanks: TextLines,
            Review: PenAndPaper,
        };

        return (
            <RsModal show={this.props.show} toggle={this.props.toggle} title="Exercise Modes">
                <RsModal.Body maxHeight={'350px'}>
                    <Links>
                        {['Blanks', 'Review'].map((featureName) => (
                            <div style={{ marginRight: '10px' }}>
                                <BannerNavLinkControlled
                                    name={featureName}
                                    icon={icons[featureName]}
                                    onClick={() => this.select(featureName)}
                                    active={this.state.featureName === featureName}
                                />
                            </div>
                        ))}
                    </Links>
                    {this.state.featureName === 'Blanks' && (
                        <div>
                            <Item>
                                <p className="bold" style={{ marginBottom: 0 }}>
                                    Standard
                                </p>
                                <Desc>Click play to show the keyword.</Desc>
                            </Item>
                            <Item>
                                <p className="bold" style={{ marginBottom: 0 }}>
                                    Free Recall
                                </p>
                                <Desc style={{ marginBottom: 0 }}>Type in the missing keyword</Desc>
                                <Desc>2 attempts per keyword</Desc>
                            </Item>
                            <Item>
                                <p className="bold" style={{ marginBottom: 0 }}>
                                    Word Bank
                                </p>
                                <Desc style={{ marginBottom: 0 }}>Click on a keyword in the word bank.</Desc>
                                <Desc>1 attempt per keyword</Desc>
                            </Item>
                        </div>
                    )}
                    {this.state.featureName === 'Review' && (
                        <div>
                            <Item>
                                <p className="bold" style={{ marginBottom: 0 }}>
                                    Easy
                                </p>
                                <Desc style={{ marginBottom: 0 }}>Unlimited attempts per question</Desc>
                                <Desc>Song hint always available</Desc>
                            </Item>
                            <Item>
                                <p className="bold" style={{ marginBottom: 0 }}>
                                    Medium
                                </p>
                                <Desc style={{ marginBottom: 0 }}>2 attempts per question</Desc>
                                <Desc>Hint given after first attempt</Desc>
                            </Item>
                            <Item>
                                <p className="bold" style={{ marginBottom: 0 }}>
                                    Hard
                                </p>
                                <Desc style={{ marginBottom: 0 }}>1 attempt per question</Desc>
                                <Desc>Hint only plays after answering</Desc>
                            </Item>
                        </div>
                    )}
                </RsModal.Body>
                <RsModal.Footer />
            </RsModal>
        );
    }
}

// <Links>
// <BannerNavLinkControlled
//   name={'Review'}
//   icon={PenAndPaper}
//   active={true}
// />
// {/* <BannerNavLinkControlled
//   name={'Blanks'}
//   icon={TextLines}
//   disabled={true}
// /> */}
// </Links>

export default AssignmentModeInfoModal;
