import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PenAndPaper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        const hover = typeof this.props.hover !== 'undefined' ? this.props.hover : this.state.hover;

        const color = this.props.disabled ? 'var(--rsDisabled)' : hover ? 'var(--rsPrimary)' : 'var(--rsSecondary)';
        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <svg
                    width={this.props.size ? this.props.size : '24px'}
                    height={this.props.size ? this.props.size : '24px'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                >
                    <line x1="11.8" y1="2.34" x2="13.15" y2="0.99" fill="none" stroke={color} strokeMiterlimit="10" />
                    <path
                        d="M7.28,8.1,8,5.45,12.63.79a2.07,2.07,0,0,1,2.88-.3,2.07,2.07,0,0,1-.31,2.87L10.58,8l-2.52.69C7.55,8.83,7.21,8.55,7.28,8.1Z"
                        fill={color}
                    />
                    <path d="M11.61,9.06l2-2,0,10.47H2V2.38H9.16L6.87,4.65,6.06,8.28a1.54,1.54,0,0,0,2,1.66Z" fill={color} />
                </svg>
            </div>
        );
    }
}

PenAndPaper.propTypes = {
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default PenAndPaper;
