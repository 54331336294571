import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../../Firebase';
import { withRouter } from 'react-router-dom';

import RsButton from '../../../ButtonsOrLinks/RsButton';
import RsInput from '../../../Forms/RsInput';
import RsModal from '../../RsModal';

const ErrorMsg = styled.h2`
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 15px;
`;

class JoinClassModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pin: '',
            disabled: true,
            errorMsg: null,
            loading: false,
            submitted: false,
        };

        this.handlePinChange = this.handlePinChange.bind(this);
        this.joinClass = this.joinClass.bind(this);
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
    }

    handlePinChange(e) {
        this.setState({ pin: e.target.value });
        if (!e.target.value) {
            this.setState({ disabled: true });
        } else {
            this.setState({ disabled: false });
        }
    }

    handleError(err) {
        let errMsg = 'Unknown Error Occurred.';
        if (err.name === 'Document not found') {
            errMsg = 'Class does not exist.';
        }
        //Implemented the two popup messages for already enrolled and requested the class
        if (err.name === 'Already Enrolled') {
            errMsg = 'You are already enrolled in this class.';
        }
        if (err.name === 'Already Requested') {
            errMsg = 'You already requested to join this class.';
        }

        this.setState({
            errMsg: errMsg,
            loading: false,
            disabled: false,
        });
    }

    async joinClass() {
        try {
            this.setState({ loading: true, disabled: true, errMsg: null });
            await this.props.firebase.joinClassAsStudent(this.state.pin);
            this.setState({ submitted: true });
        } catch (err) {
            this.handleError(err);
        }

        //Log in event 'class_joined' [Firebase Analytics]
        this.props.firebase.analytics.logEvent('class_joined');
    }

    handleModalShowHide() {
        this.props.toggle();
        this.setState({
            pin: '',
            errMsg: null,
            loading: false,
            disabled: true,
            submitted: false,
        });
    }

    render() {
        return (
            <RsModal show={this.props.show} toggle={this.handleModalShowHide} title={'Join a Class'} submit={this.joinClass}>
                <RsModal.Body>
                    {this.state.submitted ? (
                        <p>A request to join has been sent! Once your teacher accepts the request, the class will show up on this page.</p>
                    ) : (
                        <RsInput value={this.state.pin} onChange={this.handlePinChange} onKeyPress={this.handleKeyPress} />
                    )}
                    {this.state.errMsg ? <ErrorMsg>{this.state.errMsg}</ErrorMsg> : <></>}
                </RsModal.Body>
                <RsModal.Footer>
                    {this.state.submitted ? (
                        <RsButton onClick={this.handleModalShowHide}>Done</RsButton>
                    ) : (
                        <RsButton disabled={this.state.disabled} onClick={this.joinClass}>
                            {this.state.loading ? 'Joining...' : 'Submit'}
                        </RsButton>
                    )}
                </RsModal.Footer>
            </RsModal>
        );
    }
}

export default withRouter(withFirebase(JoinClassModal));
