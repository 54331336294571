import React, { Component } from 'react';
import styled from 'styled-components';
import * as ROUTES from '../../../../constants/routes';
import { withRouter, Link } from 'react-router-dom';
import { withFirebase } from '../../../Firebase';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import RsInput from '../../../UI-Library/Forms/RsInput';
import TrieSearch from 'trie-search';

const Search = styled.div`
    width: 100%;
    display: flex;
`;

/**
 * Returns the JSX that displays the search bar.
 * @param {string} query
 */

const trie = new TrieSearch();

export class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = { searchQuery: '', songs: [] };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.runSearch = this.runSearch.bind(this);
    }

    handleKeyPress(e) {
        if (e.which === 13 && this.state.searchQuery.trim() !== '') {
            this.runSearch(this.state.searchQuery);
        }
    }

    handleInputChange(e) {
        this.setState({ searchQuery: e.target.value });
    }

    runSearch() {
        this.props.history.push(ROUTES.BROWSE_SEARCH + '/' + this.state.searchQuery);
    }

    componentDidMount() {
        const fetchSongs = async () => {
            const songs = await this.props.firebase.getSongs();
            songs.forEach((song) => {
                const subject = song?.subject.name;
                trie.map(subject, subject);

                const topic = song?.topic.name;
                trie.map(topic, topic);

                const subtopic = song?.subtopic.name;
                trie.map(subtopic, subtopic);
            });
        };
        fetchSongs();
    }

    render() {
        return (
            <>
                <Search>
                    <RsInput
                        value={this.state.searchQuery}
                        onChange={this.handleInputChange}
                        placeholder={'Search a Subject'}
                        handleKeyPress={this.handleKeyPress}
                        width={'60%'}
                    />
                    <RsButton ml={'15px'} onClick={this.runSearch} disabled={this.state.searchQuery.trim() === ''}>
                        Search
                    </RsButton>
                </Search>
                <div style={{ marginTop: '15px' }}>
                    {' '}
                    <Link
                        to={ROUTES.BROWSE_SEARCH + '/' + trie.search(this.state.searchQuery)[0]}
                        disabled={this.state.searchQuery.trim() === ''}
                        style={{ color: 'white', marginLeft: '10px', fontWeight: 'bold' }}
                    >
                        {trie.search(this.state.searchQuery).length > 0 ? `Suggested Search: ${trie.search(this.state.searchQuery)[0]}` : null}
                    </Link>
                </div>
            </>
        );
    }
}

export default withFirebase(withRouter(SearchBar));
