// EXTERNAL ROUTES
export const LANDING = '/';
export const ABOUT = '/about';
export const CONTACT = '/contact';
export const INTERNAL = '/internal';
export const INVITE = '/invite';
export const FORBIDDEN = '/forbidden';
export const SIGNUP = '/signup';
export const SIGNIN = '/signin';
export const PENDINGVERIFICATION = '/pendingverification';
export const RESETPASSWORD = '/reset';

// INVITE ROUTES
export const INVITE_SONGVIEW = '/invite/songView';
export const INVITE_LOGIN = '/invite/login';

// INTERNAL ROUTES
export const DASHBOARD = '/internal/dashboard';
export const FAVORITES = '/internal/favorites';
export const STANDARDS = '/internal/standards';
export const BROWSE = '/internal/browse';
export const CONTENTMANAGER = '/internal/contentManager';
export const UNITVIEW = '/internal/unitView';
export const SONGVIEW = '/internal/songView';
export const PLAYLISTVIEW = '/internal/playlistView';
export const SETTINGS = '/internal/settings';
export const CLASSES = '/internal/classes';
export const CLASSVIEW = '/internal/classView';
export const FREELANDING = '/freelanding';
export const LESSON_VIEW = '/internal/contentManager/lessonView';

// BROWSE ROUTES
export const BROWSE_SEARCH = '/internal/browse/search';
export const BROWSE_SUBJECTS = '/internal/browse/subjects';
export const BROWSE_UNITS = '/internal/browse/units';
export const BROWSE_STANDARDS = '/internal/browse/standards';
export const BROWSE_ARTISTS = '/internal/artists';
export const BROWSE_PROFILE = '/internal/artists/profile';
export const BROWSE_ARTISTS_VIEWALL = '/internal/artists/viewall';

// SONGVIEW ROUTES
export const KARAOKE = '/internal/songView/karaoke';
export const REVIEW = '/internal/songView/review';
export const BLANKS = '/internal/songView/blanks';
export const GENIUS = '/internal/songView/breakdown';
export const INVITE_KARAOKE = '/invite/songView/karaoke';
export const INVITE_REVIEW = '/invite/songView/review';
export const INVITE_BLANKS = '/invite/songView/blanks';
export const INVITE_GENIUS = '/invite/songView/breakdown';

// CLASSES ROUTES
export const MEMBERS = '/internal/classView/:classId/members';
export const ASSIGNMENTS = '/internal/classView/:classId/assignments';
export const SHAREDCONTENT = '/internal/classView/:classId/sharedContent';

export const ASSIGNMENT = '/internal/classView/:classId/assignments/:assignmentId/';
export const ASSIGNMENTTEACHER = '/internal/classView/:classId/assignments/:assignmentId/teacher';
export const ASSIGNMENTTEACHERSUMMARY = '/internal/classView/:classId/assignments/:assignmentId/teacher/summary';
export const ASSIGNMENTTEACHERPREVIEW = '/internal/classView/:classId/assignments/:assignmentId/teacher/preview';
export const ASSIGNMENTTEACHEREDIT = '/internal/classView/:classId/assignments/:assignmentId/teacher/edit';
export const ASSIGNMENTTEACHERINDIV = '/internal/classView/:classId/assignments/:assignmentId/teacher/individual/:studentId';
export const CREATEASSIGNMENT = '/internal/classView/:classId/createAssignment';
export const ASSIGNMENTSTUDENT = '/internal/classView/:classId/assignments/:assignmentId/student/:exerciseId';
