import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Caret from '../../Icons/Caret';
import SongFeedbackModal from '../../Modals/SongFeedbackModal';

const Wrapper = styled.div`
    width: 18px;
    height: 18px;
    position: absolute;
    top: 20px;
    left: 2%;
    &:hover {
        cursor: pointer;
    }
    z-index: 999;
    @media screen and (max-width: 1000px) {
        display: none;
    }
`;

/**
 * A button for backwards navigation. If neither onClick nor to props are defined,
 * the button uses the react router to navigate backwards one layer in the stack.
 * If the window width is less than 1000px, it is hidden.
 *
 * By default, it is positioned 40px from the top and 2% from the left of the
 * container.
 */
class BackArrow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            showFeedbackModal: false,
        };
        this.redirect = this.redirect.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.completeFeedback = this.completeFeedback.bind(this);
    }

    // handleModalClose() {
    //     this.setState({ showFeedbackModal: false });
    // }

    redirect() {
        this.setState({ clicked: true });
        if (this.props.displayFeedback) {
            this.setState({ showFeedbackModal: true });
        } else {
            if (this.props.onClick) {
                this.props.onClick();
            }
            if (this.props.to) {
                this.props.history.push(this.props.to);
            }
            if (!this.props.onClick && !this.props.to) {
                this.props.history.goBack();
            }
        }
    }

    handleModalClose() {
        this.setState({ clicked: false });
        if (this.props.onClick) {
            this.props.onClick();
        }
        if (this.props.to) {
            this.props.history.push(this.props.to);
        }
        if (!this.props.onClick && !this.props.to) {
            this.props.history.goBack();
        }
    }

    completeFeedback(id, rating, message, isTeacher) {
        this.props.firebase.completeFeedback(id, rating, message, isTeacher);
    }

    render() {
        if (this.props.displayFeedback && this.state.clicked) {
            return (
                <SongFeedbackModal
                    show={true}
                    completeFeedback={this.completeFeedback}
                    handleClose={this.handleModalClose}
                    firebase={this.props.firebase}
                    userToken={this.props.userToken}
                    id={this.props.id}
                />
            );
        }
        return (
            <Wrapper>
                <Caret direction={'left'} onClick={this.redirect} />
            </Wrapper>
        );
    }
}

BackArrow.propTypes = {
    /**
     * The (optional) click behavior of the arrow button.
     */
    onClick: PropTypes.func,
    /**
     * The (optional) route to redirect to after clicking the button.
     */
    to: PropTypes.string,
    /**
     * The (optional) boolean to display feedback before redirecting.
     */
    displayFeedback: PropTypes.bool,
    /**
     * Firebase to submit feedback if displayFeedback is true.
     */
    firebase: PropTypes.object,
};

export default withRouter(BackArrow);
