// import React, { Component } from 'react';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// const MainWrapper = styled.div`
//   width: 100%;
//   padding-left: 5%;
//   padding-right: 5%;
//   padding-top: 3%;
//   height: 225px;
//   ${(props) =>
//     props.src
//       ? `
//     background: url(${props.src});
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: right center;
//     `
//       : `
//     background: var(-rsBackground);
//   `}
//   display: flex;
//   flex-direction: column;
//   flex-wrap: wrap;
//   //justify-content: center;
//   position: relative;
//   overflow: visible;
// `;

// /*
// linear-gradient(
//     0deg,
//     var(--rsBackground) 0%,
//     ${props.backgroundColor} 100%
//     )
// */

// const Filter = styled.p`
//   font-size: 32px;
//   font-weight: bold;
//   margin-bottom: 0;
//   display: contents;
// `

// const Lead = styled.p`
//   font-size: 32px;
//   font-weight: bold;
//   margin-bottom: 0;
//   display: contents;
//   color: rgba(255, 255, 255, 0.3);
//   &:hover {
//     color: var(--rsBlue);
//   }
// `;

// const Sub = styled.p`
//   font-size: 32px;
//   font-weight: bold;
//   margin-bottom: 0;
//   display: contents;
//   color: var(--rsBlue);
// `;

// const Divider = styled.p`
//   font-size: 55px;
//   margin-bottom: -10;
//   display: contents;
//   font-weight: 500;
//   color: rgba(255, 255, 255, 0.3);
// `

// const Blank = styled.p`
//   font-size: 45px;
//   margin-bottom: 0;
//   display: contents;
//   color: var(--rsBackground);
// `

// /**
//  * A basic banner to go at the top of pages, with the ability to render child
//  * elements inside of it and/or a standard title. At this time, the default
//  * color is rapStudy blue.
//  *
//  */
// class StandardsBanner extends Component {
//   render() {
//     return (
//       <MainWrapper
//         backgroundColor={this.props.color ? this.props.color : 'var(--rsBlue)'}
//         src={this.props.src}
//       >
//         <Filter>Filter by: </Filter>
//         {this.props.standards
//           ? [
//               <Blank>{'-'}</Blank>,
//               <Lead onClick={this.props.onClick}>{this.props.lead}</Lead>,
//               <Blank>{'-'}</Blank>,
//               <Divider>{' | '}</Divider>,
//               <Blank>{'-'}</Blank>,
//               <Sub>{this.props.sub}</Sub>,
//             ]
//           : [
//               <Blank>{'-'}</Blank>,
//               <Sub>{this.props.lead}</Sub>,
//               <Blank>{'-'}</Blank>,
//               <Divider>{' | '}</Divider>,
//               <Blank>{'-'}</Blank>,
//               <Lead onClick={this.props.onClick}>{this.props.sub}</Lead>,
//             ]}
//         {this.props.children}
//       </MainWrapper>
//     );
//   }
// }

// StandardsBanner.propTypes = {
//   /**
//    * The optional title.
//    */
//   lead: PropTypes.string,
//   /**
//    * The optional subtitle.
//    */
//   sub: PropTypes.string,
//   /**
//    * The background color. Defaults to rapStudy Blue.
//    */
//   color: PropTypes.string,
//   /**
//    * The background image source. If defined, color is not used.
//    */
//   src: PropTypes.string,
// };

// export default StandardsBanner;

import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MainWrapper = styled.div`
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3%;
    height: 225px;
    ${(props) =>
        props.src
            ? `
    background: url(${props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    `
            : `
    background: var(-rsBackground);
  `}
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    //justify-content: center;
    position: relative;
    overflow: visible;
`;

/*
linear-gradient(
    0deg,
    var(--rsBackground) 0%,
    ${props.backgroundColor} 100%
    )
*/

const Filter = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    display: contents;
`;

const Lead = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    display: contents;
    color: rgba(255, 255, 255, 0.3);
    &:hover {
        color: var(--rsBlue);
    }
`;

const Sub = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    display: contents;
    color: var(--rsBlue);
`;

const Divider = styled.p`
    font-size: 55px;
    margin-bottom: -10;
    display: contents;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.3);
`;

const Blank = styled.p`
    font-size: 45px;
    margin-bottom: 0;
    display: contents;
    color: var(--rsBackground);
`;

/**
 * A basic banner to go at the top of pages, with the ability to render child
 * elements inside of it and/or a standard title. At this time, the default
 * color is rapStudy blue.
 *
 */
class StandardsBanner extends Component {
    render() {
        return (
            <MainWrapper backgroundColor={this.props.color ? this.props.color : 'var(--rsBlue)'} src={this.props.src}>
                <Filter>Filter by: </Filter>
                {this.props.standards
                    ? [
                          <Blank>{'-'}</Blank>,
                          <Lead onClick={this.props.onClick}>{this.props.lead}</Lead>,
                          <Blank>{'-'}</Blank>,
                          <Divider>{' | '}</Divider>,
                          <Blank>{'-'}</Blank>,
                          <Sub>{this.props.sub}</Sub>,
                      ]
                    : [
                          <Blank>{'-'}</Blank>,
                          <Sub>{this.props.lead}</Sub>,
                          <Blank>{'-'}</Blank>,
                          <Divider>{' | '}</Divider>,
                          <Blank>{'-'}</Blank>,
                          <Lead onClick={this.props.onClick}>{this.props.sub}</Lead>,
                      ]}
                {this.props.children}
            </MainWrapper>
        );
    }
}

StandardsBanner.propTypes = {
    /**
     * The optional title.
     */
    lead: PropTypes.string,
    /**
     * The optional subtitle.
     */
    sub: PropTypes.string,
    /**
     * The background color. Defaults to rapStudy Blue.
     */
    color: PropTypes.string,
    /**
     * The background image source. If defined, color is not used.
     */
    src: PropTypes.string,
};

export default StandardsBanner;
