import React, { Component } from 'react';
import styled from 'styled-components';
import AnswerChoice from '../../../ButtonsOrLinks/AnswerChoice';

import UnsplashLink from '../../../ButtonsOrLinks/UnsplashLink';
import Correct from '../../../Icons/Correct';
import Incorrect from '../../../Icons/Incorrect';
import Lightbulb from '../../../Icons/Lightbulb';

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const AllChoices = styled.div`
    min-width: 300px;
    max-width: 40vw;
    max-height: 60vh;
    border-radius: 16px;
    background-color: var(--rsSurface);
    padding: 25px;
    box-shadow: 5px 5px 12px black;
    overflow-y: auto;
`;

const AllHints = styled.div`
    display: flex;
    margin-top: 40px;
    margin-left: 20px;
`;

const IconWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: fit-content;
`;

const ChoiceText = styled.div`
    margin-bottom: 10px;
    cursor: ${(props) => (props.diabled ? 'default' : 'pointer')};
`;

const QuestionText = styled.h1`
    font-weight: bold;
`;

const ResultIconWrap = styled.div`
    margin-right: 8px;
`;

const HiddenIcon = styled.div`
    width: 18px;
    height: 18px;
    margin-right: 8px;
`;

const ShowHint = styled.a`
    background: ${({ color, fade }) => `linear-gradient(${color}, ${fade})`};
    &:hover {
        text-decoration: none;
    }
`;

const Lyrics = styled.div`
    max-height: 50vh;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    background: ${({ color, fade }) => `linear-gradient(${color}, ${fade})`};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: fit-content;
    &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }
    padding: 20px;
    margin-left: 220px;
    color: var(--rsPrimary);
    text-decoration: none;
`;

const Image = styled.img`
    max-height: 200px;
    max-width: 100%;
    width: auto;
    margin-bottom: 10px;
    box-shadow: 5px 5px 12px black;
`;

const QuestionWrapper = styled.div`
    width: 100%;
`;

const HintIconWrap = styled.div`
    background: ${({ color, fade }) => `linear-gradient(${color}, ${fade})`};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 100px;
    height: 100px;
    margin-left: 220px;
    cursor: pointer;
    gap: 8px;
`;

const Line = styled.p`
    margin-bottom: 5px;
    font-size: 24px;
`;

/**
 * QuestionView component is a single question with answer choices.
 */
export class QuestionView extends Component {
    constructor(props) {
        super(props);
        this.showHintHandler = this.showHintHandler.bind(this);
    }

    checkAnswer(letter) {
        if (letter === this.props.question.getCorrect()) {
            return (
                <ResultIconWrap>
                    <Correct size="18px" />
                </ResultIconWrap>
            );
        } else if (this.props.question.isAnswered()) {
            return (
                <ResultIconWrap>
                    <Incorrect size="18px" />
                </ResultIconWrap>
            );
        }
    }

    showHintHandler = () => {
        this.props.clickedHint();
    };

    getContent(currentWord) {
        const question = this.props.question;

        let result = null;
        result = this.props.lrc.blocks.map((blockObj, blockIndex) => {
            return blockObj.lines.map((lineObj, lineIndex) => {
                if (lineObj.pos >= question.getLineStart() && lineObj.pos <= question.getLineEnd()) {
                    return (
                        <Line key={lineObj.pos}>
                            {lineObj.words.map((wordObj, wordIndex) => {
                                return currentWord === wordObj.numInLrc ? (
                                    <span key={wordObj.numInLrc} className="major">
                                        {wordObj.word}
                                    </span>
                                ) : (
                                    <span key={wordObj.numInLrc} className="bold">
                                        {wordObj.word}
                                    </span>
                                );
                            })}
                        </Line>
                    );
                } else {
                    return null;
                }
            });
        });
        return result;
    }

    renderHint = () => {
        console.log(this.props.color);
        return (
            <HintIconWrap color={this.props.color} fade="#000000" onClick={this.showHintHandler}>
                <Lightbulb hover={true} onClick={() => null} />
                <span style={{ fontFamily: 'Nunito' }}>Show Hint!</span>
            </HintIconWrap>
        );
    };

    render() {
        const question = this.props.question;
        const src = question.getSrc();
        const currentWord = this.props.lrc.getCurrentWord(this.props.currentTime);
        const lyrics = this.getContent(currentWord);
        const disabled = this.props.maxAttempts > 0 ? question.getAttempts() >= this.props.maxAttempts || question.isCorrect() : question.isCorrect();
        const attemptsLeft = this.props.maxAttempts > 0 ? this.props.maxAttempts - question.getAttempts() : null;
        return (
            <QuestionWrapper>
                <QuestionText>
                    {question.getIndex() + 1}. {question.getQuestion()}
                </QuestionText>

                <Container className="row">
                    <AllChoices className="col-sm-auto">
                        {this.props.maxAttempts > 0 && !question.isCorrect() && (
                            <p className="secondary text-left bold" style={{ fontSize: '14px', marginBottom: '10px' }}>
                                ATTEMPTS REMAINING: {attemptsLeft}
                            </p>
                        )}
                        <div className="text-center">
                            {src != null && <Image src={src} />}
                            {this.props.question.unsplashUid && <UnsplashLink uid={question.unsplashUid} name={question.unsplashName} />}
                        </div>

                        <ChoiceText disabled={disabled} onClick={!disabled ? () => this.props.questionChoice('A') : null} className="row">
                            <IconWrap className="col-sm-auto">
                                {question.getSelected() === 'A' || (disabled && question.getCorrect() === 'A') ? (
                                    this.checkAnswer('A')
                                ) : (
                                    <HiddenIcon></HiddenIcon>
                                )}

                                <AnswerChoice selected={question.getSelected() === 'A'} letter="A" />
                            </IconWrap>

                            <div className="col">{question.getChoice('A')}</div>
                        </ChoiceText>

                        <ChoiceText disabled={disabled} onClick={!disabled ? () => this.props.questionChoice('B') : null} className="row">
                            <IconWrap className="col-sm-auto">
                                {question.getSelected() === 'B' || (disabled && question.getCorrect() === 'B') ? this.checkAnswer('B') : <HiddenIcon />}
                                <AnswerChoice selected={question.getSelected() === 'B'} letter="B" />
                            </IconWrap>
                            <div className="col">{question.getChoice('B')}</div>
                        </ChoiceText>

                        <ChoiceText disabled={disabled} onClick={!disabled ? () => this.props.questionChoice('C') : null} className="row">
                            <IconWrap className="col-sm-auto">
                                {question.getSelected() === 'C' || (disabled && question.getCorrect() === 'C') ? this.checkAnswer('C') : <HiddenIcon />}
                                <AnswerChoice selected={question.getSelected() === 'C'} letter="C" />
                            </IconWrap>
                            <div className="col">{question.getChoice('C')}</div>
                        </ChoiceText>

                        <ChoiceText disabled={disabled} onClick={!disabled ? () => this.props.questionChoice('D') : null} className="row">
                            <IconWrap className="col-sm-auto">
                                {question.getSelected() === 'D' || (disabled && question.getCorrect() === 'D') ? this.checkAnswer('D') : <HiddenIcon />}
                                <AnswerChoice selected={question.getSelected() === 'D'} letter="D" />
                            </IconWrap>
                            <div className="col">{this.props.question.getChoice('D')}</div>
                        </ChoiceText>
                    </AllChoices>

                    <AllHints className="col">
                        <ShowHint>
                            {this.props.hint ? (
                                <Lyrics color={this.props.color} fade="#000000">
                                    {lyrics}
                                </Lyrics>
                            ) : question.isAnswered() && this.props.question.getLineStart() !== null && this.props.question.getLineEnd() !== null ? (
                                this.renderHint()
                            ) : null}
                        </ShowHint>
                    </AllHints>
                </Container>
            </QuestionWrapper>
        );
    }
}

export default QuestionView;
