import React, { Component } from 'react';
import styled from 'styled-components';
import { Collapse } from 'react-bootstrap';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import RsCheckbox from '../../../UI-Library/Forms/RsCheckbox';

const CheckItem = styled.div`
    display: flex;
    align-items: center;
`;

const FilterDiv = styled.div`
    margin-top: 2.5vh;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 0;
    width: auto;
`;

const FilterCollapse = styled(Collapse)`
    background-color: var(--rsSurface);
    border-radius: 16px;
    width: 100%;
    border: none;
`;

/**
 * Props:
 * - Array<String> grades
 * - Array<String> standards
 *
 * - FUNCTION      submitSelection
 * - FUNCTION      toggleFilterCollapse
 * - FUNCTION      filterOpen
 */
class UnitFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            standardQuery: new Set(),
            gradeQuery: new Set(),
        };
        this.toggleStandard = this.toggleStandard.bind(this);
        this.toggleGrade = this.toggleGrade.bind(this);
        this.submit = this.submit.bind(this);
        this.renderActiveSelection = this.renderActiveSelection.bind(this);
    }

    /**
     * Toggles the existence of an item inside a set.
     * @param {Set<any>} set
     * @param {any} item
     */
    setToggle(set, item) {
        if (!set || !item) {
            return;
        }
        if (set.has(item)) {
            set.delete(item);
        } else {
            set.add(item);
        }
        return set;
    }

    toggleStandard(item) {
        this.setState({
            standardQuery: this.setToggle(this.state.standardQuery, item),
        });
    }

    toggleGrade(item) {
        this.setState({
            gradeQuery: this.setToggle(this.state.gradeQuery, item),
        });
    }

    submit() {
        this.props.submitSelection(this.state.gradeQuery, this.state.standardQuery);
        this.props.toggleFilterCollapse();
    }

    renderActiveSelection() {
        const grades = Array.from(this.state.gradeQuery).sort();
        const standards = Array.from(this.state.standardQuery).sort();

        const gradesJSX = grades.map((item, index) => {
            if (index === grades.length - 1) {
                return (
                    <span key={item + index} style={{ fontSize: '14px' }}>
                        {item}
                    </span>
                );
            } else {
                return (
                    <span key={item + index} style={{ fontSize: '14px' }}>
                        {item}
                        {', '}
                    </span>
                );
            }
        });

        const standardsJSX = standards.map((item, index) => {
            if (index === standards.length - 1) {
                return (
                    <span key={item + index} style={{ fontSize: '14px' }}>
                        {item}
                    </span>
                );
            } else {
                return (
                    <span key={item + index} style={{ fontSize: '14px' }}>
                        {item}
                        {', '}
                    </span>
                );
            }
        });

        return (
            <div className="secondary">
                {grades.length !== 0 && (
                    <p className="mb-0">
                        <span className="bold" style={{ fontSize: '14px' }}>
                            Grades:{' '}
                        </span>
                        {gradesJSX}
                    </p>
                )}
                {standards.length !== 0 && (
                    <p className="mb-0">
                        <span className="bold" style={{ fontSize: '14px' }}>
                            Standards:{' '}
                        </span>
                        {standardsJSX}
                    </p>
                )}
            </div>
        );
    }

    render() {
        return (
            <FilterDiv>
                <div
                    onClick={this.props.toggleFilterCollapse}
                    aria-controls="filter-collapse"
                    aria-expanded={this.props.filterOpen}
                    style={{ cursor: 'pointer' }}
                    className="bold"
                >
                    Filter
                    <img style={{ paddingLeft: '10px' }} src="/images/icons/utility/filter.png" alt="" />
                </div>
                <FilterCollapse in={this.props.filterOpen}>
                    <div id="filter-collapse" className="row">
                        <div
                            className="col"
                            style={{
                                padding: '20px',
                            }}
                        >
                            <p className="bold">Grade</p>
                            {this.props.grades.map((item) => {
                                return (
                                    <CheckItem key={'GRADE_CHECKITEM_' + item}>
                                        <RsCheckbox
                                            key={'GRADE_' + item}
                                            selected={this.state.gradeQuery.has(item)}
                                            onSelect={() => {
                                                this.toggleGrade(item);
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>{item}</span>
                                    </CheckItem>
                                );
                            })}
                        </div>
                        <div
                            className="col"
                            style={{
                                padding: '20px',
                            }}
                        >
                            <p className="bold">Standard</p>
                            {this.props.standards.map((item) => {
                                return (
                                    <CheckItem key={'STANDARD_CHECKITEM_' + item}>
                                        <RsCheckbox
                                            key={'STANDARD' + item}
                                            selected={this.state.standardQuery.has(item)}
                                            onSelect={() => {
                                                this.toggleStandard(item);
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>{item}</span>
                                    </CheckItem>
                                );
                            })}
                        </div>
                        <div className="col-auto" style={{ display: 'flex' }}>
                            <RsButton mt={'auto'} mb={'10px'} onClick={this.submit}>
                                Save Filters
                            </RsButton>
                        </div>
                    </div>
                </FilterCollapse>
                {!this.props.filterOpen ? this.renderActiveSelection() : null}
            </FilterDiv>
        );
    }
}

export default UnitFilter;
