import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
    background: white;
    justify-content: center;
    align-items: center;
`;

const Jumbo1 = styled.div`
    background: #2e2e2e;
    min-height: 975px;
    height: calc(120vh);
    @media ${device.tablet} {
        height: calc(60vh);
        min-height: 600px;
    }
    display: block;
    align-items: center;

    padding-top: max(80px, 12%);
`;

const Header = styled.h1`
    font-size: 50px;
    text-align: center;
    margin: auto;
    // padding-right: calc(24vh);
    // padding-left: calc(24vh);
    font-weight: bold;
    white-space: nowrap;
    @media ${device.tablet} {
        font-size: 28px;
    }
    @media ${device.mobileL} {
        font-size: 24px;
    }
`;

const VideoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 100px;
    align-items: center;

    @media ${device.tablet} {
        justify-content: center;
    }
    @media ${device.mobileM} {
        padding-left: 1%;
        padding-right: 1%;
    }
`;

const VideoFrame = styled.div`
    width: 75vw;
    height: 72vh;
    background: grey;
    @media ${device.laptop} {
        width: 600px;
        height: 320px;
    }
    @media ${device.tablet} {
        width: 400px;
        height: 250px;
    }
    @media ${device.mobileL} {
        width: 280px;
        height: 180px;
    }
`;

class AboutMain extends React.Component {
    render() {
        return (
            <MainWrapper>
                <Jumbo1>
                    <Header>
                        We’re transforming the relationship kids have with education <br />
                        through the music they know and love.
                    </Header>
                    <VideoWrapper>
                        <VideoFrame>
                            <iframe
                                height="100%"
                                width="100%"
                                src="https://www.youtube.com/embed/NruUB7FGpW8"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="video"
                            ></iframe>
                        </VideoFrame>
                    </VideoWrapper>
                </Jumbo1>
            </MainWrapper>
        );
    }
}

export default AboutMain;
