import React, { Component } from 'react';
import styled from 'styled-components';
import { KARAOKE } from '../../../../constants/routes';
import PlayButton from '../../../UI-Library/Icons/PlayButton';

const Divider = styled.hr`
    border: 1px solid var(--rsSecondary);
    width: 90%;
    justify-content: center;
    margin: 0px auto;
    z-index: -1;
`;

const VersionIIAndDivider = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SingleVersionIIBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 85px;
`;

const VersionIIAllWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 65px;
`;

const VersionIIStandardContent = styled.div`
    overflow-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    height: auto;
    width: 15%;
    margin-left: 5%;
    //margin-right: 20%;
    //text-align: center;
`;
const VersionIIDescriptionContent = styled.div`
    overflow-wrap: break-word;
    height: auto;
    width: 30%;
    margin-left: 5.5%;
    margin-right: 5%;
    //margin-right: 35%;
`;

const VersionIISongsContent = styled.div`
    overflow: visible;
    height: auto;
    width: 25%;
    text-align: center;
`;

const VersionIITitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 7px;
`;

const VersionIIStandardTitle = styled.div`
    font-size: 24px;
    font: Nunito;
    //width: 33px;
    width: 15%;
    height: 33px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    margin-left: 5%;
    margin-bottom: 7px;
`;

const VersionIIDescriptionTitle = styled.div`
    font-size: 24px;
    font: Nunito;
    width: 30%;
    height: 33px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    margin-left: 5.5%;
    margin-right: 5%;
    margin-bottom: 7px;
`;

const VersionIISongsTitle = styled.div`
    font-size: 24px;
    font: Nunito;
    width: auto;
    height: 33px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    //text-align: center;
`;

const StyledFilter = styled.button`
    width: ${(props) => (props.width ? props.width : '220px')};
    height: 45px;
    line-height: 45px;
    margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
    margin-right: 15px;
    background: #2e2e2e;
    border-radius: 30px;
    border: 0;
    outline: 0;
    color: var(--rsPrimary);
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    display: flex;
    align-items: normal;
    justify-content: space-between;
    &:focus-within {
        border: 2px solid rgba(255, 255, 255, 0.6) !important;
    }
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--rsSecondary);
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--rsSecondary);
    }
    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--rsSecondary);
    }
    select > option {
        background: var(--rsForm);
    }
    position: relative;
    // &:hover {
    //   background: var(--rsSurfaceHover);
    //   height: auto;
    // }
    overflow-y: hidden;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid rgba(160, 194, 209, 0.3);
`;

const StyledOpen = styled(StyledFilter)`
    && {
        border-radius: 25px 25px 0px 0px;
    }
    z-index: 100;
`;

const StyledBottom = styled(StyledFilter)`
    && {
        border-radius: 0px 0px 30px 30px;
        //border-style: none;
    }
    &:hover {
        background: var(--rsSurfaceHover);
        height: auto;
    }
    z-index: 100;
`;

const StyledDropdown = styled(StyledFilter)`
    && {
        border-radius: 0px;
        //border-style: none;
        border-bottom: 1px solid rgba(160, 194, 209, 0.3);
    }
    &:hover {
        background: var(--rsSurfaceHover);
        height: auto;
    }
    z-index: 100;
`;

const ButtonRowWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    //z-index: 999;
    overflow-y: overlay;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
        width: 0px;
        border: 1px solid black;
    }
`;

const ButtonWrapper = styled(ButtonRowWrapper)`
    && {
        height: 45px;
    }
`;

export class SearchFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: this.props.isOpened,
            linkToSong: '',
            topics: this.props.item.topics,
            songIdArr: this.props.item.songIdArr,
            currentValue: this.props.currentVal,
            showPlayButton: false,
            opacity: 0,
        };
        this.select = this.select.bind(this);
    }

    toggle = () => {
        this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
    };

    close = () => {
        this.setState({
            isOpened: false,
        });
    };

    componentDidUpdate() {
        setTimeout(() => {
            if (this.state.isOpened) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0);
    }

    select(topic, index) {
        this.setState(
            {
                currentValue: topic,
                linkToSong:
                    //'http://localhost:3000/internal/songView/karaoke?songId=' +
                    KARAOKE + '?songId=' + this.state.songIdArr[index],
                showPlayButton: true,
                opacity: 1,
            },
            () => {
                console.log(this.state.currentValue);
                console.log(this.state.linkToSong);
            },
        );
    }
    //how to trigger a change of the opacity of PlayButton thorugh onCLick?
    render() {
        return this.state.isOpened ? (
            <ButtonRowWrapper>
                {this.state.isOpened ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <StyledOpen onClick={this.toggle}>
                            {this.state.currentValue}
                            <DropdownCaret />
                        </StyledOpen>
                        {!this.state.currentValue.includes('songs') ? (
                            //<button>
                            <PlayButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = this.state.linkToSong;
                                }}
                            />
                        ) : //</button>
                        null}
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <StyledFilter onClick={this.toggle}>
                            {this.state.currentValue}
                            <DropdownCaret />
                        </StyledFilter>
                        {!this.state.currentValue.includes('songs') ? (
                            //<button>
                            <PlayButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = this.state.linkToSong;
                                }}
                            />
                        ) : //</button>
                        null}
                    </div>
                )}
                {this.state.isOpened &&
                    this.state.topics.map((topic, index) =>
                        this.state.topics.length - 1 === index ? (
                            <StyledBottom key={topic.id} onClick={() => this.select(topic, index)}>
                                {topic}
                            </StyledBottom>
                        ) : (
                            <StyledDropdown key={topic.id} onClick={() => this.select(topic, index)}>
                                {topic}
                            </StyledDropdown>
                        ),
                    )}
            </ButtonRowWrapper>
        ) : (
            // A different wrapper when the filters are closed so the div doesn't overlay
            // the standard cards
            <ButtonWrapper>
                {this.state.isOpened ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <StyledOpen onClick={this.toggle}>
                            {this.state.currentValue}
                            <DropdownCaret />
                        </StyledOpen>
                        {!this.state.currentValue.includes('songs') ? (
                            //<button>
                            <PlayButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = this.state.linkToSong;
                                }}
                            />
                        ) : //</button>
                        null}
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <StyledFilter onClick={this.toggle}>
                            {this.state.currentValue}
                            <DropdownCaret />
                        </StyledFilter>
                        {!this.state.currentValue.includes('songs') ? (
                            //<button>
                            <PlayButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = this.state.linkToSong;
                                }}
                            />
                        ) : //</button>
                        null}
                    </div>
                )}
                {this.state.isOpened &&
                    this.state.topics.map((topic, index) =>
                        this.state.topics.length - 1 === index ? (
                            <StyledBottom key={topic.id} onClick={() => this.select(topic, index)}>
                                {topic}
                            </StyledBottom>
                        ) : (
                            <StyledDropdown key={topic.id} onClick={() => this.select(topic, index)}>
                                {topic}
                            </StyledDropdown>
                        ),
                    )}
            </ButtonWrapper>
        );
    }
}

export class DropdownCaret extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
    }
    renderPath(color) {
        return (
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11.5" cy="11.5" r="10" stroke="#A0C2D1" stroke-opacity="0.3" stroke-width="3" />
                <path d="M7 9.5L12 15L16.5 9.5" stroke="#A0C2D1" stroke-opacity="0.3" stroke-width="3" stroke-linecap="round" />
            </svg>
        );
    }

    render() {
        const color = 'var(--rsSecondary)';
        return (
            <div
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {this.renderPath(color)}
            </div>
        );
    }
}

class VersionIIView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            versionIIObjectList: [],
            propsObjectList: this.props.objectList,
            refresh: this.props.refresh,
            selectValue: ' songs',
            showPlayButton: false,
        };
        this.compare = this.compare.bind(this);
    }

    componentDidMount() {
        const objectList = this.state.propsObjectList;
        var versionIIObjectListReady = [];
        var map = new Map();
        objectList.map((outerItem, index) => {
            const curObject = outerItem;
            if (1 !== 2 - 1) {
                console.log(curObject);
            }
            const curTopic = outerItem.subTopic;
            const curSubStandard = outerItem.subStandards;
            const curSubStandardDescriptions = outerItem.subStandardDescriptions;
            //every curObject means a new topic with multiple standards and songs
            curSubStandard.map((innerItem, index) => {
                if (map.has(innerItem)) {
                    var curValue = map.get(innerItem);
                    var songIdsToAdd = curSubStandardDescriptions
                        .map((ele) => ele.songIds)
                        .flat()
                        .filter(function (item, index, inputArray) {
                            return inputArray.indexOf(item) === index;
                        });
                    if (songIdsToAdd.length !== 0 && curSubStandardDescriptions.length !== 0) {
                        var mainDescriptionToAdd = curSubStandardDescriptions[0].desc;
                        curValue.songIdArr.push(songIdsToAdd[0]);
                        curValue.description.push(mainDescriptionToAdd);
                        curValue.topics.push(curTopic);
                    }
                } else {
                    var songIds = curSubStandardDescriptions
                        .map((ele) => ele.songIds)
                        .flat()
                        .filter(function (item, index, inputArray) {
                            return inputArray.indexOf(item) === index;
                        });
                    if (curSubStandardDescriptions.length !== 0) {
                        var mainDescription = curSubStandardDescriptions[index].desc;
                    }
                    if (songIds.length !== 0) {
                        var newObject = {
                            subStanrdard: curSubStandard[index],
                            description: [mainDescription],
                            topics: [curTopic],
                            songIdArr: [songIds[0]],
                        };
                        map.set(curSubStandard[index], newObject);
                    }
                    console.log(newObject.description);
                }
                return '';
            });
            return '';
        });
        //var map = new Map([...map.entries()].sort());
        //console.log(map);
        map.forEach(function (value, key) {
            const newObject = { standard: key, object: value };
            versionIIObjectListReady.push(newObject);
        });
        var objectsWithoutK = [];
        var objectsWithK = [];
        versionIIObjectListReady.map((object, index) => {
            if (object.standard.includes('K')) {
                objectsWithK.push(object);
            } else {
                objectsWithoutK.push(object);
            }
            return '';
        });
        objectsWithK.sort(this.compare);
        objectsWithoutK.sort(this.compare);
        var versionIIObjectListReady1 = [];
        objectsWithK.map((object, index) => {
            versionIIObjectListReady1.push(object);
            return '';
        });
        objectsWithoutK.map((object, index) => {
            versionIIObjectListReady1.push(object);
            return '';
        });
        this.setState({ versionIIObjectList: versionIIObjectListReady1 }, () => {
            //console.log(this.state.versionIIObjectList);
        });
    }

    compare(objectA, objectB) {
        if (objectA.standard < objectB.standard) {
            return -1;
        } else if (objectA.standard > objectB.standard) {
            return 1;
        } else {
            return 0;
        }
    }

    render() {
        return (
            <VersionIIAndDivider key={this.props.refresh}>
                {this.state.versionIIObjectList.map((item, index) => (
                    <SingleVersionIIBlock>
                        <div>
                            {index === 0 ? (
                                <VersionIITitleWrapper>
                                    <VersionIIStandardTitle>Standard</VersionIIStandardTitle>
                                    <VersionIIDescriptionTitle>Description</VersionIIDescriptionTitle>
                                    <VersionIISongsTitle>Songs</VersionIISongsTitle>
                                </VersionIITitleWrapper>
                            ) : null}
                        </div>
                        <Divider />
                        <VersionIIAllWrapper>
                            <VersionIIStandardContent>{item.standard}</VersionIIStandardContent>
                            <VersionIIDescriptionContent>{item.object.description[0].trim()}</VersionIIDescriptionContent>
                            <VersionIISongsContent>
                                {/* <select value={this.state.selectValue} onChange={this.handleChange}>
                                    <option selected value={`${item.object.topics.length} songs`} disabled>{`${item.object.topics.length} songs`}</option>
                                {item.object.topics.map((topic, index) => (
                                    <option value={index}>
                                        {topic}
                                    </option>
                                ))}
                                </select> */}
                                <SearchFilter
                                    isOpened={false}
                                    currentVal={`${item.object.topics.length} songs`}
                                    item={item.object}
                                    songStr=" songs"
                                ></SearchFilter>
                            </VersionIISongsContent>
                        </VersionIIAllWrapper>
                    </SingleVersionIIBlock>
                ))}
            </VersionIIAndDivider>
        );
    }
}
export default VersionIIView;
