import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import RsInput from '../../../../Forms/RsInput';

const InputWrapper = styled.div`
    border: 3px solid transparent;
    border-radius: 30px;
    ${(props) => {
        if (!props.animating) return;
        const animation = props.correct ? 'feedbackRight' : 'feedbackWrong';
        return `

    @keyframes feedbackRight {
      0% {
        border: 3px solid transparent;
      }
      25% {
        border: 3px solid green;
      }
      50% {
        border: 3px solid green;
      }
      75% {
        border: 3px solid green;
      }
    }

    @keyframes feedbackWrong {
      0% {
        border: 3px solid transparent;
      }
      25% {
        border: 3px solid red;
      }
      50% {
        border: 3px solid red;
      }
      75% {
        border: 3px solid red;
      }
    }

    animation: ${animation} 1s forwards;
    
    
`;
    }}
`;

export default class AnimatedInput extends Component {
    render() {
        const { animating, correct, onChange, value, onAnimationStart, onAnimationEnd } = this.props;
        return (
            <InputWrapper animating={animating} correct={correct} onAnimationEnd={onAnimationEnd} onAnimationStart={onAnimationStart}>
                <RsInput onChange={onChange} value={value} />
            </InputWrapper>
        );
    }
}

AnimatedInput.propTypes = {
    animating: PropTypes.bool,
    correct: PropTypes.bool,
    onAnimationEnd: PropTypes.func,
    onAnimationStart: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
};
