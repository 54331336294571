import React, { Component } from 'react';
import styled from 'styled-components';
import Caret from '../../../UI-Library/Icons/Caret';

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    //margin-top: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    border-radius: 5px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
        // width: 0px;
        // border: 1px solid black;
        display: none;
    }
    //max-inline-size: 75%;
    //width:fit-content;
    //height:fit-content;
`;

const StyledButton = styled.button`
    outline: none;
    height: 25px;
    max-width: 100%;
    cursor: pointer;
    border: unset;
    border-radius: 5px;
    margin-right: 10px;
    &:hover {
        background-color: var(--rsBlue);
        color: black;
    }
    white-space: nowrap;
    text-align: center;
    ${(props) =>
        props.active
            ? `
      background-color: var(--rsBlue);
      color: black;
    `
            : `
      background-color: #3d3d3d;
      color: #ffffff;
  `}
`;

const ScrollButton = styled.div`
    margin-left: 15px;
`;

const InnerRow = styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
    max-width: 95%;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
        // width: 0px;
        // border: 1px solid black;
        display: none;
    }
`;

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: [],
            canFit: 0,
            overflow: false,
            showItems: this.props.tabs.length,
        };
        this.boxRef = React.createRef();
        // this.updateDims = this.updateDims.bind(this);
        this.checkOverflow = this.checkOverflow.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.mouseRight = this.mouseRight.bind(this);
        this.mouseLeft = this.mouseLeft.bind(this);
        this.clearRight = this.mouseRight.bind(this);
    }

    checkOverflow() {
        const elemWidth = this.boxRef.current.scrollWidth;
        const parentWidth = this.boxRef.current.parentElement.getBoundingClientRect().width;
        const tabLength = this.displayTextWidth(this.props.tabs[0], '14px Nunito');
        this.setState({ showItems: Math.floor((parentWidth - 64) / tabLength) });
        return elemWidth + 44 > parentWidth;
    }

    componentDidMount() {
        this.setState({ overflow: this.checkOverflow() });
    }

    scrollLeft() {
        this.boxRef.current.scrollLeft -= 5;
    }

    scrollRight() {
        this.boxRef.current.scrollLeft += 5;
    }

    mouseRight() {
        this.setState({ showItems: this.props.tabs.length });
        if (this.timerId) return;
        if (!this.timerId) {
            this.timerId = setInterval(this.scrollRight, 10);
        }
        document.addEventListener(
            'mouseup',
            () => {
                clearInterval(this.timerId);
                this.timerId = null;
            },
            { once: true },
        );
    }

    mouseLeft() {
        if (this.timerId2) return;
        if (!this.timerId2) {
            this.timerId2 = setInterval(this.scrollLeft, 10);
        }
        document.addEventListener(
            'mouseup',
            () => {
                clearInterval(this.timerId2);
                this.timerId2 = null;
            },
            { once: true },
        );
    }

    displayTextWidth(text, font) {
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        context.font = font;
        let metrics = context.measureText(text);
        return metrics.width + 10;
    }

    render() {
        return (
            <StyledRow>
                <InnerRow ref={this.boxRef}>
                    {this.props.tabs.slice(0, this.state.showItems).map((tab, index) => (
                        <StyledButton key={index} active={this.props.active === index} onClick={() => this.props.setActive(index)}>
                            {' '}
                            {tab}{' '}
                        </StyledButton>
                    ))}
                </InnerRow>
                {this.state.overflow ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ScrollButton onMouseDown={this.mouseLeft}>
                            <Caret direction="left" onMouseDown={this.mouseLeft} />
                        </ScrollButton>
                        <ScrollButton onMouseDown={this.mouseRight}>
                            <Caret direction="right" onMouseDown={this.mouseRight} />
                        </ScrollButton>{' '}
                    </div>
                ) : null}
            </StyledRow>
        );
    }
}
export default Tabs;
