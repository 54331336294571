import React, { Component } from 'react';
import styled from 'styled-components';

import { withFirebase } from '../Firebase';

import RsButton from '../UI-Library/ButtonsOrLinks/RsButton';

const PageWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 2%;
    padding-right: 2%;
`;

const MainWrapper = styled.div`
    max-width: 500px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const InnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Main = styled.p`
    text-align: center;
`;

const Sub = styled.p`
    text-align: center;
    font-size: 14px;
`;

/**
 * signInGoogle
 * signInEmail
 * forgotPassword
 */
class PendingVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resent: false,
        };
        this.resendVerification = this.resendVerification.bind(this);
    }

    resendVerification() {
        this.props.firebase.resendVerification();
        this.setState({ resent: true });
    }

    render() {
        return (
            <PageWrapper>
                <MainWrapper>
                    {this.state.resent && (
                        <Main>
                            We've sent the email again, make sure to check your spam! If you still don't see it after ten minutes, please let us know at
                            support@rapstudy.com
                        </Main>
                    )}
                    {!this.state.resent && (
                        <InnerWrapper>
                            <Main>
                                Great! Check your linked email <span className="major">{this.props.userToken.claims.email} </span>
                                for a message from us. Click on the attached verification link to finalize your account.
                            </Main>

                            <Sub>If you haven’t received the link, first check your spam, then try resending below or contact us at support@rapstudy.com</Sub>

                            <RsButton onClick={this.resendVerification} disabled={this.state.resent}>
                                Resend email
                            </RsButton>
                        </InnerWrapper>
                    )}
                </MainWrapper>
            </PageWrapper>
        );
    }
}

export default withFirebase(PendingVerification);
