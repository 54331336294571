import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Cross from '../../Icons/Cross';

const ModalBody = styled(Modal.Body)`
    padding-left: 50px;
    padding-right: 50px;
    max-height: ${(props) => (props.maxheight ? props.maxheight : '250px')};
    min-height: ${(props) => (props.minheight ? props.minheight : '0')};
    display: ${(props) => (props.flex ? props.flex : 'block')};
    flex-direction: ${(props) => (props.flexcol ? props.flexcol : 'row')};
    justify-content: ${(props) => (props.jcontent ? props.jcontent : 'flex-start')};
    align-items: ${(props) => (props.aitems ? props.aitems : 'flex-start')};
`;

const ModalHeader = styled(Modal.Header)`
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    align-items: center;
`;

const ModalTitle = styled(Modal.Title)`
    font-weight: bold;
`;

const ModalFooter = styled(Modal.Footer)`
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.jcontent ? props.jcontent : 'flex-end')};
`;

/**
 * The standard reusable modal. A modal is broken down into three sections:
 * The header, the body, and the footer. The header is automatically popualted
 * with the title and a cross out button that calls the passed toggle function.
 * The body can be decided by putting content inside the RsModal.Body component.
 * The footer can be decided by putting content inside the RsModal.Footer component.
 *
 * TODO: Include size prop for larger modals.
 */
export class RsModal extends Component {
    render() {
        return (
            <div
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.props.submit) {
                        this.props.submit();
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            >
                <Modal dialogClassName="border-radius-16" show={this.props.show} centered onHide={this.props.toggle}>
                    <ModalHeader>
                        <ModalTitle>{this.props.title}</ModalTitle>
                        <Cross size="18px" onClick={this.props.toggle} />
                    </ModalHeader>
                    {this.props.children}
                </Modal>
            </div>
        );
    }
}

RsModal.Body = (props) => {
    var display = 'block';
    var flexDir = 'column';
    var jContent = 'flex-start';
    var aItems = 'flex-start';

    if (props.fx === 'col-center') {
        display = 'flex';
        flexDir = 'column';
        jContent = 'center';
        aItems = 'center';
    }

    return (
        <ModalBody
            maxheight={props.maxHeight ? props.maxHeight : '250px'}
            minheight={props.minHeight}
            flex={display}
            flexcol={flexDir}
            jcontent={jContent}
            aitems={aItems}
        >
            {props.children}
        </ModalBody>
    );
};

RsModal.Footer = (props) => <ModalFooter jcontent={props.jContent}>{props.children}</ModalFooter>;

RsModal.propTypes = {
    title: PropTypes.string,
    show: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    submit: PropTypes.func,
};

export default RsModal;
