import React, { Component } from 'react';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import styled from 'styled-components';
const StyledFilter = styled.button`
    width: ${(props) => (props.width ? props.width : '220px')};
    height: 45px;
    line-height: 45px;
    margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
    margin-right: 15px;
    background: #2e2e2e;
    border-radius: 30px;
    border: 0;
    outline: 0;
    color: var(--rsPrimary);
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    display: flex;
    align-items: normal;
    justify-content: space-between;
    &:focus-within {
        border: 2px solid rgba(255, 255, 255, 0.6) !important;
    }
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--rsSecondary);
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--rsSecondary);
    }
    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--rsSecondary);
    }
    select > option {
        background: var(--rsForm);
    }
    position: relative;
    &:hover {
        background: var(--rsSurfaceHover);
        height: auto;
    }
    overflow-y: hidden;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid rgba(160, 194, 209, 0.3);
`;

const StyledOpen = styled(StyledFilter)`
    && {
        border-radius: 30px 30px 0px 0px;
    }
`;

const StyledBottom = styled(StyledFilter)`
    && {
        border-radius: 0px 0px 30px 30px;
        border-style: none;
    }
`;

const StyledDropdown = styled(StyledFilter)`
    && {
        border-radius: 0px;
        border-style: none;
        border-bottom: 1px solid rgba(160, 194, 209, 0.3);
    }
    z-index: 999;
`;

const MainWrapper = styled.div`
    align-items: flex-start;
    width: 50%;
    min-width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    position: absolute;
    z-index: 999;
    margin-left: 4%;
    margin-right: 4%;
    //margin-top: 1.5%;
    justify-content: flex-start;
    top: 140px;
`;

const ButtonRowWrapper = styled.div`
    width: 100%;
    height: 225px;
    display: block;
    position: relative;
    z-index: 999;
    overflow-y: overlay;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
        width: 0px;
        border: 1px solid black;
    }
`;

const ButtonWrapper = styled(ButtonRowWrapper)`
    && {
        height: 45px;
    }
`;

const Blank = styled.div`
    width: 0px;
`;

export class DropdownCaret extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
    }
    renderPath(color) {
        return (
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11.5" cy="11.5" r="10" stroke="#A0C2D1" stroke-opacity="0.3" stroke-width="3" />
                <path d="M7 9.5L12 15L16.5 9.5" stroke="#A0C2D1" stroke-opacity="0.3" stroke-width="3" stroke-linecap="round" />
            </svg>
        );
    }

    render() {
        const color = 'var(--rsSecondary)';
        return (
            <div
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {this.renderPath(color)}
            </div>
        );
    }
}

export class SearchFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: this.props.isOpened,
        };
    }

    toggle = () => {
        this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
    };

    close = () => {
        this.setState({
            isOpened: false,
        });
    };

    componentDidUpdate() {
        setTimeout(() => {
            if (this.state.isOpened) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0);
    }

    render() {
        return this.state.isOpened ? (
            <ButtonRowWrapper>
                {this.state.isOpened ? (
                    <StyledOpen onClick={this.toggle}>
                        {this.props.currentVal}
                        <DropdownCaret />
                    </StyledOpen>
                ) : (
                    <StyledFilter onClick={this.toggle}>
                        {this.props.currentVal}
                        <DropdownCaret />
                    </StyledFilter>
                )}
                {this.state.isOpened &&
                    this.props.itemList.map((item, index) =>
                        this.props.itemList.length - 1 === index ? (
                            <StyledBottom
                                key={item.id}
                                onClick={() => {
                                    this.props.select(item);
                                }}
                            >
                                {item.currentVal}
                            </StyledBottom>
                        ) : (
                            <StyledDropdown key={item.id} onClick={() => this.props.select(item)}>
                                {item.currentVal}
                            </StyledDropdown>
                        ),
                    )}
            </ButtonRowWrapper>
        ) : (
            // A different wrapper when the filters are closed so the div doesn't overlay
            // the standard cards
            <ButtonWrapper>
                {this.state.isOpened ? (
                    <StyledOpen onClick={this.toggle}>
                        {this.props.currentVal}
                        <DropdownCaret />
                    </StyledOpen>
                ) : (
                    <StyledFilter onClick={this.toggle}>
                        {this.props.currentVal}
                        <DropdownCaret />
                    </StyledFilter>
                )}
                {this.state.isOpened &&
                    this.props.itemList.map((item, index) =>
                        this.props.itemList.length - 1 === index ? (
                            <StyledBottom key={item.id} onClick={() => this.props.select(item)}>
                                {item.currentVal}
                            </StyledBottom>
                        ) : (
                            <StyledDropdown key={item.id} onClick={() => this.props.select(item)}>
                                {item.currentVal}
                            </StyledDropdown>
                        ),
                    )}
            </ButtonWrapper>
        );
    }
}

export class StandardsSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subjectTitle: 'Select a subject',
            standardTitle: 'Select a standard',
            gradeTitle: 'Select a grade',
            unitTitle: 'Select a strand',
            standards: [],
            subjects: [],
            grades: [],
            units: [],
            currStandardDict: null,
            currSubjectDict: null,
            currGradeDict: null,
            currUnitDict: null,
            error: null,
            ready: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.filterTitles = this.filterTitles.bind(this);
    }

    // By clicking the search button, set the state of selectedStandard and selectedSubject in the Browse component
    // the function of query data from databse can also be implemented in this handleClick()
    async handleClick() {
        this.props.setStateFromFilters(this.state.currUnitDict, this.state.standardTitle, this.state.subjectTitle);
        this.props.setShowCardStateToTrue();
        // this.props.refreshVersionII();
        //this.props.createVersionIIObjectList();
        //The function does not work, there is no data presented in versionII, is it beacuse async key word?
    }

    async componentDidMount() {
        try {
            // An array of reference objects in firebase for standards
            const gradeRefs = await this.props.firebase.getGradeRefs();
            // Use these variables to keep track of the indices of each element in each ref array
            // It is important to update the id of each object to a unique value for the resetThenSet function
            // to reset the names of the standard filters correctly
            var i = 0;
            var j = 0;
            var k = 0;
            var l = 0;
            await Promise.all(
                // Loop through each standard and modify the state.standards array to contain
                // the id of each reference, then repeat the process for subjects, grades, and units
                // using the references at each index of each loop.
                gradeRefs.map(async (gradeDict) => {
                    this.setState((prevState) => ({
                        grades: [
                            ...prevState.grades,
                            {
                                id: i,
                                currentVal: gradeDict.getId().replaceAll('%2F', '/'),
                                dict: gradeDict,
                                selected: false,
                                key: 'grades',
                            },
                        ],
                    }));
                    i++;
                    var standardRefs = await this.props.firebase.getStandardsInGrades(gradeDict.getRef());
                    standardRefs.map(async (standardDict) => {
                        this.setState((prevState) => ({
                            standards: [
                                ...prevState.standards,
                                {
                                    id: j,
                                    currentVal: standardDict.getId().replaceAll('%2F', '/'),
                                    dict: standardDict,
                                    selected: false,
                                    key: 'standards',
                                },
                            ],
                        }));
                        j++;
                        var subjectRefs = await this.props.firebase.getSubjects(standardDict.getRef());
                        subjectRefs.map(async (subjectDict) => {
                            this.setState((prevState) => ({
                                subjects: [
                                    ...prevState.subjects,
                                    {
                                        id: k,
                                        currentVal: subjectDict.getId().replaceAll('%2F', '/'),
                                        dict: subjectDict,
                                        selected: false,
                                        key: 'subjects',
                                    },
                                ],
                            }));
                            k++;
                            var unitRefs = await this.props.firebase.getUnits(subjectDict.getRef());
                            unitRefs.map(async (unitDict) => {
                                this.setState((prevState) => ({
                                    units: [
                                        ...prevState.units,
                                        {
                                            id: l,
                                            currentVal: unitDict.getId().replaceAll('%2F', '/'),
                                            dict: unitDict,
                                            selected: false,
                                            key: 'units',
                                        },
                                    ],
                                }));
                                l++;
                            });
                        });
                    });
                }),
            );
        } catch (err) {
            this.setState({ error: err });
        }
    }

    /*componentDidUpdate() {
    if(this.state.gradeTitle != 'Select a grade' && this.state.standardTitle != 'Select a standard' && this.state.subjectTitle != 'Select a subject' && this.state.unitTitle != 'Select a strand'){
      setTimeout(() => {
        this.handleClick();
      }, 3000);
    }
  }*/

    async filterTitles(currDict, filter) {
        var query = null;
        // Use the currDict at
        if (filter === 'standards') {
            query = await this.props.firebase.getStandardsInGrades(currDict.getRef());
        } else if (filter === 'subjects') {
            query = await this.props.firebase.getSubjects(currDict.getRef());
        } else {
            query = await this.props.firebase.getUnits(currDict.getRef());
        }
        let temp = [];
        for (let i = 0; i < query.length; i++) {
            temp.push({
                id: i,
                currentVal: query[i].getId().replaceAll('%2F', '/'),
                dict: query[i],
                selected: false,
                key: filter,
            });
        }
        if (filter === 'standards') {
            this.setState({ standards: temp });
        } else if (filter === 'subjects') {
            this.setState({ subjects: temp });
        } else if (filter === 'units') {
            this.setState({ units: temp });
        }
    }
    // todo cascade the filtering when an earlier filter is changed but then new ones haven't been/replace
    // the later filters with the default val
    resetThenSet = (id, key, currDict, filter) => {
        const temp = [...this.state[key]];

        temp.forEach((item) => (item.selected = false));
        temp[id].selected = true;

        this.setState({
            [key]: temp,
        });
        if (filter !== '') {
            this.filterTitles(currDict, filter);
        }
    };

    selectSubject = (item) => {
        const { id, currentVal, dict, key } = item;

        this.setState({ subjectTitle: currentVal, currSubjectDict: dict }, () => this.resetThenSet(id, key, dict, 'units'));
    };

    selectStandard = (item) => {
        const { id, currentVal, dict, key } = item;

        this.setState({ standardTitle: currentVal, currStandardDict: dict }, () => this.resetThenSet(id, key, dict, 'subjects'));
    };

    selectGrade = (item) => {
        const { id, currentVal, dict, key } = item;

        this.setState({ gradeTitle: currentVal, currGradeDict: dict }, () => this.resetThenSet(id, key, dict, 'standards'));
    };

    selectUnit = (item) => {
        const { id, currentVal, dict, key } = item;

        this.setState({ unitTitle: currentVal, currUnitDict: dict }, () => this.resetThenSet(id, key, null, ''));
    };

    resetStandard = (currentVal) => {
        this.setState({ standardTitle: currentVal });
    };

    resetSubject = (currentVal) => {
        this.setState({ subjectTitle: currentVal });
    };

    resetGrade = (currentVal) => {
        this.setState({ gradeTitle: currentVal });
    };

    resetUnit = (currentVal) => {
        this.setState({ unitTitle: currentVal });
    };

    render() {
        if (this.state.error) {
            console.log(this.state.error);
            throw this.state.error;
        }
        return (
            <MainWrapper>
                <SearchFilter
                    isOpened={false}
                    currentVal={this.state.gradeTitle}
                    defaultVal="Select a grade"
                    itemList={this.state.grades}
                    select={this.selectGrade}
                    resetVal={this.resetGrade}
                ></SearchFilter>
                <SearchFilter
                    isOpened={false}
                    currentVal={this.state.standardTitle}
                    defaultVal="Select a standard"
                    itemList={this.state.standards}
                    select={this.selectStandard}
                    resetVal={this.resetStandard}
                ></SearchFilter>
                <SearchFilter
                    isOpened={false}
                    currentVal={this.state.subjectTitle}
                    defaultVal="Select a subject"
                    itemList={this.state.subjects}
                    select={this.selectSubject}
                    resetVal={this.resetSubject}
                ></SearchFilter>
                <SearchFilter
                    isOpened={false}
                    currentVal={this.state.unitTitle}
                    defaultVal="Select a strand"
                    itemList={this.state.units}
                    select={this.selectUnit}
                    resetVal={this.resetUnit}
                ></SearchFilter>
                {this.state.gradeTitle !== 'Select a grade' &&
                this.state.standardTitle !== 'Select a standard' &&
                this.state.subjectTitle !== 'Select a subject' &&
                this.state.unitTitle !== 'Select a strand' ? (
                    <RsButton ml={'15px'} height={'39px'} max-height={'39px'} padding-left={'0px'} onClick={this.handleClick}>
                        Search
                    </RsButton>
                ) : (
                    <Blank></Blank>
                )}
            </MainWrapper>
        );
    }
}

export default StandardsSearchBar;
