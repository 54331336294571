import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Cross from '../../Icons/Cross';

const MainWrapper = styled.div``;

const SelectedWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: var(--rsForm);
    padding: 8px;
    padding-bottom: 0px;
    border-radius: 30px;
    min-height: 40px;
    margin-bottom: 5px;
`;

const SelectedWrapper2 = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    padding-bottom: 0px;
    min-height: 40px;
    margin-bottom: 5px;
`;

const Selected = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: var(--rsSurface);
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;
    margin-bottom: 10px;
`;

const SelectedText = styled.p`
    margin: 0;
    margin-right: 10px;
    color: var(--rsPrimary);
`;

const Circle = styled.div`
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--rsSecondary);
`;

/**
 * A component to pick from a list of choices. At this time, TagSelect does not
 * sort the elements. We should include a comparator prop or build in comparators
 * based on element type to sort the selection.
 *
 * TODO: Add selection comparators.
 */
class TagSelect extends Component {
    constructor(props) {
        super(props);
        this.select = this.select.bind(this);
        this.remove = this.remove.bind(this);
    }

    /**
     * Select adds an element to selected and returns it to the parent component
     * by calling onChange.
     *
     * Precondition:
     * [ele] does not already exist in this.props.selected.
     *
     * @param {string|number} ele
     */
    select(ele) {
        const copy = [...this.props.selected]; // copy to ensure we don't mess with props
        copy.push(ele);
        this.props.onChange(copy);
    }

    /**
     * Remove removes an element from selected and returns the new selected array
     * to the parent component by calling onChange.
     *
     * Precondition:
     * [ele] does not already exist in this.props.selected.
     *
     * @param {string|number} ele
     */
    remove(ele) {
        const result = this.props.selected.filter((x) => x !== ele);
        this.props.onChange(result);
    }

    render() {
        const unselected = this.props.selection.filter((ele) => !this.props.selected.includes(ele));
        return (
            <MainWrapper>
                <p className="secondary bold" style={{ fontSize: 14, marginBottom: 5 }}>
                    Click all that apply:
                </p>
                <SelectedWrapper>
                    {unselected.map((ele) => (
                        <Selected key={'TAGSELECTITEM_SELECTED_' + ele}>
                            <button className="ghost" style={{ color: 'white' }} onClick={() => this.select(ele)}>
                                {ele}{' '}
                            </button>
                        </Selected>
                    ))}
                </SelectedWrapper>
                <p className="secondary bold" style={{ fontSize: 14, marginBottom: 5 }}>
                    Selected:
                </p>
                <SelectedWrapper2>
                    {this.props.selected.map((ele) => (
                        <Selected key={'TAGSELECTITEM_SELECTED_' + ele}>
                            <SelectedText>{ele}</SelectedText>
                            <Circle>
                                <Cross color="var(--rsBackground)" onClick={() => this.remove(ele)} size="10px" />
                            </Circle>
                        </Selected>
                    ))}
                </SelectedWrapper2>
            </MainWrapper>
        );
    }
}

TagSelect.propTypes = {
    /**
     * The list of elements that are chosen.
     */
    selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    /**
     * The list of elements that are in the total set (including currently
     * selected elements).
     */
    selection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    /**
     * The function that fires when a change is made (an item is selected or
     * unselected). The function has a single parameter, which is the changed
     * selection after an action is performed on the component.
     */
    onChange: PropTypes.func.isRequired,
};

export default TagSelect;
