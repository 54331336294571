import React, { Component } from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import PropTypes from 'prop-types';

import { device } from '../../../../constants/breakpoints';

const MainBtn = styled.button`
  min-height: ${(props) => (props.height ? props.height : props.large ? '62px' : '39px')};
  min-width: ${(props) => (props.width ? props.width : '150px')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  font-size: ${(props) => (props.large ? '24px' : '18px')};
  font-weight: bold;
  padding-left: ${(props) => (props.paddingHor ? props.paddingHor : '25px')};
  padding-right: ${(props) => (props.paddingHor ? props.paddingHor : '25px')};
  outline: none;
  border: none;
  border-radius: ${(props) => (props.large !== undefined ? (props.large ? '30px' : '20px') : props.borderRadius ? props.borderRadius : '20px')};

  color: ${(props) => props.textColor};
  margin-top: ${(props) => (props.mt ? props.mt : '0')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '0')};
  margin-left: ${(props) => (props.ml ? props.ml : '0')};
  margin-right: ${(props) => (props.mr ? props.mr : '0')};

  background: ${(props) => props.bg};
  transition: all 0.3s ease 0s;

  ${(props) => (props.hover ? '&:hover{ background:' + props.hover + ';}' : '')}

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

/**
 * The standard rapStudy button.
 */
class RsButton extends Component {
    render() {
        if (this.props.disabled) {
            return (
                <MainBtn
                    bg={'#000000'}
                    textColor={'white'}
                    disabled={true}
                    ml={this.props.ml}
                    mr={this.props.mr}
                    mt={this.props.mt}
                    mb={this.props.mb}
                    width={this.props.width}
                    height={this.props.height}
                    paddingHor={this.props.paddingHor}
                    large={this.props.large}
                >
                    {this.props.children}
                </MainBtn>
            );
        }

        var hex = this.props.secondary ? '#dadada' : this.props.landing ? '#8C2155' : '#a7225b';
        const textColor = this.props.secondary ? 'black' : 'white';
        const tColor = tinycolor(hex);
        const hover = tColor.darken(10).toHexString();

        if (this.props.pageLocation === 'banner') {
            hex = this.props.buttonColor;
        }

        return (
            <MainBtn
                bg={hex}
                hover={hover}
                onClick={this.props.onClick}
                textColor={textColor}
                ml={this.props.ml}
                mr={this.props.mr}
                mt={this.props.mt}
                mb={this.props.mb}
                width={this.props.width}
                height={this.props.height}
                paddingHor={this.props.paddingHor}
                large={this.props.large}
                borderRadius={this.props.borderRadius}
            >
                {this.props.children}
            </MainBtn>
        );
    }
}

RsButton.propTypes = {
    /**
     * The function that is called when the button is clicked, if it is not disabled.
     */
    onClick: PropTypes.func,
    /**
     * Is the button disabled from clicking?
     */
    disabled: PropTypes.bool,
    /**
     * Is the button a secondary action?
     */
    secondary: PropTypes.bool,
    /**
     * Is the button landing themed?
     */
    landing: PropTypes.bool,
    /**
     * Margin left
     */
    ml: PropTypes.string,
    /**
     * Margin right
     */
    mr: PropTypes.string,
    /**
     * Margin top
     */
    mt: PropTypes.string,
    /**
     * Margin bottom
     */
    mb: PropTypes.string,
    /**
     * Width. By default, width is at least 150px.
     */
    width: PropTypes.string,
    /**
     * Height. By default, height is at least 40px.
     */
    height: PropTypes.string,
    /**
     * Horizontal padding. By default, there is 25px of horizontal padding.
     */
    paddingHor: PropTypes.string,
    /**
     * Is the button a large size?
     */
    large: PropTypes.bool,
};

export default RsButton;
