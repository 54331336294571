import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Subjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEnter() {
        this.setState({ hover: true });
    }

    handleLeave() {
        this.setState({ hover: false });
    }

    render() {
        // const hover =
        //   typeof this.props.hover !== 'undefined'
        //     ? this.props.hover
        //     : this.state.hover;

        // const color = this.props.disabled
        //   ? 'var(--rsDisabled)'
        //   : hover
        //   ? 'var(--rsPrimary)'
        //   : 'var(--rsSecondary)';

        // const data = {
        //   Math: (
        //     <svg
        //       width={this.props.size ? this.props.size : '24px'}
        //       height={this.props.size ? this.props.size : '24px'}
        //       xmlns='http://www.w3.org/2000/svg'
        //       viewBox='0 0 18 18'
        //     >
        //       <rect
        //         x='1.58'
        //         y='1.58'
        //         width='14.84'
        //         height='14.84'
        //         rx='2.55'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='5.76'
        //         y1='4.17'
        //         x2='5.76'
        //         y2='7.29'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <line
        //         x1='4.2'
        //         y1='5.73'
        //         x2='7.32'
        //         y2='5.73'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <line
        //         x1='4.66'
        //         y1='11.4'
        //         x2='6.87'
        //         y2='13.61'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <line
        //         x1='4.66'
        //         y1='13.61'
        //         x2='6.87'
        //         y2='11.4'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <line
        //         x1='10.68'
        //         y1='5.68'
        //         x2='13.81'
        //         y2='5.68'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <line
        //         x1='10.68'
        //         y1='12.45'
        //         x2='13.81'
        //         y2='12.45'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <circle cx='12.24' cy='10.7' r='0.7' fill={color} />
        //       <circle cx='12.24' cy='14.31' r='0.7' fill={color} />
        //     </svg>
        //   ),
        //   Science: (
        //     <svg
        //       width={this.props.size ? this.props.size : '24px'}
        //       height={this.props.size ? this.props.size : '24px'}
        //       xmlns='http://www.w3.org/2000/svg'
        //       viewBox='0 0 18 18'
        //     >
        //       <ellipse
        //         cx='8.95'
        //         cy='8.72'
        //         rx='8.34'
        //         ry='3.71'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.3'
        //       />
        //       <ellipse
        //         cx='8.95'
        //         cy='8.72'
        //         rx='8.34'
        //         ry='3.71'
        //         transform='matrix(0.5, -0.87, 0.87, 0.5, -3.08, 12.12)'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.3'
        //       />
        //       <ellipse
        //         cx='8.95'
        //         cy='8.72'
        //         rx='3.71'
        //         ry='8.34'
        //         transform='translate(-3.16 5.65) rotate(-30)'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.3'
        //       />
        //       <circle
        //         cx='8.95'
        //         cy='8.72'
        //         r='1.56'
        //         fill='none'
        //         stroke={color}
        //         strokeMiterlimit='10'
        //         strokeWidth='1.3'
        //       />
        //     </svg>
        //   ),
        //   'Social Studies': (
        //     <svg
        //       width={this.props.size ? this.props.size : '24px'}
        //       height={this.props.size ? this.props.size : '24px'}
        //       xmlns='http://www.w3.org/2000/svg'
        //       viewBox='0 0 18 18'
        //     >
        //       <line
        //         x1='2.6'
        //         y1='3.52'
        //         x2='12.2'
        //         y2='3.52'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <path
        //         d='M14.13,15.53H5.59A1.58,1.58,0,0,1,4,14V5.09'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <line
        //         x1='13.78'
        //         y1='9.31'
        //         x2='13.78'
        //         y2='12.49'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <path
        //         d='M1.19,4.93A1.42,1.42,0,0,1,4,4.93'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <path
        //         d='M4,14.12a1.41,1.41,0,0,0,2.82,0V12.7'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <line
        //         x1='6.84'
        //         y1='12.7'
        //         x2='15.54'
        //         y2='12.7'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <polyline
        //         points='1.19 4.93 1.19 6.34 4.02 6.34'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <path
        //         d='M14.13,15.53a1.41,1.41,0,0,0,1.41-1.41V12.7'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <polyline
        //         points='16.4 4.59 12.33 8.66 10.62 6.95 14.69 2.88'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <polygon
        //         points='10.01 9.27 10.63 6.96 12.32 8.65 10.01 9.27'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <path
        //         d='M14.59,3a1.38,1.38,0,0,1,1.92-.21,1.38,1.38,0,0,1-.21,1.92Z'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //     </svg>
        //   ),
        //   'English Language Arts': (
        //     <svg
        //       width={this.props.size ? this.props.size : '24px'}
        //       height={this.props.size ? this.props.size : '24px'}
        //       xmlns='http://www.w3.org/2000/svg'
        //       viewBox='0 0 18 18'
        //     >
        //       <path
        //         d='M14.37,15.89H5.54c-.88,0-.72-.72-.72-1.59V5.74'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <rect
        //         x='4.82'
        //         y='2.11'
        //         width='9.54'
        //         height='11.75'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <polygon
        //         points='10.62 2.62 10.63 7.98 11.59 6.7 12.56 7.98 12.56 2.62 10.62 2.62'
        //         fill='#9b9b9b'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <path
        //         d='M5.84,15.89a1,1,0,1,1,0-2'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //     </svg>
        //   ),
        //   Health: (
        //     <svg
        //       width={this.props.size ? this.props.size : '24px'}
        //       height={this.props.size ? this.props.size : '24px'}
        //       xmlns='http://www.w3.org/2000/svg'
        //       viewBox='0 0 18 18'
        //     >
        //       <path
        //         d='M4.37,10.94,9,15.39,15.27,9a4,4,0,0,0,.79-2.41,3.9,3.9,0,0,0-3.82-4A3.81,3.81,0,0,0,9,4.52l0-.16A3.8,3.8,0,0,0,5.76,2.61a3.9,3.9,0,0,0-3.82,4,4,4,0,0,0,.67,2.24H5.83L7.2,6.57l2.41,4.37,1.26-2.19h1.42'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='2'
        //       />
        //     </svg>
        //   ),
        //   Technology: (
        //     <svg
        //       width={this.props.size ? this.props.size : '24px'}
        //       height={this.props.size ? this.props.size : '24px'}
        //       xmlns='http://www.w3.org/2000/svg'
        //       viewBox='0 0 18 18'
        //     >
        //       <circle
        //         cx='8.96'
        //         cy='9'
        //         r='7.48'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <polyline
        //         points='8.88 15.63 8.88 5.47 7.3 5.47'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <polyline
        //         points='10.66 15.63 10.66 10.14 13.05 10.14'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <polyline
        //         points='2.53 8.02 6.82 8.02 6.79 15.15'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <circle
        //         cx='13.45'
        //         cy='10.16'
        //         r='0.85'
        //         fill={color}
        //         stroke={color}
        //         strokeMiterlimit='10'
        //       />
        //       <polyline
        //         points='10.47 2.58 10.47 6.5 11.66 6.5'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <circle
        //         cx='12.86'
        //         cy='6.46'
        //         r='0.85'
        //         fill={color}
        //         stroke={color}
        //         strokeMiterlimit='10'
        //       />
        //       <polyline
        //         points='4.97 14.72 4.97 10.16 4.12 10.16'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='1.5'
        //       />
        //       <circle
        //         cx='6.22'
        //         cy='5.47'
        //         r='0.85'
        //         fill={color}
        //         stroke={color}
        //         strokeMiterlimit='10'
        //       />
        //     </svg>
        //   ),
        //   'Life Skills': (
        //     <svg
        //       width={this.props.size ? this.props.size : '24px'}
        //       height={this.props.size ? this.props.size : '24px'}
        //       xmlns='http://www.w3.org/2000/svg'
        //       viewBox='0 0 18 18'
        //     >
        //       <circle
        //         cx='8.95'
        //         cy='7.82'
        //         r='2.15'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='8.95'
        //         y1='4.34'
        //         x2='8.95'
        //         y2='5.96'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='8.95'
        //         y1='9.62'
        //         x2='8.95'
        //         y2='11.24'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='10.84'
        //         y1='7.82'
        //         x2='12.46'
        //         y2='7.82'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='5.49'
        //         y1='7.82'
        //         x2='7.11'
        //         y2='7.82'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='10.27'
        //         y1='6.44'
        //         x2='11.41'
        //         y2='5.3'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='6.48'
        //         y1='10.28'
        //         x2='7.62'
        //         y2='9.13'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='7.69'
        //         y1='6.44'
        //         x2='6.54'
        //         y2='5.3'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <line
        //         x1='11.41'
        //         y1='10.28'
        //         x2='10.27'
        //         y2='9.13'
        //         fill='none'
        //         stroke={color}
        //         strokeWidth='2'
        //       />
        //       <path
        //         d='M4.19,11.27a5.92,5.92,0,1,1,10.7-3.48'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeWidth='2'
        //       />
        //       <polyline
        //         points='14.89 7.79 16 10.22 14.55 10.22 14.55 12.99 11.95 12.99 11.95 15.94 4.19 15.94 4.19 11.27'
        //         fill='none'
        //         stroke={color}
        //         strokeLinecap='round'
        //         strokeLinejoin='round'
        //         strokeWidth='2'
        //       />
        //     </svg>
        //   ),
        // };

        return (
            <div
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                onClick={this.props.disabled ? null : this.props.onClick}
                style={{
                    cursor: this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {/* {data[this.props.subject]} */}
            </div>
        );
    }
}

Subjects.propTypes = {
    /**
     * The name of the subject.
     */
    subject: PropTypes.oneOf(['English Language Arts', 'Health', 'Life Skills', 'Math', 'Science', 'Social Studies', 'Technology']),
    /**
     * Should the icon take on disabled styling?
     */
    disabled: PropTypes.bool,
    /**
     * The function fired when the icon is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
    /**
     * A hover override from the parent component. If defined, the local
     * state hover is ignored.
     */
    hover: PropTypes.bool,
};

export default Subjects;
