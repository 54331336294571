// https://www.figma.com/file/yEqp78EvktVUTG9Pw9J211/rapStudy-web?node-id=5838%3A186
import React, { Component } from 'react';
import { withFirebase } from '../../../Firebase';
import AddShareModal from '../AddShareModal';

import CreateClassModal from '../CreateJoinClass/CreateClassModal';

/**
 * Props:
 * show: boolean
 * toggle: function that toggles the show/hide state of the component
 * contentType: string, Playlist / Review / Blanks / Breakdown / Song
 * contentId : string
 */
class ShareToClassModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errMsg: '',
            classes: null,
            showCreate: false,
            createdNew: false,
            hasFetched: false,
        };
        this.add = this.add.bind(this);
        this.returnClass = this.returnClass.bind(this);
        this.submit = this.submit.bind(this);
        this.handleShowCreate = this.handleShowCreate.bind(this);
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
    }

    async componentDidMount() {
        if (this.props.show) {
            const classes = await this.props.firebase.getAdminClasses();
            this.setState({ classes: classes, hasFetched: true });
        }
    }

    async componentDidUpdate() {
        if (this.state.hasFetched) return;
        if (!this.props.show) return;
        const classes = await this.props.firebase.getAdminClasses();
        this.setState({ classes: classes, hasFetched: true });
    }

    async add(classId) {
        if (!this.props.contentType || !this.props.contentId || !classId)
            throw new Error('Precondition Invalidated: props.contentType or props.contentId or classId is falsy.');

        const success = await this.props.firebase.addContentToClass(this.props.contentType, this.props.contentId, classId);
        if (!success) {
            this.setState({ errMsg: 'That content is already shared to that class' });
        }
        return success;
    }

    async returnClass(classObj) {
        const classes = this.state.classes;
        classes.unshift(classObj);
        // await this.add(classObj.classId);
        this.setState({ classes: classes });
    }

    submit() {
        this.setState({ showCreate: false });
        this.props.toggle();
    }

    handleShowCreate() {
        this.setState({ showCreate: !this.state.showCreate, errMsg: null });
        this.props.toggle();
    }

    handleModalShowHide() {
        this.props.toggle();
        this.setState({
            errMsg: null,
            showNewPlaylistModal: false,
        });
    }

    render() {
        return (
            <AddShareModal
                add={this.add}
                title={'Add ' + this.props.contentType + ' to Class'}
                type="Class"
                items={this.state.classes}
                toggle={this.handleModalShowHide}
                itemIdName="classId"
                errorMsg={this.state.errMsg}
                show={this.props.show}
                nameOrTitle="title"
                submit={this.submit}
                toggleNew={this.handleShowCreate}
                newPrompt={'New Class'}
            >
                <CreateClassModal show={this.state.showCreate} returnClass={this.returnClass} toggle={this.handleShowCreate} />
            </AddShareModal>
        );
    }
}

export default withFirebase(ShareToClassModal);
