import React, { Component } from 'react';

import PropTypes from 'prop-types';

class Incorrect extends Component {
    render() {
        return (
            <svg
                width={this.props.size ? this.props.size : '24px'}
                height={this.props.size ? this.props.size : '24px'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
                transform="rotate(45)"
            >
                <rect x="8.24" y="0.5" width="1.53" height="17" fill="#ff4848" stroke="#ff4848" strokeLinecap="round" strokeLinejoin="round" />
                <rect
                    x="8.24"
                    y="0.6"
                    width="1.53"
                    height="17"
                    transform="translate(-0.1 18.1) rotate(-90)"
                    fill="#ff4848"
                    stroke="#ff4848"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

Incorrect.propTypes = {
    /**
     * The size of the icon. Defaults to 24px.
     */
    size: PropTypes.string,
};

export default Incorrect;
