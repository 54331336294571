import React from 'react';
import styled from 'styled-components';
import Info from '../../../UI-Library/Icons/Info';
import CircleDots from '../../../UI-Library/Icons/CircleDots';
// import DropdownList from '../DropdownList/DropdownList';
//import Lightbulb from '../../../UI-Library/Icons/Lightbulb';
import Tooltip from '../../../UI-Library/Misc/Tooltip';
//import TooltipForLightbulb from '../../../UI-Library/Misc/TooltipForLightbulb';
import { withAlert } from 'react-alert';
import DefaultImg from '../../../UI-Library/ImageContainers/DefaultImg';
import BoxPreview from '../../../UI-Library/ImageContainers/BoxPreview';
import GoogleShareToClassRoom from 'google-classroom-share';
import PdfModal from '../../../UI-Library/Modals/PdfModal';
import Tabs from '../Tabs';
import { KARAOKE } from '../../../../constants/routes';
import PlayButton from '../../../UI-Library/Icons/PlayButton';

const MainWrapper = styled.div`
    //contains images, subContent, mainContent, dropdownList, icons and tabs
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 25px;
    margin-bottom: 28px;
    background: #222222;
    //background-color: beige;
    border-radius: 10px;
    overflow-x: clip;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
`;

const TitleWrapper = styled.div`
    //contains everything
    display: flex;
    width: 100%;
    position: relative;
    font-weight: bold;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    //margin-top: 50px;
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    //margin-top: 45px;
    position: relative;
`;

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 10px;
    position: relative;
`;

const ContentWrapper = styled.div`
    overflow: hidden;
    border-radius: 10px;
    margin-left: 13px;
    //width: 100%;
    max-width: 100%;
`;

const Content = styled.div`
    //contains the mainContent, the content can be scrolled up and down now
    max-height: 100%;
    //max-width: 100%;
    height: 95px;
    padding: 10px;
    background: #3d3d3d;
    text-overflow: ellipsis;
    position: relative;
    font-size: 18px;
    overflow-y: overlay;
    overflow-wrap: break-word;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
        width: 0px;
        border: 1px solid black;
    }
    display: flex;
`;

const SubContentWrapper1 = styled.div`
    //contains the subContent with larger font-szie
    font: Nunito;
    background: #222222;
    width: 90%;
    height: 21px;
    overflow: visible;
    text-overflow: ellipsis;
    //white-space: nowrap;
    font-size: 17px;
    position: relative;
    margin-left: 13px;
    font-weight: bold;
    &:hover {
        overflow: visible;
    }
    // top: 90%;
    // left: -17%;
`;

const SubContentWrapper2 = styled.div`
    //contains the subContent with smaller font-size
    background: #222222;
    width: 80%;
    height: 20px;
    margin-top: ${(props) => (props.length > 29 ? '30px' : '5px')};
    overflow: visible;
    //white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    position: relative;
    margin-left: 13px;
    font: Nunito;
    &:hover {
        overflow: visible;
    }
    // top: 98%;
    // left: -20%;
`;

const ImageDiv = styled.div`
    width: 180px;
    height: 180px;
`;

const TabsAndMainContentDiv = styled.div`
    display: inline-block;
    flex-direction: column;
    margin-left: 3px;
    width: 85%;
    //background-color: #BDB76B;
`;

const Select = styled.select`
//font-size: 12px;
//width: 400px;
positionL relative;
margin-left: 5px;
margin-right: 5px;
//width: ${(props) => (props.width ? props.width : '15em')};
// width: 22em;
height: 30px;
//height: ${(props) => (props.height ? props.height : 'auto')};
min-height: ${(props) => (props.minHeight ? props.minHeight : '40px')};
${(props) => ('max-height: ' + props.maxHeight + ';' ? props.maxHeight : '')}
background-color: #222222;
border-radius: 30px;
border: 0;
outline: 0;
color: #FFFFFF;
padding-left: 10px;
padding-right: 10px;
border: 2px solid rgba(255, 255, 255, 1) !important;
&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    background-color: #FFFFFF;
}
&:-ms-input-placeholder { /* Internet Explorer 10-11 */
  background-color: #FFFFFF;
}
&::-ms-input-placeholder { /* Microsoft Edge */
    background-color: #FFFFFF;
}
select > option {
  background: var(--rsForm);;
  background-color: #FFFFFF;
  color: #FFFFFF;
}
@media (min-width: 1600px) {
  width: 300px;
}
`;

const Option = styled.option`
    color: #ffffff;
`;

const TabWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

const TabsContainer = styled.div`
    background: #222222;
    //max-width: 100%;
    //max-height: 30px;
    //margin-right: 13px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 13px;
    text-overflow: ellipsis;
    font-size: 14px;
    font: Nunito;
    align-items: center;
    &::-webkit-scrollbar {
        display: none;
    }
    overflow-x: scroll;
    border-radius: 5px;
    @media (min-width: 1400px) {
        margin-top: 20px;
    }
`;

const ExpandButton = styled.button`
    position: relative;
    padding-left: 3px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    height: 20px;
    background: #222222;
    color: #528fff;
    text-decoration-line: underline;
    border: none;
`;

const ExpandAllWrapper = styled.div`
    margin-right: 3px;
`;

const ExpandAll = styled.div`
    display: flex;
    flex-direction: column;
`;

const SubtitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    min-width: -webkit-fill-available;
`;

const ExpandAllEach = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const A = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const DropDownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const DropdownListAndPlayButtonWrapper = styled.div`
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    margin-right: 1px;
`;

class StandardsViewBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active,
            topic: this.props.topic,
            subTopic: this.props.subTopic,
            subStandard: this.props.subStandard,
            subStandardDescription: this.props.subStandardDescription,
            expandAll: false,
            expandSubstandard: [],
            expandSubstandardDesp: [],
            songIds: this.props.songIds,
            songNames: [],
            value: this.props.songIds[0],
            curSongName: this.props.firstSong,
            img: null,
            songLink:
                KARAOKE +
                '?songId=' +
                //'http://localhost:3000/internal/songView/karaoke?songId=' +
                this.props.songIds[0],
            pdfWindow: false,
        };
        this.copyURL = this.copyURL.bind(this);
        this.handleClickOfCollapseAll = this.handleClickOfCollapseAll.bind(this);
        this.setStateOfExpandAllAndExpandSubstandardAndExpandSubstandardDesp = this.setStateOfExpandAllAndExpandSubstandardAndExpandSubstandardDesp.bind(this);
        this.logAnalyticsShare = this.logAnalyticsShare.bind(this);
        this.togglePdf = this.togglePdf.bind(this);
        this.iconsAndDropdown = this.iconsAndDropdown.bind(this);
    }

    togglePdf() {
        this.setState({ pdfWindow: !this.state.pdfWindow });
    }

    copyURL() {
        navigator.clipboard.writeText(window.location.href);
        this.props.alert.show('Link copied to clipboard.');
    }

    close = () => {
        this.setState({ listOpen: false });
    };

    handleSetActive = (i) => {
        this.setState({ active: i });
    };

    componentDidUpdate() {
        const { listOpen } = this.state;

        setTimeout(() => {
            if (listOpen) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0);
    }

    setStateOfExpandAllAndExpandSubstandardAndExpandSubstandardDesp(subStandard, subStandardDescription) {
        this.setState({ expandAll: true });
        this.setState({ expandSubstandard: subStandard });
        this.setState({ expandSubstandardDesp: subStandardDescription });
    }

    handleClick = (event) => {
        this.setStateOfExpandAllAndExpandSubstandardAndExpandSubstandardDesp(this.props.expandSubstandard, this.props.expandSubstandardDesp);
    };

    handleClickOfCollapseAll() {
        this.setState({ expandAll: false });
    }

    handleChange = (event) => {
        this.setState({
            value: this.state.songIds[event.target.value],
            songLink:
                KARAOKE +
                '?songId=' +
                //'http://localhost:3000/internal/songView/karaoke?songId=' +
                this.state.songIds[event.target.value],
            curSongName: this.state.songNames[event.target.value].split('by')[0],
        });
    };

    logAnalyticsShare() {
        this.props.firebase.analytics.logEvent('share-to-classroom', {
            contentType: 'Karaoke',
        });
    }

    async componentDidMount() {
        const songs = await Promise.allSettled(this.props.songIds.map((id) => this.props.firebase.getSong(id)));
        var titles = songs.map(function (song) {
            if (song.status === 'fulfilled') {
                return '"' + song.value.data().origTitle + '" by ' + song.value.data().origArtist[0];
            }
            return '';
        });
        titles = titles.filter((ele) => ele !== '');
        this.setState({ songNames: titles });
        var songName;
        var subtopicRef;
        const song = songs.find((song) => song.status === 'fulfilled');
        if (song !== undefined) {
            songName = song.value.data().origTitle;
            subtopicRef = song.value.data().subtopic.subtopicRef;
            this.setState({ curSongName: '"' + songName + '"' });
        }
        if (subtopicRef !== undefined) {
            const img = await this.props.firebase.getSongCover(subtopicRef.id);
            this.setState({ img: img });
        }
    }

    iconsAndDropdown() {
        return (
            <IconWrapper>
                <ItemWrapper>
                    <Tooltip placement="bottom" text="Info (Currently N/A)">
                        <Info size={'18px'} hover={true} onClick={this.togglePdf} />
                    </Tooltip>
                    <Tooltip placement="bottom" text="Share to Google classroom">
                        <GoogleShareToClassRoom
                            key={
                                // window.location.href + '_' + this.props.currFeature
                                this.state.songLink + '_karaoke'
                            }
                            itemType="material"
                            url={this.state.songLink}
                            title={`Karaoke: ${this.state.subTopic} based on ${this.state.curSongName.substring(1).slice(0, -1)}`}
                            theme="dark"
                            size={18}
                            onShare={(type) => console.log(`GoogleShareToClassRoom:${type}`)}
                            onShareComplete={() => {
                                console.log('GoogleShareToClassRoom:onShareComplete:Karaoke');
                                this.props.logAnalyticsShare();
                            }}
                            onShareStart={() => console.log('GoogleShareToClassRoom:onShareStart')}
                        />
                    </Tooltip>
                    <Tooltip placement="bottom" text="Options (Currently N/A)">
                        <CircleDots size={'18px'} hover={true} onClick={() => null} />
                    </Tooltip>
                </ItemWrapper>
                {/* <TooltipForLightbulb
                  placement='top'
                  text={'Additional Standard (Currently N/A)'}
                >
                  <Lightbulb
                    size={'20px'}
                    disabled={false}
                    hover={true}
                    onClick={() => null}
                  />
                </TooltipForLightbulb> */}

                <Select onChange={this.handleChange}>
                    {this.state.songNames.map((item, index) => (
                        <Option key={item.id} value={index} name={item} onChange={this.handleChange}>
                            {'Based on: ' + item}
                        </Option>
                    ))}
                </Select>
            </IconWrapper>
        );
    }

    render() {
        return (
            <TitleWrapper>
                <MainWrapper>
                    <A>
                        <div>
                            <ImageDiv
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '3px',
                                }}
                            >
                                {/* <DefaultImg /> */}
                                {this.state.img ? <BoxPreview image={this.state.img} /> : <DefaultImg />}

                                {/* <img src = {this.state.defaultImageSource}
              alt = "new"/> */}
                            </ImageDiv>
                            {!this.state.expandAll ? (
                                <ExpandButton onClick={this.handleClick}>Show all standards</ExpandButton>
                            ) : (
                                <ExpandButton onClick={this.handleClickOfCollapseAll}>Hide all standards</ExpandButton>
                            )}
                        </div>

                        <TabsAndMainContentDiv>
                            <SubtitleWrapper>
                                {' '}
                                <DropDownWrapper>
                                    <SubContentWrapper1>{this.state.subTopic}</SubContentWrapper1>
                                    <SubContentWrapper2 length={this.state.subTopic.length}>{this.state.topic}</SubContentWrapper2>
                                </DropDownWrapper>
                                <this.iconsAndDropdown />
                                <DropdownListAndPlayButtonWrapper>
                                    <a href={this.state.songLink}>
                                        <PlayButton />
                                    </a>
                                </DropdownListAndPlayButtonWrapper>
                            </SubtitleWrapper>

                            <TabsContainer>
                                <Tabs active={this.state.active} setActive={(i) => this.handleSetActive(i)} tabs={this.state.subStandard} />{' '}
                                {/* this.state.tabTitles */}
                            </TabsContainer>

                            <ContentWrapper>
                                <Content>
                                    {Object.keys(this.state.subStandardDescription).map((key, index) => {
                                        if (index === this.state.active) {
                                            return <TabWrapper>{this.state.subStandardDescription[index]}</TabWrapper>;
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Content>
                            </ContentWrapper>
                        </TabsAndMainContentDiv>
                    </A>

                    <div>
                        {this.state.expandAll ? (
                            <ExpandAllWrapper>
                                <ExpandAll>
                                    {this.state.expandSubstandardDesp.map((item, index) => (
                                        <ExpandAllEach>
                                            <div>{this.state.expandSubstandard[index]}</div>
                                            <div>{item}</div>
                                        </ExpandAllEach>
                                    ))}
                                </ExpandAll>
                            </ExpandAllWrapper>
                        ) : null}
                    </div>
                </MainWrapper>

                <PdfModal
                    show={this.state.pdfWindow}
                    feature={'PDF'}
                    onHide={this.togglePdf}
                    showCross={false}
                    showFooter={true}
                    pdfLink={this.props.pdfLink}
                />
            </TitleWrapper>
        );
    }
}
const StandardsView = withAlert()(StandardsViewBase);
export default StandardsView;
