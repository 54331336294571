import React from 'react';
import styled from 'styled-components';
import '../../../../../src/index.css';
import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
    padding: 7%;
    padding-top: 3%;
    background: white;
    font-family: 'Times New Roman';
`;

const SubHeader = styled.h1`
    color: ${(props) => (props.color ? props.color : 'black')};
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    margin: 2px;
    @media ${device.tablet} {
        font-size: 14px;
        margin-bottom: 20px;
    }
    @media ${device.mobileL} {
        font-size: 12px;
        margin-bottom: 10px;
    }
`;

const Thumbnail = styled.img`
    width: auto;
    height: auto;
    background: grey;
    margin-bottom: 5px;
    border-radius: 8px;
    @media ${device.mobileL} {
        height: 150px;
    }
`;

const HeaderImg = styled(Thumbnail)`
    && {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Date = styled.p`
    color: gray;
    margin-bottom: 5px;
    margin-right: 20px;
    font-size: 12px;
    @media ${device.tablet} {
        font-size: 10px;
    }
    @media ${device.mobileL} {
        font-size: 10px;
    }
`;

const Desc = styled.p`
    color: black;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-bottom: 8px;
    font-weight: 300;
    font-size: 24px;
    @media ${device.tablet} {
        font-size: 14px;
    }
    @media ${device.mobileL} {
        font-size: 12px;
    }
`;

const ReadMore = styled.a`
    color: gray;
    text-decoration: underline;
    text-decoration-color: gray !important;
    &:hover {
        color: gray;
        text-decoration: underline;
        cursor: pointer;
    }
    font-size: 12px;
    @media ${device.tablet} {
        font-size: 10px;
    }
    @media ${device.mobileL} {
        font-size: 10px;
    }
`;

const Divider = styled.hr`
    width: 99%;
    border: ${(props) => (props.border ? props.border : '1px solid #889097')};
    margin-top: ${(props) => (props.mt ? props.mt : '10px')};
    margin-bottom: ${(props) => (props.mb ? props.mb : '10px')};
`;

const ArticleWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const SideWrapper = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const MiddleWrapper = styled(SideWrapper)`
    && {
        width: 20%;
    }
`;
const DateMoreWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const VerticalLine = styled.div`
    display: inline-block;
    position: relative;
    width: 1px;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
    border-left: ${(props) => (props.bl ? props.bl : '1px solid #889097')};
`;

const Header = styled.div`
    margin-bottom: 10px;
    color: black;
`;

class AboutNews extends React.Component {
    render() {
        const leftColData = [
            {
                thumbSrc:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fchronicle.jpg?alt=media&token=2047b9cd-ca43-4ac3-aeab-38c1c271d331',
                date: 'Nov. 3, 2020',
                publisher: 'Cornell Chronicle',
                subHeader: "Undergrads promote children's learning with rapStudy",
                desc:
                    'Pop singer Ariana Grande could have never imagined that her song “7 Rings” would one day be used to help middle-schoolers learn about the Electoral College. But that’s just what it’s doing in the hands of Cosimo Fabrizio ’22 and Drew Speckman ’21, co-founders of rapStudy, which pairs popular song melodies with new lyrics meant to help elementary and middle schoolers learn about everything from civics to the scientific method. “We know this has the capacity to change American education by changing the relationship that kids have with the learning process,” said Fabrizio, an economics and government double major in the College of Arts and Sciences. Speckman is a student in the ILR School who’s minoring in business and history.',
                link: 'https://news.cornell.edu/stories/2020/11/undergrads-promote-childrens-learning-rapstudy',
            },
        ];
        const midColData = [
            {
                thumbSrc:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2FLive_Demo_at_South_Hill_.jpg?alt=media&token=e30639d2-68c4-4754-966c-0c54fa13b6c3',
                date: 'Jan. 27, 2021',
                publisher: 'Ithaca Journal',
                subHeader: 'rapStudy is pairing popular music with educational lyrics',
                desc: 'Cornell edtech startup mixes pop songs, educational lyrics to amplify learning',
                link:
                    'https://www.ithacajournal.com/story/news/local/2021/01/27/cornell-ithaca-ny-startup-rapstudy-changes-lyrics-music-amplify-learning/6270423002/',
            },
            {
                thumbSrc:
                    'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Ffox40-2.png?alt=media&token=4de5283b-b44c-48ce-92c7-072ecdbd6015',
                date: 'Nov. 13, 2020',
                subHeader: 'Educational parodies of modern songs are helping kids stay engaged',
                publisher: 'Fox 40',
                desc: 'Cornell Student-Run Company Turns Pop Songs Into Teaching Tools',
                link: 'https://www.wicz.com/story/42909901/cornell-studentrun-company-turns-pop-songs-into-teaching-tools',
            },
        ];
        const rightColUpdates = [
            {
                header: 'Future Founders',
                desc: 'Founder and CEO Drew Speckman named as one of the entrepreneurs in the Future Founders 2021 National Fellowship Cohort.',
            },
            {
                header: 'New Standards Alignment feature',
                desc: 'Teachers now have easy access to education-critical standards!',
            },
            {
                header: 'Edtech industry booming in 2021',
                desc: 'U.S. edtech companies raised a record $2.2 billion in funding last year, and growth is continuing.',
            },
        ];
        const rightColSoon = [
            {
                header: 'Mobile App',
                desc: 'Our mobile app is soon to launch!',
            },
            {
                header: 'Redesigned home page',
                desc: 'Check out all our cool new layouts and features!',
            },
            {
                header: 'Automated lyric timing',
                desc: 'The scrolling karaoke lyrics timing process will now be streamlined.',
            },
        ];
        const leftCol = leftColData[0];
        const midColFirst = midColData[0];
        const midColSecond = midColData[1];
        return (
            <MainWrapper>
                <HeaderImg src="https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Ftherapstudynews.png?alt=media&token=804d82c8-bbc1-4586-909d-c9ea6e4801f1" />
                <Divider />
                <ArticleWrapper>
                    <SideWrapper>
                        <div style={{ color: 'black' }}>Latest News</div>
                        <Thumbnail src={leftCol.thumbSrc} />
                        <SubHeader>{leftCol.subHeader}</SubHeader>
                        <Desc>{leftCol.desc}</Desc>
                        <DateMoreWrapper>
                            <Date>{leftCol.date}</Date>
                            <ReadMore href={leftCol.link} target="_blank">
                                Read more
                            </ReadMore>
                        </DateMoreWrapper>
                    </SideWrapper>
                    <VerticalLine bl="1px solid white" />
                    <MiddleWrapper>
                        <div>Latest News</div>
                        <Thumbnail src={midColFirst.thumbSrc} />
                        <SubHeader>{midColFirst.subHeader}</SubHeader>
                        <Desc>{midColFirst.desc}</Desc>
                        <DateMoreWrapper>
                            <Date>{midColFirst.date}</Date>
                            <ReadMore href={midColFirst.link} target="_blank">
                                Read more
                            </ReadMore>
                        </DateMoreWrapper>
                        <Thumbnail src={midColSecond.thumbSrc} />
                        <SubHeader>{midColSecond.subHeader}</SubHeader>
                        <Desc>{midColSecond.desc}</Desc>
                        <DateMoreWrapper>
                            <Date>{midColSecond.date}</Date>
                            <ReadMore href={midColSecond.link} target="_blank">
                                Read more
                            </ReadMore>
                        </DateMoreWrapper>
                    </MiddleWrapper>
                    <VerticalLine />
                    <SideWrapper>
                        <Header>Company Updates</Header>
                        {rightColUpdates.map((ele, index) =>
                            index === rightColUpdates.length - 1 ? (
                                <SubHeader color="gray">
                                    {'0' + (index + 1) + '  ' + ele.header}
                                    <Desc>{ele.desc}</Desc>
                                </SubHeader>
                            ) : (
                                <SubHeader color="gray">
                                    {'0' + (index + 1) + '  ' + ele.header}
                                    <Desc>{ele.desc}</Desc>
                                    <Divider mb="5px" mt="5px" border="1px solid #889097" />
                                </SubHeader>
                            ),
                        )}
                        <Header>Coming soon!</Header>
                        {rightColSoon.map((ele, index) =>
                            index === rightColSoon.length - 1 ? (
                                <SubHeader color="gray">
                                    {'0' + (index + 1) + '  ' + ele.header}
                                    <Desc>{ele.desc}</Desc>
                                </SubHeader>
                            ) : (
                                <SubHeader color="gray">
                                    {'0' + (index + 1) + '  ' + ele.header}
                                    <Desc>{ele.desc}</Desc>
                                    <Divider mb="5px" mt="5px" border="1px solid #889097" />
                                </SubHeader>
                            ),
                        )}
                    </SideWrapper>
                </ArticleWrapper>
            </MainWrapper>
        );
    }
}

export default AboutNews;
