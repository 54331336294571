import BlanksSubmission from '../../Classes/Assignments/BlanksSubmission';
import ReviewSubmission from '../../Classes/Assignments/ReviewSubmission';
import Keyword from '../../Classes/Keyword';
import Question from '../../Classes/Question';
import Song from '../../Classes/Song';

export async function getSetsForAssignment(assignmentId) {
    const features = {
        Review: ReviewSubmission.setSubmissionConverter,
        Blanks: BlanksSubmission.setSubmissionConverter,
    };
    const assignmentRef = this.db.collection('assignments').doc(assignmentId);

    const queries = await Promise.all(
        Object.keys(features).map((featureName) =>
            assignmentRef.collection('sets').orderBy('index').where('type', '==', featureName).withConverter(features[featureName]).get(),
        ),
    );
    const result = [];
    queries.forEach((querySnap) => {
        querySnap.forEach((docSnap) => {
            result.push(docSnap.data());
        });
    });
    return result;
}

export async function getSubmissionsForStudent(assignmentId, studentId) {
    const features = {
        Review: ReviewSubmission.setSubmissionConverter,
        Blanks: BlanksSubmission.setSubmissionConverter,
    };
    const queries = await Promise.all(
        Object.keys(features).map((featureName) =>
            this.db
                .collection('setSubmissions')
                .where('studentId', '==', studentId)
                .where('assignmentId', '==', assignmentId)
                .where('type', '==', featureName)
                .orderBy('index')
                .withConverter(features[featureName])
                .get(),
        ),
    );
    const result = [];
    queries.forEach((querySnap) => {
        querySnap.forEach((docSnap) => result.push(docSnap.data()));
    });
    return result;
}

export async function getSubmissionsForAssignment(assignmentId) {
    const features = {
        Review: ReviewSubmission.setSubmissionConverter,
        Blanks: BlanksSubmission.setSubmissionConverter,
    };
    const queries = await Promise.all(
        Object.keys(features).map((featureName) => {
            return this.db
                .collection('setSubmissions')
                .where('assignmentId', '==', assignmentId)
                .where('teacherId', '==', this.auth.currentUser.uid)
                .where('type', '==', featureName)
                .orderBy('index')
                .withConverter(features[featureName])
                .get();
        }),
    );
    const result = [];
    queries.forEach((querySnap) => {
        querySnap.forEach((docSnap) => result.push(docSnap.data()));
    });
    return result;
}

export async function getAssignment(assignmentId) {
    const assignmentRef = this.db.collection('assignments').doc(assignmentId);
    const snap = await assignmentRef.get();
    if (!snap.exists) throw this.NotFoundError;
    const data = snap.data();
    data.ref = snap.ref;
    data.releaseDate = data.releaseDate.toDate();
    data.dueDate = data.dueDate.toDate();
    return data;
}

export async function getPastAssignmentsFollowed() {
    const colRef = this.db.collection('assignmentsFollowed');
    const query = colRef.where('studentId', '==', this.auth.currentUser.uid).where('dueDate', '<', new Date());
    const qSnap = await query.get();
    return qSnap.docs.map((docSnap) => docSnap.data());
}

export async function getAllClassAssignments(classId) {
    const querySnap = await this.db.collection('assignments').where('classId', '==', classId).orderBy('dueDate').get();

    const assignmentsResult = querySnap.docs.map((item) => {
        const itemData = item.data();
        itemData.ref = item.ref;
        itemData.releaseDate = itemData.releaseDate.toDate();
        itemData.dueDate = itemData.dueDate.toDate();
        return itemData;
    });

    return assignmentsResult;
}

export async function saveSubmission(submission) {
    const setSubmissionRef = this.db.collection('setSubmissions').doc(submission.getSubmissionId());
    const converter = submission.getFeatureName() === 'Review' ? ReviewSubmission.setSubmissionConverter : BlanksSubmission.setSubmissionConverter;

    await setSubmissionRef.withConverter(converter).set(submission);
}

/**
 *
 * Precondition: Set ids within each set must be valid, existing ids.
 * @param {Array<StudySet>} sets
 * @param {string} name
 * @param {string} classId
 */
export async function createAssignment(sets, name, desc, dueDate, releaseDate, classId) {
    const global = this.db;
    const assignmentsRef = this.db.collection('assignments');
    const batch = this.db.batch();

    //New assignment document has been created
    const newAssignmentDoc = assignmentsRef.doc();
    const newAssignmentId = newAssignmentDoc.id;

    const numSets = sets.length;

    //We need to loop through each set that we wish to add to our assinment to both
    // A) retrieve the information to copy over and B) actually copy it over.

    const songObjs = await Promise.all(
        sets.map(async (set, index) => {
            const setId = set.getSetId();
            const featureName = set.getFeatureName();
            const songId = set.getSongId();

            const songSnap = await global.collection('songs').doc(songId).withConverter(Song.songConverter).get();
            const songObj = songSnap.data();

            //Lets initialize a new set document for our assignment.
            const newSetDoc = newAssignmentDoc.collection('sets').doc();
            const newSetDocId = newSetDoc.id;
            let masterSub;
            let submissionToSet;

            if (featureName === 'Review') {
                masterSub = new ReviewSubmission(
                    false,
                    set.getName(),
                    'not started',
                    0,
                    0,
                    newSetDocId,
                    this.auth.currentUser.uid,
                    '',
                    set.getSetId(),
                    newAssignmentId,
                    '',
                    classId,
                    songId,
                    songObj,
                    set.getMode(),
                    releaseDate,
                    dueDate,
                    index,
                );
                const questionQuerySnap = await global
                    .collection('questionSets')
                    .doc(setId)
                    .collection('questions')
                    .withConverter(Question.questionConverter)
                    .get();
                questionQuerySnap.forEach((snap) => masterSub.add(snap.data()));
                submissionToSet = ReviewSubmission.setSubmissionConverter.toFirestore(masterSub);
            } else if (featureName === 'Blanks') {
                masterSub = new BlanksSubmission(
                    false,
                    set.getName(),
                    'not started',
                    0,
                    0,
                    newSetDocId,
                    this.auth.currentUser.uid,
                    '',
                    set.getSetId(),
                    newAssignmentId,
                    '',
                    classId,
                    songId,
                    songObj,
                    set.getMode(),
                    releaseDate,
                    dueDate,
                    index,
                );
                const keywordQuerySnap = await global.collection('keywordSets').doc(setId).collection('keywords').withConverter(Keyword.keywordConverter).get();
                keywordQuerySnap.forEach((snap) => masterSub.addKeywordInstance(snap.data()));
                submissionToSet = BlanksSubmission.setSubmissionConverter.toFirestore(masterSub);
            }

            batch.set(newSetDoc, submissionToSet);
            this.analytics.logEvent('setSubmission_create', {
                length: masterSub.getLength(),
                featureName: featureName,
                mode: masterSub.getMode(),
            });

            return songObj;
        }),
    );

    const firstSong = songObjs[0];
    const songSubject = firstSong.subject.name;
    const url = await this.getSongCover(firstSong.subtopic.subtopicRef.id);

    //Set the initial assignment details
    batch.set(newAssignmentDoc, {
        name: name,
        description: desc,
        dueDate,
        releaseDate,
        src: url,
        subject: songSubject,
        classId: classId,
        studentsCompletedCount: 0,
        createdBy: this.auth.currentUser.uid,
        numSets: numSets,
    });

    this.analytics.logEvent('assignment_create', {
        length: sets.length,
    });

    await batch.commit();
}

export async function editAssignment(assignmentRef, name, desc, release, due) {
    await assignmentRef.update({
        name: name,
        description: desc,
        releaseDate: release,
        dueDate: due,
    });

    return;
}

export async function deleteAssignment(assignmentId) {
    await this.db.collection('assignments').doc(assignmentId).delete();
}
